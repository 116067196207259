import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Button from 'components/button'
import LoginForm, { IFormValues } from 'views/auth/components/LoginForm'
import { useApplicationStore } from 'hooks/application'
import { useAuth } from 'hooks/auth'
import api, { APIError, ApiErrorCodes } from 'helpers/api'
import { HOME, TWO_FACTOR_SIGNIN } from 'routes'
import { getCurrentUser } from 'helpers/auth'
import constants from 'style/constants.module.scss'
import { redirectTo } from 'helpers/redirect'

const SignIn: React.FC = () => {
  const { login, startMicrosoftAuth, startGoogleAuth } = useAuth()
  const { push } = useHistory()
  const { setSnackbarMessage } = useApplicationStore()
  const [needVerification, setNeedVerification] = React.useState(false)
  document.title = 'Morta | Sign In'

  useEffect(() => {
    if (getCurrentUser()) {
      push(HOME)
    }
  }, [])

  const handleSubmit = async (values: IFormValues) => {
    if (values.email && values.password) {
      try {
        setNeedVerification(false)
        await login(values.email, values.password)
        push(redirectTo(HOME))
      } catch (e) {
        if (e instanceof APIError) {
          if (e.code === ApiErrorCodes.ACCOUNT_NOT_VERIFIED) {
            setNeedVerification(true)
          } else if (e.code === ApiErrorCodes.TWO_FACTOR_REQUIRED) {
            const response = await api.getTempUserToken(values.email, values.password)
            push(`${TWO_FACTOR_SIGNIN}?loginToken=${response.data.code}`)
          } else {
            setSnackbarMessage({ message: e.message, status: 'error' })
          }
        }
      }
    }
  }

  const handleSignUpWithProvider = async (name: string) => {
    switch (name) {
      case 'microsoft':
        startMicrosoftAuth()
        break
      case 'google':
        startGoogleAuth()
        break
      default:
    }
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-12 text-2xl">Welcome back, it is good to see you.</h1>
        <div>
          <Button
            internalType="outline"
            className="w-full text-base"
            style={{
              marginBottom: '10px'
            }}
            onClick={() => handleSignUpWithProvider('microsoft')}
          >
            <img style={{ marginRight: '10px' }} src="/assets/images/microsoft.svg" alt="microsoft logo" />
            Sign in with Microsoft
          </Button>
          <Button
            internalType="outline"
            className="w-full text-base"
            onClick={() => handleSignUpWithProvider('google')}
            style={{
              border: '1px solid black',
              background: 'white',
              marginBottom: '10px',
              color: constants.textPrimary
            }}
          >
            <img style={{ marginRight: '10px' }} src="/assets/images/google.svg" alt="google logo" />
            Sign in with Google
          </Button>
        </div>
        <div className="my-20px text-lg">Or continue with email</div>

        <LoginForm onSubmit={handleSubmit} />
        {needVerification && (
          <div className="mt-4 p-4 bg-light-grey">
            This account must be verified first, please check your email, or{' '}
            <Link to={`/verify-email`}>send an email verification code</Link>
          </div>
        )}

        <br />

        <div className="mb-2">
          <Link to="forgot-password">Forgot Password?</Link>
        </div>

        <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
          Don&#39;t have an account? <Link to="signup">Sign Up</Link>
        </p>
      </div>
    </div>
  )
}

export default SignIn
