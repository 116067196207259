import React, { useState } from 'react'
import { useApplicationStore } from 'hooks/application'
import api from 'helpers/api'
import Menu, { MenuProps } from 'components/menu'
import { Copy, Delete, Plus, Text, Link, PageBreak } from 'components/icons'
import { useDataContext } from 'components/process/contexts/data'
import useProject from 'hooks/project'
import Toggle from 'components/toggle'
import { IProcessSectionObject } from 'types'
import { addSectionToProcess, findSectionParent } from 'components/process/helpers/functions'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

interface SettingsMenuProps extends MenuProps {
  section: IProcessSectionObject
  setDeleteSectionModal: (open: boolean) => void
  addSection: (parentId: string | null) => void
  sectionNumber: string
}

const Settings: React.FC<SettingsMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  section,
  setDeleteSectionModal,
  addSection,
  sectionNumber
}) => {
  const { process, updateProcess, isAdmin } = useDataContext()
  const { project } = useProject()
  const [duplicatingProcessSection, setDuplicatingProcessSection] = useState<{
    isDuplicating: boolean
    status: string | null
  }>({
    isDuplicating: false,
    status: null
  })
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const updateProcessSection = (
    sectionId: string,
    children: IProcessSectionObject[],
    sectionUpdated: IProcessSectionObject
  ): IProcessSectionObject[] => {
    children.forEach((child, index) => {
      if (child.publicId === sectionUpdated?.publicId) {
        children[index] = sectionUpdated
        return children
      } else {
        return updateProcessSection(sectionId, child.children, sectionUpdated)
      }
    })
    return children
  }

  const updateDescriptionDownload = (inDownload: boolean) => {
    if (process) {
      api
        .updateProcessSection({
          name: section.name,
          description: section.description,
          processId: process.publicId,
          processSectionId: section.publicId,
          pdfIncludeDescription: inDownload,
          pdfIncludeSection: section.pdfIncludeSection,
          pageBreakBefore: section.pageBreakBefore,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          updateProcess({
            ...process,
            children: updateProcessSection(section.publicId, process.children, response.data)
          })
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  const updateSectionDownload = (inDownload: boolean) => {
    if (process) {
      api
        .updateProcessSection({
          name: section.name,
          description: section.description,
          processId: process.publicId,
          processSectionId: section.publicId,
          pdfIncludeDescription: section.pdfIncludeDescription,
          pdfIncludeSection: inDownload,
          pageBreakBefore: section.pageBreakBefore,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          updateProcess({
            ...process,
            children: updateProcessSection(section.publicId, process.children, response.data)
          })
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  const updatePageBreakBefore = (pageBreakBefore: boolean) => {
    if (process) {
      api
        .updateProcessSection({
          name: section.name,
          description: section.description,
          processId: process.publicId,
          processSectionId: section.publicId,
          pdfIncludeDescription: section.pdfIncludeDescription,
          pdfIncludeSection: section.pdfIncludeSection,
          pageBreakBefore,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          updateProcess({
            ...process,
            children: updateProcessSection(section.publicId, process.children, response.data)
          })
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  const duplicateSection = (): void => {
    if (process && section) {
      if (!duplicatingProcessSection.isDuplicating) {
        setDuplicatingProcessSection({ isDuplicating: true, status: null })
        api
          .duplicateProcessSection(project.publicId, process.publicId, section.publicId)
          .then((response) => {
            let parentId = findSectionParent(section.publicId, process)
            if (parentId === process.publicId) parentId = null
            updateProcess({ ...process, children: addSectionToProcess(parentId, process.children, response.data) })
            setDuplicatingProcessSection({ isDuplicating: false, status: 'success' })
            setSnackbarMessage({ status: 'success' })
          })
          .catch((error) => {
            displayErrorMessage(error)
            setDuplicatingProcessSection({ isDuplicating: false, status: 'error' })
          })
      }
    }
  }

  const copySectionLink = () => {
    try {
      const params = new URLSearchParams(window.location.search)
      params.set('section', section.publicId)
      const newUrl = window.location.origin + window.location.pathname + '?' + params.toString()
      navigator.clipboard.writeText(newUrl)
      setSnackbarMessage({
        status: 'success',
        message: `Copied to clipboard`
      })
      setMenuState(INITIAL_CONTEXT_MENU_STATE)
    } catch (error) {
      displayErrorMessage(error)
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
        <div className="w-full bg-light-grey font-bold p-10px text-base border-b-1px border-solid border-grey truncate">
          {sectionNumber} {section.name}
        </div>

        <div className="border-b-1px border-solid border-grey">
          <li>
            <div
              className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
              style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
              onClick={() => copySectionLink()}
            >
              <Link />
              <span className="ml-10px">Copy Link To Section</span>
            </div>
          </li>
          {isAdmin && (
            <div>
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    addSection(section.publicId)
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }}
                  data-cy="download-word"
                >
                  <Plus />
                  <span className="ml-10px">Add Subsection</span>
                </div>
              </li>
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    duplicateSection()
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }}
                  data-cy="download-word"
                >
                  <Copy />
                  <span className="ml-10px">Duplicate Section</span>
                </div>
              </li>
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                  onClick={() => {
                    setDeleteSectionModal(true)
                    setMenuState(INITIAL_CONTEXT_MENU_STATE)
                  }}
                >
                  <Delete />
                  <span className="ml-10px">Delete Section</span>
                </div>
              </li>
              <div
                className="w-full bg-light-grey font-bold p-10px text-base border-solid border-grey"
                style={{ borderWidth: '1px 0px 1px 0px' }}
              >
                Export Settings
              </div>
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                >
                  <Text />
                  <span className="ml-10px">Include Entire Section</span>
                  <Toggle
                    toggled={section.pdfIncludeSection}
                    onToggle={(toggle) => {
                      updateSectionDownload(toggle)
                    }}
                  />
                </div>
              </li>
              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                >
                  <Text />
                  <span className="ml-10px">Include Section Description</span>
                  <Toggle
                    toggled={section.pdfIncludeDescription ? true : false}
                    onToggle={(toggle) => {
                      updateDescriptionDownload(toggle)
                    }}
                  />
                </div>
              </li>

              <li>
                <div
                  className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
                  style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
                >
                  <PageBreak />
                  <span className="ml-10px">Page Break Before Section</span>
                  <Toggle
                    toggled={section.pageBreakBefore ? true : false}
                    onToggle={(toggle) => {
                      updatePageBreakBefore(toggle)
                    }}
                  />
                </div>
              </li>
            </div>
          )}
        </div>
      </div>
    </Menu>
  )
}

export default Settings
