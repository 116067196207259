import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { history } from 'helpers/history'
import { useAuth } from 'hooks/auth'
import { useProject } from 'hooks/project'
import { SIGN_IN } from 'routes'
import UserMenu from 'components/sidebar/components/usermenu'
import Search from 'components/search'
import Folders from 'components/sidebar/components/folders'
import useApplicationStore from 'hooks/application'
import { tint } from 'style/utils'
import constants from 'style/constants.module.scss'
import {
  Search as SearchIcon,
  Settings as SettingsIcon,
  Home,
  DoubleChevronLeft,
  HamburgerMenu
} from 'components/icons'
import { IContextMenuState } from 'types'
import { DEFAULT_SIDEBAR_WIDTH, INITIAL_CONTEXT_MENU_STATE, ProjectRoles } from 'app-constants'
import Modal from 'components/modal'
import InviteUserForm from 'views/settings/components/inviteUserForm'
import Templates from 'components/templates'
import { capitaliseFirstLetter } from 'helpers/utils'
import { PROJECT } from 'app-constants'

const AppSidebar: React.FC = () => {
  const { user } = useAuth()
  const { project, search, openSearch, closeSearch } = useProject()
  const { setSnackbarMessage, templatesModal, setTemplatesModal } = useApplicationStore()
  const {
    sidebarWidth,
    minSidebarWidth,
    maxSidebarWidth,
    minSidebarWidthDisplay,
    setSidebarWidth
  } = useApplicationStore()
  const dragging = useRef<boolean>(false)
  const mouseStart = useRef<number>(0)
  const originalWidth = useRef<number>(sidebarWidth)

  const view = history.location.pathname
  const [hideNavigationMenu, setHideNavigationMenu] = useState<boolean>(false)
  const [userMenu, setUserMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const [inviteUserModal, setInviteUserModal] = useState<boolean>(false)

  const userMenuWidth = 275
  const offset = 320

  const handleMouseDown = (event: React.MouseEvent) => {
    dragging.current = true
    originalWidth.current = sidebarWidth
    mouseStart.current = event.clientX
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (dragging.current === true) {
      const delta = event.clientX - mouseStart.current
      const newWidth = originalWidth.current + delta
      if (newWidth < minSidebarWidth) {
        setSidebarWidth(minSidebarWidth)
      } else if (newWidth > maxSidebarWidth) {
        setSidebarWidth(maxSidebarWidth)
      } else {
        setSidebarWidth(newWidth)
      }
    }
  }

  const handleMouseUp = () => {
    if (dragging.current === false) return
    dragging.current = false
    mouseStart.current = 0
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const navigationMenu = query.get('showNavigationMenu')
    const hideNavigationMenu = navigationMenu && navigationMenu === 'false' ? true : false
    setHideNavigationMenu(hideNavigationMenu)

    if (hideNavigationMenu) {
      setSidebarWidth(0)
    }
  }, [])

  const sectionRowClassName =
    'relative text-white w-full flex font-semibold flex-row items-center cursor-pointer transition-all border-box'
  const sectionRowStyles = { minHeight: '40px', padding: '10px 10px' }

  const sectionIconClassName = 'flex flex-row items-center text-center justify-center'
  const sectionIconStyles = { minWidth: '40px', maxWidth: '40px' }

  const sectionNameClassName = 'flex items-center text-center justify-center truncate'
  const sectionNameStyles = { paddingLeft: '6px' }

  return (
    <div>
      {sidebarWidth === 0 && !hideNavigationMenu && (
        <div
          className="fixed bg-white cursor-pointer rounded hover-bg-grey transition-all heavy-shadow"
          style={{ zIndex: 5, top: '10px', left: '10px', padding: '8.5px 10px' }}
          onClick={() => setSidebarWidth(DEFAULT_SIDEBAR_WIDTH)}
        >
          <HamburgerMenu style={{ height: '15px', width: '15px' }} />
        </div>
      )}
      <div
        className="flex flex-column h-full content-center overflow-y-auto overflow-x-hidden select-none"
        style={{ minWidth: sidebarWidth, maxWidth: sidebarWidth, backgroundColor: project.primaryColour, zIndex: 5 }}
      >
        <div id={`${view}`} className="flex flex-column w-full" style={{ padding: '3px 0px' }}>
          {view.includes('/project/') && (
            <div>
              {project.loading || sidebarWidth < minSidebarWidthDisplay ? (
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    color: '#fff',
                    pointerEvents: project.loading ? 'none' : 'all'
                  }}
                >
                  <div className={sectionRowClassName} style={sectionRowStyles}>
                    <div className={sectionIconClassName} style={sectionIconStyles}>
                      {project.loading && <div className="spin" style={{ height: '20px', width: '20px' }} />}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="relative flex text-white w-full font-semibold flex-row justify-center transition-all items-center box-border"
                  style={{ height: '40px', padding: '10px 20px 10px 10px', opacity: 0.7 }}
                >
                  <div className="flex w-full items-center truncate">
                    <div className="truncate">{project.name}</div>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => setSidebarWidth(0)}
                      style={{ marginLeft: 'auto' }}
                      title="Close Sidebar"
                    >
                      <DoubleChevronLeft />
                    </div>
                  </div>
                </div>
              )}

              <Link
                data-cy={'home'}
                style={{
                  display: 'flex',
                  width: '100%',
                  color: '#fff',
                  pointerEvents: project.loading ? 'none' : 'all'
                }}
                to={`/project/${project.publicId}`}
              >
                <div className={sectionRowClassName} style={sectionRowStyles}>
                  <div className={sectionIconClassName} style={sectionIconStyles}>
                    <Home style={{ height: '20px', width: '20px' }} />
                  </div>
                  {sidebarWidth > minSidebarWidthDisplay && (
                    <div className={sectionNameClassName} style={sectionNameStyles}>
                      Home
                    </div>
                  )}
                </div>
              </Link>

              <div
                className={sectionRowClassName}
                style={{ ...sectionRowStyles, opacity: 1 }}
                onClick={() => {
                  if (project.loading) return
                  if (search.open) {
                    closeSearch()
                  } else {
                    openSearch()
                  }
                }}
              >
                <div className={sectionIconClassName} style={sectionIconStyles}>
                  <SearchIcon style={{ height: '20px', width: '20px' }} />
                </div>
                {sidebarWidth > minSidebarWidthDisplay && (
                  <div className={sectionNameClassName} style={sectionNameStyles}>
                    Search
                  </div>
                )}
              </div>

              {(project.role === ProjectRoles.ADMIN || project.role === ProjectRoles.OWNER) && (
                <Link
                  data-cy={'home'}
                  style={{
                    display: 'flex',
                    width: '100%',
                    color: '#fff',
                    pointerEvents: project.loading ? 'none' : 'all'
                  }}
                  to={`/project/${project.publicId}/settings`}
                >
                  <div className={sectionRowClassName} style={sectionRowStyles}>
                    <div className={sectionIconClassName} style={sectionIconStyles}>
                      <SettingsIcon style={{ height: '20px', width: '20px' }} />
                    </div>
                    {sidebarWidth > minSidebarWidthDisplay && (
                      <div className={sectionNameClassName} style={sectionNameStyles}>
                        Settings
                      </div>
                    )}
                  </div>
                </Link>
              )}
              {project.loading && <div className={sectionRowClassName} style={sectionRowStyles} />}
            </div>
          )}
          {view.endsWith('/home') && (
            <div>
              {sidebarWidth > minSidebarWidthDisplay && (
                <div
                  className="relative flex text-white w-full font-semibold flex-row justify-center transition-all items-center box-border"
                  style={{ height: '40px', padding: '10px', opacity: 0.7 }}
                >
                  <div className="flex w-full items-center truncate">
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => setSidebarWidth(0)}
                      style={{ marginLeft: '5px' }}
                      title="Close Sidebar"
                    >
                      <DoubleChevronLeft />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <Folders />

        <div
          className="flex flex-column w-full flex-1 justify-center"
          style={{
            backgroundColor: tint(project.primaryColour, Number.parseInt(constants.secondaryTint)),
            padding: '0px'
          }}
        >
          {!user && (
            <Link className="text-white w-full flex" data-cy={'home'} to={SIGN_IN}>
              <div className={sectionRowClassName} style={sectionRowStyles}>
                <div className={sectionIconClassName} style={sectionIconStyles}>
                  <img
                    alt={'Anonymous User'}
                    style={{ width: 30, height: 30, borderRadius: '50%', objectFit: 'cover' }}
                    src={'/assets/images/user.png'}
                  />
                </div>
                {sidebarWidth > minSidebarWidthDisplay && (
                  <div className={sectionNameClassName} style={sectionNameStyles}>
                    Sign In
                  </div>
                )}
              </div>
            </Link>
          )}

          {user && (
            <div className={sectionRowClassName} style={{ ...sectionRowStyles, opacity: 1, width: 'fit-content' }}>
              <div
                className={sectionIconClassName}
                style={sectionIconStyles}
                onClick={(event) => {
                  setUserMenu({
                    open: true,
                    top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                    left: `${event.currentTarget.getBoundingClientRect().left - userMenuWidth + offset}px`,
                    right: 'auto',
                    bottom: 'auto'
                  })
                }}
                data-cy="user-menu"
              >
                <img
                  alt={user?.name}
                  style={{ width: 30, height: 30, borderRadius: '50%', objectFit: 'cover' }}
                  src={user && user.profilePicture ? user.profilePicture : '/assets/images/user.png'}
                />
              </div>
              {sidebarWidth > minSidebarWidthDisplay && (
                <div
                  className={sectionNameClassName}
                  style={sectionNameStyles}
                  onClick={(event) => {
                    setUserMenu({
                      open: true,
                      top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
                      left: `${event.currentTarget.getBoundingClientRect().left - userMenuWidth + offset}px`,
                      right: 'auto',
                      bottom: 'auto'
                    })
                  }}
                >
                  Me
                </div>
              )}
            </div>
          )}
        </div>

        {userMenu.open && (
          <UserMenu
            id="user-menu"
            menuState={userMenu}
            setMenuState={setUserMenu}
            width={userMenuWidth}
            setTemplatesModal={setTemplatesModal}
          />
        )}

        {inviteUserModal && (
          <Modal
            id={'invite-user-modal'}
            open={inviteUserModal}
            setOpen={setInviteUserModal}
            title={`Invite User To ${capitaliseFirstLetter(PROJECT)}`}
          >
            <InviteUserForm
              handleOnSuccess={() => {
                setInviteUserModal(false)
                setSnackbarMessage({ status: 'success', message: `Users were invited to ${PROJECT}` })
              }}
            />
          </Modal>
        )}

        {templatesModal && <Templates open={templatesModal} setOpen={setTemplatesModal} id="templates-modal" />}

        {search.open && <Search />}
        <div
          className="absolute"
          style={{
            left: `${sidebarWidth}px`,
            right: '0px',
            width: '0px',
            flexGrow: 0,
            zIndex: 1,
            top: '0px',
            bottom: '0px',
            pointerEvents: 'auto'
          }}
        >
          <div
            className="h-full"
            style={{ cursor: 'col-resize', width: '12px', marginLeft: '-6px' }}
            onMouseDown={(event: React.MouseEvent) => {
              event.preventDefault()
              handleMouseDown(event)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AppSidebar
