import React, { ReactElement, useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { getCurrentUser } from 'helpers/auth'
import { useAuth } from 'hooks/auth'
import { PROFILE, SIGN_IN } from 'routes'
import { matchPath } from 'react-router'
import { PROCESS, TABLE, SETTINGS, PROJECT } from 'routes'

interface AuthGuardProps {
  children: ReactElement
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const [redirect, setRedirect] = useState(false)
  const cookieData = getCurrentUser()
  const { user, updateUser } = useAuth()

  const allowedOptionalAuth =
    !!matchPath(window.location.pathname, SIGN_IN) ||
    !!matchPath(window.location.pathname, PROCESS) ||
    !!matchPath(window.location.pathname, TABLE) ||
    !!matchPath(window.location.pathname, SETTINGS) ||
    !!matchPath(window.location.pathname, PROFILE) ||
    !!matchPath(window.location.pathname, PROJECT)

  const tryLoadingUserFromLocalStorage = async () => {
    // Use details from localstage, so any api calls will use those creds
    updateUser(cookieData!)
  }

  useEffect(() => {
    // load user info from local storage
    if (cookieData && !user) {
      tryLoadingUserFromLocalStorage()
    }

    // redirect to login page
    if (!user && !cookieData) {
      setRedirect(true)
    }
  }, [cookieData, user])

  if (redirect && !allowedOptionalAuth) {
    return <Redirect to={SIGN_IN} />
  }

  if (!user && !allowedOptionalAuth) {
    return null
  }

  return children
}

export default AuthGuard
