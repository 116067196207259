import React, { useEffect, useState } from 'react'
import TagTable from 'components/permissions/views/tag/components/tagTable'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { IAccessPolicy, ITableWithCells } from 'types'
import { UserRoles, AccessAttributeKinds } from 'app-constants'
import { Triangle } from 'components/icons'

interface Props {
  display: boolean
  policies: IAccessPolicy[]
  onCreatePolicy: (
    role: UserRoles,
    attributeKind: AccessAttributeKinds,
    attributeId?: string,
    tagReferenceId?: string
  ) => void
  onDeletePolicy: (policy: IAccessPolicy) => void
  excludedPermissionCategories?: UserRoles[]
}

const getAllTableTagsPolicy = (policies: IAccessPolicy[], tableId: string, role: UserRoles) => {
  const allTableTagsPolicy = policies.find((policy) => {
    if (
      policy.accessAttribute.kind === AccessAttributeKinds.ALL_TABLE_TAGS &&
      policy.accessAttribute.documentTable?.publicId === tableId &&
      policy.role === role
    ) {
      return true
    }
  })
  return allTableTagsPolicy
}

const TagPermissions: React.FC<Props> = ({
  display,
  onCreatePolicy,
  policies,
  onDeletePolicy,
  excludedPermissionCategories
}) => {
  const [selectedLevel, setSelectedLevel] = useState<string>('none')
  const { project } = useProject()
  const [tagTables, setTagTables] = useState<ITableWithCells[]>([])
  const { displayErrorMessage } = useApplicationStore()

  const permissionLevels = [
    { id: 'view', level: UserRoles.VIEWER, name: 'View Permissions', icon: 'new-eye', viewbox: '0 0 512 512' },
    {
      id: 'contribute',
      level: UserRoles.CONTRIBUTER,
      name: 'Contribute Permissions',
      icon: 'pencil',
      viewbox: '0 0 512 512'
    },
    { id: 'owner', level: UserRoles.OWNER, name: 'Owner Permissions', icon: 'owner', viewbox: '0 0 128 128' }
  ].filter(
    (permissionLevel) => !excludedPermissionCategories || !excludedPermissionCategories.includes(permissionLevel.level)
  )

  const fetchProjectTags = async () => {
    try {
      const response = await api.getProjectTags(project.publicId)
      setTagTables(response.data.sort((a, b) => (a.name > b.name ? 1 : -1)))
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleAddTag = (tagReferenceId: string, role: UserRoles) => {
    onCreatePolicy(role, AccessAttributeKinds.TAG, undefined, tagReferenceId)
  }

  const handleDeleteTag = (tagId: string, role: UserRoles) => {
    const policy = policies.find((p) => {
      if (
        p.accessAttribute.kind === AccessAttributeKinds.TAG &&
        p.accessAttribute.tag?.referencePublicId === tagId &&
        p.role === role
      ) {
        return true
      }
    })
    onDeletePolicy(policy!)
  }

  const handleAddAllTags = (tableId: string, role: UserRoles) => {
    onCreatePolicy(role, AccessAttributeKinds.ALL_TABLE_TAGS, tableId)
  }

  const handleDeleteAllTags = (tableId: string, role: UserRoles) => {
    const policy = getAllTableTagsPolicy(policies, tableId, role)
    onDeletePolicy(policy!)
  }

  useEffect(() => {
    fetchProjectTags()
  }, [])

  return (
    <div className="overflow-y-auto" style={{ display: display ? 'block' : 'none', height: display ? '95%' : '0%' }}>
      {permissionLevels.map((permissionLevel, permissionIndex) => {
        const active = selectedLevel === permissionLevel.id

        // If there are polices based on tags for this role
        const hasPolicies = policies.find(
          (p) =>
            p.role === permissionLevel.level &&
            (p.accessAttribute.kind === AccessAttributeKinds.ALL_TABLE_TAGS ||
              p.accessAttribute.kind === AccessAttributeKinds.TAG)
        )
        return (
          <div
            key={permissionIndex}
            className="flex flex-column cursor-pointer select-none rounded box-border transition-all border-1px border-solid border-black hover-heavy-shadow"
            style={{ margin: '10px 5px 10px 5px', padding: '1.5rem', minHeight: active ? '200px' : '90px' }}
          >
            <div
              className="flex h-full content-center"
              onClick={() => (active ? setSelectedLevel('none') : setSelectedLevel(permissionLevel.id))}
            >
              <div className="flex flex-column justify-center">
                <div className="font-bold" style={{ marginBottom: '5px' }}>
                  {permissionLevel.name}
                  {hasPolicies ? (
                    <span className="bg-green rounded" style={{ padding: '5px', marginLeft: '10px' }}>
                      Active
                    </span>
                  ) : (
                    <span className="bg-red rounded" style={{ padding: '5px', marginLeft: '10px' }}>
                      Inactive
                    </span>
                  )}
                </div>
                <div className="w-full truncate">
                  Set the combination of tags that allow users {' ' + permissionLevel.id + ' '} access. <br />A user
                  will have to have at least one tag from each category below to be given access.
                </div>
              </div>
              <div className="flex items-center ml-auto h-full">
                <Triangle type={!active ? 'right' : 'down'} />
              </div>
            </div>
            <div
              className="relative flex flex-column transition-all"
              style={{
                maxWidth: '100%',
                marginTop: '15px',
                opacity: active ? 1 : 0,
                visibility: active ? 'visible' : 'hidden',
                height: active ? '100%' : '0px'
              }}
            >
              {active &&
                tagTables.map((tagTable, index) => {
                  return (
                    <TagTable
                      key={index}
                      policies={policies}
                      tags={tagTable}
                      tableId={tagTable.publicId}
                      onTagAdd={(tagId) => handleAddTag(tagId, permissionLevel.level)}
                      onTagDelete={(tagId) => handleDeleteTag(tagId, permissionLevel.level)}
                      role={permissionLevel.level}
                      onAllTagsAdd={handleAddAllTags}
                      onAllTagsDelete={handleDeleteAllTags}
                    />
                  )
                })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TagPermissions
