import React from 'react'

interface ToggleProps {
  toggled: boolean
  onToggle: (toggled: boolean) => void
  disabled?: boolean
}

const Toggle: React.FC<ToggleProps> = ({ toggled, onToggle, disabled }) => {
  return (
    <div
      className="ml-auto"
      style={{ minWidth: 0, flexShrink: 0 }}
      onClick={() => {
        if (!disabled) {
          onToggle(!toggled)
        }
      }}
    >
      <div
        className={`select-none transition-all ${disabled ? 'cursor-not-allowed' : 'cursor-pointer '}`}
        style={{ borderRadius: '44px' }}
        role="button"
        tabIndex={0}
      >
        <div
          className="flex box-content transition-all"
          style={{
            flexShrink: 0,
            height: '14px',
            width: '26px',
            borderRadius: '44px',
            padding: '2px',
            background: `${toggled ? 'rgb(46, 170, 220)' : 'rgba(135, 131, 120, 0.3)'}`
          }}
        >
          <div
            className="transition-all"
            style={{
              height: '14px',
              width: '14px',
              borderRadius: '44px',
              background: 'white',
              transform: `${toggled ? 'translateX(12px) translateY(0px)' : 'translateX(0px) translateY(0px)'}`
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default Toggle
