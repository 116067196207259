import React, { useState } from 'react'
import CommentThreads, { ICommentsThreadsComponentProps } from './commentThreads'
import { ICommentThreadObject, CommentReferenceType, IContextMenuState } from 'types'
import { Comment } from 'components/icons'
import Menu from 'components/menu'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { ISpreadsheetData } from 'components/spreadsheet/types'

interface ICommentsComponentProps {
  referenceType: CommentReferenceType
  referenceId: string
  openCommentThreads: number
  permissionLevel: number
  selected: boolean
  spreadsheetData?: ISpreadsheetData
  onSelectedComment: (sectionPulbicId: string) => void
  onCommentThreadsChange: (newCommentThreads: ICommentThreadObject[]) => void
}

const CommentsComponent: React.FC<ICommentsComponentProps> = ({
  referenceType,
  referenceId,
  openCommentThreads,
  permissionLevel,
  selected,
  spreadsheetData,
  onSelectedComment,
  onCommentThreadsChange
}) => {
  const [currentOpenCommentThreads, setCurrentOpenCommentThreads] = useState(openCommentThreads)
  const [commentMenu, setCommentMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const props: ICommentsThreadsComponentProps = {
    referenceType,
    referenceId,
    permissionLevel,
    selected,
    spreadsheetData,
    onOpenCommentTreadsChange: (value) => setCurrentOpenCommentThreads(value),
    onCommentThreadsChange: (newCommentThreads: ICommentThreadObject[]) => onCommentThreadsChange(newCommentThreads)
  }

  const commentWidth = 350
  const offset = 0

  return (
    <div>
      <div
        className="text-sm flex items-center justify-center"
        onClick={(event) => {
          onSelectedComment(referenceId)
          setCommentMenu({
            open: true,
            top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
            left: `${event.currentTarget.getBoundingClientRect().left - commentWidth + offset}px`,
            right: 'auto',
            bottom: 'auto'
          })
        }}
      >
        <Comment />
        <span style={{ marginLeft: '2px' }}>{currentOpenCommentThreads}</span>
      </div>

      {commentMenu.open && (
        <Menu
          id={`comment-thread-${referenceId}`}
          menuState={commentMenu}
          setMenuState={setCommentMenu}
          width={commentWidth}
        >
          <CommentThreads {...props} />
        </Menu>
      )}
    </div>
  )
}

export default CommentsComponent
