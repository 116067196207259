import React, { useState } from 'react'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import VoteCellModal from 'components/spreadsheet/components/modal/views/vote'
import { IVote } from 'types'
import { useAuth } from 'hooks/auth'
import { Visible } from 'components/icons'
import { uuidv4 } from 'helpers/utils'

interface VoteProps {
  value: IVote[] | null
  align: 'center' | 'left' | 'right'
  color: string
  readOnly: boolean
  isAdmin: boolean
  isContributor: boolean
  width: number
  rowHeight: number
  selected: boolean
  rowNumber: number | undefined
  validation: boolean
  setCellValue: (value: IVote[], onSuccess: () => void, onError: () => void) => void
  isExpanded?: boolean
}

const Vote: React.FC<VoteProps> = ({
  value,
  align,
  color,
  readOnly,
  isAdmin,
  isContributor,
  width,
  rowHeight,
  selected,
  rowNumber,
  validation,
  setCellValue,
  isExpanded
}) => {
  const votes = value && Array.isArray(value) ? value : []
  const [votesModal, setVotesModal] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const { user } = useAuth()

  const positiveVotes = votes.filter((vote: IVote) => vote.type).length
  const negativeVotes = votes.filter((vote: IVote) => !vote.type).length
  const alreadyVotedPositive =
    votes
      .filter((vote: IVote) => vote.type)
      .findIndex((vote: IVote) => user && vote.firebaseUserId === user!.firebaseUserId) !== -1
  const alreadyVotedNegative =
    votes
      .filter((vote: IVote) => !vote.type)
      .findIndex((vote: IVote) => user && vote.firebaseUserId === user!.firebaseUserId) !== -1
  const canVote = (isAdmin || isContributor) && !readOnly

  const handleVoteClick = (type: boolean) => {
    const newVotes = [...votes].filter((vote: IVote) => user && vote.firebaseUserId !== user!.firebaseUserId)

    if ((!alreadyVotedPositive && type) || (!alreadyVotedNegative && !type)) {
      newVotes.push({
        firebaseUserId: user ? user!.firebaseUserId : uuidv4(),
        type,
        timestamp: new Date().toISOString()
      })
    }

    if (processing) return
    setProcessing(true)
    setCellValue(
      newVotes,
      () => setProcessing(false),
      () => setProcessing(false)
    )
  }
  return (
    <div
      className={`cell-value ${selected ? 'focus' : ''}`}
      tabIndex={-1}
      style={{
        textAlign: align,
        color: color,
        height: getRowHeightVariable(rowHeight) - 2,
        width: width,
        maxWidth: width
      }}
      onDoubleClick={(event: React.MouseEvent) => {
        if (readOnly || isExpanded) {
          event.stopPropagation()
          return
        }
        event.stopPropagation()
      }}
    >
      <div
        className={`flex items-center justify-start border-0`}
        style={{
          lineHeight: 1.5,
          height: selected ? getRowHeightVariable(rowHeight) - 3 : 'unset',
          padding: selected && width < 112 ? 0 : 5,
          width: validation ? 'calc(100% - 25px)' : '100%'
        }}
      >
        <span className="flex items-center">
          <span style={{ marginRight: selected && width < 112 ? '10px' : '0px' }}>
            <span
              style={{ cursor: canVote ? 'pointer' : 'default' }}
              onClick={() => {
                if ((selected || isExpanded) && canVote) handleVoteClick(true)
              }}
            >
              👍
            </span>
            <span style={{ marginLeft: '3px', color: alreadyVotedPositive ? 'blue' : 'inherit', fontWeight: 600 }}>
              {positiveVotes}
            </span>
          </span>
          <span style={{ marginRight: selected && width < 112 ? '10px' : '0px' }}>
            <span
              style={{ cursor: canVote ? 'pointer' : 'default' }}
              onClick={() => {
                if ((selected || isExpanded) && canVote) handleVoteClick(false)
              }}
            >
              👎
            </span>
            <span style={{ marginLeft: '3px', color: alreadyVotedNegative ? 'blue' : 'inherit', fontWeight: 600 }}>
              {negativeVotes}
            </span>
          </span>
        </span>

        {(selected || isExpanded) && (
          <a className="flex items-center" style={{ marginLeft: 'auto' }} onClick={() => setVotesModal(true)}>
            <Visible style={{ height: '24px', width: '24px' }} />
          </a>
        )}
      </div>
      {votesModal && (
        <VoteCellModal
          id="vote-cell-modal"
          open={votesModal}
          setOpen={setVotesModal}
          votes={votes}
          rowNumber={rowNumber !== undefined ? rowNumber : -1}
        />
      )}
    </div>
  )
}

export const VoteCell = React.memo(Vote)
