import React from 'react'
import Button from 'components/button'
import Modal from 'components/modal'

interface AlterResponseDialogProps {
  open: boolean
  onClose: () => void
  type: string
  message: string
  onSave: () => void
}

const AlterResponseDialog: React.FC<AlterResponseDialogProps> = ({
  open,
  onClose,
  type,
  message,
  onSave
}: AlterResponseDialogProps) => {
  return (
    <Modal id="alter-response-modal" open={open} setOpen={onClose} title={`${type} Response?`}>
      <div>{message}</div>

      <div className="flex items-center justify-end mt-20px">
        <Button onClick={onClose}>Cancel</Button>
        <Button style={{ marginLeft: '10px' }} internalType="danger" onClick={onSave}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

export default AlterResponseDialog
