import { useRef, useState, useLayoutEffect } from 'react'

const useGridLayout = () => {
  const gridRef = useRef<HTMLDivElement>(null)
  const [gridWidth, setGridWidth] = useState(1)
  const [gridHeight, setGridHeight] = useState(1000)

  const observerCallback: ResizeObserverCallback = (entries: ResizeObserverEntry[]) => {
    window.requestAnimationFrame((): void | undefined => {
      if (!Array.isArray(entries) || !entries.length) {
        return
      }
      if (gridRef.current) {
        const { clientWidth, clientHeight } = gridRef.current!
        setGridWidth(clientWidth)
        setGridHeight(clientHeight)
      }
    })
  }
  useLayoutEffect(() => {
    if (!gridRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(observerCallback)

    resizeObserver.observe(gridRef.current!)

    return () => {
      resizeObserver.disconnect()
    }
  }, [gridRef.current])

  return { gridRef, gridWidth, gridHeight }
}

export default useGridLayout
