import React, { useState, useRef } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { useApplicationStore } from 'hooks/application'
import { IFile, IFileParentResource } from 'types'
import api from 'helpers/api'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Preview from 'components/spreadsheet/components/modal/views/attachment/components/preview'

interface AttachementCellModalProps extends ModalProps {
  files: IFile[]
  isAdmin: boolean
  isContributor: boolean
  isReadOnly: boolean
  setCellValue: (value: IFile[], onSuccess: () => void, onError: () => void) => void
  columnId?: string
}

const AttachmentCellModal: React.FC<AttachementCellModalProps> = ({
  id,
  open,
  setOpen,
  files,
  setCellValue,
  isAdmin,
  isContributor,
  isReadOnly,
  columnId
}) => {
  const { setSnackbarMessage } = useApplicationStore()
  const { spreadsheetData } = useDataContext()

  const fileUploadInputRef = useRef<HTMLInputElement>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const [fileIndex, setFileIndex] = useState<number>(0)

  const previewRef = useRef<HTMLDivElement>(null)

  const uploadFile = async (file: File) => {
    const resources: IFileParentResource[] = [
      { resource: 'document_table', publicId: spreadsheetData!.tableDetails.publicId }
    ]

    const urlData = await api.uploadFile(file, resources)
    if (urlData) {
      return urlData.data
    } else {
      setProcessing(false)
      setSnackbarMessage({ status: 'error', message: 'A file was not uploaded successfully' })
      return null
    }
  }

  const handleAddFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (processing) return
    const uploadedFiles = event.target.files
    if (uploadedFiles) {
      setProcessing(true)
      const allFiles = files ? [...files] : []

      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i]
        const newFileUrl = await uploadFile(file)
        if (newFileUrl) allFiles.push(newFileUrl)
      }

      setCellValue(
        allFiles,
        () => setProcessing(false),
        () => setProcessing(false)
      )
    }
  }

  const handleRemoveFile = (index: number) => {
    if (processing) return
    setOpen(false)
    setProcessing(true)
    const newFiles = [...files]
    newFiles.splice(index, 1)
    setCellValue(
      newFiles,
      () => setProcessing(false),
      () => setProcessing(false)
    )
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={`Viewing Attachment ${fileIndex + 1}/${files.length}: ${files[fileIndex].filename}`}
    >
      <input type="file" ref={fileUploadInputRef} style={{ display: 'none' }} onChange={handleAddFile} multiple />
      <div className="w-full" ref={previewRef} style={{ height: '600px' }}>
        {files.map((file: IFile, index: number) => {
          return (
            <Preview
              key={index}
              file={file}
              fileIndex={index}
              firstFile={index === 0}
              lastFile={index === files.length - 1}
              setFileIndex={setFileIndex}
              visible={index === fileIndex}
              tableId={spreadsheetData.tableDetails.publicId}
              columnId={columnId}
              isAdmin={isAdmin}
              isContributor={isContributor}
              isReadOnly={isReadOnly}
              handleRemoveFile={handleRemoveFile}
              handleAddFile={() => {
                if (!processing) fileUploadInputRef.current?.click()
              }}
              previewRef={previewRef}
            />
          )
        })}
      </div>
    </Modal>
  )
}

export default AttachmentCellModal
