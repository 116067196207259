import React, { useEffect, useState } from 'react'

export default function useOnScreen(ref: React.RefObject<HTMLDivElement> | undefined) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  useEffect(() => {
    if (ref && ref.current) observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return isIntersecting
}
