import api from 'helpers/api'
import useProject from 'hooks/project'
import React, { useEffect, useState } from 'react'
import { IFolderContents } from 'types'
import { Table } from 'components/table'
import Button from 'components/button'
import { history } from 'helpers/history'
import { PROCESS_BASE, TABLE_BASE, PROJECT_BASE } from 'routes'
import { useApplicationStore } from 'hooks/application'
import { Link } from 'react-router-dom'

const DeletedResources: React.FC = () => {
  const { project } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [deletedResources, setDeletedResources] = useState<IFolderContents[]>()

  useEffect(() => {
    if (project.publicId !== '') {
      api.getProjectResources(project.publicId, { onlyDeleted: true }).then((resources) => {
        setDeletedResources(resources.data)
      })
    }
  }, [project.publicId])

  const restoreResource = (resource: IFolderContents) => {
    if (resource.process) {
      api
        .restoreProcess(resource.process.publicId, {
          projectId: project.publicId,
          context: { projectId: project.publicId }
        })
        .then(() => {
          history.push(PROJECT_BASE + project.publicId + PROCESS_BASE + resource.process?.publicId)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    } else if (resource.table) {
      api
        .restoreTable(resource.table.publicId, { context: { projectId: project.publicId } })
        .then((response) => {
          history.push(
            PROJECT_BASE +
              project.publicId +
              TABLE_BASE +
              resource.table?.publicId +
              '/view/' +
              response.data.defaultViewId
          )
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  const tableData = deletedResources
    ? deletedResources.map((resource) => {
        return {
          type: {
            label: resource.type === 'process' ? 'Document' : 'Table',
            value: resource.type === 'process' ? 'Process' : 'Table'
          },
          name: {
            label: resource.process ? (
              <Link
                to={`/project/${project.publicId}/process/${resource.process.publicId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {resource.process.name}
              </Link>
            ) : resource.table ? (
              <Link
                to={`/project/${project.publicId}/table/${resource.table.publicId}/view/${resource.table.defaultViewId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {resource.table.name}
              </Link>
            ) : (
              ''
            ),
            value: resource.process ? resource.process.name : resource.table ? resource.table.name : ''
          },
          createdAt: {
            label: resource.process
              ? resource.process.createdAt.split('T')[0]
              : resource.table
              ? resource.table.createdAt.split('T')[0]
              : '',
            value: resource.process ? resource.process.createdAt : resource.table ? resource.table.createdAt : ''
          },
          deletedAt: {
            label:
              resource.process && resource.process.deletedAt
                ? resource.process.deletedAt.split('T')[0]
                : resource.table && resource.table.deletedAt
                ? resource.table.deletedAt.split('T')[0]
                : '',
            value:
              resource.process && resource.process.deletedAt
                ? resource.process.deletedAt
                : resource.table && resource.table.deletedAt
                ? resource.table.deletedAt
                : ''
          },
          restore: { label: <Button onClick={() => restoreResource(resource)}>Restore</Button>, value: '' }
        }
      })
    : []

  return (
    <div className="flex flex-column overflow-hidden h-full w-full bg-light-grey" style={{ padding: '20px 30px' }}>
      <div
        className="bg-white overflow-y rounded shadow"
        style={{ maxWidth: '100%', maxHeight: '100%', width: 'fit-content' }}
      >
        <Table
          data={tableData}
          include={[
            { header: 'Type', id: 'type' },
            { header: 'Name', id: 'name' },
            { header: 'Date Created', id: 'createdAt' },
            { header: 'Date Deleted', id: 'deletedAt' },
            { header: 'Restore Resource', id: 'restore' }
          ]}
          defaultSort={'deletedAt'}
          defaultSortAscending={true}
          sort={true}
          loading={deletedResources === undefined}
        />
      </div>
    </div>
  )
}

export default DeletedResources
