import React, { useState } from 'react'
import { SpreadsheetReducerActions, ISpreadsheetData } from 'components/spreadsheet/types'
import constants from 'style/constants.module.scss'
import { useProject } from 'hooks/project'
import { buildNewColumn } from 'components/spreadsheet/helpers/functions'
import { createTableColumnViewAction } from 'components/spreadsheet/contexts/data/actions'
import useApplicationStore from 'hooks/application'

interface HeaderProps {
  spreadsheetData: ISpreadsheetData
  setSpreadsheetData: React.Dispatch<SpreadsheetReducerActions>
}

const HeaderAddColumn: React.FC<HeaderProps> = ({ spreadsheetData, setSpreadsheetData }) => {
  const { project } = useProject()
  const { displayErrorMessage } = useApplicationStore()

  const [processing, setProcessing] = useState<boolean>(false)

  const handleSuccess = () => {
    setProcessing(false)
  }

  const handleFailure = (error: any) => {
    displayErrorMessage(error)
  }

  const handleInsertColumn = async () => {
    setProcessing(true)
    const maxSortOrder = Math.max(...spreadsheetData.viewDetails.columns.map((col) => col.sortOrder))
    const column = buildNewColumn(
      spreadsheetData,
      maxSortOrder + 1,
      project.publicId,
      project.defaultHeaderBackgroundColor,
      project.defaultHeaderTextColor
    )
    createTableColumnViewAction(
      spreadsheetData.viewDetails.publicId,
      column,
      setSpreadsheetData,
      handleSuccess,
      handleFailure
    )
  }

  return (
    <div
      id={`header-column-add`}
      className="relative box-border inline-block bg-light-grey"
      style={{
        height: `${Number.parseInt(constants.headerHeight) - 1}px`,
        width: Number(constants.columnMinWidth),
        borderRight: `1px solid ${constants.grey}`,
        left: 'auto',
        zIndex: 'auto',
        cursor: 'default'
      }}
      onClick={() => {
        if (processing) return
        handleInsertColumn()
      }}
    >
      <div
        className="w-full h-full flex items-center justify-center absolute box-inherit header-add-column font-bold text-2xl cursor-pointer select-none hover-bg-grey transition-all"
        style={{ padding: '3px' }}
      >
        +
      </div>
    </div>
  )
}

export default React.memo(HeaderAddColumn)
