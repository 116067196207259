import React from 'react'
import Button from 'components/button'
import { ITableWithCells, IProjectMember, ITag, IUserObject } from 'types'
import { ProjectRoles } from 'app-constants'
import Select from 'components/select'
import { Delete } from 'components/icons'
import { ITableData } from 'components/table'

interface MembershipRow {
  [key: string]: ITableData
}

export const getProjectMembershipData = (
  user: IUserObject | undefined,
  users: IProjectMember[],
  projectRole: string,
  tableWithTagCells: ITableWithCells[],
  onPermissionUpdated: (member: IProjectMember, tagReferenceIds: string[], tableSelectedTags: ITag[]) => void,
  onPromoteUsertoAdmin: (member: IProjectMember) => void,
  onPromoteUsertoOwner: (member: IProjectMember) => void,
  onDeleteProjectMember: (member: IProjectMember) => void,
  onAdminToMember: (member: IProjectMember) => void,
  onOwnerToAdmin: (member: IProjectMember) => void
): MembershipRow[] => {
  const rows = []

  for (const member of users) {
    const row: MembershipRow = {}
    const userTagReferenceIds = member.user.tags!.map((tag) => tag.referencePublicId)
    row['user'] = {
      label: (
        <div className="flex items-center">
          <img
            src={member.user.profilePicture ? member.user.profilePicture : '/assets/images/user.png'}
            style={{ borderRadius: '50%', width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }}
            alt={`${member.user.name} profile picture`}
          />
          <a href={`/profile/${member.user.firebaseUserId}/information`} target="_blank" rel="noopener noreferrer">
            {member.user.name}
          </a>
        </div>
      ),
      value: member.user.name
    }
    row['email'] = { label: member.user.email, value: member.user.email }
    row['role'] = {
      label: (
        <div className="flex items-center">
          {member.projectRole === ProjectRoles.OWNER &&
          projectRole === ProjectRoles.OWNER &&
          user &&
          user.email !== member.user.email ? (
            <Button internalType="danger" onClick={() => onOwnerToAdmin(member)}>
              ↓
            </Button>
          ) : member.projectRole === ProjectRoles.ADMIN && projectRole === ProjectRoles.OWNER ? (
            <Button internalType="danger" onClick={() => onAdminToMember(member)}>
              ↓
            </Button>
          ) : (
            <Button internalType="danger" disabled={true}>
              ↓
            </Button>
          )}
          <div className="flex justify-center" style={{ marginLeft: '5px', marginRight: '5px', minWidth: '75px' }}>
            {member.projectRole === ProjectRoles.OWNER
              ? 'Owner'
              : member.projectRole === ProjectRoles.ADMIN
              ? 'Builder'
              : 'Member'}
          </div>
          {member.projectRole !== ProjectRoles.OWNER && member.projectRole !== ProjectRoles.ADMIN ? (
            <Button internalType="secondary" onClick={() => onPromoteUsertoAdmin(member)}>
              ↑
            </Button>
          ) : member.projectRole === ProjectRoles.ADMIN && projectRole === ProjectRoles.OWNER ? (
            <Button internalType="secondary" onClick={() => onPromoteUsertoOwner(member)}>
              ↑
            </Button>
          ) : (
            <Button internalType="secondary" disabled={true}>
              ↑
            </Button>
          )}
        </div>
      ),
      value: member.projectRole
    }
    row['joined'] = { label: member.createdAt.split('T')[0], value: member.createdAt }

    for (const tagTable of tableWithTagCells) {
      const tagOptions = tagTable.cells.map((cell) => ({
        label: cell.value,
        value: cell.id,
        group: cell.column.name
      }))

      tagOptions.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0))
      const tableTagReferenceIds = tagTable.cells.map((cell) => cell.id)

      const defaultOptions = tagOptions.filter((option) => userTagReferenceIds.includes(option.value))

      const currentTableSelectedTags = member.user.tags!.filter((tag) =>
        tableTagReferenceIds.includes(tag.referencePublicId)
      )

      row[tagTable.publicId] = {
        label: (
          <div style={{ width: '350px' }}>
            <Select
              key={`${member.user.firebaseUserId}-${tagTable.publicId}`}
              options={tagOptions}
              onOptionClick={(option) => {
                const index = defaultOptions.findIndex((tag) => tag.value === option)
                if (index === -1) {
                  const newPermissions: string[] = defaultOptions.map((tag) => tag.value)
                  newPermissions.push(option)
                  onPermissionUpdated(member, newPermissions, currentTableSelectedTags)
                } else {
                  const newPermissions: string[] = defaultOptions.map((tag) => tag.value)
                  newPermissions.splice(index, 1)
                  onPermissionUpdated(member, newPermissions, currentTableSelectedTags)
                }
              }}
              setOptionsSelected={(options) => {
                onPermissionUpdated(member, options, currentTableSelectedTags)
              }}
              multiselect={true}
              groupBy={true}
              optionsSelected={defaultOptions.map((option) => option.value)}
            />
          </div>
        ),
        value: ''
      }
    }

    row['remove'] = {
      label:
        (member.projectRole !== ProjectRoles.OWNER && projectRole === ProjectRoles.OWNER) ||
        (member.projectRole === ProjectRoles.MEMBER && projectRole === ProjectRoles.ADMIN) ? (
          <Button internalType="danger" onClick={() => onDeleteProjectMember(member)}>
            <Delete style={{ fill: 'white' }} />
          </Button>
        ) : (
          <div />
        ),
      value: ''
    }

    rows.push(row)
  }
  return rows
}
