import { useMemo } from 'react'
import { ITableRowInGroup, GroupByDictionary, IGroupRowCreate, IGroupCollapsed } from '../types'
import { ITableRow, ITableViewColumn, ITableColumn, ITableViewGroup } from 'types'
import { sortRows } from 'components/spreadsheet/helpers/functions'
import { rowGrouper } from 'components/spreadsheet/helpers/grouping'
import { GROUP_KEY_SPLIT } from '../constants/const'

// The spread operator (and Array.assign) loads the entire source array
// onto the stack, then pushes it onto the destination array.  Once the
// source array exceeds 125,052, it causes a Stack Overflow / Range Error.
const MAX_STACK_SIZE = 10000

interface Args {
  gridHeight: number
  rowHeight: number
  scrollTop: number
  rawRows: ITableRow[]
  groupBy: ITableViewGroup[]
  expandedGroupIds?: ReadonlySet<unknown>
  totalRows: number
  collapsedGroupIds: Set<string>
  isStreaming: boolean
  columns: ITableViewColumn[]
  isContributer: boolean
  disableNewRow: boolean
  unpackMultiselectGroupView: boolean
}

const unpackRows = (rows: ITableRow[], columnId: string): ITableRow[] => {
  const newRows: ITableRow[] = []
  for (let i = 0; i < rows.length; i++) {
    const row = rows[i]
    const multiselectValue = row.rowData[columnId]
    if (multiselectValue && Array.isArray(multiselectValue)) {
      for (let j = 0; j < multiselectValue.length; j++) {
        const value = multiselectValue[j]
        if (typeof value === 'string') {
          newRows.push({ ...row, rowData: { ...row.rowData, [columnId]: [value] } })
        }
      }
    }
    if (!multiselectValue) {
      newRows.push({ ...row, rowData: { ...row.rowData, [columnId]: [] } })
    }
  }
  return newRows
}

export function useRowsViewport({
  gridHeight,
  rowHeight,
  scrollTop,
  rawRows,
  groupBy,
  totalRows,
  collapsedGroupIds,
  isStreaming,
  columns,
  isContributer,
  disableNewRow,
  unpackMultiselectGroupView
}: Args) {
  const shouldGroup = groupBy.length > 0 && !isStreaming

  // eslint-disable-next-line
  const [groupedRows, _] = useMemo(() => {
    // If we're not grouping
    if (!shouldGroup) return [undefined, totalRows]

    // Check if need to unpack multiselect columns
    const multiselectColumnIds = columns
      .filter((c) => c.kind === 'multiselect' && groupBy.find((group) => group.columnId === c.publicId))
      .map((c) => c.publicId)
    if (unpackMultiselectGroupView) {
      let newRows: ITableRow[] = []
      for (let i = 0; i < rawRows.length; i++) {
        const row = rawRows[i]
        let unpackedRows = [row]
        for (let j = 0; j < multiselectColumnIds.length; j++) {
          const columnId = multiselectColumnIds[j]
          const newUnpackedRows = unpackRows(unpackedRows, columnId)
          unpackedRows = newUnpackedRows
        }
        newRows = newRows.concat(...unpackedRows)
      }
      rawRows = newRows.map((row, index) => ({ ...row, sortOrder: index }))
    }

    const groupRows = (
      rows: ITableRow[],
      [groupByColumn, ...remainingGroupByColumns]: ITableColumn[],
      startRowIndex: number
    ): [GroupByDictionary, number] => {
      let groupRowsCount = 0
      const groups: GroupByDictionary = {}
      for (const [key, childRows] of Object.entries(rowGrouper(rows, groupByColumn))) {
        // Recursively group each parent group
        const [childGroups, childRowsCount] =
          remainingGroupByColumns.length === 0
            ? [childRows, childRows.length]
            : groupRows(childRows, remainingGroupByColumns, startRowIndex + groupRowsCount + 1) // 1 for parent row
        groups[key] = { childRows, childGroups, startRowIndex: startRowIndex + groupRowsCount, groupByColumn }
        groupRowsCount += childRowsCount + 1 // 1 for parent row
      }

      return [groups, groupRowsCount]
    }

    // We need to sort the data by the columns we're grouping by, then by
    // any columns specified in the user's sort
    const sortBy = groupBy
    const sortedRows = sortRows(rawRows, sortBy, columns)

    // Get columns used for grouping
    const toGroupColumns = groupBy.map((toGroupColumn) => {
      const column = columns.find((c) => c.publicId === toGroupColumn.columnId)!
      return column
    })

    return groupRows(sortedRows, toGroupColumns, 0)
  }, [groupBy, rowGrouper, rawRows, shouldGroup, columns])

  const [expandedRowsWithGroups] = useMemo(() => {
    if (!groupedRows) return [[]]

    const flattenedRows: Array<ITableRowInGroup | IGroupRowCreate | IGroupCollapsed> = []

    // While we're expanding the grouped rows (GroupByDictionary) into an array of rows
    // we track which group keys we've seen. This is used to only render the names of
    // groups in the first row of the group
    const seenGroupKeys = new Set<string>()

    const expandGroup = (group: GroupByDictionary | ITableRow[], parentKeys: string[]) => {
      if (Array.isArray(group)) {
        const [firstRow, ...otherRows] = group.map((row) => ({
          ...row,
          isGrouped: true,
          groupsToRender: [] as string[],
          groupKeys: [] as string[],
          start: false
        }))

        firstRow.start = true

        // Since a group can be nested inside other groups, we track the current group key
        // and all parent groups this row is a part of
        // The `renderValues` var is used to determine whether the value for the GroupCell should
        // be rendered or not
        for (let i = 0; i < parentKeys.length; i++) {
          const key = parentKeys.slice(0, i + 1).join(GROUP_KEY_SPLIT)
          if (!seenGroupKeys.has(key)) {
            seenGroupKeys.add(key)
            firstRow.groupsToRender.push(key)
          }
          firstRow.groupKeys.push(key)
        }

        // The row representing the create row component for the group
        const createNewGroupRow: IGroupRowCreate = {
          isAddRow: true,
          lastGroupRow: otherRows.length > 0 ? otherRows[otherRows.length - 1] : firstRow
        }

        flattenedRows.push(firstRow)

        // Use spread operator or push items individually depending
        // on the size of otherRows - this is to prevent 'RangeError - Maximum Call Stack Size Exceeded'
        if (otherRows.length > MAX_STACK_SIZE) {
          for (let i = 0; i < otherRows.length; i++) {
            flattenedRows.push(otherRows[i])
          }
        } else {
          flattenedRows.push(...otherRows)
        }

        // Only show if user is permissioned to add rows to table
        if (isContributer && !disableNewRow) {
          flattenedRows.push(createNewGroupRow)
        }

        return
      }

      for (const groupKey of Object.keys(group)) {
        const groupKeyPath = [...parentKeys, groupKey]
        const id = groupKeyPath.join(GROUP_KEY_SPLIT)
        const childGroup = group[groupKey]
        const groups = []

        // If the group has been collapsed, we insert a IGroupCollapsed
        // row into the list of rows
        if (collapsedGroupIds.has(id)) {
          const groupsToRender: string[] = []
          for (let i = 0; i < groupKeyPath.length; i++) {
            const key = groupKeyPath.slice(0, i + 1).join(GROUP_KEY_SPLIT)
            if (!seenGroupKeys.has(key)) {
              seenGroupKeys.add(key)
              groupsToRender.push(key)
            }
            groups.push(key)
          }

          const collapsedGroupRow = {
            isCollapsedRow: true,
            groupKey: id,
            groupKeys: groups,
            row: childGroup.childRows[0],
            groupsToRender
          }
          flattenedRows.push(collapsedGroupRow)
          continue
        }

        expandGroup(childGroup.childGroups, [...parentKeys, groupKey])
      }
    }

    expandGroup(groupedRows, [])

    return [flattenedRows]
  }, [groupedRows, collapsedGroupIds])

  const [maxHeight, startRowIndex, endRowIndex, rows] = useMemo(() => {
    // Extra rows added before and after the actual visible rows
    const rowPadding = 5
    // For standard display of rows
    if (!shouldGroup) {
      const startRowIndex = Math.max(0, Math.min(totalRows - 30, Math.floor(scrollTop / rowHeight) - rowPadding))
      const endRowIndex = Math.min(totalRows, Math.floor((scrollTop + gridHeight) / rowHeight + rowPadding))

      // The '+ 1' accounts for the "create row" row at the end
      const maxHeight = (totalRows + (disableNewRow ? 0 : 1)) * rowHeight

      const viewportRows = []
      for (let rowIndex = startRowIndex; rowIndex < endRowIndex; rowIndex++) {
        const row = rawRows[rowIndex]
        viewportRows.push(row)
      }

      return [maxHeight, startRowIndex, endRowIndex, viewportRows, []]

      // When we're grouping rows
    } else {
      const totalExpandedRows = expandedRowsWithGroups.length
      const startRowIndex = Math.max(
        0,
        Math.min(totalExpandedRows - 30, Math.floor(scrollTop / rowHeight) - rowPadding)
      )
      const endRowIndex = Math.min(totalExpandedRows, Math.floor((scrollTop + gridHeight) / rowHeight + rowPadding))
      const maxHeight = totalExpandedRows * rowHeight

      const viewportRows = []
      for (let rowIndex = startRowIndex; rowIndex < endRowIndex; rowIndex++) {
        const row = expandedRowsWithGroups[rowIndex]
        viewportRows.push(row)
      }

      return [maxHeight, startRowIndex, endRowIndex, viewportRows]
    }
  }, [gridHeight, scrollTop, expandedRowsWithGroups, rawRows, rowHeight, shouldGroup, disableNewRow])

  return {
    rowsCount: expandedRowsWithGroups.length,
    rows,
    startRowIndex,
    endRowIndex,
    maxHeight,
    groupedRows,
    expandedRowsWithGroups
  }
}
