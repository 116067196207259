import React, { useState } from 'react'
import Button from 'components/button'

export interface IFormValues {
  code: string
}

interface Props {
  onSubmit: (values: IFormValues) => void
}

const TwoFactorCodeForm: React.FC<Props> = ({ onSubmit }) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [values, setValues] = useState<IFormValues>({
    code: ''
  })

  const codeError = values.code === ''

  const handleSubmit = async () => {
    setSubmitting(true)
    await onSubmit(values)
    setSubmitting(false)
  }

  return (
    <div className="w-full">
      <input
        name="code"
        type="text"
        placeholder="The code from your authenticator device"
        autoComplete="off"
        autoFocus
        onChange={(event) => setValues({ ...values, code: event.target.value })}
      />
      {codeError && (
        <p className="text-red" style={{ marginTop: '10px' }}>
          The code cannot be blank
        </p>
      )}
      <Button
        style={{ marginTop: '20px', width: '100%', fontSize: '1rem' }}
        disabled={codeError}
        onClick={() => handleSubmit()}
        isLoading={submitting}
      >
        Sign In
      </Button>
    </div>
  )
}

export default TwoFactorCodeForm
