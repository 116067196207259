import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { deleteTableRowAction } from 'components/spreadsheet/contexts/data/actions'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'

interface DeleteRowModalProps extends ModalProps {
  initialRowId: string | undefined
}

const DeleteRowModal: React.FC<DeleteRowModalProps> = ({ id, open, setOpen, initialRowId }) => {
  const [processing, setProcessing] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>()

  useEffect(() => {
    setRowId(initialRowId)
  }, [])

  const { setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const onSuccess = () => {
    setOpen(false)
    setProcessing(false)
    setSnackbarMessage({
      status: 'success',
      message: 'Row successfully deleted.'
    })
  }

  const onFailure = () => {
    setSnackbarMessage({
      status: 'error',
      message: 'Something went wrong deleting the row. Please refresh and try again.'
    })
  }

  const handleDeleteRow = () => {
    if (rowId) {
      setProcessing(true)
      deleteTableRowAction([rowId], project.publicId, spreadsheetData, setSpreadsheetData, onSuccess, onFailure)
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Confirm Deletion">
      {`Are you sure you want to delete the row?`}

      <div className="flex justify-end" style={{ marginTop: '30px' }}>
        <Button
          internalType="secondary"
          style={{ marginRight: '15px' }}
          onClick={() => {
            if (!processing) setOpen(false)
          }}
        >
          Keep Row
        </Button>
        <Button
          internalType="danger"
          onClick={() => {
            if (!processing) handleDeleteRow()
          }}
        >
          Delete Row
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteRowModal
