import React, { useState, useEffect, useRef, memo } from 'react'
import api from 'helpers/api'
import CommentUser from 'components/comments/commentUserDetails'
import { ICommentObject, ActionMenuType } from 'types'
import Button from 'components/button'
import { useApplicationStore } from 'hooks/application'
import CommentActionsMenu from '../commentActionsMenu'
import { ISpreadsheetData } from 'components/spreadsheet/types'

interface ICommentActionsButtonsProps {
  projectId: string
  action: ActionMenuType | null
  comment: ICommentObject
  commentThreadId: string
  commentText: string
  spreadsheetData?: ISpreadsheetData
  onYesButton: (comment: ICommentObject) => void
  onCancelButton: () => void
}

const CommentActionsButtons: React.FC<ICommentActionsButtonsProps> = ({
  projectId,
  action,
  comment,
  commentThreadId,
  commentText,
  spreadsheetData,
  onYesButton,
  onCancelButton
}) => {
  const [disabled, setDisabled] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const { displayErrorMessage } = useApplicationStore()

  const handleOnSelectedActionButton = () => {
    setDisabled(true)
    setIsProcessing(true)
    let additionalContext = {}
    if (spreadsheetData) {
      additionalContext = {
        processPublicId: spreadsheetData.processId,
        processSectionPublicId: spreadsheetData.processSectionId,
        processResponsePublicId: spreadsheetData.processResponseId
      }
    }
    if (action === 'edit') {
      const data = { commentText: commentText, context: { projectId, ...additionalContext } }
      api
        .updateComment(commentThreadId, comment.publicId, data)
        .then((response) => {
          onYesButton(response.data)
          setIsProcessing(false)
        })
        .catch((error) => displayErrorMessage(error))
    } else {
      const context = { context: { projectId, ...additionalContext } }
      api
        .deleteComment(context, commentThreadId, comment.publicId)
        .then((response) => {
          onYesButton(response.data)
          setIsProcessing(false)
        })
        .catch((error) => displayErrorMessage(error))
    }
  }

  return (
    <div className="block" style={{ margin: '5px' }}>
      {action != 'edit' && (
        <div>
          <span style={{ height: '20px' }}>Are you sure you want to</span> <span className="bold">{action}</span>{' '}
          <span>this comment?</span>
        </div>
      )}
      <div className="inline-flex">
        <Button
          style={{ marginRight: '5px', width: '80px' }}
          onClick={() => handleOnSelectedActionButton()}
          disabled={disabled || !commentText}
          isLoading={isProcessing}
        >
          {action === 'edit' ? 'Update' : 'Yes'}
        </Button>
        <Button style={{ marginRight: '5px', width: '80px' }} internalType="danger" onClick={() => onCancelButton()}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

interface ICommentComponentProps {
  projectId: string
  commentThreadId: string
  isResolvedCommentThread: boolean
  initialComment: ICommentObject
  index: number
  spreadsheetData?: ISpreadsheetData
  onCommentChange: (index: number, comment: ICommentObject) => void
}

const ReplyCommentComponent: React.FC<ICommentComponentProps> = ({
  projectId,
  commentThreadId,
  isResolvedCommentThread,
  initialComment,
  index,
  spreadsheetData,
  onCommentChange
}) => {
  const [comment, setComment] = useState(initialComment)
  const [commentText, setCommentText] = useState(initialComment.commentText)

  const [readOnly, setReadOnly] = useState(true)
  const [selectedAction, setSelectedAction] = useState<ActionMenuType | null>(null)

  const commentRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (isResolvedCommentThread) {
      setCommentText(initialComment.commentText)
      setReadOnly(true)
      setSelectedAction(null)
    }
  }, [isResolvedCommentThread])

  const onSelecteActionMenu = (action: ActionMenuType) => {
    setSelectedAction(action)
    switch (action) {
      case 'edit':
        setReadOnly(!readOnly)
        break
      case 'delete':
        setCommentText(initialComment.commentText)
        setReadOnly(true)
        break
    }
  }

  const handleYesButton = (comment: ICommentObject) => {
    setComment(comment)
    setReadOnly(true)
    setSelectedAction(null)
    onCommentChange(index, comment)
  }

  const handleCanelButton = () => {
    setCommentText(initialComment.commentText)
    setReadOnly(true)
    setSelectedAction(null)
  }

  useEffect(() => {
    if (!readOnly) {
      if (commentRef.current) {
        commentRef.current!.selectionStart = commentRef.current!.value.length
        commentRef.current!.selectionEnd = commentRef.current!.value.length
        commentRef.current.focus()
      }
    }
  }, [readOnly])

  if (comment.deletedAt) {
    return null
  }

  return (
    <div className="flex flex-column w-full" style={{ margin: '5px 0px', padding: '0px 5px' }}>
      <div className="inline-flex space-between w-full">
        <CommentUser comment={comment} />
        <div className="inline-flex">
          {comment.isOwner && !isResolvedCommentThread && (
            <CommentActionsMenu onSelectedActionMenu={(action: ActionMenuType) => onSelecteActionMenu(action)} />
          )}
        </div>
      </div>
      {readOnly ? (
        <div className="w-full bg-white rounded whitespace-pre-wrap select-text" style={{ padding: '10px' }}>
          {commentText}
        </div>
      ) : (
        <textarea
          id={`comment-${comment.publicId}`}
          placeholder="Type your comment here..."
          value={commentText}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setCommentText(event.target.value)}
          readOnly={readOnly}
          ref={commentRef}
          wrap="hard"
        />
      )}
      {selectedAction && !isResolvedCommentThread && (
        <CommentActionsButtons
          projectId={projectId}
          action={selectedAction}
          comment={comment}
          commentText={commentText}
          commentThreadId={commentThreadId}
          spreadsheetData={spreadsheetData}
          onYesButton={(updatedComment: ICommentObject) => handleYesButton(updatedComment)}
          onCancelButton={() => handleCanelButton()}
        />
      )}
    </div>
  )
}

function areEqualComment(prevComment: ICommentObject, nextComment: ICommentObject) {
  return (
    prevComment.deletedAt === nextComment.deletedAt &&
    prevComment.updatedAt === nextComment.updatedAt &&
    prevComment.commentText === nextComment.commentText &&
    prevComment.isOwner === prevComment.isOwner &&
    prevComment.user.firebaseUserId === prevComment.user.firebaseUserId
  )
}

function areEqual(prevProps: ICommentComponentProps, nextProps: ICommentComponentProps) {
  return (
    prevProps.commentThreadId === nextProps.commentThreadId &&
    prevProps.index === nextProps.index &&
    prevProps.initialComment === nextProps.initialComment &&
    prevProps.isResolvedCommentThread === nextProps.isResolvedCommentThread &&
    areEqualComment(prevProps.initialComment, nextProps.initialComment)
  )
}

export default memo(ReplyCommentComponent, areEqual)
