import React, { useEffect, useState } from 'react'
import constants from 'style/constants.module.scss'
import api from 'helpers/api'
import useApplicationStore from 'hooks/application'

interface ContributionsProps {
  userId?: string
}

const Contributions: React.FC<ContributionsProps> = ({ userId }) => {
  const { displayErrorMessage } = useApplicationStore()

  const contributionLevels = [
    {
      colour: '#ebedf0'
    },
    {
      colour: '#9beaa9'
    },
    {
      colour: '#41c563'
    },
    {
      colour: '#31a14e'
    },
    {
      colour: '#206f39'
    }
  ]

  const [contributions, setContributions] = useState<Record<string, number>>()

  useEffect(() => {
    if (userId) {
      api
        .getContributions(userId)
        .then((response) => {
          setContributions(response.data)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    } else {
      setContributions(undefined)
    }
  }, [userId])

  const startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1))

  return (
    <div style={{ marginTop: '40px' }}>
      <div className="text-base w-full">Activity</div>

      <div
        className="flex flex-column border-1px border-grey border-solid w-full rounded heavy-shadow"
        style={{ marginTop: '20px', height: '200px', padding: '10px', maxWidth: '880px' }}
      >
        {contributions ? (
          <svg className="text-sm" width="100%" height="100%">
            <g transform="translate(15,20)">
              {new Array(52).fill(0).map((_, weekIndex) => {
                const showMonth = weekIndex % 4 === 0
                const month = new Date(startDate.getTime() + weekIndex * 7 * 24 * 60 * 60 * 1000)
                const xOffset = 16 * weekIndex
                return (
                  <g key={weekIndex} transform={`translate(${xOffset},2)`}>
                    {showMonth && (
                      <text x={14} y={-8}>
                        {month.toLocaleDateString('default', { month: 'short', year: '2-digit' })}
                      </text>
                    )}

                    {new Array(7).fill(0).map((_, dayIndex) => {
                      const daysAhead = weekIndex * 7 + dayIndex
                      const dateKey = new Date(startDate.getTime() + daysAhead * 24 * 60 * 60 * 1000)
                        .toISOString()
                        .split('T')[0]
                        .split('-')
                        .map((x) => parseInt(x))
                        .join('-')

                      const numberContributions = contributions
                        ? contributions[dateKey]
                          ? contributions[dateKey]
                          : 0
                        : 0

                      const contributionLevel = Math.ceil(numberContributions / 25)
                      return (
                        <rect
                          key={dayIndex}
                          width={11}
                          height={11}
                          y={dayIndex * 17}
                          fill={
                            contributionLevels[contributionLevel] && contributionLevels[contributionLevel].colour
                              ? contributionLevels[contributionLevel].colour
                              : contributionLevels[4].colour
                          }
                          style={{ outline: `1px solid ${constants.grey}` }}
                        />
                      )
                    })}
                  </g>
                )
              })}
            </g>
          </svg>
        ) : (
          <span className="skeleton-box rounded w-full" style={{ height: '278px' }} />
        )}
        <div className="text-sm flex flex-row items-center justify-end" style={{ marginTop: 'auto' }}>
          <span style={{ marginRight: '5px' }}>Less</span>

          {contributionLevels.map((level, index) => {
            return (
              <svg key={index} width={10} height={10} style={{ display: 'inline-block', marginRight: '2px' }}>
                <title>A summary of contributions to documents and tables in the Morta platform.</title>
                <rect width={10} height={10} fill={level.colour} rx={2} ry={2} />
              </svg>
            )
          })}

          <span style={{ marginLeft: '3px' }}>More</span>
        </div>
      </div>
    </div>
  )
}

export default Contributions
