import React from 'react'
import constants from 'style/constants.module.scss'
import { rowHeights } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'

interface Props {
  loading?: boolean
  value: string
  rowHeight: rowHeights
  isRootGroup?: boolean
  useGreyBackground?: boolean
}

const RowNumberCell: React.FC<Props> = ({ value, loading, rowHeight, isRootGroup, useGreyBackground }) => {
  const width = Number(constants.rowNumberColumnWidth)
  return (
    <div
      className="table sticky bg-inherit text-center box-border"
      style={{
        zIndex: 3,
        borderBottom: `1px solid ${constants.grey}`,
        borderRight: `1px soloid ${constants.lightGrey}`,
        borderLeft: 'none',
        width: `${Number.parseInt(constants.rowNumberColumnWidth)}px`,
        height: getRowHeightVariable(rowHeight),
        left: '0px',
        lineHeight: `${getRowHeightVariable(rowHeight)}px`,
        borderTop: isRootGroup ? `1px solid ${constants.darkerGrey}` : 'none',
        backgroundColor: useGreyBackground ? constants.lightGrey : 'none'
      }}
    >
      <div>
        {loading ? (
          <span
            className="skeleton-box"
            style={{ width: `${width}px`, height: `${getRowHeightVariable(rowHeight) - 6}px` }}
          />
        ) : (
          <div>{value ? value : null}</div>
        )}
      </div>
    </div>
  )
}

export default React.memo(RowNumberCell)
