import { ICellValue, ITableViewColour, ITableViewColumn } from 'types'
import { doesFilterMatchValue } from 'components/spreadsheet/helpers/filtering'
import constants from 'style/constants.module.scss'

export const getCellColour = (
  column: ITableViewColumn,
  value?: ICellValue,
  colourSettings?: ITableViewColour[],
  greyBackground?: boolean
) => {
  let colour = greyBackground ? constants.lightGrey : '#fff'
  if (column && colourSettings && value !== undefined) {
    for (let i = 0; i < colourSettings.length; i++) {
      const colourSetting = colourSettings[i]
      if (colourSetting.columnId === column.publicId) {
        const match = doesFilterMatchValue(
          colourSetting.filterType,
          colourSetting.value,
          colourSetting.multipleValues,
          value,
          '',
          '',
          [],
          [],
          [],
          [],
          column.kind
        )
        if (match) {
          colour = colourSetting.backgroundColour
          break
        }
      }
    }
  }
  return colour
}
