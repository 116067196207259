import React from 'react'
import Checkbox from 'components/checkbox'
import { ITableWithCells, IAccessPolicy, ITag, OptionProps } from 'types'
import { UserRoles, AccessAttributeKinds } from 'app-constants'
import Select from 'components/select'

interface Props {
  tableId: string
  tags: ITableWithCells
  policies: IAccessPolicy[]
  onTagAdd: (tagId: string) => void
  onTagDelete: (tagId: string) => void
  onAllTagsAdd: (tableId: string, role: UserRoles) => void
  onAllTagsDelete: (tableId: string, role: UserRoles) => void
  role: UserRoles
}

const isTagInCurrentTable = (tag: ITag, tableId: string) => {
  /* eslint-disable-next-line */
  const [prefix, tagTableID, _columnId, _rowId] = tag.referencePublicId.split('/')
  return tagTableID === tableId
}

const TagTable: React.FC<Props> = ({
  tableId,
  tags,
  policies,
  onTagAdd,
  onTagDelete,
  role,
  onAllTagsAdd,
  onAllTagsDelete
}) => {
  const tagOptions: OptionProps[] = tags.cells.map((cell) => ({
    label: cell.value,
    value: cell.id,
    group: cell.column.name
  }))

  // List of policy.accessAttribute.tag.referencePublicId
  const tagPolicyReferenceIds = policies
    .filter(
      (policy) =>
        policy.accessAttribute.kind == AccessAttributeKinds.TAG &&
        isTagInCurrentTable(policy.accessAttribute.tag!, tableId) &&
        policy.role === role
    )
    .map((p) => p.accessAttribute.tag!.referencePublicId)

  const selectedTagOptions = tagOptions.filter(
    (option) => option.value !== undefined && tagPolicyReferenceIds.includes(option.value)
  )

  // If there is a policy for the "Allow any tag" option
  const allTableTagsPolicy = policies.find((policy) => {
    if (
      policy.accessAttribute.kind === AccessAttributeKinds.ALL_TABLE_TAGS &&
      policy.accessAttribute.documentTable?.publicId === tableId &&
      policy.role === role
    ) {
      return true
    }
  })

  const handleChange = (option: string) => {
    if (!selectedTagOptions.find((o) => o.value == option)) {
      onTagAdd(option)
    } else {
      onTagDelete(option)
    }
  }

  const handleSelectAll = () => {
    if (allTableTagsPolicy) {
      onAllTagsDelete(tableId, role)
    } else {
      onAllTagsAdd(tableId, role)
    }
  }

  const active = selectedTagOptions.length > 0 || allTableTagsPolicy !== undefined

  return (
    <div className="flex items-center transition-all rounded" style={{ margin: '10px', opacity: active ? 1 : 0.5 }}>
      <div className="font-bold truncate" style={{ minWidth: '150px' }}>
        {tags.name + ' Tags'}:
      </div>
      <div style={{ minWidth: '150px' }}>
        <Checkbox checked={allTableTagsPolicy ? true : false} onChange={handleSelectAll}>
          At least one tag
        </Checkbox>
      </div>

      <div style={{ width: '500px', marginLeft: '20px' }}>
        <Select
          options={tagOptions}
          onOptionClick={(option) => handleChange(option)}
          multiselect={true}
          groupBy={true}
          optionsSelected={selectedTagOptions.map((option) => (option.value ? option.value : ''))}
          disabled={allTableTagsPolicy ? true : false}
        />
      </div>
    </div>
  )
}

export default TagTable
