import React from 'react'
import Button from 'components/button'
import { IntegrationProviders } from 'app-constants'
export const BUTTON_WIDTH = '185px'

interface IntegrationProviderViewProps {
  provider: IntegrationProviders
  logo: string
  connected: boolean
  redirectUrl: string
  connectingAccount: boolean
  disconnectAccount: (provider: IntegrationProviders) => void
}

const IntegrationProviderView: React.FC<IntegrationProviderViewProps> = ({
  provider,
  logo,
  connected,
  redirectUrl,
  connectingAccount,
  disconnectAccount
}) => {
  return (
    <div>
      <div className="flex flex-column rounded heavy-shadow w-full p-10px flex-wrap my-20px border-1px border-solid border-black">
        <div className="flex flex-row items-center">
          <img src={logo} alt={`${provider} logo`} style={{ width: '150px', marginRight: 'auto' }} />

          {!connected ? (
            <a
              href={redirectUrl}
              style={{
                cursor: connectingAccount ? 'not-allowed' : 'auto',
                pointerEvents: connectingAccount ? 'none' : 'auto'
              }}
            >
              <Button style={{ width: BUTTON_WIDTH }} disabled={connectingAccount}>
                Connect Account
              </Button>
            </a>
          ) : (
            <Button
              internalType="danger"
              style={{ width: BUTTON_WIDTH }}
              onClick={() => disconnectAccount(provider)}
              disabled={connectingAccount}
            >
              Disconnect Account
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default IntegrationProviderView
