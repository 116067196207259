import React, { IframeHTMLAttributes } from 'react'

export interface IFrameProps extends IframeHTMLAttributes<HTMLIFrameElement> {
  blockProps: { src: string; height: number }
}

const IFrame: React.FC<IFrameProps> = (props) => {
  const { blockProps } = props

  return <iframe height={blockProps.height} width={'100%'} src={blockProps.src} frameBorder="0"></iframe>
}

export default IFrame
