import React, { useEffect, useState } from 'react'
import api from 'helpers/api'
import useApplicationStore from 'hooks/application'
import Button from 'components/button'
import useAuth from 'hooks/auth'
import { Table } from 'components/table'
import { ISubscriptionComparison, IUserObject } from 'types'
import constants from 'style/constants.module.scss'
import Modal from 'components/modal'

const Subscription = () => {
  document.title = 'Subscription'
  const { displayErrorMessage } = useApplicationStore()
  const { user } = useAuth()

  const [loadingSubscription, setLoadingSubscription] = useState<boolean>(true)
  const [me, setMe] = useState<IUserObject>()
  const [subscriptionComparison, setSubscriptionComparison] = useState<ISubscriptionComparison[]>()
  const [allocateSubscriptionModal, setAllocateSubscriptionModal] = useState<boolean>(false)
  const [newSubscriptionUser, setNewSubscriptionUser] = useState<string>('')

  useEffect(() => {
    api
      .getMe()
      .then((response) => {
        if (response && response.data) {
          setMe(response.data)
          setLoadingSubscription(false)
        }
      })
      .catch((e) => {
        displayErrorMessage(e)
        setLoadingSubscription(false)
      })
  }, [])

  useEffect(() => {
    api
      .getSubscriptionComparison()
      .then((response) => {
        setSubscriptionComparison(response.data)
      })
      .catch((e) => {
        displayErrorMessage(e)
      })
  }, [])

  return (
    <div className="w-full" style={{ padding: '0 30px' }}>
      <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
        <div>Active Subscription Plan</div>
        {!loadingSubscription && (me?.subscriptionLevel === 0 || me?.isOnFreeTrial) && (
          <a
            href={`mailto:mo@morta.io?subject=Morta Account Upgrade (${
              user && user.email
            })&body=Hello, I would like to upgrade my account to an advanced license. Please let me know the next steps.`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 'auto' }}
          >
            <Button internalType="flashing" data-cy="upgrade-subscription" disabled={loadingSubscription}>
              Upgrade Subscription
            </Button>
          </a>
        )}
      </div>

      <div className="flex flex-row items-center rounded heavy-shadow w-full p-10px flex-wrap my-20px border-1px border-solid border-black">
        {loadingSubscription ? (
          <span className="skeleton-box" style={{ marginRight: '10px', width: '50px', height: '50px' }} />
        ) : (
          <div className="flex flex-column w-full box-shadow p-4" style={{ gap: '20px' }}>
            {me && me.subscriptionLevel === 0 && (
              <div>
                <div className="font-bold text-xl">Basic Plan</div>
                <div>
                  You are currently on the basic plan. You are <b>unable to create documents and tables on Morta</b>, as
                  well as access other functionality.
                </div>
                <div className="text-secondary italic">Upgrade for £100 per month</div>
              </div>
            )}

            {me && me.subscriptionLevel > 0 && (
              <div>
                <div className="font-bold text-xl mb-4">
                  Advanced Plan{me && me.isOnFreeTrial && ` Free Trial (${me.freeTrialDaysRemaining} Days Remaining)`}
                </div>
                <div className="mb-4">
                  It is great having you on the advanced plan 🚀. You are able to do everything you want on Morta,
                  including creating as many documents and tables as you want.
                </div>
                <div className="text-secondary italic">{me && me.isOnFreeTrial && 'Upgrade for £100 per month'}</div>
              </div>
            )}
          </div>
        )}
      </div>

      {me && (me.subscriptionQuota > 0 || me.numberOfManagedSubscriptionUsers > 0) && (
        <div className="mb-8">
          <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
            <div style={{ marginRight: 'auto' }}>
              Allocate Advanced Licenses ({me.subscriptionQuota - me.numberOfManagedSubscriptionUsers} Remaining)
            </div>
            {me.numberOfManagedSubscriptionUsers < me.subscriptionQuota ? (
              <Button
                internalType="accept"
                onClick={() => {
                  setAllocateSubscriptionModal(true)
                }}
              >
                + Allocate Advanced License
              </Button>
            ) : (
              <Button internalType="danger" disabled={true}>
                Maximum Users Allocated ({me.subscriptionQuota})
              </Button>
            )}
          </div>
          <Table
            include={[
              {
                header: 'Name',
                id: 'name'
              },
              {
                header: 'Email',
                id: 'email'
              },
              {
                header: 'Remove Subscription',
                id: 'remove'
              }
            ]}
            data={
              me && me.managedSubscriptionUsers
                ? me.managedSubscriptionUsers.map((user) => {
                    return {
                      name: {
                        label: user.name,
                        value: user.name
                      },
                      email: {
                        label: user.email,
                        value: user.email
                      },
                      remove: {
                        label: (
                          <Button
                            internalType="danger"
                            onClick={() => {
                              window.confirm(`Are you sure you want to remove ${user.email} from your subscription?`) &&
                                api
                                  .removeLicense(user.email)
                                  .then(() => {
                                    window.location.reload()
                                  })
                                  .catch((e) => {
                                    displayErrorMessage(e)
                                  })
                            }}
                          >
                            Remove
                          </Button>
                        ),
                        value: 'Remove'
                      }
                    }
                  })
                : []
            }
            loading={false}
            defaultSort="name"
            defaultSortAscending={true}
          />
        </div>
      )}

      <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
        <div>Subscription Plan Comparison</div>
      </div>
      <Table
        include={[
          {
            header: 'API Path',
            id: 'path'
          },
          {
            header: 'Description',
            id: 'description'
          },
          {
            header: 'Method',
            id: 'method'
          },
          {
            header: 'Basic Plan',
            id: 'basic'
          },
          {
            header: 'Advanced Plan',
            id: 'advanced'
          }
        ]}
        data={
          subscriptionComparison
            ? subscriptionComparison.map((subElement) => {
                return {
                  endpoint: {
                    label: subElement.endpoint,
                    value: subElement.endpoint
                  },
                  path: {
                    label: (
                      <div className="truncate" style={{ width: '300px' }} title={subElement.path}>
                        {subElement.path}
                      </div>
                    ),
                    value: subElement.path
                  },
                  description: {
                    label: (
                      <div className="truncate" style={{ width: '300px' }} title={subElement.description}>
                        {subElement.description}
                      </div>
                    ),
                    value: subElement.description
                  },
                  method: {
                    label: (
                      <div
                        className={`flex items-center justify-center text-xs font-bold border-1 border-grey border-solid ${
                          subElement.method === 'GET'
                            ? 'bg-light-green'
                            : subElement.method === 'PUT'
                            ? 'bg-light-blue'
                            : subElement.method === 'POST'
                            ? 'bg-light-orange'
                            : subElement.method === 'DELETE'
                            ? 'bg-light-pink'
                            : 'bg-light-grey'
                        }`}
                        style={{ padding: '2px', marginRight: '20px' }}
                      >
                        {subElement.method}
                      </div>
                    ),
                    value: subElement.method
                  },
                  basic: {
                    label: (
                      <div className="flex w-full items-center justify-center">{subElement.basic ? '✅' : '❌'}</div>
                    ),
                    value: subElement.basic ? 'Yes' : 'No'
                  },
                  advanced: {
                    label: (
                      <div className="flex w-full items-center justify-center">{subElement.advanced ? '✅' : '❌'}</div>
                    ),
                    value: subElement.advanced ? 'Yes' : 'No'
                  }
                }
              })
            : []
        }
        loading={subscriptionComparison === undefined}
        defaultSort="path"
        defaultSortAscending={true}
        highlightColumn={me && me.subscriptionLevel === 0 ? 'basic' : 'advanced'}
        highlightColor={constants.reallyLightYellow}
      />

      {me && allocateSubscriptionModal && (
        <Modal
          title="Allocate Advanced License"
          id="allocate-license"
          open={allocateSubscriptionModal}
          setOpen={setAllocateSubscriptionModal}
        >
          <div className="mb-8">
            You have {me.subscriptionQuota - me.numberOfManagedSubscriptionUsers} licenses left to allocate.
          </div>

          <input
            type="text"
            placeholder="Email Address"
            className="w-full mb-8"
            onChange={(event) => setNewSubscriptionUser(event.target.value)}
          />

          <div className="flex flex-row items-center w-full">
            <Button
              className="ml-auto"
              internalType="accept"
              disabled={newSubscriptionUser === ''}
              onClick={() =>
                api
                  .allocateLicense(newSubscriptionUser)
                  .then(() => {
                    window.location.reload()
                  })
                  .catch((e) => {
                    displayErrorMessage(e)
                  })
              }
            >
              Allocate License
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Subscription
