import React, { useEffect, useRef, useState, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { IContextMenuState } from 'types'
import constants from 'style/constants.module.scss'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { KEY_PRESS } from 'components/spreadsheet/constants/const'

export interface MenuProps {
  id: string
  menuState: IContextMenuState
  setMenuState: (menuState: IContextMenuState) => void
  width?: number
  children?: React.ReactNode
  zIndex?: number
  onClose?: () => void
  rootComponent?: HTMLElement | null
}

const Menu: React.FC<MenuProps> = ({
  id,
  menuState,
  setMenuState,
  children,
  width,
  zIndex,
  onClose,
  rootComponent
}) => {
  const appRootElement = document.getElementById('root') || document.body // Default to body if root is not found
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const componentWidth = width || Number(constants.defaultMenuWidth)
  const [componentHeight, setComponentHeight] = useState<number>(0)

  const handleMenuClose = useCallback(() => {
    if (onClose) onClose()
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }, [onClose, setMenuState])

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!menuState.open || !contextMenuRef.current) return

      const composedPath = event.composedPath()
      const isInside = composedPath.some((element) => {
        const htmlElement = element as HTMLElement
        return (
          htmlElement === contextMenuRef.current ||
          (htmlElement.id &&
            (htmlElement.id.includes('select-options') || htmlElement.id.includes('change-column-kind-modal')))
        )
      })

      if (!isInside) {
        handleMenuClose()
      }
    },
    [menuState.open, handleMenuClose]
  )

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (menuState.open && event.key === KEY_PRESS.esc) {
        handleMenuClose()
      }
    },
    [menuState.open, handleMenuClose]
  )
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleClickOutside, handleKeyDown])

  useEffect(() => {
    if (menuState.open && contextMenuRef.current) {
      setComponentHeight(contextMenuRef.current.getBoundingClientRect().height)
    }
  }, [menuState.open])

  const getValidatedPosition = useCallback(
    (value: string, axis: 'horizontal' | 'vertical') => {
      const num = parseFloat(value.replace(/px$/, ''))
      const margin = 10 // margin from the bottom of the screen
      if (axis === 'horizontal') {
        return `${Math.min(window.innerWidth - componentWidth, Math.max(0, num))}px`
      }
      return `${Math.min(window.innerHeight - componentHeight - margin, Math.max(0, num))}px`
    },
    [componentWidth, componentHeight]
  )

  const validatedTop = getValidatedPosition(menuState.top, 'vertical')
  const validatedLeft = getValidatedPosition(menuState.left, 'horizontal')
  if (menuState.open) {
    return ReactDOM.createPortal(
      <div
        id={id}
        ref={contextMenuRef}
        className="absolute bg-white rounded border-1 border-solid border-grey select-none h-fit whitespace-nowrap text-sm"
        style={{
          top: validatedTop,
          left: validatedLeft,
          width: `${componentWidth}px`,
          zIndex: zIndex || 1000
        }}
      >
        {children}
      </div>,
      rootComponent || appRootElement
    )
  } else {
    return null
  }
}

export default React.memo(Menu)
