import React from 'react'
import PieChart from 'components/chart/components/pie'
import BarChart from 'components/chart/components/bar'
import { IChartType } from 'types'
import { ChartProps } from 'types'

const Chart: React.FC<ChartProps> = ({ type, data, showLegend, showValues, loading, axisNames }) => {
  const getChart = (type: IChartType) => {
    const properties = { data, showLegend, showValues }
    if (type === 'pie') return <PieChart {...properties} />
    else if (type === 'bar') return <BarChart {...properties} axisNames={axisNames} />
    else if (type === null) return null
    else return <div>Chart Not Found</div>
  }
  if (loading)
    return (
      <div className="spinner-chart">
        <div className="spin" style={{ height: 50, width: 50 }} />
      </div>
    )

  return getChart(type)
}

export default Chart
