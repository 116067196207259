import React from 'react'
import { Link } from 'react-router-dom'
import { useProject } from 'hooks/project'
import { tint } from 'style/utils'
import constants from 'style/constants.module.scss'

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type?: 'button' | 'reset' | 'submit' | undefined
  internalType?: 'primary' | 'outline' | 'secondary' | 'danger' | 'accept' | 'grey' | 'gradient' | 'flashing'
  to?: string
  isLoading?: boolean
  innerRef?: React.Ref<HTMLButtonElement>
}

const Button = (props: ButtonProps) => {
  const {
    style = {},
    className = '',
    onClick,
    type = 'button',
    children,
    disabled,
    internalType = 'primary',
    to,
    isLoading,
    innerRef,
    ...rest
  } = props
  const { project } = useProject()

  let backgroundColor = constants.blue
  let backgroundImage = ''
  let otherClasses = ''

  switch (internalType) {
    case 'primary':
      backgroundColor = tint(project.primaryColour, Number.parseInt(constants.secondaryTint))
      otherClasses = 'text-white button-shadow-hover'
      break
    case 'outline':
      backgroundColor = 'white'
      otherClasses = 'border-1px border-solid border-black text-primary button-shadow-hover'
      break
    case 'secondary':
      backgroundColor = project.primaryColour
      otherClasses = 'text-white button-shadow-hover'
      break
    case 'danger':
      backgroundColor = constants.red
      otherClasses = 'button-shadow-hover'
      break
    case 'accept':
      backgroundColor = constants.green
      otherClasses = 'button-shadow-hover'
      break
    case 'grey':
      backgroundColor = constants.grey
      otherClasses = 'text-primary'
      break
    case 'gradient':
      backgroundImage = `linear-gradient(to right, #afaee5 0%, #006dc0 40%)`
      otherClasses = 'button-shadow-hover border-0'
      break
    case 'flashing':
      backgroundColor = constants.alertColor
      otherClasses = 'pulsate-border bold text-primary'
      break
    default:
      break
  }

  if (to) {
    return (
      <Link className="flex flex-none items-center" to={to}>
        <button
          ref={innerRef}
          className={`${className} ${otherClasses} font-semibold`}
          type={type}
          style={{ ...style, backgroundColor }}
          onClick={onClick}
          disabled={disabled || isLoading}
          {...rest}
        >
          {!isLoading && <div className="flex w-full items-center justify-center">{children}</div>}
          {isLoading && (
            <div className="flex w-full items-center justify-center">
              {children}
              <div className="spin" style={{ color: 'white', marginLeft: '10px', height: '20px', width: '20px' }} />
            </div>
          )}
        </button>
      </Link>
    )
  } else {
    return (
      <button
        ref={innerRef}
        className={`${className} ${otherClasses} font-semibold select-none`}
        style={{ ...style, backgroundColor, backgroundImage }}
        type={type}
        disabled={disabled || isLoading}
        onClick={onClick}
        {...rest}
      >
        {!isLoading && <div className="flex w-full items-center justify-center">{children}</div>}
        {isLoading && (
          <div className="flex w-full items-center justify-center">
            {children}
            <div className="spin" style={{ marginLeft: '10px', height: '20px', width: '20px' }} />
          </div>
        )}
      </button>
    )
  }
}

export default Button
