import React, { useEffect, useRef, useState } from 'react'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { APIError } from 'helpers/api'
import useApplicationStore from 'hooks/application'
import useProject from 'hooks/project'
import CodeMirror from 'react-codemirror'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import { DATE_COLUMN_TYPES, NUMBER_COLUMN_TYPES } from 'components/spreadsheet/constants/const'
import IconSelector from 'components/spreadsheet/components/cell/components/icon'
import { capitaliseFirstLetter } from 'helpers/utils'
import Button from 'components/button'
import constants from 'style/constants.module.scss'

interface ScriptProps {
  columnPublicId: string
  script: string | undefined | null
  handleUpdateColumnError: (error: any) => void
}

const Script: React.FC<ScriptProps> = ({ columnPublicId, script, handleUpdateColumnError }) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { setSnackbarMessage } = useApplicationStore()

  const [newScript, setNewScript] = useState<string>('')
  const [scriptError, setScriptError] = useState<string>('')

  const codeMirrorRef = useRef<any>(null)

  useEffect(() => {
    if (script) setNewScript(script)
  }, [script])

  const handleScriptChange = () => {
    if (columnPublicId) {
      updateColumn(
        project.publicId,
        'script',
        spreadsheetData,
        setSpreadsheetData,
        columnPublicId,
        newScript,
        () => {
          setSnackbarMessage({ status: 'success', message: 'The column was updated successfully!' })
          setScriptError('')
        },
        (error) => {
          if (error instanceof APIError) {
            if (error.rawCode === 422) {
              handleUpdateColumnError('Something is wrong with your script')
              setScriptError(error.message)
            } else {
              handleUpdateColumnError(error)
            }
          } else {
            handleUpdateColumnError(error)
          }
        },
        {
          runScriptOnAllCells: true
        }
      )
    }
  }

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        {scriptError !== '' && (
          <pre
            className="text-sm w-full bg-light-grey"
            style={{
              lineHeight: 2,
              marginBottom: '20px',
              padding: '10px',
              wordWrap: 'break-word',
              whiteSpace: 'pre-wrap',
              wordBreak: 'normal',
              border: `2px solid ${constants.lightRed}`
            }}
          >
            {scriptError}
          </pre>
        )}

        <CodeMirror
          ref={codeMirrorRef}
          value={script ? script : ''}
          onChange={(code: string) => setNewScript(code)}
          options={{
            lineNumbers: true,
            mode: 'python',
            indentUnit: 4,
            indentWithTabs: false,
            lineWrapping: true,
            foldGutter: true,
            gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
            theme: 'material-darker',
            extraKeys: {
              Tab: function (cm) {
                const spaces = Array(5).join(' ')
                cm.replaceSelection(spaces)
              }
            }
          }}
        />

        <div className="flex" style={{ marginTop: '10px' }}>
          <Button className="ml-auto" internalType="accept" onClick={() => handleScriptChange()}>
            Update Script
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Script
