import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Modal, { ModalProps } from 'components/modal'
import { IProcessObject } from 'types'
import api from 'helpers/api'
import Editor from 'components/editor'
import Button from 'components/button'
import ProcessComponent from 'components/process'
import { PERMISSIONS } from 'helpers/auth'
import useAuth from 'hooks/auth'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
import useProject from 'hooks/project'
import useApplicationStore from 'hooks/application'

const Templates: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { user } = useAuth()
  const { project } = useProject()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [searchText, setSearchText] = useState<string>('')
  const [templates, setTemplates] = useState<Partial<IProcessObject>[]>()
  const [selectedTemplate, setSelectedTemplate] = useState<{
    templateId: string
    projectId: string
  }>()

  const templateStyle = {}
  const templateClass =
    'items-center rounded cursor-pointer transition-all border-1px border-solid border-black box-border hover-heavy-shadow bg-white'

  useEffect(() => {
    api.getTemplates().then((response) => {
      setTemplates(response.data)
    })
  }, [])

  const duplicateProcess = (templateId: string) => {
    api
      .duplicateProcess({
        publicId: templateId,
        targetProjectId: project.publicId,
        duplicatePermissions: true
      })
      .then(() => {
        setSnackbarMessage({
          status: 'success',
          message: 'Duplication has started. You will receive an email once it has been complete.'
        })
        setOpen(false)
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={
        !selectedTemplate ? (
          <input
            className="text-base-sm"
            type="text"
            placeholder={'Search for a template'}
            autoFocus
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            style={{ width: '40%', height: '100%' }}
          />
        ) : (
          <Button internalType="outline" onClick={() => setSelectedTemplate(undefined)}>
            ← Back
          </Button>
        )
      }
      removePadding={true}
    >
      {!selectedTemplate ? (
        <div>
          <div className="flex text-base-sm">
            <div className="overflow-y-auto w-full" style={{ padding: '30px 40px' }}>
              {templates ? (
                <div className="grid gap-16px" style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(400px, 1fr))' }}>
                  {templates.map((template, templateIndex) => {
                    return (
                      <div
                        key={templateIndex}
                        className={templateClass}
                        style={templateStyle}
                        tabIndex={0}
                        onClick={() => {
                          if (template && template.publicId && template.projectPublicId) {
                            setSelectedTemplate({
                              templateId: template.publicId,
                              projectId: template.projectPublicId
                            })
                          }
                        }}
                      >
                        <div
                          className="flex relative w-full overflow-hidden justify-center items-center"
                          style={{ height: '100px' }}
                        >
                          {template.logo && (
                            <img
                              src={template.logo}
                              className="absolute"
                              style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%'
                              }}
                            />
                          )}
                        </div>
                        <div>
                          <div
                            className="font-bold text-lg box-border border-t-1px border-solid border-darker-grey"
                            style={{ marginBottom: '10px', padding: '10px' }}
                          >
                            {template.name}
                          </div>
                          <div className="overflow-y-auto b" style={{ height: '200px', padding: '5px' }}>
                            <Editor
                              databaseDoc={template.description}
                              readOnly={true}
                              editorId={templateIndex.toString()}
                              border={false}
                            />
                          </div>
                          {template && template.createdBy && (
                            <div
                              className="box-border border-t-1px border-solid border-darker-grey"
                              style={{ padding: '15px 10px' }}
                            >
                              <div className="flex items-center">
                                <img
                                  src={
                                    template.createdBy.profilePicture
                                      ? template.createdBy.profilePicture
                                      : '/assets/images/user.png'
                                  }
                                  style={{ height: '25px', width: '25px', borderRadius: '50%' }}
                                />
                                <div className="text-secondary" style={{ marginLeft: '5px' }}>
                                  Created by{' '}
                                  <Link
                                    to={`/profile/${template.createdBy.firebaseUserId}/information`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {template.createdBy.name}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div>
                  <div className="font-bold text-2xl" style={{ marginBottom: '16px' }}>
                    <span className="skeleton-box" style={{ height: '32px', width: '200px' }} />
                  </div>
                  <div className="grid grid-cols-2 sm-grid-cols-1 gap-16px">
                    <span className="skeleton-box" style={{ height: '100px' }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ height: '50vh' }}>
            <ProcessComponent
              processPublicId={selectedTemplate.templateId}
              permissionCap={PERMISSIONS.viewer}
              disableControls={true}
            />
          </div>

          <div className="flex justify-end border-t-2px border-solid border-grey" style={{ padding: '20px' }}>
            <Button
              to={`/project/${selectedTemplate.projectId}/process/${selectedTemplate.templateId}`}
              onClick={() => {
                setOpen(false)
                setSelectedTemplate(undefined)
              }}
            >
              View In {capitaliseFirstLetter(PROJECT)}
            </Button>
            <Button
              internalType="accept"
              style={{ marginLeft: '10px' }}
              onClick={() => duplicateProcess(selectedTemplate.templateId)}
              disabled={!user || project.publicId === ''}
            >
              {user
                ? project.publicId !== ''
                  ? 'Use This Template'
                  : `Go To A ${capitaliseFirstLetter(PROJECT)} To Use Template`
                : 'Sign In To Use This Template'}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  )
}
export default Templates
