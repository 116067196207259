import React, { useEffect, useState } from 'react'
import { numberWithCommas } from 'components/spreadsheet/helpers/format'
import constants from 'style/constants.module.scss'
import { IAchievementsObject } from 'types'
import api from 'helpers/api'
import useApplicationStore from 'hooks/application'

interface AchievementsProps {
  userId?: string
}

const Achievements: React.FC<AchievementsProps> = ({ userId }) => {
  const { displayErrorMessage } = useApplicationStore()

  const [achievements, setAchievements] = useState<IAchievementsObject>()
  useEffect(() => {
    if (userId) {
      api
        .getAchievements(userId)
        .then((response) => {
          setAchievements(response.data)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    } else {
      setAchievements(undefined)
    }
  }, [userId])

  return (
    <div className="mt-20px font-bold w-full border-t-1px border-grey border-solid">
      <div className="mt-10px">Achievements</div>
      <div className="flex flex-row flex-wrap" style={{ marginTop: '10px' }}>
        {achievements !== undefined && achievements.createdProcesses !== undefined ? (
          achievements.createdProcesses !== 0 ? (
            <div className="relative inline-block" title={`${achievements.createdProcesses} Documents Created`}>
              <img src="/assets/images/built-processes.png" style={{ height: '60px', width: '60px' }} />
              <div
                className="absolute text-sm"
                style={{
                  padding: '2px',
                  bottom: -5,
                  right: -2,
                  backgroundColor: constants.lightBlue,
                  borderRadius: '30%'
                }}
              >
                x{numberWithCommas(achievements.createdProcesses)}
              </div>
            </div>
          ) : (
            <div />
          )
        ) : (
          <span className="skeleton-box" style={{ width: '60px', height: '60px', borderRadius: '50%' }} />
        )}

        {achievements !== undefined && achievements.createdTables !== undefined ? (
          achievements.createdTables !== 0 ? (
            <div
              className="relative inline-block"
              title={`${achievements.createdTables} Tables Created`}
              style={{ marginLeft: '15px' }}
            >
              <img src="/assets/images/created-tables.png" style={{ height: '60px', width: '60px' }} />
              <div
                className="absolute text-sm"
                style={{
                  padding: '2px',
                  bottom: -5,
                  right: -2,
                  backgroundColor: constants.lightRed,
                  borderRadius: '30%'
                }}
              >
                x{numberWithCommas(achievements.createdTables)}
              </div>
            </div>
          ) : (
            <div />
          )
        ) : (
          <span
            className="skeleton-box"
            style={{ marginLeft: '15px', width: '60px', height: '60px', borderRadius: '50%' }}
          />
        )}
      </div>
    </div>
  )
}

export default Achievements
