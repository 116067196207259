import React from 'react'
import { ICommentObject } from 'types'
import { dateTime, getDifferenceInSeconds, getDifferenceInDays } from 'helpers/date'
interface ICommentUserProps {
  comment: ICommentObject
}

const CommentUser: React.FC<ICommentUserProps> = ({ comment }) => {
  if (!comment || !comment.user) {
    return null
  }

  const fixedCreatedAt = dateTime(new Date(comment.createdAt))
  const fixedUpdatedAt = dateTime(new Date(comment.updatedAt))
  const editTime = getDifferenceInSeconds(fixedUpdatedAt, fixedCreatedAt)
  const isEdited = comment.updatedAt && editTime > 0
  const moreThanOneDay = getDifferenceInDays(fixedUpdatedAt, fixedCreatedAt) >= 1
  const createdDateFormatted = fixedCreatedAt.toLocaleDateString()

  return (
    <div className="relative inline-flex text-sm" style={{ margin: '5px', float: 'left' }}>
      <img
        src={comment.user.profilePicture ? comment.user.profilePicture : '/assets/images/user.png'}
        style={{ width: '20px', height: '20px', borderRadius: '50%', objectFit: 'cover' }}
      />
      <div style={{ display: 'inline-grid' }}>
        <span style={{ marginRight: '5px' }}>{comment.user!.name}</span>
        <div>
          {moreThanOneDay ? (
            <span className="italic">{createdDateFormatted}</span>
          ) : (
            <span className="italic">{createdDateFormatted}</span>
          )}
          {isEdited && <span> (edited)</span>}
        </div>
      </div>
    </div>
  )
}
export default CommentUser
