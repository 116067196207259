import React, { useState, useEffect } from 'react'
import { getRowHeightVariable, isSameValueStringValue } from 'components/spreadsheet/helpers/functions'

interface Props {
  value: boolean | null
  align: 'center' | 'left' | 'right'
  color: string
  readOnly: boolean
  width: number
  rowHeight: number
  selected: boolean
  handleKeyDown: (event: React.KeyboardEvent) => void
  setCellValue: (value: string | null) => void
}

const Checkbox: React.FC<Props> = ({
  value,
  align,
  color,
  readOnly,
  width,
  rowHeight,
  selected,
  handleKeyDown,
  setCellValue
}) => {
  const [currentValue, setCurrentValue] = useState<string>(
    value === undefined || value === null ? 'false' : value.toString()
  )

  useEffect(() => {
    if (!isSameValueStringValue(currentValue, value)) {
      setCurrentValue(value !== null && value !== undefined ? value.toString() : 'false')
    }
  }, [value])

  return (
    <div
      className={`cell-value ${selected ? 'focus' : ''} fit-text`}
      tabIndex={-1}
      style={{
        textAlign: align,
        color: color,
        width: width,
        maxWidth: width,
        maxHeight: `${getRowHeightVariable(rowHeight) - 1}px`
      }}
    >
      <input
        type="checkbox"
        checked={currentValue === 'true'}
        disabled={readOnly}
        onChange={(event) => {
          setCurrentValue(event.target.checked.toString())
          setCellValue(event.target.checked.toString())
        }}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}

export const CheckboxCell = React.memo(Checkbox)
