import React from 'react'
import { EditorState, Modifier, RichUtils } from 'draft-js'
import { Highlight } from 'components/icons'

export const colorStyleFn: any = () => ({
  red: {
    background: '#FFBFB5'
  },
  yellow: {
    background: '#F7CC62'
  },
  green: {
    background: '#B5DCAF'
  },
  blue: {
    background: '#D6E8FA'
  },
  purple: {
    background: '#D8C3FF'
  }
})

interface HighlighterColorsProps {
  editorState: EditorState
  onChange: (nextEditState: any) => void
}

export const HighlighterColors: React.FC<HighlighterColorsProps> = ({
  editorState,
  onChange
}: HighlighterColorsProps) => {
  const colorMap = colorStyleFn()

  function toggleColor(toggledColor: string): void {
    const selection = editorState.getSelection()

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(colorMap).reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color)
    }, editorState.getCurrentContent())

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style')

    const currentStyle = editorState.getCurrentInlineStyle()

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state: any, color: any) => {
        return RichUtils.toggleInlineStyle(state, color)
      }, nextEditorState)
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor)
    }
    onChange(nextEditorState)
  }

  return (
    <div>
      <ColorControls editorState={editorState} onToggle={toggleColor} onChange={onChange} />
    </div>
  )
}

export const colorStyleMap: any = {
  red: {
    background: '#FFBFB5'
  },
  yellow: {
    background: '#F7CC62'
  },
  green: {
    background: '#B5DCAF'
  },
  blue: {
    background: '#D6E8FA'
  },
  purple: {
    background: '#D8C3FF'
  }
}

const ColorControls = (props: any) => {
  const currentStyle = props.editorState ? props.editorState.getCurrentInlineStyle() : null
  const colorButtons = []
  const colorMap = colorStyleFn()

  for (const key in colorMap) {
    colorButtons.push({ name: key, background: colorMap[key].background })
  }

  function handleMouseDown(event: React.MouseEvent<HTMLDivElement, MouseEvent>, color: string) {
    event.preventDefault()
    props.onToggle(color)
  }

  return (
    <div className="flex" style={{ padding: '12px 20px' }}>
      {colorButtons.map((button: any) => (
        <div
          key={button.name}
          className="cursor-pointer"
          style={{
            padding: '2px 0px',
            margin: '0px 5px',
            background: button.background,
            border: currentStyle && currentStyle.has(button.name) ? '1px solid #000' : '1px solid transparent',
            width: '22px',
            height: '22px',
            borderRadius: '50%'
          }}
          onMouseDown={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleMouseDown(event, button.name)}
        />
      ))}
    </div>
  )
}

interface HighlighterIconProps {
  onClick: (event: React.MouseEvent) => void
}

export const HighlighterIcon: React.FC<HighlighterIconProps> = ({ onClick }: HighlighterIconProps) => {
  return (
    <div
      className="flex items-center hover-bg-light-grey transition-all rounded"
      title="Highlight Text"
      style={{ padding: '7px', marginRight: '10px', cursor: 'pointer' }}
      onClick={onClick}
    >
      <Highlight />
    </div>
  )
}
