import React, { useEffect, useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import TableJoinEdit from 'components/spreadsheet/components/modal/views/tablejoins/edit'
import { ITable, ITableJoin } from 'types'
import { useProject } from 'hooks/project'
import { getProjectTables } from 'helpers/utils'
import { deleteTableJoin } from 'components/spreadsheet/contexts/data/actions'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { Plus } from 'components/icons'
import useApplicationStore from 'hooks/application'
import { Table } from 'components/table'
import api from 'helpers/api'

const TableJoinsModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const [screen, setScreen] = useState<number>(0)
  const [editingJoin, setEditingJoin] = useState<ITableJoin | undefined>(undefined)
  const [tables, setTables] = useState<ITable[]>([])
  const [joins, setJoins] = useState<ITableJoin[] | undefined>(undefined)
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  useEffect(() => {
    if (project.publicId !== '') {
      getProjectTables(project.publicId)
        .then((tables) => {
          api
            .getTableJoins(spreadsheetData.tableDetails.publicId)
            .then((response) => setJoins(response.data))
            .catch((e) => displayErrorMessage(e))
          setTables(tables)
        })
        .catch((e) => displayErrorMessage(e))
    }
  }, [project.publicId])

  const handleDeleteTableJoin = (joinTable: ITableJoin) => {
    if (window.confirm('Are you sure you want to delete this table look up?')) {
      setOpen(false)
      deleteTableJoin(
        spreadsheetData,
        setSpreadsheetData,
        joinTable!.publicId,
        project.publicId,
        () => {
          setSnackbarMessage({ status: 'success', message: 'Table look up deleted successfully' })
        },
        (error) => {
          displayErrorMessage(error)
        }
      )
    } else {
      return
    }
  }

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={
        screen === 0 ? 'All Table Look Ups' : screen === 1 ? 'Add New Table Lookup' : 'Edit Existing Table Look Up'
      }
    >
      {/* Screen 0 = Viewing All Table Lookups */}
      {screen === 0 && (
        <div>
          <div style={{ height: '400px' }}>
            <Table
              include={[
                {
                  header: 'Look Up Table Source',
                  id: 'joinTableName'
                },
                {
                  header: 'View Source',
                  id: 'viewLink'
                },
                {
                  header: 'Matching Columns',
                  id: 'matchingColumns'
                },
                {
                  header: 'Imported Columns',
                  id: 'importedColumns'
                },
                {
                  header: 'Edit Look Up',
                  id: 'editLookup'
                },
                {
                  header: 'Delete Look Up',
                  id: 'deleteLookup'
                }
              ]}
              data={
                joins && project && project.publicId !== ''
                  ? joins.map((join) => {
                      const matchingColumns = join.joinColumns
                        ?.map(
                          (joinColumn) =>
                            spreadsheetData.tableDetails.columns.find(
                              (column) => column.publicId === joinColumn.targetColumnId
                            )?.name
                        )
                        ?.join(', ')
                      const importedColumns = join.dataColumns
                        ?.map(
                          (dataColumn) =>
                            spreadsheetData.tableDetails.columns.find(
                              (column) => column.publicId === dataColumn.targetColumnId
                            )?.name
                        )
                        ?.join(', ')

                      return {
                        joinTableName: {
                          label: `${join.joinTableName} (${join.joinViewName})`,
                          value: `${join.joinTableName} (${join.joinViewName})`
                        },

                        viewLink: {
                          label: (
                            <a
                              href={`/project/${project.publicId}/table/${join.joinTableId}/view/${join.joinViewId}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button internalType="primary">View</Button>
                            </a>
                          ),
                          value: 'View'
                        },
                        matchingColumns: {
                          label: (
                            <div
                              className="truncate"
                              style={{ maxWidth: '200px', minWidth: '200px' }}
                              title={matchingColumns ? matchingColumns : ''}
                            >
                              {matchingColumns ? matchingColumns : ''}
                            </div>
                          ),
                          value: matchingColumns ? matchingColumns : ''
                        },
                        importedColumns: {
                          label: (
                            <div
                              className="truncate"
                              style={{ maxWidth: '200px', minWidth: '200px' }}
                              title={importedColumns ? importedColumns : ''}
                            >
                              {importedColumns ? importedColumns : ''}
                            </div>
                          ),
                          value: importedColumns ? importedColumns : ''
                        },
                        editLookup: {
                          label: (
                            <Button
                              internalType="secondary"
                              onClick={() => {
                                setEditingJoin(join)
                                setScreen(2)
                              }}
                            >
                              Edit
                            </Button>
                          ),
                          value: 'Edit'
                        },
                        deleteLookup: {
                          label: (
                            <Button internalType="danger" onClick={() => handleDeleteTableJoin(join)}>
                              Delete
                            </Button>
                          ),
                          value: 'Delete'
                        }
                      }
                    })
                  : []
              }
              defaultSort={'joinTableName'}
              defaultSortAscending={false}
              sort={false}
              loading={!joins}
            />
          </div>

          <div className="flex items-center justify-end" style={{ marginTop: '10px' }}>
            <Button
              internalType="accept"
              onClick={() => {
                setScreen(1)
              }}
            >
              <Plus />
              Add New Table Look Up
            </Button>
          </div>
        </div>
      )}

      {/* Screen 1 = Adding New Table Lookup */}
      {screen === 1 && (
        <TableJoinEdit
          key={`table-join-new`}
          spreadsheetData={spreadsheetData}
          setSpreadsheetData={setSpreadsheetData}
          setOpen={setOpen}
          selected={false}
          tables={tables}
          setScreen={setScreen}
          setEditingJoin={setEditingJoin}
        />
      )}

      {/* Screen 2 = Editing Existing Lookup */}
      {screen === 2 && editingJoin && (
        <TableJoinEdit
          spreadsheetData={spreadsheetData}
          setSpreadsheetData={setSpreadsheetData}
          joinTable={editingJoin}
          setOpen={setOpen}
          selected={true}
          tables={tables}
          setScreen={setScreen}
          setEditingJoin={setEditingJoin}
        />
      )}
    </Modal>
  )
}

export default React.memo(TableJoinsModal)
