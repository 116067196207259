import React from 'react'
import { Visible } from 'components/icons'
import Menu, { MenuProps } from 'components/menu'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { getAllGroupRows } from 'components/spreadsheet/helpers/grouping'

const GroupContextMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData, groupedRows } = useDataContext()
  const handleToggle = () => {
    if (groupedRows && menuState.columnId !== undefined) {
      setSpreadsheetData({
        type: 'TOGGLE_COLUMN_COLLAPSE',
        columnId: menuState.columnId,
        rows: getAllGroupRows(groupedRows)
      })
      setMenuState({ ...menuState, open: false })
    }
  }

  const label =
    spreadsheetData.collapsedColumnIds.size > 0 &&
    menuState.columnId !== undefined &&
    spreadsheetData.collapsedColumnIds.has(menuState.columnId)
      ? 'Expand column'
      : 'Collapse column'

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        <li>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{ padding: '0 10px', height: '32px', lineHeight: '32px' }}
            onClick={handleToggle}
          >
            <Visible /> <span className="ml-10px">{label}</span>
          </div>
        </li>
      </div>
    </Menu>
  )
}

export default React.memo(GroupContextMenu)
