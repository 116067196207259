export function dateTime(date: Date) {
  const minuteOffest = date.getTimezoneOffset()
  return new Date(+date - minuteOffest * 60 * 1000)
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24

export const getDifferenceInSeconds = (endDate: Date, startDate: Date) => {
  const seconds = (endDate.getTime() - startDate.getTime()) / 1000
  return seconds
}

export const getDifferenceInDays = (endDate: Date, startDate: Date) => {
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export const isDate = (date: string) => {
  return !Number.isNaN(new Date(date).getTime())
}
