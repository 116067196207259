import React, { useState } from 'react'
import { IUserObject } from 'types'
import Button from 'components/button'
import EditInfoModal from 'views/profile/components/editInfo/EditInfoModal'
import Contributions from 'views/profile/components/information/components/contributions'
import Public from 'views/profile/components/information/components/public'
import Pinned from 'views/profile/components/information/components/pinned'
import Achievements from 'views/profile/components/information/components/achievements'
import { Organisation, Location, Link, Twitter, LinkedIn } from 'components/icons'

interface InformationProps {
  userInfo?: IUserObject
  ownProfile: boolean
  updateUser: (arg0: any) => void
}

const Information: React.FC<InformationProps> = ({ userInfo, ownProfile, updateUser }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const getTwitterHandle = (url: string) => {
    try {
      const handle = url.split('https://twitter.com/')[1].replace('/', '')
      return '@' + handle
    } catch {
      return url
    }
  }

  const getLinkedInHandle = (url: string) => {
    try {
      const handle = url.split('/in/')[1].replace('/', '')
      return handle
    } catch {
      return url
    }
  }

  return (
    <div className="flex justify-center w-full">
      <div style={{ width: '90%', marginRight: 'auto', marginLeft: 'auto', paddingTop: '30px' }}>
        <div
          className="grid"
          style={{
            gridAutoFlow: 'column',
            gridTemplateColumns: 'auto 0 minmax(0, calc(100% - 296px - 24px)',
            gridGap: '24px'
          }}
        >
          {/* Profile Information Section */}
          <div className="flex flex-column" style={{ gridColumn: 1, width: '296px' }}>
            {userInfo ? (
              <img
                className="border-1 border-grey border-solid"
                src={userInfo && userInfo.profilePicture ? userInfo.profilePicture : '/assets/images/user.png'}
                style={{ height: '296px', width: '296px', objectFit: 'cover', borderRadius: '50%' }}
              />
            ) : (
              <span className="skeleton-box" style={{ height: '296px', width: '296px', borderRadius: '50%' }} />
            )}

            <div className="flex flex-column w-full items-center justify-center">
              {userInfo ? (
                <h1 className="text-3xl font-bold mt-20px">{userInfo.name}</h1>
              ) : (
                <span className="skeleton-box mt-20px" style={{ width: '122px', height: '32px' }} />
              )}
              {userInfo ? (
                <div className="mt-10px italic">{userInfo.bio}</div>
              ) : (
                <span className="skeleton-box mt-10px" style={{ width: '200px', height: '20px' }} />
              )}
              {ownProfile && (
                <Button className="mt-20px" onClick={() => setModalOpen(true)}>
                  Edit Profile
                </Button>
              )}
              <div className="w-full flex flex-column mt-20px text-sm">
                <div className="flex flex-row items-center w-full" title="Organisation">
                  <Organisation />
                  {userInfo ? (
                    <span className="truncate" style={{ marginLeft: '10px' }}>
                      {userInfo.organisation ? userInfo.organisation : 'Not set'}
                    </span>
                  ) : (
                    <span className="skeleton-box" style={{ marginLeft: '10px', width: '50px', height: '20px' }} />
                  )}
                </div>
                <div className="flex flex-row items-center w-full" style={{ marginTop: '5px' }} title="Location">
                  <Location />
                  {userInfo ? (
                    <span className="truncate" style={{ marginLeft: '10px' }}>
                      {userInfo.location ? userInfo.location : 'Not set'}
                    </span>
                  ) : (
                    <span className="skeleton-box" style={{ marginLeft: '10px', width: '50px', height: '20px' }} />
                  )}
                </div>
                <div className="flex flex-row items-center w-full" style={{ marginTop: '5px' }} title="Website">
                  <Link />
                  {userInfo ? (
                    <span className="truncate" style={{ marginLeft: '10px' }}>
                      {userInfo.website ? (
                        <a href={userInfo.website} target="_blank" rel="noopener noreferrer">
                          {userInfo.website}
                        </a>
                      ) : (
                        'Not set'
                      )}
                    </span>
                  ) : (
                    <span className="skeleton-box" style={{ marginLeft: '10px', width: '50px', height: '20px' }} />
                  )}
                </div>
                <div className="flex flex-row items-center w-full" style={{ marginTop: '5px' }} title="Twitter">
                  <Twitter />
                  {userInfo ? (
                    userInfo.twitter ? (
                      <a
                        className="truncate"
                        style={{ marginLeft: '10px' }}
                        href={userInfo.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getTwitterHandle(userInfo.twitter)}
                      </a>
                    ) : (
                      <span style={{ marginLeft: '10px' }}>Not Set</span>
                    )
                  ) : (
                    <span className="skeleton-box" style={{ marginLeft: '10px', width: '50px', height: '20px' }} />
                  )}
                </div>
                <div className="flex flex-row items-center w-full" style={{ marginTop: '5px' }} title="LinkedIn">
                  <LinkedIn />
                  {userInfo ? (
                    userInfo.linkedin ? (
                      <a
                        className="truncate"
                        style={{ marginLeft: '10px' }}
                        href={userInfo.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLinkedInHandle(userInfo.linkedin)}
                      </a>
                    ) : (
                      <span style={{ marginLeft: '10px' }}>Not Set</span>
                    )
                  ) : (
                    <span className="skeleton-box" style={{ marginLeft: '10px', width: '50px', height: '20px' }} />
                  )}
                </div>
              </div>
            </div>
            <Achievements userId={userInfo?.firebaseUserId} />
          </div>

          {/* Contribution Section */}
          <div className="flex flex-column" style={{ padding: '0px 40px', gridColumn: '2/span 2' }}>
            <Pinned userId={userInfo?.firebaseUserId} ownProfile={ownProfile} />
            <Contributions userId={userInfo?.firebaseUserId} />
            <Public userId={userInfo?.firebaseUserId} />
          </div>
        </div>
        {modalOpen && userInfo && ownProfile && (
          <EditInfoModal
            id="edit-information-modal"
            open={modalOpen}
            setOpen={setModalOpen}
            updateUser={updateUser}
            user={userInfo}
          />
        )}
      </div>
    </div>
  )
}

export default Information
