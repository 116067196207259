import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Spreadsheet, Process, Template } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import useApplicationStore from 'hooks/application'

interface ResourceMenuProps extends MenuProps {
  openNewProcessModal: () => void
  openNewTableModal: () => void
}

const ResourceMenu: React.FC<ResourceMenuProps> = ({
  id,
  menuState,
  setMenuState,
  width,
  openNewProcessModal,
  openNewTableModal
}) => {
  const { setTemplatesModal } = useApplicationStore()
  const menuClass = 'w-full flex items-center hover-font-bold text-primary cursor-pointer transition-all'

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div
        className={menuClass}
        style={{ padding: '10px' }}
        onClick={() => {
          openNewTableModal()
          setMenuState(INITIAL_CONTEXT_MENU_STATE)
        }}
      >
        <Spreadsheet />
        <div style={{ marginLeft: '7px' }}>Create New Table</div>
      </div>

      <div
        className={menuClass}
        style={{ padding: '10px' }}
        onClick={() => {
          openNewProcessModal()
          setMenuState(INITIAL_CONTEXT_MENU_STATE)
        }}
      >
        <Process />
        <div style={{ marginLeft: '7px' }}>Create New Document</div>
      </div>

      <div
        className={menuClass}
        style={{ padding: '10px' }}
        onClick={() => {
          setTemplatesModal(true)
          setMenuState(INITIAL_CONTEXT_MENU_STATE)
        }}
      >
        <Template />
        <div style={{ marginLeft: '7px' }}>Create From Template</div>
      </div>
    </Menu>
  )
}

export default ResourceMenu
