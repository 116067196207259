import React, { useState, useRef } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { IUserObject } from 'types'
import Button from 'components/button'
import api from 'helpers/api'
import useApplicationStore from 'hooks/application'

interface EditInfoModalProps extends ModalProps {
  user: IUserObject
  updateUser: (user: IUserObject) => void
}

const EditInfoModal: React.FC<EditInfoModalProps> = ({ id, open, setOpen, user, updateUser }) => {
  const { displayErrorMessage } = useApplicationStore()

  const [userDetails, setUserDetails] = React.useState<IUserObject>(user)
  const inputFile: any = React.useRef(null)

  function handleClick() {
    // Check there are no errors
    if (!userDetails || userDetails.name === '') return null
    if (userDetails.twitter && userDetails.twitter !== '' && !userDetails.twitter.includes('https://twitter.com/'))
      return null
    if (
      userDetails.linkedin &&
      userDetails.linkedin !== '' &&
      !userDetails.linkedin.includes('https://www.linkedin.com/')
    )
      return null
    // Errors passed

    updateUser(userDetails)
    setOpen(false)
  }

  const onImageClick = () => {
    inputFile.current.click()
  }

  const onImageChange = async (e: any) => {
    const file = e.target.files[0]
    try {
      const response = await api.uploadFile(file)
      setUserDetails({ ...userDetails, profilePicture: response.data.url })
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Update Profile">
      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Name:
        </div>
        <input
          required
          id="name-input"
          defaultValue={userDetails.name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setUserDetails({ ...userDetails, name: event.target.value })
          }
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Bio:
        </div>
        <textarea
          id="biography-input"
          defaultValue={userDetails.bio}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            setUserDetails({ ...userDetails, bio: event.target.value })
          }
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Picture
        </div>

        <img
          style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }}
          src={userDetails && userDetails.profilePicture ? userDetails.profilePicture : '/assets/images/user.png'}
          alt={userDetails.name}
        />
        <input type="file" id="file" ref={inputFile} onChange={onImageChange} style={{ display: 'none' }} />
        <Button onClick={() => onImageClick()}>Edit</Button>
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Organisation:
        </div>
        <input
          id="organisation-input"
          defaultValue={userDetails.organisation}
          onChange={(event) => setUserDetails({ ...userDetails, organisation: event.target.value })}
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Location:
        </div>
        <input
          id="location-input"
          defaultValue={userDetails.location}
          onChange={(event) => setUserDetails({ ...userDetails, location: event.target.value })}
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Website:
        </div>
        <input
          id="websiten-input"
          defaultValue={userDetails.website}
          onChange={(event) => setUserDetails({ ...userDetails, website: event.target.value })}
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          University:
        </div>
        <input
          id="university-input"
          defaultValue={userDetails.university}
          onChange={(event) => setUserDetails({ ...userDetails, university: event.target.value })}
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Degree:
        </div>
        <input
          id="university-degree-input"
          defaultValue={userDetails.universityDegree}
          onChange={(event) => setUserDetails({ ...userDetails, universityDegree: event.target.value })}
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          Twitter URL:
        </div>
        <input
          id="twitter-input"
          defaultValue={userDetails.twitter}
          onChange={(event) => setUserDetails({ ...userDetails, twitter: event.target.value })}
        />
      </div>

      <div className="flex items-center mb-8">
        <div className="mr-20px" style={{ width: '150px' }}>
          LinkedIn URL:
        </div>
        <input
          id="linkedin-input"
          defaultValue={userDetails.linkedin}
          onChange={(event) => setUserDetails({ ...userDetails, linkedin: event.target.value })}
        />
      </div>

      <div className="flex justify-end mt-20px">
        <Button onClick={() => handleClick()} data-cy="submit-update-button">
          Save Profile
        </Button>
      </div>
    </Modal>
  )
}

export default EditInfoModal
