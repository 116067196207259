import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { Save } from 'components/icons'
import { saveCurrentView, saveNewView } from 'components/spreadsheet/contexts/data/actions'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

const SaveMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const successfulViewSave = () => {
    setSnackbarMessage({ status: 'success', message: 'The view has been saved!' })
    setMenuState(INITIAL_CONTEXT_MENU_STATE)
  }

  const unsuccessfulViewSave = (error: any) => {
    displayErrorMessage(error)
  }

  const handleViewSave = () => {
    saveCurrentView(project.publicId, spreadsheetData, setSpreadsheetData, successfulViewSave, unsuccessfulViewSave)
  }

  const handleNewViewSave = () => {
    saveNewView(project.publicId, spreadsheetData, setSpreadsheetData, successfulViewSave, unsuccessfulViewSave)
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {spreadsheetData.userConfiguration.unsavedChanges && (
          <div className="truncate text-red" style={{ margin: '5px', padding: '5px' }}>
            There are unsaved changes
          </div>
        )}

        {!spreadsheetData.userConfiguration.unsavedChanges && (
          <div className="truncate text-dark-green" style={{ margin: '5px', padding: '5px' }}>
            Everything is up to date
          </div>
        )}

        <li>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => handleViewSave()}
          >
            <Save />
            <span className="ml-10px">Save Current View</span>
          </div>
        </li>

        <li>
          <div
            className="relative flex items-center rounded text-primary select-none transition-all truncate cursor-pointer hover-bg-light-grey"
            style={{
              padding: '0 10px',
              height: '32px',
              lineHeight: '32px'
            }}
            onClick={() => handleNewViewSave()}
          >
            <Save />
            <span className="ml-10px">Save As New View</span>
          </div>
        </li>
        <div style={{ margin: '5px', padding: '5px', whiteSpace: 'pre-wrap' }}>
          This will save the current configuration including sorts, filters, grouping, hidden columns, locked columns
          and column order.
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(SaveMenu)
