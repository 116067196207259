import React, { useEffect, useState } from 'react'
import { ILogObject, IProject } from 'types'
import api from 'helpers/api'
import { capitaliseFirstLetter } from 'helpers/utils'
import { PROJECT } from 'app-constants'
import useApplicationStore from 'hooks/application'

interface PublicProps {
  userId?: string
}

const projectLine = (contextProject: Pick<IProject, 'publicId' | 'name'> | null) => {
  if (contextProject) {
    return (
      <span>
        inside the <b>{contextProject.name}</b> project
      </span>
    )
  } else {
    return null
  }
}

let previousDate: string | null = null

const Public: React.FC<PublicProps> = ({ userId }) => {
  const { displayErrorMessage } = useApplicationStore()

  const [publicContributions, setPublicContributions] = useState<ILogObject[]>()

  useEffect(() => {
    if (userId) {
      api
        .getPublicContributions(userId)
        .then((response) => {
          setPublicContributions(response.data)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    } else {
      setPublicContributions(undefined)
    }
  }, [userId])

  return (
    <div className="text-base" style={{ marginTop: '40px', paddingBottom: '10px' }}>
      Recent Public {capitaliseFirstLetter(PROJECT)} Contributions
      <div className="mt-20px text-sm">
        {userId && publicContributions ? (
          publicContributions.length !== 0 ? (
            <div>
              {publicContributions.map((contribution, index) => {
                const dateString = new Date(contribution.createdAt).toLocaleDateString()
                const showDateString = dateString !== previousDate
                previousDate = showDateString ? dateString : previousDate
                return (
                  <div
                    key={index}
                    className="flex items-center"
                    style={{ height: '30px', marginTop: showDateString ? '20px' : '0px' }}
                  >
                    <div className="flex items-center" style={{ height: '100%', minWidth: '80px' }}>
                      {showDateString ? dateString : ''}
                    </div>
                    <div
                      className="h-full flex items-center border-l-1px border-grey border-solid truncate"
                      style={{ marginLeft: '10px', paddingLeft: '10px' }}
                    >
                      performed
                      <b style={{ marginLeft: '5px', marginRight: '5px' }}>{contribution.verb}</b> inside the{' '}
                      <b style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {contribution.contextProcess
                          ? contribution.contextProcess.name
                          : contribution.contextTable
                          ? contribution.contextTable.name
                          : ''}{' '}
                      </b>
                      <span style={{ marginRight: '5px' }}>{contribution.resource}</span>{' '}
                      {projectLine(contribution.contextProject)}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="italic">No public {PROJECT} contributions</div>
          )
        ) : (
          <span className="skeleton-box rounded" style={{ width: '430px', height: '30px' }} />
        )}
      </div>
    </div>
  )
}

export default Public
