import { ICellValue, ITableRow, ITableColumn, IFile } from 'types'
import { GROUP_KEY_SPLIT } from 'components/spreadsheet/constants/const'
import { GroupByDictionary, IColumnTypes } from 'components/spreadsheet/types'
import { coerceToValue } from 'components/spreadsheet/helpers/functions'

const NO_VALUE_KEY = 'EmptyCellValue'

// Create a key for the attachements columns
export const createAttachmentColumnKey = (value: IFile[]): string => {
  let groupKey = ''
  if (value && value.length > 0) {
    const filenames = value.map((file) => file.filename).join(',')
    groupKey = `${filenames}`
  } else {
    groupKey = NO_VALUE_KEY
  }
  return groupKey
}

// Give a cell value, this will return the grouping key used for it.
// The grouping key must be a string
export const createGroupKey = (value: ICellValue, kind: IColumnTypes): string => {
  let key = ''
  if (value !== undefined && kind === 'attachment') {
    key = createAttachmentColumnKey(value as IFile[])
    return key
  }

  const v = coerceToValue(value, kind)
  if (typeof v === 'string') {
    key = v
  } else {
    key = v.toString()
  }
  key = key.trim().replace(/ /g, '_')
  return key
}

// This is the grouping function for rows
export const rowGrouper = (rows: ITableRow[], column: ITableColumn | undefined) => {
  // The order of keys in an object are guaranteed in JS if the keys are strings or Symbols.
  // So we use the prefix here to cause the keys to be strings. Strings that are integers
  // are interpreted as integers so just calling the toString() method won't work.
  const prefix = '_'

  return rows.reduce((prev, current) => {
    const value = column ? current.rowData[column.publicId] : ''
    const groupKey = value === null || column === undefined ? NO_VALUE_KEY : createGroupKey(value, column.kind)
    const groupKeyWithPrefix = `${prefix}${groupKey}`

    if (!prev.hasOwnProperty(groupKeyWithPrefix)) {
      prev[groupKeyWithPrefix] = []
    }
    prev[groupKeyWithPrefix].push(current)
    return prev
  }, {} as Record<string, Array<ITableRow>>)
}

// This finds all the grouping keys for a column
export const getGroupingKeysForColumn = (rows: ITableRow[], groupByColumns: ITableColumn[], columnId: string) => {
  const columnGroupKeys = new Set<string>()

  const groupRows = (
    rows: ITableRow[],
    [groupByColumn, ...remainingGroupByColumns]: ITableColumn[],
    parentGroupKeys: string[]
  ) => {
    for (const [key, childRows] of Object.entries(rowGrouper(rows, groupByColumn))) {
      // If we're on the group we want the grouping keys for, we don't need to recurse any further
      if (groupByColumn.publicId === columnId) {
        columnGroupKeys.add([...parentGroupKeys, key].join(GROUP_KEY_SPLIT))
      } else {
        if (childRows.length > 0) {
          groupRows(childRows, remainingGroupByColumns, [...parentGroupKeys, key])
        }
      }
    }
  }

  groupRows(rows, groupByColumns, [])

  return columnGroupKeys
}

export const getGroupRowsFromKeys = (groupKeys: string[], groupedRows: GroupByDictionary) => {
  let rows: ITableRow[] = []
  let group: any = undefined

  for (let i = 0; i < groupKeys.length; i++) {
    const key = groupKeys[i].split(GROUP_KEY_SPLIT)[i]
    if (group === undefined) {
      group = groupedRows[key]
      rows = group.childRows
    } else {
      group = group.childGroups[key]
      rows = group.childRows
    }
  }
  return rows
}

export const getAllGroupRows = (groupedRows: GroupByDictionary) => {
  let rows: ITableRow[] = []
  const groupKeys = Object.keys(groupedRows)
  for (let i = 0; i < groupKeys.length; i++) {
    const key = groupKeys[i]
    const group = groupedRows[key]
    rows = rows.concat(group.childRows)
  }

  return rows
}
