import React from 'react'

interface CheckboxProps {
  defaultChecked?: boolean
  onChange: (event: any) => void
  children?: any
  checked?: boolean
  dataCy?: string
  disabled?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  defaultChecked,
  onChange,
  children,
  checked,
  dataCy,
  disabled
}: CheckboxProps) => {
  const disabledStyle = disabled ? 'disabled' : ''

  return (
    <div>
      <label className={`checkbox-container ${disabledStyle}`} style={{ display: !!children ? 'inherit' : 'unset' }}>
        {children}
        {checked === undefined && (
          <input type="checkbox" defaultChecked={defaultChecked} onChange={onChange} disabled={!!disabled} />
        )}
        {checked !== undefined && <input type="checkbox" checked={checked} onChange={onChange} disabled={!!disabled} />}
        <span className={`checkmark ${disabledStyle}`} data-cy={dataCy}></span>
      </label>
    </div>
  )
}

export default Checkbox
