import { UserRoles } from 'app-constants'

export const userRoleOptions = [
  {
    value: UserRoles.NO_ACCEESS,
    label: 'No access'
  },
  {
    value: UserRoles.VIEWER,
    label: 'Viewer'
  },
  {
    value: UserRoles.CONTRIBUTER,
    label: 'Contributer'
  },
  {
    value: UserRoles.OWNER,
    label: 'Owner'
  }
]
