import React, { useState } from 'react'
import Button from 'components/button'
import api from 'helpers/api'
import useProject from 'hooks/project'
import useApplicationStore from 'hooks/application'

const BulkUpdateText: React.FC = () => {
  const { project } = useProject()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()

  const [textToReplace, setTextToReplace] = useState<string>('')
  const [replacingText, setReplacingText] = useState<string>('')

  const applyChanges = () => {
    if (window.confirm('Are you sure you want to apply these changes?')) {
      api({
        method: 'PUT',
        endpoint: `/project/${project.publicId}`,
        data: {
          bulkUpdateText: {
            searchText: textToReplace,
            replaceText: replacingText
          }
        }
      })
        .then(() => {
          setSnackbarMessage({
            status: 'success'
          })
          setTextToReplace('')
          setReplacingText('')
        })
        .catch((e) => {
          displayErrorMessage(e)
        })
    }
  }

  return (
    <div className="w-full overflow-y-auto" style={{ padding: '10px 30px' }}>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Text To Replace
        </div>
        <div style={{ marginBottom: '20px' }}>
          This is the text contained in the document title, section titles and section content that you want to replace.
          This currently excludes table content.
        </div>
        <input
          type="text"
          placeholder="e.g. [CLIENT_NAME]"
          value={textToReplace}
          onChange={(event) => setTextToReplace(event.target.value)}
        />
      </div>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Replacing Text
        </div>
        <div style={{ marginBottom: '20px' }}>This is the text that will replace the text you entered above.</div>
        <input
          type="text"
          placeholder="e.g. ABC Limited"
          value={replacingText}
          onChange={(event) => setReplacingText(event.target.value)}
        />
      </div>
      <div className="mb-30px">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px border-b-2px border-solid border-grey">
          Apply Changes
        </div>

        <Button
          internalType="danger"
          onClick={() => applyChanges()}
          disabled={textToReplace === '' || replacingText === ''}
        >
          Apply Changes
        </Button>
      </div>
    </div>
  )
}

export default BulkUpdateText
