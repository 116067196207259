import React from 'react'
import Modal, { ModalProps } from 'components/modal'
import Audit from 'components/audit'

import { useDataContext } from 'components/process/contexts/data'

const AuditLogModal: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { process } = useDataContext()
  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Audit Log">
      <Audit type={'process'} resourceId={process ? process.publicId : ''} />
    </Modal>
  )
}

export default AuditLogModal
