import React from 'react'
import Select from 'components/select'
import { OptionProps } from 'types'

interface Props {
  onChange: (option: string) => void
  onMultipleChange: (options: string[]) => void
  tags: OptionProps[]
  name: string
  selectedTags: string[]
  keyword: string
}

const VariablesSelect: React.FC<Props> = ({ onChange, onMultipleChange, tags, name, selectedTags, keyword }) => {
  return (
    <div
      className="bg-white transition-all rounded"
      style={{ flex: 1, margin: '0px 10px 10px 0px', minWidth: '200px' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '2px solid #999BB545',
          paddingBottom: '0.5rem',
          marginBottom: '10px'
        }}
      >
        <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center' }}>
          <div style={{ fontSize: '90%', fontWeight: 600 }}>{`${name} ${keyword}`}</div>
        </div>
      </div>
      <Select
        options={tags}
        optionsSelected={selectedTags}
        groupBy={true}
        multiselect={true}
        onOptionClick={(option) => onChange(option)}
        setOptionsSelected={(options) => onMultipleChange(options)}
      />
    </div>
  )
}

export default VariablesSelect
