import useProject from 'hooks/project'
import React, { useEffect } from 'react'
import { history } from 'helpers/history'
import { PROJECT_BASE } from 'routes'

const Project: React.FC = () => {
  const { project } = useProject()

  useEffect(() => {
    if (project.publicId !== '') {
      if (project.defaultProcessId) {
        const newUrl = PROJECT_BASE + project.publicId + '/process/' + project.defaultProcessId
        history.push(newUrl)
      } else {
        const newUrl = PROJECT_BASE + project.publicId + '/settings'
        history.push(newUrl)
      }
    }
  }, [project.publicId])

  return (
    <div>
      <div></div>
    </div>
  )
}

export default Project
