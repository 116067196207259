import React, { useState, createRef, useEffect } from 'react'
import { useDataContext } from 'components/process/contexts/data'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'
import useProject from 'hooks/project'
import Button from 'components/button'

const Image: React.FC = () => {
  const { project } = useProject()
  const { process, updateProcess, isAdmin, editMode } = useDataContext()
  const { displayErrorMessage } = useApplicationStore()
  const inputFile = createRef<HTMLInputElement>()

  const [src, setSrc] = useState<string>()
  const [ready, setReady] = useState<boolean>(false)

  const onImageClick = () => {
    if (inputFile.current) {
      inputFile.current.click()
    }
  }

  const onImageChange = async (e: any) => {
    if (process) {
      const file = e.target.files[0]
      e.target.value = ''

      try {
        const response = await api.uploadFile(file)
        updateProcess({ ...process, logo: response.data.url })
      } catch (e) {
        displayErrorMessage(e)
      }
    }
  }

  const signUrl = async () => {
    if (process && process.logo) {
      const signedFileTokenResponse = await api.signUrl(process.logo)
      setSrc(signedFileTokenResponse.data.url)
      setReady(true)
    }
    if (process && !process.logo && project && project.defaultBanner) {
      const signedFileTokenResponse = await api.signUrl(project.defaultBanner)
      setSrc(signedFileTokenResponse.data.url)
      setReady(true)
    }
  }

  useEffect(() => {
    if (process && process.logo) {
      signUrl()
    } else if (project && project.defaultBanner) {
      signUrl()
    }
  }, [process && process.logo, project && project.defaultBanner])

  return (
    <div className="relative w-full hover-opacity-1" style={{ height: '30vh' }}>
      {isAdmin && editMode && (
        <div
          className="absolute bg-white text-sm flex rounded overflow-hidden transition-all"
          style={{
            whiteSpace: 'nowrap',
            color: 'rgba(55, 53, 47, 0.6)',
            maxWidth: `calc(100% - 16px)`,
            minHeight: '24px',
            right: '8px',
            bottom: '20px',
            zIndex: 2,
            marginRight: '150px',
            padding: '5px'
          }}
        >
          <Button
            onClick={() => {
              onImageClick()
            }}
          >
            Change Image
          </Button>
          {process && process.logo && (
            <Button
              internalType="danger"
              style={{ marginLeft: '5px' }}
              onClick={() => {
                if (window.confirm('Are you sure you want to remove the image?')) {
                  if (process) {
                    updateProcess({ ...process, logo: null })
                    setReady(false)
                    setSrc(undefined)
                  }
                }
              }}
            >
              Remove Image
            </Button>
          )}
          <input
            type="file"
            id="file"
            ref={inputFile}
            onChange={onImageChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
        </div>
      )}
      {ready && src ? (
        <img src={src} className="h-full w-full" style={{ objectFit: 'cover' }} />
      ) : (
        <div className="h-full w-full" style={{ backgroundColor: project.primaryColour }} />
      )}
    </div>
  )
}

export default Image
