import React from 'react'
import Permissions from 'components/permissions'
import { AccessResourceKinds } from 'app-constants'
import { useDataContext } from 'components/process/contexts/data'
import Modal, { ModalProps } from 'components/modal'
import Flag from 'components/flag'
import constants from 'style/constants.module.scss'
import useProject from 'hooks/project'
import useApplicationStore from 'hooks/application'
import api from 'helpers/api'
import Button from 'components/button'

const PermissionsModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { project } = useProject()
  const { setSnackbarMessage, displayErrorMessage } = useApplicationStore()

  const handleBulkSyncPermissions = () => {
    if (project && process) {
      if (window.confirm('Are you sure you want to synchronise permissions?')) {
        api
          .updateViewsProcessPermissions(process.publicId)
          .then(() =>
            setSnackbarMessage({ message: 'View permissions have been synced successfully!', status: 'success' })
          )
          .catch((error) => displayErrorMessage(error))
      }
    }
  }

  const { process } = useDataContext()
  if (process) {
    return (
      <Modal id={id} open={open} setOpen={setOpen} title="Permissions" onClose={onClose}>
        <div
          style={{
            listStyle: 'none',
            margin: '10px'
          }}
        >
          <div className="flex items-center border-b-2px border-solid border-grey py-10px mb-4 font-bold">
            Sync Permissions <Flag text="Beta" color={constants.red} />
          </div>
          <div>
            A <b>one-off</b> synchronisation of permissions for <b>all table views</b> contained in the document. If a
            user has contributor access to the document, they will be added as a contributor to all views contained in
            this document. No permissions will be removed from the table views.
          </div>
          <div className="flex items-center" style={{ marginBottom: '40px', marginTop: '20px' }}>
            <Button
              internalType="accept"
              className="text-base"
              onClick={() => {
                handleBulkSyncPermissions()
              }}
            >
              <span>Sync Permissions</span>
            </Button>
          </div>

          <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Document Permissions</div>
          <Permissions resource={AccessResourceKinds.PROCESS} resourceId={process.publicId} />
        </div>
      </Modal>
    )
  } else {
    return <div />
  }
}

export default PermissionsModal
