import { getAuthToken } from 'helpers/auth'

const apiUrl = process.env.REACT_APP_API_HOST
const apiVersion = process.env.REACT_APP_API_VERSION

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

export const downloadFileToClient = async (url: string, filename: string): Promise<any> => {
  const fullUrl = `${apiUrl}${apiVersion}${url}`
  const token = getAuthToken()

  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', fullUrl, true)

    if (token) {
      xhr.setRequestHeader('Authorization', token)
    }

    xhr.responseType = 'arraybuffer'
    xhr.onerror = function (e) {
      reject(e)
    }
    xhr.onload = function () {
      if (this.status === 200) {
        const disposition = xhr.getResponseHeader('Content-Disposition')
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
        }
        const type = xhr.getResponseHeader('Content-Type') || undefined

        let blob
        if (typeof File === 'function') {
          try {
            blob = new File([this.response], filename, { type: type })
          } catch (e) {
            /* Edge */
          }
        }
        if (typeof blob === 'undefined') {
          blob = new Blob([this.response], { type: type })
        }

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename)
        } else {
          const URL = window.URL || window.webkitURL
          const downloadUrl = URL.createObjectURL(blob)

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            const a = document.createElement('a')
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              window.location.href = downloadUrl
            } else {
              a.href = downloadUrl
              a.download = filename
              document.body.appendChild(a)
              a.click()
            }
          } else {
            window.location.href = downloadUrl
          }

          setTimeout(function () {
            URL.revokeObjectURL(downloadUrl)
          }, 100) // cleanup
        }
        resolve('done')
      } else {
        try {
          const enc = new TextDecoder('utf-8')
          const text = enc.decode(this.response)
          const json = JSON.parse(text)
          reject(json['detail']['message'])
        } catch {
          reject('Something went wrong')
        }
      }
    }
    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhr.send()
  })
}

export const downloadTextToClient = (filename: string, text: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
