import React, { useState, useEffect } from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable, isSameValueStringValue } from 'components/spreadsheet/helpers/functions'
import { formatPercentDisplayValue } from 'components/spreadsheet/helpers/format'
import useApplicationStore from 'hooks/application'

interface NumberCellProps {
  value: number | null
  color: string
  kind: IColumnTypes
  readOnly: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  validation: boolean
  handleKeyDown: (event: React.KeyboardEvent) => void
  setCellValue: (value: string | null) => void
  thousandSeparator: boolean
  decimalPlaces: number
}

const NumberCellComponent: React.FC<NumberCellProps> = ({
  value,
  color,
  kind,
  readOnly,
  editing,
  width,
  rowHeight,
  selected,
  validation,
  handleKeyDown,
  setCellValue,
  thousandSeparator,
  decimalPlaces
}) => {
  const { displayErrorMessage } = useApplicationStore()

  const [currentValue, setCurrentValue] = useState<string>(
    value !== null && value !== undefined ? value.toString() : ''
  )

  useEffect(() => {
    if (!isSameValueStringValue(currentValue, value)) {
      setCurrentValue(value !== null && value !== undefined ? value.toString() : '')
    }
  }, [value])

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    if (event.target.value === '' && kind !== 'duration') {
      setCurrentValue(event.target.value)
      setCellValue(event.target.value)
    } else if (kind === 'percentage' && !Number.isNaN(newValue)) {
      const percent = newValue / 100
      setCurrentValue(String(percent))
      setCellValue(String(percent))
    } else if (kind === 'integer' && Number.isInteger(newValue)) {
      setCurrentValue(event.target.value)
      setCellValue(String(newValue))
    } else if (kind !== 'integer' && kind !== 'duration' && event.target.value === '.') {
      setCurrentValue(event.target.value)
      setCellValue(event.target.value)
    } else if (kind !== 'integer' && kind !== 'duration' && !Number.isNaN(newValue)) {
      setCurrentValue(event.target.value)
      setCellValue(event.target.value)
    } else if (kind === 'duration') {
      let duration = 0
      try {
        let negative = false
        const inputs = event.target.value.split(':')
        const hours = inputs[0]
        if (hours.includes('-')) {
          negative = true
        }

        let numberHours = Number.parseInt(hours)
        numberHours = Math.abs(numberHours)

        const minutes = inputs[1]
        const numberMinutes = Number.parseInt(minutes)

        const seconds = inputs[2]
        const numberSeconds = Number.parseInt(seconds)

        if (!isNaN(numberHours)) duration += numberHours * 60 * 60
        if (!isNaN(numberMinutes)) duration += numberMinutes * 60
        if (!isNaN(numberSeconds)) duration += numberSeconds

        if (negative) duration *= -1
      } catch (error) {
        displayErrorMessage(error)
      }

      setCurrentValue(String(duration))
      setCellValue(String(duration))
    }
  }

  const convertToDuration = (input: string) => {
    if (input === '') {
      return ''
    } else {
      try {
        const seconds = Number.parseInt(input)
        const s = Math.abs(seconds)
        const t = [0, 0, 0]
        let r = s % 3600

        t[0] = Math.floor(s / 3600)
        t[1] = Math.floor(r / 60)
        r = r % 60
        t[2] = r

        return (
          (seconds < 0 ? '-' : '') +
          (t[0] < 10 ? '0' : '') +
          (thousandSeparator ? convertToThousandSeparator(t[0].toString()) : t[0]) +
          ':' +
          (t[1] < 10 ? '0' + t[1] : t[1])
        )
      } catch {
        return ''
      }
    }
  }

  const convertToFloat = (input: string) => {
    if (input !== undefined && input !== null && input !== '') {
      const value = parseFloat(input)

      if (thousandSeparator) {
        return convertToThousandSeparator(value.toFixed(decimalPlaces))
      } else {
        return value.toFixed(decimalPlaces)
      }
    } else {
      return ''
    }
  }

  const convertToThousandSeparator = (value: string) => {
    const [beforeDecimal, afterDecimal] = value.split('.')
    if (afterDecimal !== undefined) {
      return beforeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + afterDecimal
    } else {
      return beforeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  const getFormattedValue = () => {
    let value = currentValue !== undefined ? currentValue : ''

    switch (kind) {
      case 'percentage':
        value = formatPercentDisplayValue(Number(currentValue), 'en-GB', decimalPlaces)
        break
      case 'duration':
        value = convertToDuration(currentValue)
        break
      case 'float':
        value = convertToFloat(currentValue)
        break
      default:
        if (thousandSeparator) {
          value = convertToThousandSeparator(value)
        }
        break
    }

    return value
  }

  const formattedValue = getFormattedValue()

  if (editing && !readOnly) {
    return (
      <input
        className="cell-value-editing focus"
        type={kind === 'duration' ? 'text' : 'number'}
        defaultValue={kind === 'duration' ? convertToDuration(currentValue) : currentValue}
        style={{ height: `${getRowHeightVariable(rowHeight) - 1}px` }}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        onFocus={(event) => {
          event.target.value = ''
        }}
        autoFocus
      ></input>
    )
  } else {
    return (
      <div
        className={`cell-value ${selected ? 'focus' : ''} fit-text`}
        tabIndex={-1}
        style={{
          textAlign: 'right',
          color: color,
          width: width,
          maxWidth: width,
          maxHeight: `${getRowHeightVariable(rowHeight) - 1}px`
        }}
      >
        <div
          style={{
            width: validation ? 'calc(100% - 25px)' : '100%',
            wordBreak: 'break-word',
            overflow: 'hidden',
            maxHeight: 'inherit',
            whiteSpace: 'pre-wrap'
          }}
        >
          {formattedValue}
        </div>
      </div>
    )
  }
}

export const NumberCell = React.memo(NumberCellComponent)
