import React, { useRef, useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { ChevronDown, ChevronUp, Cross } from 'components/icons'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
import { searchTable } from 'components/spreadsheet/contexts/data/actions'
import { iconHeight } from 'components/icons'
import constants from 'style/constants.module.scss'
import { numberWithCommas } from 'components/spreadsheet/helpers/format'
import { ITableRow } from 'types'
import { IViewPortColumn } from 'components/spreadsheet/types'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

interface SearchMenuProps extends MenuProps {
  columns: IViewPortColumn[]
}

const SearchMenu: React.FC<SearchMenuProps> = ({ id, menuState, setMenuState, width, columns }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [search, setSearch] = useState<string>(spreadsheetData.searchTerm)
  const searchTimeout = useRef<TimeoutID | null>(null)

  const handleSearchChange = (search: string) => {
    setSearch(search)
    if (!spreadsheetData.searching) setSpreadsheetData({ type: 'SEARCHING' })
    if (searchTimeout.current !== null) cancelTimeout(searchTimeout.current)

    searchTimeout.current = requestTimeout(() => {
      if (search !== '') searchTable(setSpreadsheetData, search)
      else {
        setSpreadsheetData({ type: 'CLEAR_SEARCH' })
      }
    }, 1000)
  }

  const selectNext = (forward: boolean) => {
    const interval = forward ? 1 : -1
    if (spreadsheetData.selectedSearchCell.rowId) {
      const rowIndex = spreadsheetData.rows.findIndex(
        (row: ITableRow) => row.publicId === spreadsheetData.selectedSearchCell.rowId
      )
      const row = spreadsheetData.rows.find(
        (row: ITableRow) => row.publicId === spreadsheetData.selectedSearchCell.rowId
      )
      if (row) {
        const columnIndex = row.searched.findIndex(
          (columnId: string) => columnId === spreadsheetData.selectedSearchCell.columnId
        )

        // If the next searched value is the next column
        const nextColumn = row.searched[columnIndex + interval]
        if (nextColumn) {
          setSpreadsheetData({
            type: 'SET_SELECTED_SEARCH_CELL',
            rowId: row.publicId,
            columnId: nextColumn,
            columnNumber: columnIndex + interval,
            rowNumber: rowIndex
          })
        }

        // Else find the next row containing a non-zero length searched array
        let newRowFound = false
        let testRowIndex = rowIndex + interval
        while (!newRowFound) {
          if (testRowIndex === rowIndex) newRowFound = true
          else {
            if (testRowIndex > spreadsheetData.rows.length - 1) testRowIndex = 0
            else if (testRowIndex < 0) testRowIndex = spreadsheetData.rows.length
            const testRow = spreadsheetData.rows[testRowIndex]
            if (testRow && testRow.searched && testRow.searched.length !== 0) {
              const columnId = forward ? testRow.searched[0] : testRow.searched[testRow.searched.length - 1]
              const columnIndex = columns.findIndex((column) => column.column.publicId === columnId)

              setSpreadsheetData({
                type: 'SET_SELECTED_SEARCH_CELL',
                rowId: testRow.publicId,
                columnId: columnId,
                columnNumber: columnIndex,
                rowNumber: testRowIndex
              })
              newRowFound = true
            } else {
              testRowIndex += interval
            }
          }
        }
      }
    } else {
      const initialSearchedRow = spreadsheetData.rows.findIndex(
        (row) => row.searched && Array.isArray(row.searched) && row.searched.length > 0
      )
      if (initialSearchedRow !== undefined) {
        const row = spreadsheetData.rows[initialSearchedRow]
        if (row && row.searched.length !== 0) {
          const columnId = row.searched[0]
          const columnIndex = columns.findIndex((column) => column.column.publicId === columnId)
          setSpreadsheetData({
            type: 'SET_SELECTED_SEARCH_CELL',
            rowId: row.publicId,
            columnId: columnId,
            columnNumber: columnIndex,
            rowNumber: initialSearchedRow
          })
        }
      }
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            style={{ width: '200px', marginRight: 'auto' }}
            autoFocus
            value={search}
            placeholder="Search Within Table"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSearchChange(event.target.value)}
          />
          {spreadsheetData.searching && <div className="spin" style={{ height: iconHeight, width: iconHeight }} />}

          {spreadsheetData.searchTerm !== '' && !spreadsheetData.searching && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ fontSize: '85%', overflow: 'hidden', color: constants.darkerGrey }}>
                {spreadsheetData.searchFound === 0
                  ? 'No Results'
                  : `${numberWithCommas(spreadsheetData.searchFound)} Results`}
              </div>

              <div
                className="flex items-center justify-center bg-light-grey hover-bg-grey cursor-pointer rounded transition-all"
                style={{
                  marginLeft: '5px',
                  padding: '5px'
                }}
                onClick={() => selectNext(false)}
              >
                <ChevronUp />
              </div>
              <div
                className="flex items-center justify-center bg-light-grey hover-bg-grey cursor-pointer rounded transition-all"
                style={{
                  padding: '5px'
                }}
                onClick={() => selectNext(true)}
              >
                <ChevronDown />
              </div>

              <div
                className="flex items-center justify-center bg-light-grey hover-bg-grey cursor-pointer rounded transition-all"
                style={{ marginLeft: '5px', padding: '5px' }}
                onClick={() => {
                  setSearch('')
                  setSpreadsheetData({ type: 'CLEAR_SEARCH' })
                  setMenuState(INITIAL_CONTEXT_MENU_STATE)
                }}
              >
                <Cross />
              </div>
            </div>
          )}
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(SearchMenu)
