import React from 'react'
import SignedFileDownloadLink from './SignedFileDownloadLink'

import { IFile, IResponseFilePreviousVersion } from 'types'

interface Props {
  fileList: Array<IResponseFilePreviousVersion | IFile>
}

const FileList: React.FC<Props> = ({ fileList }: Props) => {
  return (
    <div>
      {fileList.map((file, fileNumber: number) => {
        return (
          <div key={fileNumber} style={{ marginBottom: '10px' }}>
            {`Uploaded File #${fileNumber + 1}: `}
            <SignedFileDownloadLink file={file} />
          </div>
        )
      })}
    </div>
  )
}

export default FileList
