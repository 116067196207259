import React from 'react'
import constants from 'style/constants.module.scss'
import RowNumberCell from './RowNumberCell'
import SpacerCell from './SpacerCell'

import { IViewPortColumn } from 'components/spreadsheet/types'
import { ITableRow, ICellValue, ITableViewColour } from 'types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import { getCellColour } from 'components/spreadsheet/helpers/colouring'

interface RowProps {
  width: number
  onClick: (values?: Record<string, ICellValue>, position?: number) => void
  rowNumber: number
  rowHeight: number
  uniqueNumber: number
  columns: IViewPortColumn[]
  colourSettings?: ITableViewColour[]
  lastGroupRow?: ITableRow
}

const AddRow: React.FC<RowProps> = ({
  width,
  onClick,
  rowNumber,
  rowHeight,
  uniqueNumber,
  lastGroupRow,
  columns,
  colourSettings
}) => {
  const groupedCells = columns.filter((column) => column.isGrouped)
  const otherCells = columns.filter((column) => !column.isGrouped)
  const groupHasReadOnlyColumns = groupedCells.some(
    (column) => column.column.isJoined || column.column.scriptEnabled || column.column.formulaEnabled
  )

  const handleClick = () => {
    if (lastGroupRow) {
      if (groupHasReadOnlyColumns) return
      const values: Record<string, ICellValue> = {}
      for (const column of columns) {
        if (column.isGrouped) {
          values[column.column.publicId] = lastGroupRow.rowData[column.column.publicId]
        }
      }
      onClick(values)
    } else {
      onClick()
    }
  }

  return (
    <div
      key="add-row"
      id={
        lastGroupRow === undefined
          ? `add-row-${uniqueNumber}`
          : `add-group-row-${uniqueNumber}-${lastGroupRow.publicId}`
      }
      className={`absolute inline-flex bg-white ${groupHasReadOnlyColumns ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      style={{
        margin: 0,
        padding: 0,
        height: 'auto',
        width: `${width}px`,
        top: `${Number(constants.headerHeight) + rowNumber * getRowHeightVariable(rowHeight)}px`
      }}
      onClick={handleClick}
      data-testid="add-row"
    >
      <RowNumberCell loading={false} value={``} rowHeight={rowHeight} useGreyBackground={lastGroupRow ? true : false} />
      {groupedCells.map((col) => (
        <SpacerCell
          key={col.column.publicId}
          width={col.column.width}
          frozen={col.isFrozen}
          grouped={col.isGrouped}
          left={col.left}
          shadeBackground={col.isGrouped}
          islastFrozenColumn={col.isLastFrozenColumn}
          height={getRowHeightVariable(rowHeight)}
          colour={
            lastGroupRow && lastGroupRow.rowData && col && col.column && col.column.kind !== 'multiselect'
              ? getCellColour(col.column, lastGroupRow.rowData[col.column.publicId], colourSettings, true)
              : constants.lightGrey
          }
        />
      ))}
      {otherCells.map((col, index) => (
        <SpacerCell
          key={col.column.publicId}
          width={col.column.width}
          frozen={col.isFrozen}
          grouped={col.isGrouped}
          left={col.left}
          shadeBackground={col.isGrouped}
          islastFrozenColumn={col.isLastFrozenColumn}
          height={getRowHeightVariable(rowHeight)}
          groupHasReadOnlyColumns={groupHasReadOnlyColumns}
        >
          {index === 0 ? <div className="text-primary">+ Add new row</div> : null}
        </SpacerCell>
      ))}
    </div>
  )
}

export default React.memo(AddRow)
