import React from 'react'
import { ActionMenuType } from 'types'
import { Pencil, Delete } from 'components/icons'

interface ICommentActionMenu {
  onSelectedActionMenu: (action: ActionMenuType) => void
}
const CommentActionsMenu: React.FC<ICommentActionMenu> = ({ onSelectedActionMenu }) => {
  const handleOnSelectedActionMenu = (action: ActionMenuType) => {
    onSelectedActionMenu(action)
  }

  return (
    <div className="cursor-pointer flex items-center">
      <div className="p-10px" onClick={() => handleOnSelectedActionMenu('edit')}>
        <Pencil />
      </div>
      <div className="p-10px" onClick={() => handleOnSelectedActionMenu('delete')}>
        <Delete />
      </div>
    </div>
  )
}

export default CommentActionsMenu
