import React, { useEffect, useState } from 'react'
import Button from 'components/button'
import { useApplicationStore } from 'hooks/application'
import Modal, { ModalProps } from 'components/modal'
import { Copy } from 'components/icons'

interface DeleteResourceProps extends ModalProps {
  resourceName: string
  deleteResource: () => void
  restoring?: boolean
  notUndo?: boolean
  otherContent?: React.ReactNode
  onOpen?: () => void
  disableCopy?: boolean
  customHeading?: string
}

const DeleteModal: React.FC<DeleteResourceProps> = ({
  id,
  open,
  setOpen,
  resourceName,
  onClose,
  deleteResource,
  restoring,
  notUndo,
  otherContent,
  onOpen,
  disableCopy,
  customHeading
}: DeleteResourceProps) => {
  const [deleteName, setDeleteName] = useState<string>('')
  const { setSnackbarMessage } = useApplicationStore()

  const confirmDeleteResource = () => {
    setDeleteName('')
    setOpen(false)
    deleteResource()
    if (onClose) onClose()
  }

  useEffect(() => {
    if (open && onOpen) {
      onOpen()
    }

    return () => {
      setDeleteName('')
      if (onClose) onClose()
    }
  }, [open])

  return (
    <Modal
      id={id}
      open={open}
      setOpen={setOpen}
      title={
        customHeading
          ? customHeading
          : `Are you sure you want to ${
              notUndo ? 'permanently delete' : restoring ? 'restore' : 'archive'
            } this resource?`
      }
      warningBanner={true}
      onClose={onClose}
    >
      <div>
        <div>
          To confirm your action please type the name of the resource in the box below.{' '}
          {notUndo ? 'You are not able to reverse this action so please double check it is correct.' : ''}
        </div>

        {otherContent ? otherContent : null}

        <div
          className="flex flex-row items-center flex-wrap rounded shadow w-full p-20px select-none"
          style={{ marginTop: '30px', marginBottom: '30px' }}
        >
          <div data-cy="resource-name-text">{resourceName}</div>
          {!disableCopy && (
            <div
              className="ml-auto cursor-pointer"
              title="Copy"
              onClick={() => {
                navigator.clipboard.writeText(resourceName)
                setSnackbarMessage({ status: 'success', message: 'Resource name copied!' })
              }}
            >
              <Copy />
            </div>
          )}
        </div>
        <input
          data-cy="resource-delete-name"
          className="w-full"
          onChange={(event) => setDeleteName(event.target.value)}
          style={{ marginTop: '10px', marginBottom: '20px' }}
        />
      </div>
      <div className="flex justify-end" style={{ marginTop: '20px' }}>
        <Button
          className="text-base"
          internalType="danger"
          data-cy={'resource-confirm-delete'}
          onClick={() => confirmDeleteResource()}
          style={{ padding: '10px' }}
          disabled={!(deleteName === resourceName)}
        >
          {notUndo
            ? 'I understand the consequences, permanently delete this resource'
            : restoring
            ? 'Yes, I want to restore this resource'
            : 'I understand the consequences, archive this resource'}
        </Button>
      </div>
    </Modal>
  )
}

export default DeleteModal
