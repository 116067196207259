import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import Button from 'components/button'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'

import { useDataContext } from 'components/process/contexts/data'
import Toggle from 'components/toggle'

const ExportModal: React.FC<ModalProps> = ({ id, open, setOpen, onClose }) => {
  const { process } = useDataContext()
  const { displayErrorMessage } = useApplicationStore()
  const [exportFormat, setExportFormat] = useState<string>('docx')
  const [tablesAsLinks, setTablesAsLinks] = useState<boolean>(false)
  const [processDescription, setProcessDescription] = useState<boolean>(false)
  const [pageFormat, setPageFormat] = useState<string>('A4')
  const [pageOrientation, setPageOrientation] = useState<string>('portrait')
  const [fontType, setFontType] = useState<string>('arial')
  const [fontSize, setFontSize] = useState<number>(12)
  const [downloading, setDownloading] = useState<boolean>(false)

  const exportProcess = () => {
    if (process) {
      setDownloading(true)
      api
        .downloadProcess(
          process.publicId,
          process.name,
          exportFormat,
          tablesAsLinks,
          processDescription,
          pageFormat,
          pageOrientation,
          fontSize,
          fontType
        )
        .then(() => {
          setDownloading(false)
          setOpen(false)
          if (onClose) onClose()
        })
        .catch((error) => {
          displayErrorMessage(error)
          setDownloading(false)
          setOpen(false)
          if (onClose) onClose()
        })
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} onClose={onClose} title="Export Document">
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Export Format</div>
        <div className="w-full">
          <select value={exportFormat} onChange={(event) => setExportFormat(event.target.value)}>
            <option value="docx">DOCX</option>
            <option value="pdf">PDF</option>
          </select>
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Page Format</div>
        <div className="w-full">
          <select value={pageFormat} onChange={(event) => setPageFormat(event.target.value)}>
            <option value="A1">A1</option>
            <option value="A2">A2</option>
            <option value="A3">A3</option>
            <option value="A4">A4</option>
            <option value="letter">Letter</option>
            <option value="legal">Legal</option>
          </select>
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Page Orientation</div>
        <div className="w-full">
          <select value={pageOrientation} onChange={(event) => setPageOrientation(event.target.value)}>
            <option value="portrait">Portrait</option>
            <option value="landscape">Landscape</option>
          </select>
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Font Type</div>
        <div className="w-full">
          <select value={fontType} onChange={(event) => setFontType(event.target.value)}>
            <option value="arial">Arial</option>
            <option value="verdana">Verdana</option>
          </select>
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Font Size</div>
        <div className="w-full">
          <input
            type="number"
            value={fontSize}
            min={2}
            max={20}
            step={1}
            onChange={(event) => setFontSize(Number.parseFloat(event.target.value))}
          />
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Export All Tables As Links</div>
        <div className="w-full">
          <Toggle toggled={tablesAsLinks} onToggle={(toggled: boolean) => setTablesAsLinks(toggled)} />
        </div>
      </div>
      <div className="mb-8 flex items-center" style={{ height: '45px' }}>
        <div style={{ width: '60%' }}>Include Document Description</div>
        <div className="w-full">
          <Toggle toggled={processDescription} onToggle={(toggled: boolean) => setProcessDescription(toggled)} />
        </div>
      </div>
      <div className="mb-8 flex items-center justify-end" style={{ height: '45px' }}>
        <Button
          internalType="danger"
          onClick={() => {
            setOpen(false)
            if (onClose) onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          style={{ marginLeft: '15px' }}
          internalType="accept"
          onClick={() => exportProcess()}
          isLoading={downloading}
        >
          Export
        </Button>
      </div>
    </Modal>
  )
}

export default ExportModal
