import { ITrigger } from 'types'

export type ITriggerNoId = Omit<ITrigger, 'publicId'>

export const isTableTriggerSelected = (triggers: ITriggerNoId[]) => {
  return triggers.find((t) => t.resource === 'table') ? true : false
}

export const isProcessTriggerSelected = (triggers: ITriggerNoId[]) => {
  return triggers.find((t) => t.resource === 'process') ? true : false
}
