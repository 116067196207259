import React, { useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { ITableColumn } from 'types'
import { sortDirections } from 'components/spreadsheet/types'
import { Cross, Drag } from 'components/icons'
import {
  TEXT_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  SELECT_COLUMN_TYPES,
  COLUMN_NAME
} from 'components/spreadsheet/constants/const'
import { BLOCK_GROUP_BY } from 'components/spreadsheet/constants/const'
import { useApplicationStore } from 'hooks/application'
import IconSelector from 'components/spreadsheet/components/cell/components/icon'
import Checkbox from 'components/checkbox'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import Select from 'components/select'

interface GroupMenuProps extends MenuProps {
  handleToggleCollapseAll: () => void
}

const GroupMenu: React.FC<GroupMenuProps> = ({ id, menuState, setMenuState, width, handleToggleCollapseAll }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [dragOver, setDragOver] = useState<number>(-1)
  const groupSettings = spreadsheetData.userConfiguration.groupSettings
  const noOfGroups = groupSettings.length
  const { setSnackbarMessage } = useApplicationStore()

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.currentTarget.id
    event.dataTransfer.setData('sortNumber', id.split('-')[1])
    event.dataTransfer.effectAllowed = 'move'
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    const id = event.currentTarget.id
    setDragOver(Number(id.split('-')[1]))
    event.dataTransfer.dropEffect = 'move'
  }

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const draggedSortNumber = event.dataTransfer.getData('sortNumber')
    if (draggedSortNumber !== '' && Number(draggedSortNumber) !== dragOver)
      setSpreadsheetData({
        type: 'CHANGE_GROUP_ORDER',
        existingIndex: Number(draggedSortNumber),
        newIndex: dragOver
      })
    setDragOver(-1)
  }

  const handleAddColumnGroup = (columnId: string) => {
    const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
    if (column) {
      const columnName = column.name
      const columnKind = column.kind
      if (BLOCK_GROUP_BY.includes(columnKind)) {
        setSnackbarMessage({ status: 'error', message: `Grouping columns of type ${columnKind} is not allowed` })
        return
      }

      const groupExists = !!groupSettings.find((groupSetting) => groupSetting.columnId === columnId)
      if (!groupExists) {
        setSpreadsheetData({ type: 'ADD_GROUP', columnId, columnName })
      } else setSnackbarMessage({ status: 'error', message: `You are already grouping by that ${COLUMN_NAME}.` })
    }
  }

  const handleDeleteColumnGroup = (columnId: string) => {
    setSpreadsheetData({ type: 'DELETE_GROUP', columnId })
  }

  const handleChangeDirection = (columnId: string, newDirection: sortDirections) => {
    setSpreadsheetData({ type: 'CHANGE_GROUP_DIRECTION', columnId, newDirection })
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {noOfGroups === 0 && (
          <div>
            <div className="font-bold truncate" style={{ padding: '3px 10px', marginBottom: '10px' }}>
              Your data is not grouped.
            </div>
            <div
              className="truncate"
              style={{ padding: '5px 10px', marginBottom: '20px', marginTop: '20px' }}
            >{`Group allows you to group rows by a particular ${COLUMN_NAME}.`}</div>
          </div>
        )}

        {noOfGroups > 0 && (
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <li>
              {groupSettings.map((group, sortNumber: number) => {
                const column = spreadsheetData.viewDetails.columns.find(
                  (column: ITableColumn) => column.publicId === group.columnId
                )

                return (
                  <div
                    key={sortNumber}
                    id={`sort-${sortNumber}`}
                    className="flex items-center rounded"
                    style={{ padding: '10px', height: '50px' }}
                    draggable={!spreadsheetData.refreshing && noOfGroups > 1}
                    onDragStart={handleDragStart}
                    onDragOver={handleDragOver}
                    onDrop={handleOnDrop}
                    onDragEnter={handleDragEnter}
                  >
                    <div
                      className="flex items-center cursor-pointer text-secondary"
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        if (!spreadsheetData.refreshing) handleDeleteColumnGroup(group.columnId)
                      }}
                    >
                      <Cross />
                    </div>
                    <div style={{ marginRight: '15px', minWidth: '57.75px' }}>
                      {sortNumber === 0 && `Group by`}
                      {sortNumber > 0 && `then by`}
                    </div>

                    <div
                      className="flex items-center bg-light-grey rounded"
                      style={{ width: '130px', height: '38px', marginRight: '15px', padding: '5px' }}
                    >
                      <IconSelector kind={column ? column.kind : 'text'} />
                      <div className="truncate" style={{ marginLeft: '10px' }}>
                        {group.columnName}
                      </div>
                    </div>

                    <div style={{ marginRight: '15px', minWidth: '57.75px' }}>from</div>
                    <div
                      className="flex items-center bg-light-grey rounded"
                      style={{ width: '160px', marginLeft: noOfGroups <= 1 ? 'auto' : '0px', padding: '5px' }}
                    >
                      <div
                        className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                          ${group.direction === sortDirections.asc ? 'bg-grey' : ''}`}
                        style={{ marginRight: '10px', padding: '5px', flex: 1 }}
                        onClick={() => {
                          if (!spreadsheetData.refreshing && group.direction !== sortDirections.asc) {
                            handleChangeDirection(group.columnId, sortDirections.asc)
                          }
                        }}
                      >
                        {column && TEXT_COLUMN_TYPES.includes(column.kind) && `A → Z`}
                        {column && NUMBER_COLUMN_TYPES.includes(column.kind) && `1 → 9`}
                        {column && DATE_COLUMN_TYPES.includes(column.kind) && `Jan→Dec`}
                        {column && SELECT_COLUMN_TYPES.includes(column.kind) && `A → Z`}
                        {column && 'link' === column.kind && `A → Z`}
                        {column && 'attachment' === column.kind && `A → Z`}
                        {column && 'checkbox' === column.kind && `🔲 → ☑️`}
                      </div>
                      <div
                        className={`flex items-center justify-center rounded border-1px border-solid border-transparent cursor-pointer 
                        ${group.direction === sortDirections.desc ? 'bg-grey' : ''}`}
                        style={{ padding: '5px', flex: 1 }}
                        onClick={() => {
                          if (!spreadsheetData.refreshing && group.direction !== sortDirections.desc) {
                            handleChangeDirection(group.columnId, sortDirections.desc)
                          }
                        }}
                      >
                        {column && TEXT_COLUMN_TYPES.includes(column.kind) && `Z → A`}
                        {column && NUMBER_COLUMN_TYPES.includes(column.kind) && `9 → 1`}
                        {column && DATE_COLUMN_TYPES.includes(column.kind) && `Dec→Jan`}
                        {column && SELECT_COLUMN_TYPES.includes(column.kind) && `Z → A`}
                        {column && 'link' === column.kind && `Z → A`}
                        {column && 'attachment' === column.kind && `Z → A`}
                        {column && 'checkbox' === column.kind && `☑️ → 🔲`}
                      </div>
                    </div>

                    {noOfGroups > 1 && (
                      <div className="flex items-center cursor-grab text-secondary ml-auto">
                        <Drag />
                      </div>
                    )}
                  </div>
                )
              })}
            </li>
          </div>
        )}
        <div className="flex items-center rounded" style={{ padding: '10px', height: '80px', marginTop: '30px' }}>
          <span style={{ minWidth: '150px', marginRight: '10px' }}>Column To Group:</span>
          <div className="w-full">
            <Select
              options={spreadsheetData.viewDetails.columns
                .filter((column) => !groupSettings.find((groupSetting) => groupSetting.columnId === column.publicId))
                .filter((column) => !spreadsheetData.userConfiguration.hiddenColumns.includes(column.publicId))
                .map((column) => {
                  return { value: column.publicId, label: column.name }
                })}
              onOptionClick={(option) => handleAddColumnGroup(option)}
              info="Only columns that have not been grouped can be selected."
            />
          </div>
        </div>
        <div className="flex items-center rounded" style={{ padding: '10px', height: '50px', marginTop: '30px' }}>
          {noOfGroups > 0 && (
            <Button
              className="text-primary bg-light-grey font-medium"
              onClick={() => handleToggleCollapseAll()}
              style={{ marginRight: '15px' }}
            >
              Collapse All
            </Button>
          )}
          {noOfGroups > 0 && (
            <Button
              className="text-primary bg-light-grey font-medium"
              onClick={() => setSpreadsheetData({ type: 'EXPAND_ALL_GROUPS' })}
            >
              Expand All
            </Button>
          )}
        </div>
        {spreadsheetData.isAdmin && noOfGroups > 0 && (
          <div
            className="flex items-center rounded"
            style={{ fontSize: '90% !important', padding: '10px', height: '50px' }}
          >
            <div style={{ fontSize: '90% !important' }}>
              <Checkbox
                defaultChecked={spreadsheetData.viewDetails.unpackMultiselectGroupView}
                onChange={(event) =>
                  setSpreadsheetData({
                    type: 'CHANGE_UNPACK_MULTISELECT_GROUP_VIEW',
                    unpackMultiselectGroupView: event.target.checked
                  })
                }
              >
                Unpack Multiselect Values (Reload Required)
              </Checkbox>
            </div>
          </div>
        )}
        {spreadsheetData.isAdmin && noOfGroups > 0 && (
          <div
            className="flex items-center rounded"
            style={{ fontSize: '90% !important', padding: '10px', height: '50px' }}
          >
            <div style={{ fontSize: '90% !important' }}>
              <Checkbox
                defaultChecked={spreadsheetData.viewDetails.collapsedGroupView}
                onChange={(event) =>
                  setSpreadsheetData({
                    type: 'CHANGE_COLLAPSED_GROUP_VIEW',
                    collapsedGroupView: event.target.checked
                  })
                }
              >
                Default Collapse Groups On Load
              </Checkbox>
            </div>
          </div>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(GroupMenu)
