import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { SIGN_IN } from 'routes'

// According to the openid spec, front channel logout is a url
// that is embedded within an iframe, which will cause the currently logged in
// user to be logged out
const FrontChannelLogout: React.FC = () => {
  const { logout, user } = useAuth()
  const { push } = useHistory()

  const logoutUser = async () => {
    logout()
    push(SIGN_IN)
  }

  React.useEffect(() => {
    if (user) {
      logoutUser()
    }
  }, [])

  return <div>{user ? 'Logging out user' : 'User logged out'}</div>
}

export default FrontChannelLogout
