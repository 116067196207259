import { IUserObject } from 'types'

export const generateRecoveryCodeTextFileContents = (user: IUserObject, codes: Array<string>) => {
  const codeText = codes.join('\n')
  const text = `SAVE YOUR RECOVERY CODES
Keep these recovery codes somewhere safe but accessible.

${codeText}

${user.email}

* You can only use each recovery code once.
* These codes were generated on: ${new Date().toDateString()}.`

  return text
}
