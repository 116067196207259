import React, { useRef, useEffect, useState, useLayoutEffect } from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'

interface TextCellProps {
  value: string
  align: 'center' | 'left' | 'right'
  color: string
  kind: IColumnTypes
  readOnly: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  isExpanded?: boolean
  validation: boolean
  handleKeyDown: (event: React.KeyboardEvent) => void
  setCellValue: (value: string | null) => void
}

const TextCellComponent: React.FC<TextCellProps> = ({
  value,
  align,
  color,
  kind,
  readOnly,
  editing,
  width,
  rowHeight,
  selected,
  isExpanded,
  validation,
  handleKeyDown,
  setCellValue
}) => {
  const inputRef: React.RefObject<HTMLDivElement> = useRef(null)
  const textareaRef: React.RefObject<HTMLTextAreaElement> = useRef(null)
  const [currentValue, setCurrentValue] = useState<string>(value ? value : '')
  const [cursorPosition, setCurrentPosition] = useState<number>()

  useEffect(() => {
    const newValue = value ? value : ''
    if (newValue !== currentValue) {
      setCurrentValue(newValue)
    }
  }, [value])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      if (cursorPosition !== undefined) {
        textareaRef.current.setSelectionRange(cursorPosition, cursorPosition)
      }
    }
  }, [currentValue])

  // We use useLayoutEffect here, because we need to wait for all DOM event to finish before
  // focusing the input, and setting the cursor to the end of the text
  useLayoutEffect(() => {
    if (editing && kind === 'text' && textareaRef.current) {
      textareaRef.current.focus()
      // move cursor to end of text
      const position = value ? value.length : 0
      textareaRef.current.setSelectionRange(position, position)
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px'
    }
  }, [editing])

  const handleKeyDownEditingTextArea = (event: React.KeyboardEvent) => {
    const isSpecialKeyPressed = event.metaKey || event.altKey
    if (event.key === 'Enter' && isSpecialKeyPressed) {
      // Add new line if user presses ALT or Meta keys + Enter
      if (textareaRef.current) {
        const textRefValue = textareaRef.current.value
        const curPos = textareaRef.current.selectionStart
        let valueWithLineBreak = textRefValue
        if (curPos === textRefValue.length) {
          // Add break line at the end of the text
          valueWithLineBreak = textRefValue + String.fromCharCode(13, 10)
          setCurrentPosition(undefined)
        } else {
          // Add break line in the middle of the text
          valueWithLineBreak = textRefValue.slice(0, curPos) + String.fromCharCode(13, 10) + textRefValue.slice(curPos)
          setCurrentPosition(curPos + 1)
        }

        setCellValue(valueWithLineBreak)
      }
    } else {
      setCurrentPosition(undefined)
      handleKeyDown(event)
    }
  }

  if (editing && !readOnly) {
    switch (kind) {
      case 'text':
        return (
          <textarea
            ref={textareaRef}
            className={`cell-value-editing ${editing ? 'focus' : ''}`}
            onChange={(event) => {
              setCurrentValue(event.target.value)
              setCellValue(event.target.value)
            }}
            value={!!currentValue ? currentValue : ''}
            style={{
              font: 'inherit',
              minWidth: width,
              minHeight: isExpanded ? 'unset' : getRowHeightVariable(rowHeight),
              paddingTop: `10px`,
              boxSizing: isExpanded ? 'border-box' : 'unset',
              resize: isExpanded ? 'vertical' : 'both'
            }}
            onKeyDown={handleKeyDownEditingTextArea}
            autoFocus
          ></textarea>
        )

      case 'email':
      case 'tag':
      case 'variable':
      case 'phone':
        return (
          <input
            className="cell-value-editing focus"
            style={{ height: getRowHeightVariable(rowHeight) - 1 }}
            value={!!currentValue ? currentValue : ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentValue(event.target.value)
              setCellValue(event.target.value)
            }}
            onKeyDown={handleKeyDown}
            autoFocus
          ></input>
        )
      default:
        return null
    }
  } else {
    return (
      <div
        className={`cell-value ${selected ? 'focus' : ''}`}
        ref={inputRef}
        tabIndex={-1}
        style={{
          textAlign: align,
          color: color,
          width: width,
          maxWidth: width,
          maxHeight: isExpanded ? '100%' : `${getRowHeightVariable(rowHeight) - 3}px`
        }}
      >
        <div
          style={{
            width: validation ? 'calc(100% - 25px)' : '100%',
            maxHeight: 'inherit',
            wordBreak: 'break-word',
            whiteSpace: 'pre-wrap',
            overflowY: isExpanded ? 'auto' : selected ? 'scroll' : 'hidden'
          }}
        >
          {typeof value === 'object' ? '' : value}
        </div>
      </div>
    )
  }
}

export const TextCell = React.memo(TextCellComponent)
