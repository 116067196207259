import { IHeadingStyle } from 'types'

export const getSectionDisplayNumber = (sectionAbove: string, subsectionNumber: number, formatting: IHeadingStyle) => {
  let output = `${sectionAbove}`
  // Number display output

  if (formatting.numberingStyle === undefined || formatting.numberingStyle === 0) {
    if (formatting.startAt0 !== undefined && formatting.startAt0) {
      output += `${subsectionNumber.toString()}.`
    } else {
      output += `${(subsectionNumber + 1).toString()}.`
    }
  }

  // Letter display output
  else if (formatting.numberingStyle === 1) {
    output += `${convertToLetter(subsectionNumber + 1)}.`
  }

  // Roman numerals display output
  else if (formatting.numberingStyle === 2) {
    output += `${convertToRoman(subsectionNumber + 1)}.`
  } else if (formatting.numberingStyle === 3) {
    output = ''
  }

  return output
}

const convertToLetter = (number: number) => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  let charIndex = number % alphabet.length
  let quotient = number / alphabet.length

  if (charIndex - 1 == -1) {
    charIndex = alphabet.length
    quotient--
  }
  result = alphabet.charAt(charIndex - 1)
  if (quotient >= 1) {
    result = convertToLetter(Math.round(quotient)) + result
  } else {
    result = '' + result
  }

  return result
}

const convertToRoman = (number: number) => {
  const roman: any = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  }
  let str = ''

  for (const i of Object.keys(roman)) {
    const q = Math.floor(number / roman[i])
    number -= q * roman[i]
    str += i.repeat(q)
  }

  return str
}
