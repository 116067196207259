import { ResponsePayload } from 'types'
import { getAuthToken } from 'helpers/auth'

const apiUrl = process.env.REACT_APP_API_HOST
const apiVersion = process.env.REACT_APP_API_VERSION

export const get = async <T>(uri: string): Promise<ResponsePayload<T>> => {
  const headers = getHeaders()
  return httpMethod(uri, { method: 'GET', headers: headers })
}

export const post = async <S, T>(uri: string, body: S, signal?: AbortSignal): Promise<ResponsePayload<T>> => {
  return httpMethod(uri, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: getHeaders(true),
    signal: signal
  })
}

export const put = async <S, T>(uri: string, body: S): Promise<ResponsePayload<T>> => {
  return httpMethod(uri, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: getHeaders(true)
  })
}

export const del = async <S, T>(uri: string, body?: S): Promise<ResponsePayload<T>> => {
  return httpMethod(uri, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: getHeaders(!!body)
  })
}

const getHeaders = (hasBody?: boolean) => {
  const token = getAuthToken()
  return {
    Accept: 'application/json',
    'Content-Type': hasBody ? 'application/json' : '',
    Authorization: token!
  }
}

const httpMethod = <T>(uri: string, options: RequestInit): Promise<ResponsePayload<T>> => {
  return fetch(`${apiUrl}${apiVersion}${uri}`, { ...options })
    .then(handleErrors)
    .then((response: Response) => response.json())
    .then((body: ResponsePayload<T>) => body)
}

const handleErrors = (response: Response) => {
  if (!response.ok) {
    return Promise.reject(response)
  }
  return response
}
