import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface AconexIntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedProject: string
  setSelectedProject: (project: string) => void
}

const Asite: React.FC<AconexIntegrationProps> = ({ selectedProject, setSelectedProject }) => {
  const { user } = useAuth()
  const { displayErrorMessage } = useApplicationStore()

  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.asiteConnected) {
      setLoadingProjects(true)
      api
        .integrationPassthrough({
          method: 'GET',
          headers: {
            Authorization: 'Bearer $token$'
          },
          path: `https://dmsak.asite.com/api/workspace/workspacelist`,
          sourceSystem: IntegrationProviders.ASITE
        })
        .then((response) => {
          let projects: ISyncResource[] = []
          if (response.data.status === '200') {
            let projectData = response.data.body['asiteDataList']['workspaceVO']

            // If only one project, convert to array
            if (!Array.isArray(projectData)) {
              projectData = [projectData]
            }

            projects = projectData.map((project: any) => {
              return {
                id: project['Workspace_Id'],
                name: project['Workspace_Name']
              }
            })
          }
          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingProjects(false)
        })
    }
  }, [])

  return (
    <div>
      {user && user.asiteConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project/Workspace<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((project) => {
                      return {
                        label: project.name,
                        value: project.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              setSelectedProject(option)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
          />
        </div>
      )}
    </div>
  )
}

export default Asite
