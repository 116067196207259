import React, { ReactChildren } from 'react'
import { ContentBlock, ContentState } from 'draft-js'
import { EditorPlugin } from '@draft-js-plugins/editor'

interface LinkProps {
  contentState: ContentState
  entityKey: string
  children: ReactChildren
}

export const createAnchorPlugin = (): EditorPlugin => {
  const findLinkEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity()
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
    }, callback)
  }

  const Link = (props: LinkProps) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData()

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    )
  }
  return {
    decorators: [
      {
        strategy: findLinkEntities,
        component: Link
      }
    ]
  }
}

export default createAnchorPlugin
