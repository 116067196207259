import React, { useState, useEffect } from 'react'
import UserPermissions from 'components/permissions/views/user'
import TagPermissions from 'components/permissions/views/tag'
import { IAccessPolicy } from 'types'
import { UserRoles, AccessResourceKinds, AccessAttributeKinds } from 'app-constants'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { useProject } from 'hooks/project'
import constants from 'style/constants.module.scss'

interface Props {
  resource: AccessResourceKinds
  resourceId: string
  excludedPermissionCategories?: UserRoles[]
}

const Permissions: React.FC<Props> = ({ resource, resourceId, excludedPermissionCategories }) => {
  const { project } = useProject()
  const [permissionTab, setPermissionTab] = useState<string>('user')
  const [policies, setPolicies] = useState<Array<IAccessPolicy>>()
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const tabs = [
    { id: 'user', name: 'General Permissions', color: constants.green, icon: 'type-person' },
    { id: 'tags', name: 'Permission Using Tags', color: constants.blue, icon: 'type-tag' }
  ]

  useEffect(() => {
    getResourcePolicies()
  }, [])

  const getResourcePolicies = async () => {
    try {
      const response = await api.getResourcePermissions(resource, resourceId)
      setPolicies(response.data)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleCreateAccessPolicy = async (
    role: UserRoles,
    attributeKind: AccessAttributeKinds,
    attributeId?: string,
    tagReferenceId?: string
  ) => {
    try {
      const policy = {
        attributeKind,
        attributeId,
        role,
        resourceKind: resource,
        resourceId,
        tagReferenceId,
        context: { projectId: project.publicId }
      }

      // Special case if the resource is a table, the role should be owner
      if (resource === AccessResourceKinds.TABLE) {
        policy.role = UserRoles.OWNER
      }

      const response = await api.createAccessPolicy(policy)
      setPolicies([...policies!, response.data])
      setSnackbarMessage({ message: 'New permission added', status: 'success' })
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleUpdateAccessPolicy = async (policy: IAccessPolicy, role: UserRoles) => {
    // No_ACCESS is not a role type in the backend, instead we just delete the policy
    if (role == UserRoles.NO_ACCEESS) {
      try {
        await api.deleteAccessPolicy(project.publicId, policy)
        setPolicies(policies?.filter((p) => p.publicId !== policy.publicId))
        setSnackbarMessage({ message: 'Permission updated', status: 'success' })
      } catch (e) {
        displayErrorMessage(e)
      }
    } else {
      try {
        const response = await api.updateAccessPolicy(project.publicId, policy, role)
        setPolicies(policies?.map((p) => (p.publicId === policy.publicId ? response.data : p)))
        setSnackbarMessage({ message: 'Permission updated', status: 'success' })
      } catch (e) {
        displayErrorMessage(e)
      }
    }
  }

  const handleDeleteAccessPolicy = async (policy: IAccessPolicy) => {
    try {
      await api.deleteAccessPolicy(project.publicId, policy)
      setPolicies(policies?.filter((p) => p.publicId !== policy.publicId))
      setSnackbarMessage({ message: 'Permission deleted', status: 'success' })
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  if (!policies) {
    return null
  }

  return (
    <div
      className="flex flex-column h-full overflow-hidden select-none"
      style={{ minWidth: '300px', paddingTop: '20px', paddingBottom: '20px' }}
    >
      <div
        className="static flex text-primary justify-start overflow-x-auto overflow-y-hidden"
        style={{ marginBottom: '10px' }}
      >
        <div
          className="flex justify-center items-center text-center cursor-pointer"
          style={{
            boxShadow: permissionTab === tabs[0].id ? `inset 0 -5px 0 0 ${tabs[0].color}` : 'inset 0 -5px 0 0 grey',
            minWidth: '200px',
            height: '40px',
            paddingBottom: '0.5rem'
          }}
          onClick={() => setPermissionTab(tabs[0].id)}
        >
          <div style={{ fontWeight: 500, color: 'inherit' }}>{tabs[0].name}</div>
        </div>

        {project && (
          <div
            className="flex justify-center items-center text-center cursor-pointer"
            style={{
              boxShadow: permissionTab === tabs[1].id ? `inset 0 -5px 0 0 ${tabs[1].color}` : 'inset 0 -5px 0 0 grey',
              minWidth: '200px',
              height: '40px',
              paddingBottom: '0.5rem'
            }}
            onClick={() => setPermissionTab(tabs[1].id)}
          >
            <div style={{ fontWeight: 500, color: 'inherit' }}>{tabs[1].name}</div>
          </div>
        )}
      </div>
      <UserPermissions
        policies={policies}
        display={permissionTab === 'user'}
        onCreatePolicy={handleCreateAccessPolicy}
        onUpdatePolicy={handleUpdateAccessPolicy}
        excludedPermissionCategories={excludedPermissionCategories}
      />
      <TagPermissions
        policies={policies}
        display={permissionTab === 'tags'}
        onCreatePolicy={handleCreateAccessPolicy}
        onDeletePolicy={handleDeleteAccessPolicy}
        excludedPermissionCategories={excludedPermissionCategories}
      />
    </div>
  )
}

export default Permissions
