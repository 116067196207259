import React from 'react'
import * as Sentry from '@sentry/browser'
import Button from 'components/button'
import { Triangle } from 'components/icons'

interface Props {
  children: React.ReactNode
}

class ErrorBoundary extends React.Component<
  unknown,
  {
    hasError: boolean
    eventId: string
    error: null | Error
    errorInfo: null | React.ErrorInfo
    reportClicked: boolean
    seeDetails: boolean
  }
> {
  constructor(props: Props) {
    super(props)
    this.state = { hasError: false, eventId: '', error: null, errorInfo: null, reportClicked: false, seeDetails: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ hasError: true, eventId: eventId, error, errorInfo })
    })
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="w-full h-full flex justify-center items-center flex-column" style={{ maxWidth: '450px' }}>
          <div className="mb-10px"> An issue with this functionality has occured.</div>
          {(!!this.state.error || !!this.state.errorInfo) && (
            <Button
              onClick={() => {
                this.setState({ seeDetails: !this.state.seeDetails })
              }}
              className="mb-10px"
            >
              Show details
              <Triangle type={this.state.seeDetails ? 'down' : 'right'} />
            </Button>
          )}
          {/* {this.state.seeDetails && !!this.state.error && <div>Error: {JSON.stringify(this.state.error, null, 4)}</div>} */}
          {this.state.seeDetails && !!this.state.errorInfo && (
            <div>ErrorInfo: {JSON.stringify(this.state.errorInfo, null, 4)}</div>
          )}
          <Button
            className="mb-10px"
            onClick={() => {
              this.setState({ reportClicked: true })
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }}
          >
            {this.state.reportClicked ? 'Thank you' : 'Report feedback'}
          </Button>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
