import useApplicationStore from 'hooks/application'
import React from 'react'

interface NavProps {
  sections: string[]
  selectedSection: string
  onClick: (section: string) => void
  primaryColor: string
}

const baseStyle = {
  padding: '10px',
  height: '40px',
  margin: '0px 10px'
}

const Nav: React.FC<NavProps> = ({ sections, selectedSection, onClick, primaryColor }) => {
  const { sidebarWidth } = useApplicationStore()
  return (
    <div
      className="sticky box-border bg-white flex items-center bottom-shadow overflow-y-hidden overflow-x-auto"
      style={{
        padding: `10px 10px 3px ${sidebarWidth === 0 ? '50px' : '10px'}`,
        top: 0,
        minHeight: '60px',
        maxHeight: '60px'
      }}
    >
      {sections.map((section, sectionNumber) => {
        const isSelected = section === selectedSection
        return (
          <div
            key={sectionNumber}
            className={`${
              isSelected ? 'font-bold' : 'hover-border-grey'
            } whitespace-nowrap border-solid border-transparent flex items-center justify-center cursor-pointer select-none border-b-3px box-border transition-all`}
            style={isSelected ? { ...baseStyle, borderColor: primaryColor } : baseStyle}
            onClick={() => onClick(section)}
          >
            {section}
          </div>
        )
      })}
    </div>
  )
}

export default Nav
