import React, { useEffect, useState } from 'react'
import { userRoleOptions } from 'components/permissions/utils'
import { useApplicationStore } from 'hooks/application'
import { useAuth } from 'hooks/auth'
import api from 'helpers/api'
import { IAccessPolicy, IUserObject } from 'types'
import { UserRoles } from 'app-constants'

interface Props {
  policy: IAccessPolicy
  onUpdatePolicy: (policy: IAccessPolicy, role: UserRoles) => void
  excludedPermissionCategories?: UserRoles[]
}

const UserPermissions: React.FC<Props> = ({ policy, onUpdatePolicy, excludedPermissionCategories }) => {
  const { displayErrorMessage } = useApplicationStore()
  const { user } = useAuth()
  const [userInfo, setUserInfo] = useState<IUserObject>()
  const [loaded, setLoaded] = useState(false)

  const fetchUser = async () => {
    try {
      const response = await api.getUser(policy.accessAttribute.user!.firebaseUserId!)
      setUserInfo(response.data)
      setLoaded(true)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value = parseInt(event.target.value as string)
    onUpdatePolicy(policy, value)
  }

  useEffect(() => {
    fetchUser()
  }, [policy])

  return (
    <div
      className="flex items-center cursor-pointer rounded border-box select-none border-1px border-solid border-black transition-all"
      style={{ padding: '1.5rem', minHeight: '90px', margin: '0px 5px 10px 5px' }}
    >
      {!loaded && (
        <div className="flex w-full">
          <span
            className="skeleton-box"
            style={{ marginRight: '10px', width: '50px', height: '50px', borderRadius: '50%' }}
          />
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <span className="skeleton-box" style={{ marginBottom: '5px', width: '150px', height: '17px' }} />
            <span className="skeleton-box" style={{ marginBottom: '5px', width: '300px', height: '17px' }} />
          </div>
          <div className="ml-auto" style={{ width: '130px' }}>
            <span className="skeleton-box" style={{ marginBottom: '5px', width: '130px', height: '18.75px' }} />
          </div>
        </div>
      )}

      {loaded && userInfo && (
        <div style={{ display: 'contents' }}>
          <img
            style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', objectFit: 'cover' }}
            alt={userInfo.name}
            src={userInfo && userInfo.profilePicture ? userInfo.profilePicture : '/assets/images/user.png'}
          />

          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div style={{ fontWeight: 700, marginBottom: '5px' }}>
              {user && userInfo.firebaseUserId === user!.firebaseUserId ? 'Me' : userInfo.name}
            </div>
            <div>
              Set permissions for this user (
              <a href={`/profile/${userInfo.firebaseUserId}/information`} target="_blank" rel="noopener noreferrer">
                view profile
              </a>
              ).
            </div>
          </div>
          <div className="ml-auto" style={{ width: '130px' }}>
            <select
              value={
                policy
                  ? excludedPermissionCategories && excludedPermissionCategories.includes(policy.role)
                    ? ''
                    : policy.role
                  : -1
              }
              onChange={handleChange}
            >
              {userRoleOptions
                .filter(
                  (userRole) => !excludedPermissionCategories || !excludedPermissionCategories.includes(userRole.value)
                )
                .map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserPermissions
