import React from 'react'
import { getQueryParameter } from 'helpers/utils'
import PasswordResetForm, { IFormValues } from 'views/auth/components/PasswordResetForm'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { SIGN_IN } from 'routes'
import Button from 'components/button'

const SetNewPassword: React.FC = () => {
  const [hasReset, setHasReset] = React.useState(false)
  const token = getQueryParameter('token')
  const { displayErrorMessage } = useApplicationStore()
  document.title = 'Morta | Forgot Password'

  const handlePasswordReset = async (values: IFormValues) => {
    try {
      await api.resetPassword(token!, values.password1)
      setHasReset(true)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  if (!token) {
    return <p>Please check the url your typed into the url bar, we cannot find the password reset token!</p>
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-12 text-2xl">Reset your password</h1>
        {hasReset ? (
          <div>
            <p>Your password has been updated successfully.</p>
            <br />
            <Button to={SIGN_IN}>Sign in to your account</Button>
          </div>
        ) : (
          <PasswordResetForm onSubmit={handlePasswordReset} />
        )}
      </div>
    </div>
  )
}

export default SetNewPassword
