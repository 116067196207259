import React, { useState, useRef } from 'react'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import { IContextMenuState } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import { IFile, IFileParentResource } from 'types'
import api from 'helpers/api'
import AttachmentCellModal from 'components/spreadsheet/components/modal/views/attachment'
import { useApplicationStore } from 'hooks/application'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { Plus, Visible } from 'components/icons'

interface AttachmentCellProps {
  value: IFile[]
  align: 'center' | 'left' | 'right'
  color: string
  readOnly: boolean
  isAdmin: boolean
  isContributor: boolean
  width: number
  rowHeight: number
  selected: boolean
  columnId: string | undefined
  isExpanded?: boolean
  setCellValue: (value: IFile[], onSuccess: () => void, onError: () => void) => void
}

const AttachmentCellComponent: React.FC<AttachmentCellProps> = ({
  value,
  align,
  color,
  width,
  readOnly,
  isAdmin,
  isContributor,
  rowHeight,
  selected,
  isExpanded,
  setCellValue,
  columnId
}) => {
  const inputRef: React.RefObject<HTMLDivElement> = useRef(null)
  const { spreadsheetData } = useDataContext()
  const attachmentUploaderInputRef: React.RefObject<HTMLInputElement> = useRef(null)
  const [attachmentMenu, setAttachmentMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)
  const { setSnackbarMessage } = useApplicationStore()
  const [processing, setProcessing] = useState<boolean>(false)
  // hideInput allows to (re)render a input element every time a file is uploaded.
  // This allows the onchange event to be fired in the case a user deletes a file and chooses to upload the same file again.
  const [hideInput, setHideInput] = useState<boolean>(false)
  const [attachmentModal, setAttachmentModal] = useState<boolean>(false)

  const uploadFile = async (file: File) => {
    const resources: IFileParentResource[] = [
      { resource: 'document_table', publicId: spreadsheetData!.tableDetails.publicId }
    ]

    const urlData = await api.uploadFile(file, resources)
    if (urlData) {
      return urlData.data
    } else {
      setProcessing(false)
      setSnackbarMessage({ status: 'error', message: 'A file was not uploaded successfully' })
      return null
    }
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (processing) return
    const uploadedFiles = event.target.files
    if (uploadedFiles) {
      setProcessing(true)
      const allFiles = value ? [...value] : []
      setHideInput(true)
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i]
        const newFileUrl = await uploadFile(file)
        if (newFileUrl) allFiles.push(newFileUrl)
      }
      setHideInput(false)
      setCellValue(
        allFiles,
        () => setProcessing(false),
        () => setProcessing(false)
      )
    }
  }

  const canAddLinks = isAdmin || isContributor
  return (
    <div
      className={`cell-value ${selected ? 'focus' : ''}`}
      ref={inputRef}
      tabIndex={-1}
      style={{
        textAlign: align,
        color: color,
        maxHeight: `${getRowHeightVariable(rowHeight) - 3}px`,
        position: 'relative',
        width: width - 30,
        maxWidth: width - 30
      }}
      onContextMenu={() =>
        setAttachmentMenu({
          ...attachmentMenu,
          open: false
        })
      }
      onClick={(event: React.MouseEvent) => {
        event.persist()
        const { left, bottom } = event.currentTarget.getBoundingClientRect()
        setAttachmentMenu({
          ...attachmentMenu,
          open: true,
          top: `${bottom}px`,
          left: `${left}px`,
          bottom: 'auto',
          right: 'auto'
        })
      }}
      onDoubleClick={(event) => event.stopPropagation()}
    >
      <div className="flex items-center truncate" style={{ width: isExpanded ? 'unset' : 'inherit' }}>
        {(selected || isExpanded) && !readOnly && canAddLinks && (
          <Button
            internalType="grey"
            style={{ maxWidth: '27px', width: '27px', padding: '1px' }}
            onClick={() => {
              if (!processing) attachmentUploaderInputRef.current?.click()
            }}
          >
            {processing ? <div className="spin" style={{ height: '15px', width: '15px' }} /> : <Plus />}
          </Button>
        )}
        {!hideInput && (
          <input
            type="file"
            ref={attachmentUploaderInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            multiple
          />
        )}
        {Array.isArray(value) && value.length > 0 ? (
          <div className="flex items-center">
            <div
              className="inline-flex items-center relative"
              style={{
                height: 'inherit',
                maxHeight: 'inherit',
                width: isExpanded ? 400 : `calc(100% - ${selected ? 40 : 10}px)`
              }}
            >
              <span className="truncate space-between">{`${value.length} File${value.length === 1 ? '' : 's'}: ${value
                .filter((v) => v !== null)
                .map((file) => file.filename)
                .join(';')}`}</span>
            </div>

            {(selected || isExpanded) && (
              <div
                className="absolute cursor-pointer"
                style={{ alignSelf: 'center', right: 0, marginRight: '5px' }}
                onClick={() => setAttachmentModal(true)}
              >
                <Visible style={{ width: '20px', height: '20px' }} />
              </div>
            )}
          </div>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
      {attachmentModal && Array.isArray(value) && value.length > 0 && (
        <AttachmentCellModal
          id="attachment-cell-modal"
          open={attachmentModal}
          setOpen={setAttachmentModal}
          files={value}
          setCellValue={setCellValue}
          isAdmin={isAdmin}
          isContributor={isContributor}
          isReadOnly={readOnly}
          columnId={columnId}
        />
      )}
    </div>
  )
}

export const AttachmentCell = React.memo(AttachmentCellComponent)
