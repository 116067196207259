import React, { useState } from 'react'
import { PieChartProps } from 'types'
import constants from 'style/constants.module.scss'

const PieChart: React.FC<PieChartProps> = ({ data, showLegend, showValues }) => {
  const total = data.reduce((sum, { value }) => sum + value, 0)
  const centerX = 150
  const centerY = 150
  const radius = showLegend ? 100 : 125
  let currentAngle = -Math.PI / 16

  const [hoveredLabel, setHoveredLabel] = useState<string | null>(null)

  const handleMouseOver = (label: string): void => {
    setHoveredLabel(label)
  }

  const handleMouseOut = (): void => {
    setHoveredLabel(null)
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center' }}>
      <svg width="100%" height="100%" viewBox="0 0 300 300">
        {data.map((segment, index) => {
          const angle = (segment.value / total) * 2 * Math.PI
          const x1 = centerX + radius * Math.cos(currentAngle)
          const y1 = centerY + radius * Math.sin(currentAngle)
          currentAngle += angle
          const x2 = centerX + radius * Math.cos(currentAngle)
          const y2 = centerY + radius * Math.sin(currentAngle)

          const linex2 = centerX + radius * Math.cos(currentAngle - angle / 2)
          const lineY2 = centerY + radius * Math.sin(currentAngle - angle / 2)

          const largeArcFlag = angle > Math.PI ? 1 : 0
          const spacing = showLegend ? (index % 2 === 0 ? 20 : 40) : 20

          const labelX = centerX + (radius + spacing) * Math.cos(currentAngle - angle / 2)
          const labelY = centerY + (radius + spacing) * Math.sin(currentAngle - angle / 2)

          const isHovered = !showLegend ? hoveredLabel === segment.label : false
          const percentage = ((segment.value / total) * 100).toFixed(2)

          let theta = Math.atan2(y2 - y1, x2 - x1) // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
          theta *= 180 / Math.PI // [0, 180] then [-180, 0]; clockwise; 0° = east

          return (
            <g key={index} preserveAspectRatio="xMidYMin slice">
              {segment.value === total ? (
                <circle
                  fill={segment.color}
                  r={radius}
                  cx={centerX}
                  cy={centerY}
                  onMouseOver={() => handleMouseOver(segment.label)}
                  onMouseOut={handleMouseOut}
                />
              ) : (
                [
                  <path
                    key="path"
                    d={`M${centerX},${centerY} L${x1},${y1} A${radius},${radius} 0 ${largeArcFlag} 1 ${x2},${y2} Z`}
                    fill={segment.color}
                    onMouseOver={() => handleMouseOver(segment.label)}
                    onMouseOut={handleMouseOut}
                  />,
                  <line
                    key="line"
                    x1={centerX}
                    y1={centerY}
                    x2={Math.cos(currentAngle) * radius + centerX}
                    y2={Math.sin(currentAngle) * radius + centerY}
                    stroke={constants.lightGrey}
                  />
                ]
              )}

              {(isHovered || showLegend) && (
                <text
                  x={labelX}
                  y={labelY}
                  textAnchor={theta > 0 ? 'start' : 'end'}
                  fill="black"
                  style={{ display: 'block', margin: '0 auto', fontSize: '6px' }}
                >
                  <tspan x={labelX}>
                    {segment.label} {percentage}% {showValues ? `,${segment.value.toFixed(2)}` : ''}
                  </tspan>
                </text>
              )}
              {(isHovered || showLegend) && (
                <line x1={linex2} y1={lineY2} x2={labelX} y2={labelY} stroke={'black'} strokeWidth="0.5" />
              )}
            </g>
          )
        })}
      </svg>
    </div>
  )
}

export default PieChart
