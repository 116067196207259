import React from 'react'
import {
  Text,
  Integer,
  Float,
  Percentage,
  Time,
  Select,
  MultiSelect,
  Link,
  Attachment,
  Variable,
  Phone,
  Email,
  Question,
  Vote,
  Checkbox
} from 'components/icons'
import { IColumnTypes } from 'components/spreadsheet/types'

interface IconSelectorProps {
  kind: IColumnTypes
}

const IconSelector: React.FC<IconSelectorProps> = ({ kind }) => {
  if (kind === 'text') return <Text />
  else if (kind === 'integer') return <Integer />
  else if (kind === 'float') return <Float />
  else if (kind === 'percentage') return <Percentage />
  else if (kind === 'date') return <Time />
  else if (kind === 'datetime') return <Time />
  else if (kind === 'select') return <Select />
  else if (kind === 'multiselect') return <MultiSelect />
  else if (kind === 'link') return <Link />
  else if (kind === 'multilink') return <Link />
  else if (kind === 'tag') return <Attachment />
  else if (kind === 'variable') return <Variable />
  else if (kind === 'attachment') return <Attachment />
  else if (kind === 'phone') return <Phone />
  else if (kind === 'email') return <Email />
  else if (kind === 'vote') return <Vote />
  else if (kind === 'checkbox') return <Checkbox />
  else if (kind === 'duration') return <Time />
  else return <Question />
}

export default IconSelector
