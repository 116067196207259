import React, { useState } from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import MultilinkCellModal from 'components/spreadsheet/components/modal/views/multilink'
import { Visible } from 'components/icons'

interface Props {
  value: string[] | null
  align: 'center' | 'left' | 'right'
  color: string
  kind: IColumnTypes
  readOnly: boolean
  isAdmin: boolean
  isContributor: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  validation: boolean
  setCellValue: (value: string[], onSuccess: () => void, onError: () => void) => void
  isExpanded?: boolean
}

const MultiLink: React.FC<Props> = ({
  value,
  align,
  color,
  readOnly,
  isAdmin,
  isContributor,
  width,
  rowHeight,
  selected,
  validation,
  setCellValue,
  isExpanded
}) => {
  const [multilinkModal, setMultilinkModal] = useState<boolean>(false)

  return (
    <div
      className={`cell-value ${selected ? 'focus' : ''}`}
      tabIndex={-1}
      style={{
        textAlign: align,
        color: color,
        height: getRowHeightVariable(rowHeight) - 2,
        width: width,
        maxWidth: width
      }}
      onDoubleClick={(event: React.MouseEvent) => {
        if (readOnly || isExpanded) {
          event.stopPropagation()
          return
        }
        event.stopPropagation()
      }}
    >
      <div className="truncate flex" style={{ width: validation ? 'calc(100% - 25px)' : '100%' }}>
        <div className="w-full inline-flex truncate space-between" style={{ border: 0, paddingRight: '5px' }}>
          <span className="self-center">{`${(value ? value : []).length} link(s)`}</span>
          {(selected || isExpanded) && (
            <div style={{ cursor: 'pointer' }} className="self-center" onClick={() => setMultilinkModal(true)}>
              <Visible style={{ height: '24px', width: '24px' }} />
            </div>
          )}
        </div>
      </div>
      {multilinkModal && (
        <MultilinkCellModal
          id="multilink-cell-modal"
          open={multilinkModal}
          setOpen={setMultilinkModal}
          links={value ? value : []}
          setCellValue={setCellValue}
          isAdmin={isAdmin}
          isContributor={isContributor}
        />
      )}
    </div>
  )
}

export const MultiLinkCell = React.memo(MultiLink)
