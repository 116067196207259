import React, { useEffect, useState } from 'react'
import Modal from 'components/modal'
import Button from 'components/button'
import ProjectSecretsList from 'views/settings/components/secrets/components/ProjectSecretsList'
import ProjectSecretForm, {
  ProjectSecretFormValues
} from 'views/settings/components/secrets/components/ProjectSecretForm'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { IProjectSecret } from 'types'
import useProject from 'hooks/project'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'

const Secrets: React.FC = () => {
  const { displayErrorMessage } = useApplicationStore()
  const [projectSecrets, setProjectSecrets] = useState<IProjectSecret[]>([])
  const [showAddSecret, setShowAddSecret] = useState(false)
  const { project } = useProject()

  useEffect(() => {
    fetchSecrets()
  }, [])

  const fetchSecrets = async () => {
    try {
      const response = await api.getProjectSecrets(project.publicId)
      setProjectSecrets(response.data)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleCreateSecret = async (values: ProjectSecretFormValues) => {
    try {
      await api.createProjectSecret(project, values.name, values.value)
      fetchSecrets()
      setShowAddSecret(false)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleDeleteSecret = async (secret: IProjectSecret) => {
    try {
      await api.deleteProjectSecret(project, secret)
      fetchSecrets()
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleEditSecret = async (secret: IProjectSecret, values: ProjectSecretFormValues, closeModal: () => void) => {
    try {
      await api.updateProjectSecret(project, secret, values.name, values.value)
      fetchSecrets()
      closeModal()
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  return (
    <div className="w-full overflow-y-scroll" style={{ padding: '10px 30px' }}>
      <div className="mb-16">
        <div className="flex flex-row items-center w-full text-lg font-bold mt-10px mb-30px py-10px">
          <div className="w-full font-medium text-base my-20px">
            Secrets are variables that are only available to {PROJECT} builders. These values can be used inside of
            scripts (by referencing <i>args[&quot;secrets&quot;][&quot;my_secret_key&quot;])</i> and will be hidden from
            users of that table.
          </div>
          <div className="ml-auto" onClick={() => setShowAddSecret(true)} style={{ width: '135px' }}>
            <Button>+ Add Secret</Button>
          </div>
        </div>

        <ProjectSecretsList secrets={projectSecrets} onDelete={handleDeleteSecret} onEdit={handleEditSecret} />
      </div>

      {showAddSecret && (
        <Modal
          id={'add-secret-modal'}
          open={showAddSecret}
          setOpen={setShowAddSecret}
          title={`Add New ${capitaliseFirstLetter(PROJECT)} Secret`}
        >
          <ProjectSecretForm onSubmit={handleCreateSecret} />
        </Modal>
      )}
    </div>
  )
}

export default Secrets
