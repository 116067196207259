import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface ViewpointIntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedEnterprise: string
  selectedProject: string
  selectedFolder: string
  setSelectedEnterprise: (enterprise: string) => void
  setSelectedProject: (project: string) => void
  setSelectedFolder: (folder: string) => void
}

const ViewpointIntegration: React.FC<ViewpointIntegrationProps> = ({
  type,
  selectedEnterprise,
  selectedProject,
  selectedFolder,
  setSelectedEnterprise,
  setSelectedProject,
  setSelectedFolder
}) => {
  const { user } = useAuth()
  const { displayErrorMessage } = useApplicationStore()

  const [enterprises, setEnterprises] = useState<ISyncResource[]>()
  const [loadingEnterprises, setLoadingEnterprises] = useState<boolean>(false)
  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)
  const [folders, setFolders] = useState<ISyncResource[]>()
  const [loadingFolders, setLoadingFolders] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.viewpointConnected) {
      setLoadingEnterprises(true)
      api
        .integrationPassthrough({
          method: 'GET',
          path:
            'https://api.4projects.com/api/QueryList?contextId=FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF&select=id,name&resource=enterprise&recursiveSearch=true&latestRevisionOnly=true&token=$token$',
          sourceSystem: IntegrationProviders.VIEWPOINT
        })
        .then((response) => {
          let enterprises: ISyncResource[] = []
          if (response.data.status === '200') {
            const operationResults = response['data']['body']['OperationResults']
            const operationSuccess = operationResults[0]['Success']
            if (operationSuccess) {
              const queryListResponse = response['data']['body']['QueryListResponseInfo']['Rows']
              enterprises = queryListResponse.map((row: any) => {
                return {
                  id: row['Fields'][0]['Value'],
                  name: row['Fields'][1]['Value']
                }
              })
            }
          }

          setEnterprises(enterprises)
          setLoadingEnterprises(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingEnterprises(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && user.viewpointConnected && selectedEnterprise && selectedEnterprise !== '') {
      setLoadingProjects(true)
      api
        .integrationPassthrough({
          method: 'GET',
          sourceSystem: IntegrationProviders.VIEWPOINT,
          path:
            `https://api.4projects.com/api/QueryListPaging?contextId=${selectedEnterprise}` +
            `&select=id,name,fullpath` +
            `&resource=project` +
            `&recursiveSearch=true&latestRevisionOnly=false` +
            `&fetch=2500&currentIndex=1&token=$token$`
        })
        .then((response) => {
          const responseJson = response['data']['body']
          const operationResults = responseJson['OperationResults']
          const operationSuccess = operationResults[0]['Success']

          let projects: ISyncResource[] = []
          if (operationSuccess) {
            const rows = responseJson['QueryListResponseInfo']['Rows']
            projects = rows.map((row: any) => {
              let group = ''
              let site = ''
              let remainingPath = ''

              try {
                const fullPath = row['Fields'][0]['Value']
                const splitPath = fullPath.split('/')
                splitPath.shift()
                site = splitPath[0]
                splitPath.shift()
                remainingPath = splitPath.join('/')
                group = `Site: ${site}`
              } catch {
                group = 'No Site'
              }
              return {
                id: row['Fields'][1]['Value'],
                name: remainingPath,
                group
              }
            })
          }

          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingProjects(false)
        })
    }
  }, [selectedEnterprise])

  useEffect(() => {
    if (user && user.viewpointConnected && selectedProject && selectedProject !== '') {
      setLoadingFolders(true)
      const resourceSearch = type === 'RFIs' ? 'taskfolder' : 'documentfolder'

      api
        .integrationPassthrough({
          method: 'GET',
          sourceSystem: IntegrationProviders.VIEWPOINT,
          path:
            `https://api.4projects.com/api/QueryListPaging?contextId=${selectedProject}` +
            `&select=id,name` +
            `&resource=${resourceSearch}` +
            `&recursiveSearch=false&latestRevisionOnly=false` +
            `&fetch=2500&currentIndex=1&token=$token$`
        })
        .then((response) => {
          const responseJson = response['data']['body']
          const operationResults = responseJson['OperationResults']
          const operationSuccess = operationResults[0]['Success']

          let folders: ISyncResource[] = []
          if (operationSuccess) {
            const rows = responseJson['QueryListResponseInfo']['Rows']

            folders = rows.map((row: any) => {
              return {
                id: row['Fields'][0]['Value'],
                name: row['Fields'][1]['Value']
              }
            })
          }
          setFolders(folders)
          setLoadingFolders(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingFolders(false)
        })
    }
  }, [selectedProject])

  return (
    <div>
      {user && user.viewpointConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Enterprise<span className="text-red">*</span>
          </div>

          <Select
            options={
              enterprises
                ? enterprises.map((enterprise) => {
                    return {
                      label: enterprise.name,
                      value: enterprise.id
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedEnterprise(option)
              setSelectedProject('')
              setProjects(undefined)
              setSelectedFolder('')
              setFolders(undefined)
            }}
            optionsSelected={[selectedEnterprise]}
            loading={loadingEnterprises}
          />
        </div>
      )}

      {user && user.viewpointConnected && selectedEnterprise !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects.map((project) => {
                    return {
                      label: project.name,
                      value: project.id,
                      group: project.group ? project.group : ''
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedProject(option)
              setSelectedFolder('')
              setFolders(undefined)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
            groupBy={true}
          />
        </div>
      )}

      {user && user.viewpointConnected && selectedProject !== '' && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Top Level Folder
          </div>
          <Select
            options={
              folders
                ? folders.map((folder) => {
                    return {
                      label: folder.name,
                      value: folder.id
                    }
                  })
                : []
            }
            onOptionClick={(option) => {
              setSelectedFolder(option)
            }}
            optionsSelected={[selectedFolder]}
            loading={loadingFolders}
          />
        </div>
      )}
    </div>
  )
}

export default ViewpointIntegration
