import React from 'react'
import { getMasqueradeToken } from 'helpers/auth'
import useAuth from 'hooks/auth'

const MasqueradeWarning = () => {
  const { user } = useAuth()
  if (!getMasqueradeToken()) {
    return null
  }

  return (
    <div
      className="absolute bg-red text-white p-4 rounded"
      style={{ left: '50%', bottom: '10%', transform: 'translate(-50%, 100%)', zIndex: 99 }}
    >
      Logged in as {user?.email}. Proceed with caution!
    </div>
  )
}

export default MasqueradeWarning
