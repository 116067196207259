import React, { useState } from 'react'
import Modal from 'components/modal'
import Button from 'components/button'
import { Table } from 'components/table'
import { IProjectSecret } from 'types'
import ProjectSecretForm, {
  ProjectSecretFormValues
} from 'views/settings/components/secrets/components/ProjectSecretForm'
import { capitaliseFirstLetter } from 'helpers/utils'
import { PROJECT } from 'app-constants'
import useApplicationStore from 'hooks/application'

interface Props {
  secrets: IProjectSecret[]
  onDelete: (secret: IProjectSecret) => void
  onEdit: (secret: IProjectSecret, values: ProjectSecretFormValues, closeModal: () => void) => Promise<void>
}

const ProjectSecretsList: React.FC<Props> = ({ secrets, onDelete, onEdit }) => {
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()
  const [currentlyEditingSecret, setCurrentlyEditingSecret] = useState<boolean>(false)
  const [selectedSecret, setSelectedSecret] = useState<IProjectSecret>()

  const handleEdit = async (values: ProjectSecretFormValues) => {
    try {
      await onEdit(selectedSecret!, values, () => {
        setCurrentlyEditingSecret(false)
        setSelectedSecret(undefined)
      })
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const header = [
    { header: 'Name', id: 'name' },
    { header: 'Value', id: 'value' },
    { header: 'Created At', id: 'createdAt' },
    { header: 'Updated At', id: 'updatedAt' },
    { header: '', id: 'controls' }
  ]

  const hideValue = (value: string) => {
    return new Array(value.length + 1).join('*')
  }

  const tableData = secrets.map((secret) => {
    return {
      name: { label: secret.name, value: secret.name },
      value: {
        label: secret.isReadOnly ? secret.value : hideValue(secret.value),
        value: secret.isReadOnly ? secret.value : hideValue(secret.value)
      },
      createdAt: {
        label: secret.createdAt ? new Date(secret.createdAt).toLocaleDateString() : '',
        value: secret.createdAt
      },
      updatedAt: {
        label: secret.updatedAt ? new Date(secret.updatedAt).toLocaleDateString() : '',
        value: secret.updatedAt
      },
      controls: {
        label: (
          <div key={secret.publicId} className="flex text-right justify-right">
            <Button
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                setCurrentlyEditingSecret(true)
                setSelectedSecret(secret)
              }}
            >
              Edit
            </Button>
            {!secret.isReadOnly ? (
              <Button
                internalType="danger"
                style={{ marginLeft: '10px', width: '100px' }}
                onClick={() => onDelete(secret)}
              >
                Delete
              </Button>
            ) : (
              <Button
                internalType="accept"
                style={{ marginLeft: '10px', width: '100px' }}
                onClick={() => {
                  navigator.clipboard.writeText(secret.value)
                  setSnackbarMessage({ status: 'success', message: 'Webhook signing secret copied to clipboard' })
                }}
              >
                Copy Value
              </Button>
            )}
          </div>
        ),

        value: ''
      }
    }
  })

  return (
    <div>
      <Table data={tableData} include={header} defaultSort={'name'} defaultSortAscending={true} sort={false} />
      <Modal
        id="editing-secret-modal"
        open={currentlyEditingSecret}
        setOpen={setCurrentlyEditingSecret}
        onClose={() => setSelectedSecret(undefined)}
        title={`Edit ${capitaliseFirstLetter(PROJECT)} Secret`}
      >
        <ProjectSecretForm secret={selectedSecret} onSubmit={handleEdit} />
      </Modal>
    </div>
  )
}

export default ProjectSecretsList
