import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { rowHeights } from 'components/spreadsheet/types'
import { SmallRowHeight, MediumRowHeight, TallRowHeight, ExtraTallRowHeight } from 'components/icons'
import { useDataContext } from 'components/spreadsheet/contexts/data'

const RowHeightMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()

  const handleRowHeightChange = (newRowHeight: number) => {
    setSpreadsheetData({ type: 'CHANGE_ROW_HEIGHT', newRowHeight })
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width} zIndex={3000}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '0px' }}>
        <div className="truncate" style={{ margin: '5px', padding: '5px' }}>
          Select a row height:
        </div>

        <ul
          className="overflow-y-autp overflow-x-hidden"
          style={{ listStyle: 'none', padding: 0, margin: 0, maxHeight: '200px' }}
        >
          <div
            className={`relative m-10px rounded select-none flex items-center cursor-pointer ${
              spreadsheetData.viewDetails.rowHeight === 0 ? 'bg-light-blue' : ''
            }`}
            onClick={() => handleRowHeightChange(0)}
            style={{ height: '33px', padding: '5px' }}
          >
            <SmallRowHeight />
            <div className="text-primary truncate" style={{ marginLeft: '8px', height: '33px', lineHeight: '33px' }}>
              {rowHeights[0]}
            </div>
          </div>
          <div
            className={`relative m-10px rounded select-none flex items-center cursor-pointer ${
              spreadsheetData.viewDetails.rowHeight === 1 ? 'bg-light-blue' : ''
            }`}
            onClick={() => handleRowHeightChange(1)}
            style={{ height: '33px', padding: '5px' }}
          >
            <MediumRowHeight />
            <div className="text-primary truncate" style={{ marginLeft: '8px', height: '33px', lineHeight: '33px' }}>
              {rowHeights[1]}
            </div>
          </div>
          <div
            className={`relative m-10px rounded select-none flex items-center cursor-pointer ${
              spreadsheetData.viewDetails.rowHeight === 2 ? 'bg-light-blue' : ''
            }`}
            onClick={() => handleRowHeightChange(2)}
            style={{ height: '33px', padding: '5px' }}
          >
            <TallRowHeight />
            <div className="text-primary truncate" style={{ marginLeft: '8px', height: '33px', lineHeight: '33px' }}>
              {rowHeights[2]}
            </div>
          </div>
          <div
            className={`relative m-10px rounded select-none flex items-center cursor-pointer ${
              spreadsheetData.viewDetails.rowHeight === 3 ? 'bg-light-blue' : ''
            }`}
            onClick={() => handleRowHeightChange(3)}
            style={{ height: '33px', padding: '5px' }}
          >
            <ExtraTallRowHeight />
            <div className="text-primary truncate" style={{ marginLeft: '8px', height: '33px', lineHeight: '33px' }}>
              {rowHeights[3]}
            </div>
          </div>
        </ul>
      </div>
    </Menu>
  )
}

export default React.memo(RowHeightMenu)
