import React, { useEffect, useState } from 'react'
import { IntegrationProviders } from 'app-constants'
import useAuth from 'hooks/auth'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { ISyncResource, IIntegrationSyncResourceTypes } from 'types'
import Select from 'components/select'

interface AconexIntegrationProps {
  type: IIntegrationSyncResourceTypes
  selectedProject: string
  setSelectedProject: (project: string) => void
  selectedDocTypes: string[] | undefined
  setSelectedDocTypes: (docTypes: string[]) => void
}

const Aconex: React.FC<AconexIntegrationProps> = ({
  selectedProject,
  setSelectedProject,
  selectedDocTypes,
  setSelectedDocTypes
}) => {
  const { user } = useAuth()
  const { displayErrorMessage } = useApplicationStore()

  const [projects, setProjects] = useState<ISyncResource[]>()
  const [loadingProjects, setLoadingProjects] = useState<boolean>(false)

  const [docTypes, setDocTypes] = useState<ISyncResource[]>()
  const [loadingDocTypes, setLoadingDocTypes] = useState<boolean>(false)

  useEffect(() => {
    if (user && user.aconexConnected) {
      setLoadingProjects(true)
      api
        .integrationPassthrough({
          method: 'GET',
          headers: {
            Authorization: 'Bearer $token$'
          },
          path: `https://api.aconex.com/api/projects`,
          sourceSystem: IntegrationProviders.ACONEX
        })
        .then((response) => {
          let projects: ISyncResource[] = []
          if (
            response.data.status === '200' &&
            'ProjectResults' in response.data.body &&
            'SearchResults' in response.data.body['ProjectResults'] &&
            response.data.body['ProjectResults']['SearchResults']
          ) {
            let projectsData = response.data.body['ProjectResults']['SearchResults']['Project']
            const totalResults = Number(response.data.body['ProjectResults']['@TotalResults'])
            if (totalResults === 1) {
              projectsData = [projectsData]
            }

            projects = projectsData.map((project: any) => {
              return {
                id: project['ProjectId'],
                name: project['ProjectShortName']
              }
            })
          }
          setProjects(projects)
          setLoadingProjects(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingProjects(false)
        })
    }
  }, [])

  useEffect(() => {
    if (user && user.aconexConnected && selectedProject) {
      setLoadingDocTypes(true)
      api
        .integrationPassthrough({
          method: 'GET',
          headers: {
            Authorization: 'Bearer $token$'
          },
          path: `https://api.aconex.com/api/projects/${selectedProject}/register/schema`,
          sourceSystem: IntegrationProviders.ACONEX
        })
        .then((response) => {
          let docTypes: ISyncResource[] = []
          try {
            if (response.data.status === '200') {
              // Check if MultiValueSchemaField exists
              if (!response.data.body['RegisterSchema']['SearchSchemaFields']['MultiValueSchemaField'])
                return Promise.reject('MultiValueSchemaField not found in response')

              const docTypesData = response.data.body['RegisterSchema']['SearchSchemaFields'][
                'MultiValueSchemaField'
              ].find((field: any) => field['Identifier'] === 'doctype')

              if (docTypesData['SchemaValues'] === null || docTypesData['SchemaValues']['SchemaValue'] === null)
                return Promise.reject('SchemaValues or SchemaValue not found in response')

              // If array map, else just use the object
              if (Array.isArray(docTypesData['SchemaValues']['SchemaValue'])) {
                docTypes = docTypesData['SchemaValues']['SchemaValue'].map((docType: any) => {
                  return {
                    id: docType['Value'],
                    name: docType['Value']
                  }
                })
              } else {
                docTypes = [
                  {
                    id: docTypesData['SchemaValues']['SchemaValue']['Value'],
                    name: docTypesData['SchemaValues']['SchemaValue']['Value']
                  }
                ]
              }
            }
          } catch (e) {
            displayErrorMessage(e)
          }

          setDocTypes(docTypes)
          setLoadingDocTypes(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setLoadingDocTypes(false)
        })
    }
  }, [selectedProject])

  return (
    <div>
      {user && user.aconexConnected && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Project<span className="text-red">*</span>
          </div>
          <Select
            options={
              projects
                ? projects
                    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
                    .map((project) => {
                      return {
                        label: project.name,
                        value: project.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              setSelectedProject(option)
            }}
            optionsSelected={[selectedProject]}
            loading={loadingProjects}
          />
        </div>
      )}

      {user && user.aconexConnected && selectedProject && (
        <div style={{ marginTop: '30px' }}>
          <div className="font-bold flex" style={{ marginBottom: '10px' }}>
            Select Document Types
          </div>
          <Select
            options={
              docTypes
                ? docTypes
                    .sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
                    .map((docType) => {
                      return {
                        label: docType.name,
                        value: docType.id
                      }
                    })
                : []
            }
            onOptionClick={(option) => {
              if (selectedDocTypes) {
                if (selectedDocTypes.includes(option)) {
                  setSelectedDocTypes(selectedDocTypes.filter((docType) => docType !== option))
                } else {
                  setSelectedDocTypes([...selectedDocTypes, option])
                }
              } else {
                setSelectedDocTypes([option])
              }
            }}
            optionsSelected={selectedDocTypes}
            loading={loadingDocTypes}
            multiselect={true}
          />
        </div>
      )}
    </div>
  )
}

export default Aconex
