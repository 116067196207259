import React from 'react'
import Spreadsheet from 'components/spreadsheet/index'
import { useParams } from 'react-router-dom'
import ErrorBoundary from 'components/error'

interface RouteParams {
  projectId: string
  tableId: string
  viewId: string
}

const Table: React.FC = () => {
  const params = useParams<RouteParams>()
  return (
    <ErrorBoundary>
      <Spreadsheet tableId={params.tableId} tableViewId={params.viewId} />
    </ErrorBoundary>
  )
}

export default Table
