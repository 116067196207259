import React from 'react'
import constants from 'style/constants.module.scss'

interface CellProps {
  frozen: boolean
  width: number
  left?: number
  grouped: boolean
  shadeBackground?: boolean
  islastFrozenColumn: boolean
  height: number
  children?: React.ReactNode
  colour?: string
  groupHasReadOnlyColumns?: boolean
}

const SpacerCell: React.FC<CellProps> = ({
  width,
  frozen,
  left,
  grouped,
  children,
  shadeBackground,
  islastFrozenColumn,
  height,
  colour,
  groupHasReadOnlyColumns
}) => {
  return (
    <div
      className={`flex items-center box-border ${!!groupHasReadOnlyColumns ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      title={
        !!groupHasReadOnlyColumns
          ? `Adding rows in a group is disabled when groups contain read only colums e.g. script or joined columns`
          : ''
      }
      style={{
        width: `${width}px`,
        paddingLeft: '5px',
        position: frozen ? 'sticky' : 'relative',
        left: frozen && left ? `${left}px` : 'initial',
        borderTop: 'none',
        borderLeft: 'none',
        boxShadow: islastFrozenColumn
          ? `${Number.parseInt(constants.frozenShadowWidth)}px 0 1px ${constants.grey}`
          : 'none',
        borderRight: grouped ? `1px solid ${constants.darkerGrey}` : 'none',
        backgroundColor: colour ? colour : shadeBackground ? constants.lightGrey : 'none',
        zIndex: frozen ? 2 : 'initial',
        height: `${height}px`
      }}
    >
      {children}
    </div>
  )
}

export default React.memo(SpacerCell)
