import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import SignupForm, { IFormValues } from 'views/auth/components/SignupForm'
import { useApplicationStore } from 'hooks/application'
import { PRIVACY, SIGN_IN, TERMS } from 'routes'
import { APIError } from 'helpers/api'

const SignUp: React.FC = () => {
  const [state, setState] = useState({
    step: 1,
    legalCheckbox: false
  })
  const { register } = useAuth()
  const { setSnackbarMessage } = useApplicationStore()
  document.title = 'Morta | Sign Up'

  const handleSubmitSignup = async (values: IFormValues) => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        password: values.password1
      }
      await register(payload)
      setState({ ...state, step: 3 })
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({
          message: e.message,
          status: 'error',
          duration: 30000,
          action: (
            <a
              style={{
                backgroundColor: 'white',
                border: '1px solid white',
                padding: '5px',
                borderRadius: '2px',
                marginLeft: '10px'
              }}
              href={`/signin`}
            >
              Return To Sign In
            </a>
          )
        })
      }

      return
    }

    setState({ ...state, step: 3 })
  }

  const handleSignWithEmail = () => {
    setState({ ...state, step: 2 })
  }

  const SelectSignUpMethod = (
    <React.Fragment>
      <br />
      <Checkbox
        defaultChecked={state.legalCheckbox}
        dataCy="legal-checkbox"
        onChange={(event) => setState({ ...state, legalCheckbox: event.target.checked })}
      >
        I have read, understood and accept the{' '}
        <a href={TERMS} target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href={PRIVACY} target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </Checkbox>
      <br />
      <Button
        disabled={!state.legalCheckbox}
        width="100%"
        data-cy="signup-email-button"
        onClick={handleSignWithEmail}
        style={{ fontSize: '1rem' }}
      >
        Sign Up With Email
      </Button>

      <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
        Already have an account or want to login with an external provider (e.g. Google or Microsoft)?{' '}
        <Link to={SIGN_IN}>Sign In</Link>
      </p>
    </React.Fragment>
  )

  const VerificationSent = (
    <React.Fragment>
      <p>
        A verification email has been sent to your email address. Please verify your email and then continue to sign in.
      </p>
      <br />
      <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
        Return to <Link to={SIGN_IN}>Sign In</Link>
      </p>
    </React.Fragment>
  )

  const UserDetails = <SignupForm onSubmit={handleSubmitSignup} />

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-4 text-2xl">Welcome to Morta</h1>
        {state.step === 1 && SelectSignUpMethod}
        {state.step === 2 && UserDetails}
        {state.step === 3 && VerificationSent}
      </div>
    </div>
  )
}

export default SignUp
