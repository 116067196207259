import React, { useState, useEffect } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { useProject } from 'hooks/project'
import { useApplicationStore } from 'hooks/application'
import { ITableViewWithColumns } from 'types'
import { IColumnTypes } from 'components/spreadsheet/types'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import constants from 'style/constants.module.scss'
import Button from 'components/button'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import api from 'helpers/api'

export interface ColumnKindModalProps extends ModalProps {
  newKind: IColumnTypes
  columnId: string | undefined
  onCancel: () => void
}

const ColumnKindModal: React.FC<ColumnKindModalProps> = ({
  id,
  open,
  setOpen,
  newKind,
  columnId,
  onCancel,
  zIndex
}) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const { displayErrorMessage } = useApplicationStore()

  const [processing, setProcessing] = useState<boolean>(false)
  const [usedViews, setUsedViews] = useState<ITableViewWithColumns[]>([])

  const handleClose = () => {
    setOpen(false)
    setProcessing(false)
  }

  const handleFailure = (error: any) => {
    displayErrorMessage(error)
    setProcessing(false)
  }

  const handleUpdateColumn = () => {
    if (columnId) {
      setProcessing(true)
      updateColumn(
        project.publicId,
        'kind',
        spreadsheetData,
        setSpreadsheetData,
        columnId,
        newKind,
        handleClose,
        handleFailure
      )
    }
  }

  useEffect(() => {
    if (open && columnId) {
      if (columnId) {
        api
          .getColumnUsedViews(spreadsheetData.tableDetails.publicId, columnId)
          .then((response) => {
            setUsedViews(response.data)
          })
          .catch((error) => {
            displayErrorMessage(error)
          })
      }
    }
  }, [open])

  const getUsedViewFilters = () => {
    const usedViewFilters = []

    for (let i = 0; i < usedViews.length; i++) {
      let include = false
      const view = usedViews[i]
      for (let j = 0; j < view.filterSettings.length; j++) {
        const filter = view.filterSettings[j]
        if (filter.columnId === columnId) include = true
      }

      if (include) [usedViewFilters.push(view)]
    }

    return usedViewFilters
  }

  const usedViewFilters = getUsedViewFilters()

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Confirm Column Change" zIndex={zIndex}>
      <div>
        Are you sure you want to change the kind of this column? There is a chance for{' '}
        <b style={{ color: constants.red }}>data to be lost</b> in all of the cells in this column.{' '}
        {usedViewFilters.length > 0 ? (
          <span>
            <br />
            <br />
            Also, <b style={{ color: constants.red }}>
              all filter settings related to this column will be removed
            </b>{' '}
            from the following views in this table:
          </span>
        ) : (
          ''
        )}
      </div>
      {usedViewFilters.length > 0 && (
        <ul style={{ marginTop: '20px', padding: '20px' }}>
          {usedViewFilters.map((view: ITableViewWithColumns, viewNumber: number) => {
            return (
              <li style={{ marginBottom: '5px' }} key={viewNumber}>
                <a
                  href={`/project/${project.publicId}/table/${spreadsheetData.tableDetails.publicId}/view/${view.publicId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {view.name}
                </a>
              </li>
            )
          })}
        </ul>
      )}
      <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'flex-end' }}>
        <Button
          internalType="secondary"
          style={{ maxWidth: '150px', marginRight: '15px' }}
          onClick={() => {
            if (!processing) {
              setOpen(false)
              onCancel()
            }
          }}
        >
          Keep Current Kind
        </Button>
        <Button
          internalType="danger"
          style={{ maxWidth: '150px' }}
          onClick={() => {
            if (!processing) handleUpdateColumn()
          }}
        >
          Change Kind
        </Button>
      </div>
    </Modal>
  )
}

export default ColumnKindModal
