import React, { useState, useEffect } from 'react'
import { IViewPortColumn, IAggregateTypes, ISpreadsheetContextMenuState } from 'components/spreadsheet/types'
import { calculateAggregate } from 'components/spreadsheet/helpers/aggregate'
import { iconHeight } from 'components/icons'
import { ITableRow } from 'types'
import { formatPercentDisplayValue } from 'components/spreadsheet/helpers/format'
import constants from 'style/constants.module.scss'
import { useDataContext } from 'components/spreadsheet/contexts/data'

interface AggregateProps {
  cumulativeWidth: number
  width: number
  frozen: boolean
  isLastFrozenColumn: boolean
  borderTop: string
  backgroundColor: string
  height: number
  streaming?: boolean
  rows?: ITableRow[]
  column?: IViewPortColumn
  columnNumber?: number
  optionClick?: (menuState: ISpreadsheetContextMenuState) => void
}

const Aggregate: React.FC<AggregateProps> = ({
  cumulativeWidth,
  width,
  frozen,
  isLastFrozenColumn,
  borderTop,
  backgroundColor,
  height,
  streaming,
  rows,
  column,
  columnNumber,
  optionClick
}) => {
  const { spreadsheetData } = useDataContext()
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (!streaming && column && rows) {
      const overwrittenAggregate =
        columnNumber === 0 &&
        (column.column.aggregate === undefined || column.column.aggregate === IAggregateTypes.None)
          ? IAggregateTypes['# Rows']
          : column.column.aggregate
      if (overwrittenAggregate !== undefined && overwrittenAggregate !== IAggregateTypes.None) {
        let newRows = rows.map((row) => row.rowData[column.column.publicId])

        if (column && column.column.kind === 'checkbox') {
          newRows = newRows.map((newRow) => {
            return !newRow || newRow === 'false' ? null : newRow
          })
        }
        setValue(calculateAggregate(newRows, overwrittenAggregate, spreadsheetData.totalRows))
      }
    }
  }, [rows, column])

  const displayValue =
    column &&
    !isNaN(Number.parseFloat(value)) &&
    column.column.kind === 'percentage' &&
    (column.column.aggregate === IAggregateTypes.Sum ||
      column.column.aggregate === IAggregateTypes.Average ||
      column.column.aggregate === IAggregateTypes.Min ||
      column.column.aggregate === IAggregateTypes.Max ||
      column.column.aggregate === IAggregateTypes.Range)
      ? formatPercentDisplayValue(Number.parseFloat(value))
      : value

  return (
    <div
      className={`relative inline-block box-border select-none ${
        optionClick !== undefined ? 'cursor-pointer hover-bg-grey transition-all' : ''
      }`}
      onClick={(event) => {
        if (optionClick && !streaming) {
          event.preventDefault()
          optionClick({
            open: true,
            top: `${event.clientY + 5}px`,
            left: `${event.clientX - 120}px`,
            right: 'auto',
            bottom: 'auto',
            columnNumber: column ? column.originalPosition : -1,
            columnId: column ? column.column.publicId : '',
            rowNumber: -1,
            rowId: ''
          })
        }
      }}
      style={{
        width: width ? `${width}px` : '100%',
        position: frozen ? 'sticky' : 'absolute',
        left: `${cumulativeWidth}px`,
        zIndex: frozen ? 6 : 'auto',
        borderTop: borderTop,
        backgroundColor: backgroundColor,
        height: `${height}px`,
        boxShadow: isLastFrozenColumn
          ? `${Number.parseInt(constants.frozenShadowWidth)}px 0 1px ${constants.grey}`
          : 'none'
      }}
    >
      {streaming && !column && (
        <div className="flex items-center text-sm absolute w-full h-full box-inherit" style={{ padding: '5px' }}>
          <div className="spin" style={{ height: iconHeight, width: iconHeight }} />
        </div>
      )}
      {!streaming && (
        <div className="flex items-center text-sm absolute w-full h-full box-inherit" style={{ padding: '5px' }}>
          {column && column.column.aggregate !== IAggregateTypes.None && (
            <div>{`${IAggregateTypes[column.column.aggregate]}: ${displayValue}`}</div>
          )}
          {column &&
            columnNumber === 0 &&
            (column.column.aggregate === undefined || column.column.aggregate === IAggregateTypes.None) && (
              <div>{`${IAggregateTypes[IAggregateTypes['# Rows']]}: ${value}`}</div>
            )}
        </div>
      )}
    </div>
  )
}

export default React.memo(Aggregate)
