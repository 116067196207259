import {
  INotification,
  ResponsePayload,
  IProjectSecret,
  ITableWithCells,
  IFile,
  IFileParentResource,
  ISignedUrlToken,
  ILogObject,
  ITable,
  ICommentObject,
  ICommentThreadObject,
  IAccessPolicy,
  IUserObject,
  ISummaryUser,
  IProjectMember,
  ITag,
  IProject,
  ICommentThreadStats,
  IContext,
  IOAuthConnectUrl,
  IMemberInvite,
  I2FaSetup,
  ResponseError,
  IProcess,
  IApiKey,
  INotificationExecution,
  ICeleryStats,
  IProcessObject,
  IProcessSectionObject,
  IFolderContents,
  CommentThreadStatusType,
  CommentReferenceType,
  IAchievementsObject,
  IPinnedProjectObject,
  IUsed,
  ITableRow,
  IIntegrationRedirects,
  IIntegrationPassthroughResponse,
  IIntegrationData,
  ISubscriptionComparison,
  ITableViewWithColumns,
  EditorContent,
  ITableViewColumn,
  IColumnAlterOptions,
  ITableRowCompressed,
  ICellValue,
  INewView,
  ICreateTableJoin,
  ITableJoin,
  IFormulaHelper
} from 'types'
import { UserRoles, AccessResourceKinds, AccessAttributeKinds, IntegrationProviders } from 'app-constants'
import { downloadFileToClient } from 'helpers/fileDownload'
import { getAuthToken } from 'helpers/auth'
import { convertToApiFilter } from 'components/spreadsheet/helpers/functions'
import { ICreateTableColumnView } from 'components/spreadsheet/contexts/data/actions'
import { decodeMultiStream } from '@msgpack/msgpack'
import { SpreadsheetInProcessContext } from 'components/spreadsheet/types'
import { post } from 'helpers/http-service'

const apiUrl = process.env.REACT_APP_API_HOST
const apiVersion = process.env.REACT_APP_API_VERSION

export class APIError extends Error {
  code: ApiErrorCodes
  rawCode: number
  message: string
  errors?: ResponseError['detail']['errors']
  constructor(code: ApiErrorCodes, rawCode: number, message: string, errors?: ResponseError['detail']['errors']) {
    super()
    this.message = message
    this.code = code
    this.errors = errors
    this.rawCode = rawCode
  }
}

type methods = 'GET' | 'POST' | 'PUT' | 'DELETE'

export enum ApiErrorCodes {
  ACCOUNT_NOT_VERIFIED = 'account_not_verified',
  ACCOUNT_DEACTIVATED = 'account_deactivated',
  SUBSCRIPTION_LEVEL = 'subscription_level_error',
  TWO_FACTOR_REQUIRED = 'two_factor_required',
  REQUEST_PARAMS_ERROR = 'request_params_error',
  UNKNOWN = 'unknown'
}

export interface FetchParams {
  method: methods
  headers: any
  mode?: 'cors' | 'navigate' | 'no-cors' | undefined
  credentials?: 'omit' | 'include' | 'same-origin'
  body?: string
  signal?: AbortSignal
}

// Transforms an object into an url query to be used in api - GET calls.
function objectUrlQueryfy(data: any): string {
  // If data is null or not valid return empty query.
  if (!data) {
    return ''
  }
  let query = ''
  // iterate throw the properties
  for (const property in data) {
    // do not use  !data[property] check otherwise it will remove Zero or false values.
    if (data.hasOwnProperty(property) && data[property] != null && data[property] !== '') {
      query = query ? query + '&' : '?'
      if (Array.isArray(data[property]) && data[property].length > 0) {
        query += property + '=' + data[property].join(`&${property}=`)
      } else {
        query += property + '=' + encodeURIComponent(data[property])
      }
    }
  }
  return query
}

interface QueueItem {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  endpoint: string
  data?: any
  rawBody?: boolean
  resolve: (response: Response) => void
  reject: (reason?: any) => void
}

const requestQueue: QueueItem[] = []
let lastRequestTimestamp = 0
const RATE_LIMIT = 100

async function sendRequest({ method = 'GET', endpoint, data, rawBody = false }: ApiParams): Promise<Response> {
  return new Promise((resolve, reject) => {
    requestQueue.push({ method, endpoint, data, rawBody, resolve, reject })
    processQueue()
  })
}

function processQueue(): void {
  if (requestQueue.length === 0) return

  const now = Date.now()
  const nextRequestTime = lastRequestTimestamp + RATE_LIMIT

  if (now >= nextRequestTime) {
    const { method, endpoint, data, rawBody, resolve, reject } = requestQueue.shift()!
    lastRequestTimestamp = now
    executeRequest({ method, endpoint, data, rawBody, resolve, reject })
  }

  setTimeout(processQueue, nextRequestTime - now)
}

async function executeRequest({ method, endpoint, data, rawBody, resolve, reject }: QueueItem): Promise<void> {
  let url = `${apiUrl}${apiVersion}${endpoint}`

  const headers = new Headers({
    'Cache-Control': 'no-cache'
  })

  if (data && method !== 'GET' && !rawBody) {
    headers.set('Content-Type', 'application/json')
  }

  const authToken = getAuthToken()
  if (authToken) {
    headers.set('Authorization', authToken)
  }

  const params: RequestInit = {
    method,
    mode: 'cors',
    headers, // Pass the initialized Headers object
    credentials: 'include',
    body: data && method !== 'GET' ? (rawBody ? data : JSON.stringify(data)) : undefined
  }

  if (data && method === 'GET') {
    url += objectUrlQueryfy(data)
  }

  try {
    const response = await fetch(url, params)
    if (response.ok) {
      resolve(response)
    } else {
      if (response.status >= 400 && response.status < 500) {
        const error = (await response.json()) as ResponseError
        throw new APIError(error.code, response.status, 'APIError: ' + error.detail.message, error.detail.errors)
      } else {
        reject(new Error('Failed to fetch'))
      }
    }
  } catch (error) {
    reject(error)
  }
}

export interface ApiParams {
  method: methods
  endpoint: string
  data?: any
  rawBody?: boolean
}

export default async function api(params: ApiParams): Promise<any> {
  try {
    const response = await sendRequest(params)

    if (response.ok) {
      let content = await response.json()
      if (response.status !== 200) content = { ...content, status: response.status }
      return content
    }

    // 4xx errors, these are handled by the server properly and should
    // always return a JSON encoded error
    if (response.status >= 400 && response.status < 500) {
      const error = (await response.json()) as ResponseError
      throw new APIError(error.code, response.status, 'APIError: ' + error.detail.message, error.detail.errors)
    }

    // typically 5xx errors, we can't expect the response to contain any
    // useful information
    throw new APIError(ApiErrorCodes.UNKNOWN, 500, 'Unknown error')
  } catch (error) {
    // network/transport level errors, we won't have much info here
    throw error
  }
}

interface SearchProjectParams {
  search: string
  projectId: string
  processPublicId?: string
}

api.searchProjectResources = async (params: SearchProjectParams) => {
  const projectId = params['projectId']
  const paramsToSend: { search: string; projectId?: string; processPublicId?: string } = { ...params }
  delete paramsToSend.projectId
  return api({ method: 'GET', endpoint: `/project/${projectId}/search-resources`, data: paramsToSend })
}

api.searchProjectAI = async (params: SearchProjectParams) => {
  const projectId = params['projectId']
  const paramsToSend: { search: string; projectId?: string; processPublicId?: string } = { ...params }
  delete paramsToSend.projectId
  return api({ method: 'GET', endpoint: `/project/${projectId}/search-ai`, data: paramsToSend })
}

api.deleteProject = async (projectId: string) => {
  return api({ method: 'DELETE', endpoint: `/project/${projectId}` })
}

api.permanentlyDeleteProject = async (projectId: string) => {
  return api({ method: 'DELETE', endpoint: `/project/${projectId}/permanent` })
}

interface RestoreProjectParams {
  projectId: string
}

api.restoreProject = async (params: RestoreProjectParams) => {
  const projectId = params['projectId']
  const paramsToSend: { projectId?: string } = { ...params }
  delete paramsToSend.projectId
  return api({ method: 'PUT', endpoint: `/project/${projectId}/restore` })
}

api.duplicateProject = async (params: Omit<DuplicateParams, 'targetProjectId' | 'duplicateLinkedTables'>) => {
  return api({
    method: 'POST',
    endpoint: `/project/${params.publicId}/duplicate`,
    data: {
      duplicatePermissions: params.duplicatePermissions,
      lockResource: params.lockResource
    }
  })
}

api.getProjectDeletedProcesses = async (projectId: string) => {
  return api({ method: 'GET', endpoint: `/project/${projectId}/deletedprocesses`, data: { context: { projectId } } })
}

api.getProjectDeletedTables = async (projectId: string) => {
  return api({ method: 'GET', endpoint: `/project/${projectId}/deletedtables` })
}

interface InviteUserParams {
  emails: string[]
  projectId: string
  tags?: string[]
  projectRole?: string
}

api.createInvite = async (params: InviteUserParams): Promise<string> => {
  return api({
    method: 'POST',
    endpoint: `/project/${params.projectId}/invite-multiple`,
    data: {
      emails: params.emails,
      tags: params.tags ? params.tags : undefined,
      projectRole: params.projectRole ? params.projectRole : undefined
    }
  })
}

api.updateInvite = async (
  invite: IMemberInvite,
  params: Pick<InviteUserParams, 'tags' | 'projectId' | 'projectRole'>
): Promise<IMemberInvite> => {
  return api({
    method: 'PUT',
    endpoint: `/project/${params.projectId}/invite/${invite.publicId}`,
    data: {
      tags: params.tags,
      projectRole: params.projectRole
    }
  })
}

interface NewTableParams extends IContext {
  name: string
  type: string
  columns?: { name: string; width?: number; kind?: string }[]
  projectId?: string
}

api.createNewTable = async (params: NewTableParams): Promise<ResponsePayload<ITable>> => {
  if (!params.columns || params.columns.length === 0) {
    params.columns = []
    params.columns.push({ name: 'Column 1', kind: 'text' })
  }
  // delete params.projectId //TODO supply project id to link table to project (backend work)
  return api({ method: 'POST', endpoint: '/table', data: params })
}

api.createNewView = async (
  tableId: string,
  name: string,
  type: number,
  projectId: string
): Promise<ResponsePayload<ITableViewWithColumns>> => {
  return api({
    method: 'POST',
    endpoint: `/table/${tableId}/views/duplicate-default`,
    data: {
      name,
      type,
      context: {
        projectId
      }
    }
  })
}

api.createNewBlankView = async (
  tableId: string,
  newView: INewView
): Promise<ResponsePayload<ITableViewWithColumns>> => {
  return api({
    method: 'POST',
    endpoint: `/table/${tableId}/views`,
    data: newView
  })
}

interface UpdateTableParams extends IContext {
  name: string
  type?: string | null
  logo?: string | null
  variables?: string[]
  keepValidationsInSync?: boolean
  keepColoursInSync?: boolean
  syncHourlyFrequency?: number
}

api.updateTable = async (tableId: string, params: UpdateTableParams): Promise<ResponsePayload<ITable>> => {
  return api({ method: 'PUT', endpoint: `/table/${tableId}`, data: params })
}

api.updateTableView = async (
  viewId: string,
  projectId: string,
  field:
    | 'name'
    | 'description'
    | 'type'
    | 'disableNewRow'
    | 'allowContributorDelete'
    | 'displayValidationErrorRows'
    | 'displayCommentRows'
    | 'collapsedGroupView'
    | 'unpackMultiselectGroupView',
  value: string | EditorContent | null | number | boolean
) => {
  return api({
    method: 'PUT',
    endpoint: `/table/views/${viewId}`,
    data: { [field]: value, context: { projectId: projectId } }
  })
}

api.updateWholeTableView = async (
  viewId: string,
  newTableView: Partial<ITableViewWithColumns> & IContext
): Promise<ResponsePayload<ITableViewWithColumns>> => {
  return api({ method: 'PUT', endpoint: `/table/views/${viewId}`, data: newTableView })
}

api.deleteView = async (viewId: string, projectId: string) => {
  return api({
    method: 'DELETE',
    endpoint: `/table/views/${viewId}`,
    data: { context: { projectId } }
  })
}

api.getTableJoins = async (tableId: string): Promise<ResponsePayload<ITableJoin[]>> => {
  return api({ method: 'GET', endpoint: `/table/${tableId}/joins` })
}

api.createTableJoin = async (
  tableId: string,
  projectId: string,
  tableJoin: ICreateTableJoin
): Promise<ResponsePayload<ITable>> => {
  return api({
    method: 'POST',
    endpoint: `/table/${tableId}/join`,
    data: { ...tableJoin, context: { projectId } }
  })
}

api.updateTableJoin = async (
  tableId: string,
  joinId: string,
  projectId: string,
  tableJoin: ICreateTableJoin
): Promise<ResponsePayload<ITable>> => {
  return api({
    method: 'PUT',
    endpoint: `/table/${tableId}/join/${joinId}`,
    data: { ...tableJoin, context: { projectId } }
  })
}

api.deleteTableJoin = async (tableId: string, joinId: string, projectId: string) => {
  return api({
    method: 'DELETE',
    endpoint: `/table/${tableId}/join/${joinId}`,
    data: { context: { projectId } }
  })
}

api.createTableColumn = async (
  viewId: string,
  column: ICreateTableColumnView
): Promise<ResponsePayload<ITableViewColumn>> => {
  return api({
    method: 'POST',
    endpoint: `/table/views/${viewId}/columns`,
    data: column
  })
}

api.updateTableColumn = async (
  viewId: string,
  columnId: string,
  column: Partial<ITableViewColumn> & IContext & Partial<{ alterOptions: IColumnAlterOptions }>
): Promise<ResponsePayload<ITableViewColumn>> => {
  return api({
    method: 'PUT',
    endpoint: `/table/views/${viewId}/columns/${columnId}`,
    data: column
  })
}

api.deleteTableColumn = async (
  tableId: string,
  columnId: string,
  projectId: string
): Promise<ResponsePayload<string>> => {
  return api({
    method: 'DELETE',
    endpoint: `/table/${tableId}/column/${columnId}`,
    data: { context: { projectId } }
  })
}

api.deleteTableRows = async (
  viewId: string,
  rowIds: string[],
  projectId: string,
  processPublicId?: string,
  processSectionPublicId?: string,
  processResponsePublicId?: string
): Promise<ResponsePayload<string>> => {
  return api({
    method: 'DELETE',
    endpoint: `/table/views/${viewId}/rows`,
    data: {
      rowIds: rowIds,
      context: {
        projectId: projectId,
        processPublicId: processPublicId,
        processSectionPublicId: processSectionPublicId,
        processResponsePublicId: processResponsePublicId
      }
    }
  })
}

interface GetFileParams {
  tableId: string
  columnId: string
  filename: string
}
api.getFile = ({ tableId, columnId, filename }: GetFileParams) => {
  const url =
    `/table/${tableId}/file` +
    encodeURIComponent(
      JSON.stringify({
        columnId,
        filename
      })
    )

  return downloadFileToClient(url, filename)
}

export interface IGetTableViewQueryParams {
  ignoreCachedOptions?: boolean
}

api.getTableView = async (
  tableViewId: string,
  params?: IGetTableViewQueryParams
): Promise<ResponsePayload<ITableViewWithColumns>> => {
  const queryString = params ? objectUrlQueryfy(params!) : ''
  return api({ method: 'GET', endpoint: `/table/views/${tableViewId}${queryString}` })
}

export interface IGetTableViewsQueryParams {
  ignoreColumns?: boolean
}

api.getTableViews = async (
  tableId: string,
  params?: IGetTableViewsQueryParams
): Promise<ResponsePayload<ITableViewWithColumns[]>> => {
  const queryString = params ? objectUrlQueryfy(params!) : ''
  return api({ method: 'GET', endpoint: `/table/${tableId}/views${queryString}` })
}

api.getTableViewCSV = async (
  viewId: string,
  filename: string,
  processId?: string,
  filters?: Array<any>,
  displayCommentRows?: number
) => {
  const query = new URLSearchParams()
  const filtersJson = filters ? convertToApiFilter(filters).map((filter) => JSON.stringify(filter)) : undefined

  filtersJson?.forEach((filter) => query.append('filter', filter))
  if (processId) {
    query.append('processId', processId)
  }
  if (displayCommentRows !== undefined) {
    query.append('displayCommentRows', displayCommentRows.toString())
  }

  const url = `/table/views/${viewId}/csv?${query.toString()}`
  return downloadFileToClient(url, filename)
}

api.getTableComments = async (tableId: string): Promise<ResponsePayload<ICommentThreadStats[]>> => {
  return api({ method: 'GET', endpoint: `/comment_thread/stats?referenceType=table&mainReferenceId=${tableId}` })
}

interface IAsyncRowsReturn {
  type: 'header' | 'rows'
  rows?: ITableRowCompressed[]
  header?: Array<string>
}

api.getTableViewRowsAsync = async function* (
  tableId: string,
  tableViewId: string,
  isAdmin: boolean,
  processId?: string
): AsyncGenerator<IAsyncRowsReturn> {
  let uri = isAdmin ? `/table/${tableId}/rows-stream` : `/table/views/${tableViewId}/rows-stream`
  if (processId !== undefined) {
    uri = uri + `?processId=${processId}`
  }
  const response = await fetch(`${apiUrl}${apiVersion}${uri}`, {
    method: 'GET',
    headers: {
      Authorization: getAuthToken()!
    }
  })

  if (response.body === null) {
    return
  }

  let gotHeader = false
  for await (const data of decodeMultiStream(response.body)) {
    if (!gotHeader) {
      gotHeader = true
      yield { type: 'header', header: data as string[] }
    } else {
      const rows = data as ITableRowCompressed[]
      yield { type: 'rows', rows: rows }
    }
  }
}

api.createNewRow = (
  projectId: string,
  tableViewId: string,
  rowData: Record<string, unknown> = {},
  sortOrder?: number,
  context?: SpreadsheetInProcessContext
): Promise<ResponsePayload<ITableRow[]>> => {
  const payload: { rowData: Record<string, unknown>; sortOrder?: number } = {
    rowData
  }
  if (sortOrder !== undefined) {
    payload.sortOrder = sortOrder
  }

  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }

  return api({
    method: 'POST',
    endpoint: `/table/views/${tableViewId}/rows`,
    data: { rows: [payload], context: processContext }
  })
}

api.createNewRows = (
  projectId: string,
  tableViewId: string,
  rows: Array<{ rowData: Record<string, unknown>; sortOrder?: number }>,
  context?: SpreadsheetInProcessContext
): Promise<ResponsePayload<ITableRow[]>> => {
  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }
  return api({ method: 'POST', endpoint: `/table/views/${tableViewId}/rows`, data: { rows, context: processContext } })
}

api.createBlankRows = (
  projectId: string,
  tableViewId: string,
  numberRows: number,
  context?: SpreadsheetInProcessContext,
  values?: Record<string, ICellValue>
): Promise<ResponsePayload<ITableRow[]>> => {
  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }

  const rows = []
  for (let i = 0; i < numberRows; i++) {
    if (values) {
      rows.push({ rowData: { ...values } })
    } else {
      rows.push({ rowData: {} })
    }
  }

  return api({ method: 'POST', endpoint: `/table/views/${tableViewId}/rows`, data: { rows, context: processContext } })
}

api.previewRow = (
  projectId: string,
  tableViewId: string,
  rowData: Record<string, unknown> = {},
  signal: AbortSignal,
  context?: SpreadsheetInProcessContext
): Promise<ResponsePayload<Record<string, ICellValue>>> => {
  const processContext = context
    ? {
        projectId: projectId,
        processPublicId: context.processId,
        processSectionPublicId: context.processSectionId,
        processResponsePublicId: context.processResponseId
      }
    : { projectId: projectId }

  return post(`/table/views/${tableViewId}/preview-row`, { rowData, context: processContext }, signal)
}

api.getChartData = async (tableViewId: string, params?: any): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: `/table/views/${tableViewId}/chart${params ? '?' + new URLSearchParams(params) : ''}`
  })
}

interface GetDistinctParams {
  tableId: string
  columnId: string
}

api.getDistinct = async ({ tableId, columnId }: GetDistinctParams): Promise<ResponsePayload<string[]>> => {
  const url = `/table/${tableId}/column/${columnId}/distinct`

  return api({
    method: 'GET',
    endpoint: url
  })
}

api.getUser = async (firebaseId: string): Promise<ResponsePayload<IUserObject>> => {
  return api({ method: 'GET', endpoint: `/user/${firebaseId}` })
}

api.getUserByPublicId = async (publicId: string): Promise<ResponsePayload<IUserObject>> => {
  return api({ method: 'GET', endpoint: `/user/public/${publicId}` })
}

api.getMe = async (): Promise<ResponsePayload<IUserObject>> => {
  return api({ method: 'GET', endpoint: `/user/me` })
}

export interface NewUserParams {
  name: string
  email: string
  password: string
}

api.createUser = async (params: NewUserParams) => {
  return api({ method: 'POST', endpoint: '/user', data: params })
}

api.getBilling = async () => {
  return api({ method: 'GET', endpoint: '/user/billing' })
}

interface DeleteResourceParams extends IContext {
  projectId?: string
}

api.deleteProcess = async (processId: string, params: DeleteResourceParams) => {
  return api({ method: 'DELETE', endpoint: `/process/${processId}`, data: params })
}

api.deleteTable = async (tableId: string, params: DeleteResourceParams) => {
  return api({ method: 'DELETE', endpoint: `/table/${tableId}`, data: params })
}

interface RestoreProcessParams extends IContext {
  projectId?: string
}

api.restoreProcess = async (processId: string, params: RestoreProcessParams) => {
  return api({ method: 'PUT', endpoint: `/process/${processId}/restore`, data: params })
}

interface RestoreTableParams extends IContext {
  projectId?: string
}

api.getTable = async (tableId: string, ignoreCachedOptions?: boolean): Promise<ResponsePayload<ITable>> => {
  return api({ method: 'GET', endpoint: `/table/${tableId}?ignoreCachedOptions=${ignoreCachedOptions}` })
}

api.restoreTable = async (tableId: string, params: RestoreTableParams) => {
  return api({ method: 'PUT', endpoint: `/table/${tableId}/restore`, data: params })
}

interface NewProcessParams extends IContext {
  name: string
  type: string
  projectId?: string
}
api.createNewProcess = async (params: NewProcessParams) => {
  return api({ method: 'POST', endpoint: '/process', data: params })
}

interface DuplicateParams {
  publicId: string
  targetProjectId: string
  duplicatePermissions: boolean
  duplicateTables?: boolean
  lockResource?: boolean
}

api.duplicateTable = async (params: DuplicateParams) => {
  return api({
    method: 'POST',
    endpoint: `/table/${params.publicId}/duplicate`,
    data: {
      targetProjectId: params.targetProjectId,
      duplicateLinkedTables: params.duplicateTables === undefined ? undefined : params.duplicateTables,
      duplicatePermissions: params.duplicatePermissions
    }
  })
}

api.duplicateProcess = async (params: DuplicateParams) => {
  return api({
    method: 'POST',
    endpoint: `/process/${params.publicId}/duplicate`,
    data: {
      targetProjectId: params.targetProjectId,
      duplicateLinkedTables: params.duplicateTables === undefined ? undefined : params.duplicateTables,
      duplicatePermissions: params.duplicatePermissions,
      lockResource: params.lockResource
    }
  })
}

api.duplicateView = async (
  tableId: string,
  params: Omit<DuplicateParams, 'targetProjectId' | 'duplicateLinkedTables'>
) => {
  return api({
    method: 'POST',
    endpoint: `/table/${tableId}/views/${params.publicId}/duplicate`,
    data: {
      duplicatePermissions: params.duplicatePermissions,
      lockResource: params.lockResource
    }
  })
}

interface UpdateProcessParams extends IContext {
  processId: string
  name?: string
  type?: string
  description?: any
  logo?: string | null
  permissions?: Array<Array<string>>
  primaryColour?: string
  secondaryColour?: string
  variables?: string[]
  expandByDefault?: boolean
  allowComments?: boolean
  isTemplate?: boolean
  lockedTemplate?: boolean
}

api.updateProcess = async (params: UpdateProcessParams) => {
  const processId = params['processId']
  const paramsToSend: {
    name?: string
    type?: string
    description?: any
    expandByDefault?: boolean
    allowComments?: boolean
    processId?: string
    logo?: string | null
    permissions?: Array<Array<string>>
    variables?: string[]
    isTemplate?: boolean
    lockedTemplate?: boolean
  } = { ...params }
  delete paramsToSend.processId
  return api({ method: 'PUT', endpoint: `/process/${processId}`, data: paramsToSend })
}

interface GetProcessParams {
  excludeChildren?: boolean
}

api.getProcess = async (uuid: string, params: GetProcessParams): Promise<ResponsePayload<IProcessObject>> => {
  return api({ method: 'GET', endpoint: '/process/' + uuid, data: params })
}

api.getProcessAnalytics = async (uuid: string) => {
  return api({ method: 'GET', endpoint: '/process/' + uuid + '/analytics' })
}

api.getDeletedProcessSections = async (processId: string) => {
  return api({ method: 'GET', endpoint: `/process/${processId}/deletedsections` })
}

interface NewProcessSectionParams extends IContext {
  name: string
  plaintextDescription: string
  processId: string
  parentId: string | null
}

api.createNewProcessSection = async (params: NewProcessSectionParams) => {
  const processId = params['processId']
  const paramsToSend: {
    name: string
    plaintextDescription: string
    parentId: string | null
    processId?: string
  } = { ...params }
  delete paramsToSend.processId
  return api({ method: 'POST', endpoint: `/process/${processId}/section`, data: paramsToSend })
}

interface GetProcessSectionParams {
  mainParentSection?: boolean
}

api.getProcessSection = async (
  processId: string,
  processSectionId: string,
  params: GetProcessSectionParams
): Promise<ResponsePayload<IProcessSectionObject>> => {
  return api({
    method: 'GET',
    endpoint: '/process/' + processId + '/section/' + processSectionId,
    data: params
  })
}

interface UpdateProcessSectionParams extends IContext {
  name: string
  description: any
  processId: string
  processSectionId: string
  pdfIncludeDescription?: boolean
  pdfIncludeSection?: boolean
  pageBreakBefore?: boolean
}

api.updateProcessSection = async (params: UpdateProcessSectionParams) => {
  const processId = params['processId']
  const processSectionId = params['processSectionId']
  const paramsToSend: {
    name: string
    description: any
    processId?: string
    processSectionId?: string
    pdfIncludeDescription?: boolean
    pdfIncludeSection?: boolean
    pageBreakBefore?: boolean
  } = { ...params }
  delete paramsToSend.processId
  delete paramsToSend.processSectionId

  return api({ method: 'PUT', endpoint: `/process/${processId}/section/${processSectionId}`, data: paramsToSend })
}

interface UpdateProcessSectionOrderParams extends IContext {
  processSections: Array<any>
  processId: string
}

api.updateProcessSectionOrder = async (params: UpdateProcessSectionOrderParams) => {
  const processId = params['processId']
  const paramsToSend: {
    processSections: Array<any>
    processId?: string
  } = { ...params }
  delete paramsToSend.processId

  return api({ method: 'PUT', endpoint: `/process/${processId}/changesectionorder`, data: paramsToSend })
}

api.deleteProcessSection = async (projectId: string, processId: string, processSectionId: string) => {
  return api({
    method: 'DELETE',
    endpoint: `/process/${processId}/section/${processSectionId}`,
    data: { context: { projectId } }
  })
}

api.restoreProcessSection = async (projectId: string, processId: string, processSectionId: string) => {
  return api({
    method: 'PUT',
    endpoint: `/process/${processId}/section/${processSectionId}/restore`,
    data: { context: { projectId } }
  })
}

api.duplicateProcessSection = async (projectId: string, processId: string, processSectionId: string) => {
  return api({
    method: 'POST',
    endpoint: `/process/${processId}/section/${processSectionId}/duplicate`,
    data: { context: { projectId } }
  })
}

interface NewProcessResponseParams extends IContext {
  type: string
  processId: string
  processSectionId: string
}

api.createNewProcessResponse = async (params: NewProcessResponseParams) => {
  const processId = params['processId']
  const processSectionId = params['processSectionId']
  const paramsToSend: {
    type: string
    processId?: string
    processSectionId?: string
  } = { ...params }
  delete paramsToSend.processId
  delete paramsToSend.processSectionId

  return api({
    method: 'POST',
    endpoint: `/process/${processId}/section/${processSectionId}/response`,
    data: paramsToSend
  })
}

interface UpdateProcessResponseParams extends IContext {
  type?: string
  typeOptions?: any
  resetAfterResponse?: boolean
  enableSubmission?: boolean
  processId: string
  processSectionId: string
  processResponseId: string
  pdfIncludeResponse?: boolean
  expireResponse?: boolean
}

api.updateProcessResponse = async (params: UpdateProcessResponseParams) => {
  const processId = params['processId']
  const processSectionId = params['processSectionId']
  const processResponseId = params['processResponseId']
  const paramsToSend: {
    type?: string
    typeOptions?: any
    resetAfterResponse?: boolean
    processId?: string
    processSectionId?: string
    processResponseId?: string
    pdfIncludeResponse?: boolean
    expireResponse?: boolean
  } = { ...params }
  delete paramsToSend.processId
  delete paramsToSend.processSectionId
  delete paramsToSend.processResponseId

  return api({
    method: 'PUT',
    endpoint: `/process/${processId}/section/${processSectionId}/response/${processResponseId}`,
    data: paramsToSend
  })
}

interface SubmitProcessResponseParams extends IContext {
  response: any
  processId: string
  processSectionId: string
  processResponseId: string
}

api.submitProcessResponse = async (params: SubmitProcessResponseParams) => {
  const processId = params['processId']
  const processSectionId = params['processSectionId']
  const processResponseId = params['processResponseId']
  const paramsToSend: {
    response: any
    processId?: string
    processSectionId?: string
    processResponseId?: string
  } = { ...params }
  delete paramsToSend.processId
  delete paramsToSend.processSectionId
  delete paramsToSend.processResponseId

  return api({
    method: 'PUT',
    endpoint: `/process/${processId}/section/${processSectionId}/response/${processResponseId}/submit`,
    data: paramsToSend
  })
}

api.resetProcessResponse = async (
  projectId: string,
  processId: string,
  processSectionId: string,
  processResponseId: string
) => {
  return api({
    method: 'PUT',
    endpoint: `/process/${processId}/section/${processSectionId}/response/${processResponseId}/reset`,
    data: { context: { projectId } }
  })
}

api.deleteProcessResponse = async (
  projectId: string,
  processId: string,
  processSectionId: string,
  processResponseId: string
) => {
  return api({
    method: 'DELETE',
    endpoint: `/process/${processId}/section/${processSectionId}/response/${processResponseId}`,
    data: { context: { projectId } }
  })
}

api.downloadProcessPDF = async (processId: string, fileName: string) => {
  const url = `/process/${processId}/pdf`
  return downloadFileToClient(url, fileName)
}

api.downloadProcessDoc = async (processId: string, fileName: string) => {
  const url = `/process/${processId}/doc`
  return downloadFileToClient(url, fileName)
}

api.downloadProcess = async (
  processId: string,
  fileName: string,
  exportFormat: string,
  tableLinks: boolean,
  description: boolean,
  pageFormat: string,
  pageOrientation: string,
  fontSize: number,
  fontType: string
) => {
  const url =
    `/process/${processId}/export?exportFormat` +
    `=${exportFormat}&tableLinks=${tableLinks.toString()}&` +
    `description=${description.toString()}&` +
    `pageFormat=${pageFormat}&pageOrientation=${pageOrientation}` +
    `&fontSize=${fontSize}&fontType=${fontType}`
  return downloadFileToClient(url, fileName)
}

api.createNotification = async (projectId: string, values: any): Promise<ResponsePayload<INotification>> => {
  return api({
    method: 'POST',
    endpoint: `/notifications`,
    data: {
      projectId: projectId,
      ...values
    }
  })
}

api.updateNotification = async (notification: INotification, values: any): Promise<ResponsePayload<INotification>> => {
  return api({
    method: 'PUT',
    endpoint: `/notifications/${notification.publicId}`,
    data: values
  })
}

api.getEvents = async (
  resourcePublicId: string,
  type: string,
  page: number,
  users: string[],
  verb: string[],
  startDate: string | null,
  endDate: string | null,
  search: string | null
): Promise<ResponsePayload<ILogObject[]>> => {
  return api({
    method: 'GET',
    endpoint: `/notifications/events/${resourcePublicId}`,

    data:
      users.length > 0
        ? { type, page, users, verb, startDate, endDate, search }
        : { type, page, verb, startDate, endDate, search }
  })
}

api.getAdminEvents = async (
  page: number,
  users: string[],
  email: string,
  verb: string[],
  startDate: string | null,
  endDate: string | null,
  search: string | null,
  projects: string[]
): Promise<ResponsePayload<ILogObject[]>> => {
  let data: {
    page: number
    verb: string[]
    email: string
    startDate: string | null
    endDate: string | null
    search: string | null
    users?: string[]
    projects?: string[]
  } = { page, verb, email, startDate, endDate, search }
  if (users.length > 0) {
    data = { ...data, users }
  }
  if (projects.length > 0) {
    data = { ...data, projects }
  }
  return api({
    method: 'GET',
    endpoint: `/admin/events`,
    data
  })
}

api.getNotifications = async (projectId: string): Promise<ResponsePayload<INotification[]>> => {
  return api({ method: 'GET', endpoint: `/project/${projectId}/notifications` })
}

api.deleteNotification = async (publicId: string) => {
  return api({
    method: 'DELETE',
    endpoint: `/notifications/${publicId}`
  })
}

api.getNotificationEventTypes = async (): Promise<ResponsePayload<string[]>> => {
  return api({
    method: 'GET',
    endpoint: `/notifications/event-types`
  })
}

interface IProjectResourcesCall {
  typeId?: string
  projectPermissions?: boolean
  excludeProcesses?: boolean
  excludeTables?: boolean
  onlyDeleted?: boolean
}

api.getProjectResources = async (
  projectId: string,
  args: IProjectResourcesCall
): Promise<ResponsePayload<IFolderContents[]>> => {
  return api({
    method: 'POST',
    endpoint: `/project/${projectId}/resources`,
    data: args
  })
}

api.getProjects = async (): Promise<ResponsePayload<IProject[]>> => {
  return api({
    method: 'GET',
    endpoint: '/user/projects'
  })
}

api.getUserProjectTags = async (projectId: string): Promise<ResponsePayload<ITag[]>> => {
  return api({
    method: 'GET',
    endpoint: `/user/projects/${projectId}/tags`
  })
}

api.getOwnerProjects = async (): Promise<ResponsePayload<IProject[]>> => {
  return api({
    method: 'GET',
    endpoint: '/user/owner-projects'
  })
}

api.getPublicProjects = async (): Promise<ResponsePayload<IProject[]>> => {
  return api({
    method: 'GET',
    endpoint: '/user/public-projects'
  })
}

api.getPinnedProjects = async (firebaseUserId: string): Promise<ResponsePayload<IPinnedProjectObject[]>> => {
  return api({
    method: 'GET',
    endpoint: `/user/${firebaseUserId}/pinned-projects`
  })
}

api.getAchievements = async (firebaseUserId: string): Promise<ResponsePayload<IAchievementsObject>> => {
  return api({
    method: 'GET',
    endpoint: `/user/${firebaseUserId}/achievements`
  })
}

api.getContributions = async (firebaseUserId: string): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: `/user/${firebaseUserId}/contributions`
  })
}

api.getPublicContributions = async (firebaseUserId: string): Promise<ResponsePayload<ILogObject[]>> => {
  return api({
    method: 'GET',
    endpoint: `/user/${firebaseUserId}/public-contributions`
  })
}

api.getProjectSecrets = async (projectId: string): Promise<ResponsePayload<IProjectSecret[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/secrets`
  })
}

api.getProjectTags = async (projectId: string): Promise<ResponsePayload<ITableWithCells[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/tags`
  })
}

api.getProjectVariables = async (projectId: string): Promise<ResponsePayload<ITableWithCells[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/variables`
  })
}

api.getProjectTables = async (projectId: string): Promise<ResponsePayload<ITable[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/tables`
  })
}

api.uploadFile = async (file: File, resources?: IFileParentResource[]): Promise<ResponsePayload<IFile>> => {
  const body = new FormData()
  body.set('file', file)

  if (resources) {
    body.set('resources', JSON.stringify(resources))
  }

  return api({
    method: 'POST',
    endpoint: `/files`,
    rawBody: true,
    data: body
  })
}

api.postCSV = async (name: string, type: string, csv: File, hasHeader: boolean, projectId: string) => {
  const token = getAuthToken()

  return new Promise(function (resolve, reject) {
    const formData = new FormData()
    formData.append('csv', csv)
    formData.append('name', name)
    formData.append('type', type)
    formData.append('hasHeader', hasHeader.toString())
    if (projectId) {
      formData.append('projectId', projectId)
    }

    const xhr = new XMLHttpRequest()
    // Add any event handlers here...
    xhr.open('POST', `${apiUrl}${apiVersion}/table/csv`, true)
    xhr.setRequestHeader('Authorization', token!)

    xhr.send(formData)
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve(xhr.response)
        } else {
          try {
            const json = JSON.parse(xhr.response)
            reject(new APIError(xhr.status.toString() as ApiErrorCodes, xhr.status, json['detail']['message'], []))
          } catch (e) {
            reject(new APIError(xhr.status.toString() as ApiErrorCodes, xhr.status, 'Something went wrong', []))
          }
        }
      }
    }
  })
}

api.signUrl = async (url: string): Promise<ResponsePayload<ISignedUrlToken>> => {
  return api({
    method: 'POST',
    endpoint: '/files/sign',
    data: {
      url
    }
  })
}

interface CreateCommentThread extends IContext {
  referenceType: CommentReferenceType
  referenceId: string
  commentText: string
  mainReferenceId?: string
}

api.postCommentThread = async (payload: CreateCommentThread): Promise<ResponsePayload<ICommentThreadObject>> => {
  return api({
    method: 'POST',
    endpoint: '/comment_thread',
    data: payload
  })
}

api.updateStatusCommentThread = async (
  context: IContext,
  commentThreadId: string,
  status: CommentThreadStatusType
): Promise<ResponsePayload<ICommentThreadObject>> => {
  return api({
    method: 'PUT',
    endpoint: `/comment_thread/${commentThreadId}/${status}`,
    data: { ...context }
  })
}

api.deleteCommentThread = async (
  context: IContext,
  commentThreadId: string
): Promise<ResponsePayload<ICommentThreadObject>> => {
  return api({
    method: 'DELETE',
    endpoint: `/comment_thread/${commentThreadId}`,
    data: { ...context }
  })
}

api.getCommentThreadsForReference = async (
  referenceType: CommentReferenceType,
  referenceId: string,
  mainReference?: string
): Promise<ResponsePayload<ICommentThreadObject[]>> => {
  return api({
    method: 'GET',
    endpoint: `/comment_thread?referenceType=${referenceType}&referenceId=${referenceId}${
      mainReference ? '&mainReference=' + mainReference : ''
    }`
  })
}

api.getCommentThreadsStatsPerReference = async (
  referenceType: CommentReferenceType,
  mainReferenceId?: string
): Promise<ResponsePayload<ICommentThreadStats[]>> => {
  return api({
    method: 'GET',
    endpoint: `/comment_thread/stats?referenceType=${referenceType}&mainReferenceId=${mainReferenceId}`
  })
}

interface CreateCommentProps extends IContext {
  commentText: string
}
api.postComment = async (
  commentThreadId: string,
  data: CreateCommentProps
): Promise<ResponsePayload<ICommentObject>> => {
  return api({
    method: 'POST',
    endpoint: '/comment_thread/' + commentThreadId + '/comment',
    data
  })
}

api.deleteComment = async (
  context: IContext,
  commentThreadId: string,
  commentId: string
): Promise<ResponsePayload<ICommentObject>> => {
  return api({
    method: 'DELETE',
    endpoint: `/comment_thread/${commentThreadId}/comment/${commentId}`,
    data: { ...context }
  })
}

interface UpdateCommentProps extends IContext {
  commentText: string
}

api.updateComment = async (
  commentThreadId: string,
  commentId: string,
  data: UpdateCommentProps
): Promise<ResponsePayload<ICommentObject>> => {
  return api({
    method: 'PUT',
    endpoint: `/comment_thread/${commentThreadId}/comment/${commentId}`,
    data: data
  })
}

api.getResourcePermissions = async (
  resource: string,
  resourceId: string
): Promise<ResponsePayload<IAccessPolicy[]>> => {
  return api({
    method: 'GET',
    endpoint: `/permissions`,
    data: {
      resource,
      resourceId
    }
  })
}

api.getTag = async (tagId: string): Promise<ResponsePayload<ITag>> => {
  return api({
    method: 'GET',
    endpoint: `/permissions/tag?tagId=${tagId}`
  })
}

export interface NewPolicyRequest extends IContext {
  role: UserRoles
  resourceKind: AccessResourceKinds
  resourceId: string
  attributeKind: AccessAttributeKinds
  attributeId?: string
  tagReferenceId?: string
}
api.createAccessPolicy = async (policy: NewPolicyRequest): Promise<ResponsePayload<IAccessPolicy>> => {
  return api({
    method: 'POST',
    endpoint: `/permissions`,
    data: policy
  })
}

api.updateAccessPolicy = async (
  projectId: string,
  policy: IAccessPolicy,
  role: UserRoles
): Promise<ResponsePayload<IAccessPolicy>> => {
  return api({
    method: 'PUT',
    endpoint: `/permissions/${policy.publicId}`,
    data: {
      role,
      context: { projectId }
    }
  })
}

api.deleteAccessPolicy = async (projectId: string, policy: IAccessPolicy) => {
  return api({
    method: 'DELETE',
    endpoint: `/permissions/${policy.publicId}`,
    data: {
      context: { projectId }
    }
  })
}

api.updateViewsProcessPermissions = async (resourceId: string): Promise<ResponsePayload<IAccessPolicy[]>> => {
  return api({
    method: 'PUT',
    endpoint: `/process/sync-views-permissions`,
    data: {
      resourceId
    }
  })
}

api.getProjectMembers = async (projectId: string): Promise<ResponsePayload<IProjectMember[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/members`
  })
}

api.getInvitedMembers = async (projectId: string): Promise<ResponsePayload<IMemberInvite[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/invitedmembers`
  })
}

api.deleteProjectInvite = async (projectId: string, inviteId: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'DELETE',
    endpoint: `/project/${projectId}/invite/${inviteId}`
  })
}

api.resendProjectInvite = async (projectId: string, inviteId: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/project/${projectId}/invite/${inviteId}`
  })
}

api.addUserTag = async (user: ISummaryUser, tagReferenceId: string): Promise<ResponsePayload<ITag>> => {
  return api({
    method: 'POST',
    endpoint: `/user/${user.publicId}/tags`,
    data: {
      tagReferenceId
    }
  })
}

api.deleteUserTag = async (user: ISummaryUser, tag: ITag) => {
  return api({
    method: 'DELETE',
    endpoint: `/user/${user.publicId}/tags/${tag.publicId}`
  })
}

api.updateProjectMemberRole = async (
  user: ISummaryUser,
  project: IProject,
  role: string
): Promise<ResponsePayload<IProjectMember>> => {
  return api({
    method: 'PUT',
    endpoint: `/project/${project.publicId}/changeuserrole/${user.firebaseUserId}`,
    data: {
      role
    }
  })
}

api.deleteProjectMember = async (user: ISummaryUser, project: IProject) => {
  return api({
    method: 'DELETE',
    endpoint: `/project/${project.publicId}/removeuser/${user.firebaseUserId}`
  })
}

api.loginUser = async (email: string, password: string): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'POST',
    endpoint: `/user/authenticate`,
    data: {
      email,
      password
    }
  })
}

api.verifyAccount = async (token: string): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'POST',
    endpoint: `/user/verify-account`,
    data: {
      token
    }
  })
}

api.sendEmailVerification = async (email: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/user/send-verification-email`,
    data: {
      email
    }
  })
}

api.sendPasswordReset = async (email: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/user/send-password-reset-email`,
    data: {
      email
    }
  })
}

api.resetPassword = async (token: string, password: string): Promise<ResponsePayload<null>> => {
  return api({
    method: 'POST',
    endpoint: `/user/reset-password`,
    data: {
      token,
      password
    }
  })
}

api.completeGoogleOAuth = async (code: string): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'GET',
    endpoint: `/identities/google/complete`,
    data: {
      code
    }
  })
}

api.getGoogleOauthConnectUrl = async (): Promise<ResponsePayload<IOAuthConnectUrl>> => {
  return api({
    method: 'GET',
    endpoint: `/identities/google/connect`
  })
}

api.completeMicrosoftOAuth = async (code: string): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'GET',
    endpoint: `/identities/microsoft/complete`,
    data: {
      code
    }
  })
}

api.getMicrosoftOauthConnectUrl = async (): Promise<ResponsePayload<IOAuthConnectUrl>> => {
  return api({
    method: 'GET',
    endpoint: `/identities/microsoft/connect`
  })
}

api.getAllUsers = async (email: string, subscriptionLevel?: number): Promise<ResponsePayload<IUserObject[]>> => {
  let url = `/admin/users?email=${email}`
  if (subscriptionLevel !== undefined) {
    url += `&subscriptionLevel=${subscriptionLevel}`
  }

  return api({
    method: 'GET',
    endpoint: url
  })
}

api.getAllProjects = async (simple = false): Promise<ResponsePayload<IProject[]>> => {
  return api({
    method: 'GET',
    endpoint: `/admin/projects?simple=${simple}`
  })
}

api.getMasqueradeCredentials = async (
  user: IUserObject
): Promise<ResponsePayload<{ token: string; expires: number }>> => {
  return api({
    method: 'POST',
    endpoint: `/admin/users/${user.publicId}/masquerade`
  })
}

api.logoutUser = async () => {
  return api({
    method: 'POST',
    endpoint: `/user/logout`
  })
}

api.createProjectSecret = async (
  project: IProject,
  name: string,
  value: string
): Promise<ResponsePayload<IProjectSecret>> => {
  return api({
    method: 'POST',
    endpoint: `/project/${project.publicId}/secrets`,
    data: {
      name,
      value
    }
  })
}

api.deleteProjectSecret = async (project: IProject, secret: IProjectSecret): Promise<ResponsePayload<string>> => {
  return api({
    method: 'DELETE',
    endpoint: `/project/${project.publicId}/secrets/${secret.publicId}`
  })
}

api.updateProjectSecret = async (
  project: IProject,
  secret: IProjectSecret,
  name: string,
  value: string
): Promise<ResponsePayload<IProjectSecret>> => {
  return api({
    method: 'PUT',
    endpoint: `/project/${project.publicId}/secrets/${secret.publicId}`,
    data: {
      name,
      value
    }
  })
}

api.setup2FA = async (): Promise<ResponsePayload<I2FaSetup>> => {
  return api({ method: 'POST', endpoint: `/user/2fa/setup` })
}

api.complete2FaSetup = async (secret: string, code: string): Promise<ResponsePayload<string[]>> => {
  return api({ method: 'POST', endpoint: `/user/2fa/setup/complete`, data: { secret, code } })
}

api.disable2FaSetup = async (code: string): Promise<ResponsePayload<void>> => {
  return api({ method: 'POST', endpoint: `/user/2fa/remove`, data: { code } })
}

api.getTempUserToken = async (email: string, password: string): Promise<ResponsePayload<{ code: string }>> => {
  return api({ method: 'POST', endpoint: `/user/token`, data: { email, password } })
}

api.loginWith2Fa = async (twoFactorCode: string, token: string): Promise<ResponsePayload<IUserObject>> => {
  return api({ method: 'POST', endpoint: `/user/2fa/authenticate`, data: { twoFactorCode, token } })
}

api.updateUserAccount = async (
  oldPassword?: string,
  password?: string,
  passwordConfirm?: string,
  twoFactorCode?: string,
  allowSupportAccess?: boolean
): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'PUT',
    endpoint: `/user/account`,
    data: {
      oldPassword,
      password,
      passwordConfirm,
      twoFactorCode,
      allowSupportAccess
    }
  })
}
interface INewApiKey {
  tempKey: string
  hash: string
  name: string
  prefix: string
  publicId: string
}
api.updateApiKey = async (apiKey: IApiKey): Promise<ResponsePayload<IApiKey>> => {
  return api({
    method: 'PUT',
    endpoint: '/user/apikey/' + apiKey.publicId,
    data: {
      name: apiKey.name
    }
  })
}

api.getIntegrationRedirects = async (): Promise<ResponsePayload<IIntegrationRedirects>> => {
  return api({
    method: 'GET',
    endpoint: '/integrations/redirects'
  })
}

api.getAccessToken = async (
  provider: IntegrationProviders,
  authorizationCode: string,
  state?: string
): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'POST',
    endpoint: `/integrations/${provider}/get-access-token`,
    data: {
      authorizationCode,
      state
    }
  })
}

api.disconnectAccount = async (provider: IntegrationProviders): Promise<ResponsePayload<IUserObject>> => {
  return api({
    method: 'DELETE',
    endpoint: `/integrations/${provider}/disconnect`
  })
}

api.syncIntegration = async (
  tableId: string,
  providerName: string,
  data: IIntegrationData,
  updatingIntegration: boolean
): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: updatingIntegration
      ? `/table/${tableId}/sync/${providerName}/update`
      : `/table/${tableId}/sync/${providerName}`,
    data
  })
}

api.manualSyncIntegration = async (tableId: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'GET',
    endpoint: `/table/${tableId}/sync/manual`
  })
}

api.getSyncIntegrationInfo = async (
  tableId: string
): Promise<ResponsePayload<{ isSyncing: boolean; lastSync: string | null; failedSyncAttempts: number }>> => {
  return api({ method: 'GET', endpoint: `/table/${tableId}/sync/info` })
}
api.deleteIntegration = async (tableId: string, provider: IntegrationProviders): Promise<ResponsePayload<string>> => {
  return api({
    method: 'DELETE',
    endpoint: `/table/${tableId}/sync/${provider}`
  })
}

api.createApiKey = async (apiKey: Partial<IApiKey>): Promise<ResponsePayload<INewApiKey>> => {
  return api({
    method: 'POST',
    endpoint: '/user/apikey',
    data: {
      name: apiKey.name
    }
  })
}

api.deleteApiKey = async (apiKey: Partial<IApiKey>): Promise<ResponsePayload<string>> => {
  return api({
    method: 'DELETE',
    endpoint: '/user/apikey/' + apiKey.publicId
  })
}

interface UserSearchParameters {
  query?: string
  processId?: string
  projectId?: string
  tableViewId?: string
}
api.searchUsers = async (params: UserSearchParameters): Promise<ResponsePayload<ISummaryUser[]>> => {
  return api({ method: 'GET', endpoint: `/user/search`, data: params })
}

api.getProjectProcesses = async (projectId: string): Promise<ResponsePayload<IProcess[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/processes`
  })
}

api.getSentProjectNotifications = async (
  projectId: string,
  notificationId?: string,
  page = 1,
  size = 25
): Promise<ResponsePayload<INotificationExecution[]>> => {
  return api({
    method: 'GET',
    endpoint: `/project/${projectId}/sent-notifications`,
    data: {
      notificationId,
      page,
      size
    }
  })
}

api.getCeleryStats = async (): Promise<ResponsePayload<ICeleryStats>> => {
  return api({
    method: 'GET',
    endpoint: `/admin/celery/stats`
  })
}

api.redisStats = async () => {
  return api({
    method: 'GET',
    endpoint: `/admin/redis/stats`
  })
}

api.flushRedis = async (): Promise<ResponsePayload<string>> => {
  return api({
    method: 'GET',
    endpoint: `/admin/redis/flush`
  })
}

api.executeCeleryQueueActions = async (action: string, queue?: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'GET',
    endpoint: `/admin/celery/${action}${queue ? `/${queue}` : ''}`
  })
}

api.getProcessStats = async (): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: '/admin/process/stats'
  })
}

api.getTableStats = async (): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: '/admin/table/stats'
  })
}

api.getUserStats = async (): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: '/admin/user/stats'
  })
}

api.getTotalUsers = async (): Promise<ResponsePayload<{ totalUsers: number; advancedUsers: number }>> => {
  return api({
    method: 'GET',
    endpoint: '/admin/total-users'
  })
}

api.verifyUserEmail = async (userId: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/admin/users/${userId}/verify`
  })
}

api.adminRemove2fa = async (userId: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/admin/users/${userId}/2fa/remove`
  })
}

api.adminUpdateUser = async (
  userId: string,
  name: string,
  email: string,
  password: string | null,
  subscriptionLevel: number,
  subscriptionQuota: number,
  subscriptionManagerEmail: string | null
): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/admin/users/${userId}/update`,
    data: {
      name,
      email,
      password: password === undefined || password === null || password === '' ? null : password,
      subscriptionLevel,
      subscriptionQuota,
      subscriptionManagerEmail
    }
  })
}

api.getProjectStats = async (): Promise<ResponsePayload<Record<string, number>>> => {
  return api({
    method: 'GET',
    endpoint: '/admin/project/stats'
  })
}

api.requestPermission = async (project: string, type: string, id: string): Promise<ResponsePayload<boolean>> => {
  return api({
    method: 'GET',
    endpoint: `/permissions/request/${project}/${type}/${id}`
  })
}

api.getWhereTableUsed = async (publicId: string): Promise<ResponsePayload<IUsed[]>> => {
  return api({
    method: 'GET',
    endpoint: `/table/${publicId}/used`
  })
}

api.getColumnUsedViews = async (
  tableId: string,
  columnId: string
): Promise<ResponsePayload<ITableViewWithColumns[]>> => {
  return api({
    method: 'GET',
    endpoint: `/table/${tableId}/column/${columnId}/views`
  })
}

api.upsertRows = async (
  tableViewId: string,
  rows: Array<{ rowData: Record<string, unknown>; sortOrder?: number }>,
  upsertColumnName: string
): Promise<ResponsePayload<ITableRow[]>> => {
  return api({
    method: 'POST',
    endpoint: `/table/views/${tableViewId}/rows/upsert`,
    data: {
      rows,
      upsertColumnName
    }
  })
}

api.getTemplates = async (): Promise<ResponsePayload<Partial<IProcessObject>[]>> => {
  return api({
    method: 'GET',
    endpoint: `/user/templates`
  })
}

interface IIntegrationPassthrough {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'
  path: string
  sourceSystem: IntegrationProviders
  headers?: Record<string, string>
  data?: Record<string, any>
}

api.integrationPassthrough = async (
  args: IIntegrationPassthrough
): Promise<ResponsePayload<IIntegrationPassthroughResponse>> => {
  return api({
    method: 'POST',
    endpoint: `/integrations/passthrough`,
    data: args
  })
}

api.downloadChartImage = async (tableViewId: string, fileName: string, params?: any) => {
  const url = `/table/views/${tableViewId}/chart/image${params ? '?' + new URLSearchParams(params) : ''}`
  return downloadFileToClient(url, fileName)
}

api.getSubscriptionComparison = async (): Promise<ResponsePayload<ISubscriptionComparison[]>> => {
  return api({
    method: 'GET',
    endpoint: `/subscription/comparison`
  })
}

api.allocateLicense = async (email: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/subscription/allocate`,
    data: {
      email
    }
  })
}

api.removeLicense = async (email: string): Promise<ResponsePayload<string>> => {
  return api({
    method: 'POST',
    endpoint: `/subscription/remove`,
    data: {
      email
    }
  })
}

api.getFormulaHelper = async (viewId: string, columnId: string): Promise<ResponsePayload<IFormulaHelper[]>> => {
  return api({
    method: 'GET',
    endpoint: `/table/views/${viewId}/column/${columnId}/formula-info`
  })
}
