import React from 'react'

interface FlagProps {
  text: string
  color: string
}

const Flag: React.FC<FlagProps> = ({ text, color }) => {
  return (
    <div
      className="flex items-center justify-center text-xs h-full box-border font-bold"
      style={{
        position: 'relative',
        height: '20px',
        textAlign: 'center',
        color: 'white',
        marginLeft: '5px',
        padding: '1px',
        backgroundColor: color,
        borderRadius: '5px',
        top: '-1px'
      }}
    >
      {text}
    </div>
  )
}

export default Flag
