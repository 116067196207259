import React from 'react'
import Modal, { ModalProps } from 'components/modal'
import { IDuplicatedChild } from 'types'

interface DuplicateChildrenProps extends ModalProps {
  duplicatedChildren: IDuplicatedChild[]
}

const DuplicateChildren: React.FC<DuplicateChildrenProps> = ({ id, open, setOpen, duplicatedChildren }) => {
  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Duplicated Resources">
      <ul style={{ padding: '20px' }}>
        {duplicatedChildren.length > 0 &&
          duplicatedChildren.map((child, index) => (
            <li key={index} className="duplicate-child" style={{ marginBottom: '10px' }}>
              <a href={child.link} target="_blank" rel="noreferrer">
                {child.name}
              </a>
            </li>
          ))}

        {duplicatedChildren.length === 0 && (
          <div className="no-duplicated-children">No resources have been duplicated.</div>
        )}
      </ul>
    </Modal>
  )
}

export default DuplicateChildren
