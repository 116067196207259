import React from 'react'
import Modal, { ModalProps } from 'components/modal'
import Audit from 'components/audit'
import { useDataContext } from 'components/spreadsheet/contexts/data'

interface AuditLogModalProps extends ModalProps {
  verbs?: string[]
  rowId?: string
}

const AuditLogModal: React.FC<AuditLogModalProps> = ({ id, open, setOpen, verbs, rowId }) => {
  const { spreadsheetData } = useDataContext()
  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Audit Log">
      <Audit type={'table'} resourceId={spreadsheetData.tableDetails.publicId} verbs={verbs} searchTerm={rowId} />
    </Modal>
  )
}

export default AuditLogModal
