import React, { useEffect, useState } from 'react'
import { IVariableMap, ITableWithCells, ResponsePayload } from 'types'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import Select from 'components/select'

interface ResourceTypeProps {
  handleChange: (value: string | null) => void
  defaultValue: string | null
  helperText: string
}

const ResourceType: React.FC<ResourceTypeProps> = ({ handleChange, defaultValue, helperText }) => {
  const { project } = useProject()
  const [type, setType] = useState<string | null>()
  const [variables, setVariables] = useState<IVariableMap>()

  useEffect(() => {
    if (project && project.publicId !== '') {
      api.getProjectVariables(project.publicId).then((response: ResponsePayload<ITableWithCells[]>) => {
        if (response.data) {
          const variables: IVariableMap = {}

          for (let i = 0; i < response.data.length; i++) {
            const table: ITableWithCells = response.data[i]
            const cells = table.cells
            for (let j = 0; j < cells.length; j++) {
              const cell = cells[j]
              variables[cell.id] = {
                label: cell.value ? cell.value : '',
                group: `${table.name} - ${cell.column.name}`,
                value: cell.id
              }
            }
          }
          const selectedType = defaultValue && defaultValue in variables ? variables[defaultValue].value : defaultValue
          setVariables(variables)
          setType(selectedType)
        }
      })
    }
  }, [project])

  const options = variables
    ? Object.keys(variables).map((variableId: string) => {
        return variables[variableId]
      })
    : undefined

  return (
    <Select
      options={options ? options : []}
      onOptionClick={(option) => {
        setType(option)
        handleChange(option)
      }}
      groupBy={true}
      optionsSelected={type !== null && type !== undefined ? [type] : []}
      info={helperText}
      loading={variables === undefined}
      freeEntry={true}
      customHeight={'28px'}
      customPadding={'5px'}
    />
  )
}

export default ResourceType
