import { getAuthToken } from 'helpers/auth'
import React, { createContext, useEffect } from 'react'
import { io, Socket } from 'socket.io-client'
import { IEventSocket, IMultipleCellsUpdatedSocket } from 'types'

interface ServerToClientEvents {
  noArg: () => void
  basicEmit: (a: number, b: string, c: Buffer) => void
  withAck: (d: string, callback: (e: number) => void) => void
  multiple_cells_updated: (data: IMultipleCellsUpdatedSocket) => void
  event: (data: IEventSocket) => void
}

interface ClientToServerEvents {
  join: (publicId: string) => void
  leave: (publicId: string) => void
}

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  process.env.REACT_APP_WEBSOCKET_HOST ? process.env.REACT_APP_WEBSOCKET_HOST : '',
  { transports: ['websocket'], withCredentials: true, auth: { token: getAuthToken() } }
)

const _useSocketProvider = () => {
  useEffect(() => {
    socket.on('connect_error', () => {
      socket.io.opts.transports = ['polling', 'websocket']
    })
  }, [])
  return {
    socket
  }
}

type SocketContextType = ReturnType<typeof _useSocketProvider>

const SocketContext = createContext<SocketContextType | undefined>(undefined)

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = _useSocketProvider()
  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
}

export const useSocket = () => {
  const context = React.useContext(SocketContext)

  if (context === undefined) {
    throw new Error('useSocket can only be used within a Socket context')
  }

  return context
}

export default useSocket
