import React from 'react'
import constants from 'style/constants.module.scss'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import { IGroupCollapsed, IViewPortColumn } from 'components/spreadsheet/types'
import GroupCell from 'components/spreadsheet/components/grouping/GroupCell'
import RowNumberCell from 'components/spreadsheet/components/addRow/RowNumberCell'
import Aggregate from 'components/spreadsheet/components/aggregate'
import { ITableRow, ITableViewColour, IContextMenuState } from 'types'

interface Props {
  width: number
  rowNumber: number
  colourSettings: ITableViewColour[]
  rowHeight: number
  collapsedRow: IGroupCollapsed
  onGroupToggle: (groupKey: string) => void
  onGroupContext: (menu: IContextMenuState) => void
  rows: ITableRow[]
  streaming: boolean
  groupedColumns: IViewPortColumn[]
  nonGroupedColumns: IViewPortColumn[]
}

const CollapsedRow: React.FC<Props> = ({
  width,
  rowNumber,
  rowHeight,
  colourSettings,
  collapsedRow,
  onGroupToggle,
  onGroupContext,
  rows,
  streaming,
  groupedColumns,
  nonGroupedColumns
}) => {
  let startedRenderingLabels = false
  let isFirstColumnGroupLabel = false

  const groupedColumnElements = groupedColumns.map((vColumn, index) => {
    const groupKey = collapsedRow.groupKeys[index]
    const value = collapsedRow.row.rowData[vColumn.column.publicId]
    let shouldShowValue = false

    if (collapsedRow.groupsToRender.includes(groupKey)) {
      shouldShowValue = true
      startedRenderingLabels = true
      if (index === 0) {
        isFirstColumnGroupLabel = true
      }
    }

    return (
      <GroupCell
        key={vColumn.column.publicId}
        column={vColumn}
        rowHeight={rowHeight}
        colourSettings={colourSettings}
        onGroupToggle={onGroupToggle}
        groupKey={collapsedRow.groupKey}
        value={value}
        canCollapse={false}
        isWithinCollapsedGroup={startedRenderingLabels}
        onContext={onGroupContext}
        shouldShowValue={shouldShowValue}
      />
    )
  })

  return (
    <div
      className="absolute inline-flex"
      style={{
        margin: 0,
        padding: 0,
        height: 'auto',
        width: `${width}px`,
        top: `${Number(constants.headerHeight) + rowNumber * getRowHeightVariable(rowHeight)}px`
      }}
    >
      <RowNumberCell value={''} rowHeight={rowHeight} isRootGroup={isFirstColumnGroupLabel} useGreyBackground={true} />
      {groupedColumnElements}

      {nonGroupedColumns.map((column: IViewPortColumn) => {
        return (
          <Aggregate
            key={column.column.publicId}
            column={column}
            cumulativeWidth={column.left}
            width={column.column.width}
            frozen={column.isFrozen}
            isLastFrozenColumn={column.isLastFrozenColumn}
            borderTop={`1px solid ${constants.darkerGrey}`}
            backgroundColor={'white'}
            height={getRowHeightVariable(rowHeight)}
            rows={rows}
            streaming={streaming}
          />
        )
      })}
    </div>
  )
}

export default CollapsedRow
