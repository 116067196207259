import React from 'react'
import { IFile } from 'types'

import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'

import { IResponseFilePreviousVersion } from 'types'

interface Props {
  file: IResponseFilePreviousVersion | IFile
}

const SignedFileDownloadLink: React.FC<Props> = ({ file }: Props) => {
  const [url, setUrl] = React.useState(file.url)

  React.useEffect(() => {
    if (isMortaUploadedUrl(file.url)) {
      signUrl()
    } else {
      // setReady(true)
    }
  }, [file])

  const signUrl = async () => {
    const signedFileTokenResponse = await api.signUrl(file.url)
    setUrl(signedFileTokenResponse.data.url)
    // setReady(true)
  }

  return (
    <a target="_blank" rel="noopener noreferrer" href={url} download>
      {file.filename}
    </a>
  )
}

export default SignedFileDownloadLink
