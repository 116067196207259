import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { KEY_PRESS } from 'components/spreadsheet/constants/const'
import { Cross } from 'components/icons'

export interface ModalProps {
  id: string
  open: boolean
  setOpen: (open: boolean) => void
  title?: React.ReactNode
  children?: React.ReactNode
  onClose?: () => void
  warningBanner?: boolean
  warningBannerText?: string
  removePadding?: boolean
  zIndex?: number
}

const Modal: React.FC<ModalProps> = ({
  id,
  open,
  setOpen,
  children,
  onClose,
  title,
  warningBanner,
  warningBannerText,
  removePadding,
  zIndex
}) => {
  const appRootElement = document.getElementById('root')
  const modalRef = useRef<HTMLDivElement>(null)

  const handleModalClose = () => {
    if (open) {
      if (onClose) onClose()
      setOpen(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KEY_PRESS.esc) {
      handleModalClose()
    }
  }

  const handleClick = (event: MouseEvent) => {
    if (modalRef.current) {
      const modalContent = modalRef.current
      if (event.target == modalContent) handleModalClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleClick)
      handleModalClose()
    }
  }, [])

  if (appRootElement && open) {
    return ReactDOM.createPortal(
      <div
        id={id}
        className="fixed inset-0 overflow-auto bg-grey-40 select-none"
        style={{ zIndex: zIndex === undefined ? 1000 : zIndex }}
        onContextMenu={(event) => event.stopPropagation()}
      >
        <div
          ref={modalRef}
          className="flex justify-center h-full w-full overflow-x-hidden overflow-y-auto modal-padding"
        >
          <div
            className="modal-width relative bg-white flex flex-column rounded shadow overflow-hidden p-0"
            style={{
              maxHeight: 'calc(100vh - 80px)',
              margin: 'auto 0px',
              height: 'auto'
            }}
          >
            <div className="flex flex-1 flex-column overflow-hidden">
              {title && (
                <div
                  className="text-lg font-bold border-solid border-b-2px border-grey truncate"
                  style={{ padding: '16px 40px', height: '66px' }}
                >
                  {title}
                </div>
              )}
              {warningBanner && (
                <div
                  className="w-full flex items-center font-semibold"
                  style={{ height: '50px', backgroundColor: '#fff8c5', padding: '30px 40px' }}
                >
                  <span className="text-lg" style={{ marginRight: '10px', color: '#9a6800' }}>
                    ⚠
                  </span>
                  {warningBannerText ? warningBannerText : "Unexpected bad things will happen if you don't read this!"}
                </div>
              )}
              <div
                className="flex flex-column overflow-auto"
                style={{ padding: removePadding ? '0px 0px' : '30px 40px' }}
              >
                {children}
              </div>
            </div>
            <div
              className="absolute rounded font-bold cursor-pointer hover-bg-light-grey"
              style={{ top: 0, right: 0, margin: '20px 40px', zIndex: 1 }}
              onClick={() => handleModalClose()}
            >
              <Cross style={{ height: '20px', width: '20px' }} />
            </div>
          </div>
        </div>
      </div>,
      appRootElement
    )
  } else {
    return <div></div>
  }
}

export default Modal
