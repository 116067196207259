import React from 'react'
import ProcessComponent from 'components/process'
import ErrorBoundary from 'components/error'
import { useParams } from 'react-router'

const Process: React.FC = () => {
  const params: { projectId?: string; processId?: string } = useParams()

  return (
    <ErrorBoundary>
      {params && params.processId && <ProcessComponent processPublicId={params.processId} />}
    </ErrorBoundary>
  )
}

export default Process
