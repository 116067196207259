import React, { useState } from 'react'
import { numberWithCommas } from 'components/spreadsheet/helpers/format'

const RoiCalculator: React.FC = () => {
  document.title = 'Morta | ROI Calculator'

  const [hoursSpentNow, setHoursSpentNow] = useState<number>(5)
  const [hoursSpentAfter, setHoursSpentAfter] = useState<number>(1)
  const [annualSalary, setAnnualSalary] = useState<number>(40000)
  const [licensesNeeded, setLicensesNeeded] = useState<number>(1)
  const [upfrontHours, setUpfrontHours] = useState<number>(1)

  const hoursPerWeekSaved = hoursSpentNow - hoursSpentAfter
  const hoursPerYearSaved = hoursPerWeekSaved * 52
  const averageHourlyRate = annualSalary / (52 * 40)
  const valueOfTimeSavedPerWeek = hoursPerWeekSaved * averageHourlyRate
  const valueOfTimeSavedPerYear = valueOfTimeSavedPerWeek * 52
  const costOfMortaPerYear = licensesNeeded * 1200
  const costOfUpfrontTimeInvestment = upfrontHours * averageHourlyRate
  const roi =
    ((valueOfTimeSavedPerYear - costOfUpfrontTimeInvestment - costOfMortaPerYear) /
      (costOfUpfrontTimeInvestment + costOfMortaPerYear)) *
    100

  return (
    <div className="flex justify-center h-full w-full" style={{ padding: '20px', gap: '20px' }}>
      <div className="flex flex-column h-full" style={{ flex: 1, gap: '10px' }}>
        <div className="flex flex-column bg-light-grey rounded" style={{ flex: 1, padding: '10px', gap: '15px' }}>
          <div className="text-xl font-bold mb-4">Step 1: Time Saved</div>
          <div className="flex items-center bg-light-green" style={{ padding: '5px' }}>
            <div>How many hours per week does the task/use case currently take?</div>
            <input
              className="ml-auto"
              type="number"
              min={1}
              value={hoursSpentNow}
              onChange={(e) => setHoursSpentNow(Number(e.target.value))}
              style={{ width: '150px' }}
            />
          </div>
          <div className="flex items-center bg-light-green" style={{ padding: '5px' }}>
            <div>How many hours per week will the task take with Morta?</div>
            <input
              className="ml-auto"
              min={1}
              type="number"
              value={hoursSpentAfter}
              onChange={(e) => setHoursSpentAfter(Number(e.target.value))}
              style={{ width: '150px' }}
            />
          </div>
          <div className="italic" style={{ padding: '5px' }}>
            Hours saved: <b>{numberWithCommas(Math.round(hoursPerWeekSaved * 100) / 100)} per week</b> /{' '}
            <b>{numberWithCommas(Math.round(hoursPerYearSaved * 100) / 100)} per year</b>
          </div>
        </div>

        <div className="flex flex-column bg-light-grey rounded" style={{ flex: 1, padding: '10px', gap: '15px' }}>
          <div className="text-xl font-bold mb-4">Step 2: Value of Time Saved</div>
          <div className="flex items-center bg-light-green" style={{ padding: '5px' }}>
            <div>What is the average annual salary (in GBP) of the employees performing the task?</div>
            <input
              className="ml-auto"
              type="number"
              min={1}
              value={annualSalary}
              onChange={(e) => setAnnualSalary(Number(e.target.value))}
              style={{ width: '150px' }}
            />
          </div>
          <div className="italic" style={{ padding: '5px' }}>
            Average employee hourly rate: <b>£{numberWithCommas(Math.round(averageHourlyRate * 100) / 100)} per hour</b>
          </div>
          <div className="italic" style={{ padding: '5px' }}>
            Value of time saved per year by using Morta: <b>£{numberWithCommas(Math.round(valueOfTimeSavedPerYear))}</b>
          </div>
        </div>

        {/* Cost of Morta */}
        <div className="flex flex-column bg-light-grey rounded" style={{ flex: 1, padding: '10px', gap: '15px' }}>
          <div className="text-xl font-bold mb-4">Step 3: Cost Of Morta</div>
          <div className="flex items-center bg-light-green" style={{ padding: '5px' }}>
            <div>How many Morta advanced licenses are needed?</div>
            <input
              className="ml-auto"
              type="number"
              min={1}
              value={licensesNeeded}
              onChange={(e) => setLicensesNeeded(Number(e.target.value))}
              style={{ width: '150px' }}
            />
          </div>
          <div className="italic" style={{ padding: '5px' }}>
            Morta cost per year: <b>£{numberWithCommas(Math.round(costOfMortaPerYear * 100) / 100)}</b>
          </div>
          <div className="flex items-center bg-light-green" style={{ padding: '5px' }}>
            <div>How many hours are needed by the business to set up the task in Morta?</div>
            <input
              className="ml-auto"
              type="number"
              min={1}
              value={upfrontHours}
              onChange={(e) => setUpfrontHours(Number(e.target.value))}
              style={{ width: '150px' }}
            />
          </div>
          <div className="italic" style={{ padding: '10px' }}>
            Cost of up front time investment:{' '}
            <b>£{numberWithCommas(Math.round(costOfUpfrontTimeInvestment * 100) / 100)}</b>
          </div>
        </div>
      </div>

      <div
        className="flex flex-column bg-light-blue round rounded"
        style={{ height: 'fit-content', flex: 1, padding: '10px', gap: '10px' }}
      >
        <div className="text-xl font-bold mb-4">ROI Calculation</div>
        <div className="italic" style={{ padding: '5px' }}>
          Total Benefit (time saved per year): <b>£{numberWithCommas(Math.round(valueOfTimeSavedPerYear))}</b>
        </div>
        <div className="italic" style={{ padding: '5px' }}>
          Total Cost (Morta license + upfront time):{' '}
          <b>£{numberWithCommas(Math.round(costOfUpfrontTimeInvestment + costOfMortaPerYear))}</b>
        </div>
        <div className="italic" style={{ padding: '5px' }}>
          ROI = ((Total Benefit - Total Cost) / Total Cost)) * 100
        </div>
        <div
          className="font-bold text-xl heavy-shadow rounded bg-light-orange"
          style={{ padding: '5px', marginTop: '20px', marginBottom: '20px' }}
        >
          ROI = {roi.toFixed(2)}%
        </div>
      </div>
    </div>
  )
}

export default RoiCalculator
