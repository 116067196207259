import React, { useEffect, useState } from 'react'
import Select from 'components/select'
import { IPinnedProjectObject, ISimpleProject } from 'types'
import api from 'helpers/api'
import { Contributors, Views } from 'components/icons'
import { numberWithCommas } from 'components/spreadsheet/helpers/format'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
import useApplicationStore from 'hooks/application'

interface PinnedProps {
  userId?: string
  ownProfile: boolean
}

const Pinned: React.FC<PinnedProps> = ({ userId, ownProfile }) => {
  const { displayErrorMessage } = useApplicationStore()

  const [pinnedProjects, setPinnedProjects] = useState<IPinnedProjectObject[]>()
  const [availableProjects, setAvailableProjects] = useState<ISimpleProject[]>([])

  useEffect(() => {
    if (ownProfile) {
      api
        .getPublicProjects()
        .then((response) => {
          setAvailableProjects(response.data)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }, [ownProfile])

  useEffect(() => {
    if (userId) {
      api
        .getPinnedProjects(userId)
        .then((response) => {
          setPinnedProjects(response.data)
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    } else {
      setPinnedProjects(undefined)
    }
  }, [userId])

  const onPinProject = (projectId: string) => {
    if (userId) {
      api({
        method: 'PUT',
        endpoint: '/user/projects/' + projectId + '/pin'
      })
        .then((response) => {
          if (response && response.data) {
            setPinnedProjects(response.data)
          }
        })
        .catch((error) => {
          displayErrorMessage(error)
        })
    }
  }

  return (
    <div>
      <div className="text-base" style={{ paddingBottom: '10px' }}>
        Pinned Public {capitaliseFirstLetter(PROJECT)}s
      </div>

      {ownProfile && (
        <Select
          options={availableProjects.map((project) => {
            return { value: project.publicId, label: project.name }
          })}
          optionsSelected={userId && pinnedProjects ? pinnedProjects.map((project) => project.publicId) : []}
          onOptionClick={(option) => onPinProject(option)}
          multiselect={true}
        />
      )}
      {userId && pinnedProjects ? (
        pinnedProjects.length !== 0 ? (
          <div className="grid grid-cols-2 sm-grid-cols-1 mt-10px" style={{ gap: '10px' }}>
            {pinnedProjects.map((project, index) => {
              return (
                <a
                  key={index}
                  href={`/project/${project.publicId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-column border-1px border-solid border-black box-border hover-heavy-shadow rounded transition-all"
                  style={{ padding: '15px' }}
                >
                  <div className="flex items-center">
                    <div
                      className="text-primary flex items-center justify-center rounded text-xl text-white"
                      style={{
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: project.primaryColour
                      }}
                    >
                      {project.name.substring(0, 2)}
                    </div>
                    <div className="text-primary font-normal px-10px truncate">{project.name}</div>
                  </div>
                  <div className="flex items-center mt-20px">
                    <div className="flex items-center text-sm text-primary">
                      <Views style={{ height: '20px', width: '20px' }} />
                      <div style={{ marginLeft: '5px' }}>{`${numberWithCommas(project.views)} view${
                        project.views === 1 ? '' : 's'
                      }`}</div>
                    </div>
                    <div className="flex items-center text-sm text-primary" style={{ marginLeft: '20px' }}>
                      <Contributors style={{ height: '20px', width: '20px' }} />
                      <div style={{ marginLeft: '5px' }}>{`${numberWithCommas(project.contributors)} contributor${
                        project.contributors === 1 ? '' : 's'
                      }`}</div>
                    </div>
                  </div>
                </a>
              )
            })}
          </div>
        ) : (
          <div className="mt-10px italic">No pinned {PROJECT}s</div>
        )
      ) : (
        <span className="skeleton-box rounded" style={{ marginTop: '15px', width: '208px', height: '130px' }} />
      )}
    </div>
  )
}

export default Pinned
