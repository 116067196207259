import React from 'react'
import { Cross } from 'components/icons'
import { ITableColumn } from 'types'
import Select from 'components/select'
import { IFilterType, IUIFilter } from 'components/spreadsheet/types'
import { isValidFilterTypeForColType } from 'components/spreadsheet/helpers/filtering'
import { FilterTypesToDescription } from 'components/spreadsheet/constants/const'
import FilterInput from './FilterInput'
import IconSelector from 'components/spreadsheet/components/cell/components/icon'

interface Props {
  uiFilter: IUIFilter
  position: number
  columns: ITableColumn[]
  onChange: (value: IUIFilter) => void
  onDelete: (value: IUIFilter) => void
  onChangeGrouping: (uiFilter: IUIFilter, grouping: string) => void
  optionsOpen?: boolean
  focus?: boolean
}

const FILTER_GROUPING_OPTIONS = [
  { label: 'Or', value: 'or' },
  { label: 'And', value: 'and' }
]

const FilterItem: React.FC<Props> = ({
  uiFilter,
  position,
  columns,
  onChange,
  onDelete,
  onChangeGrouping,
  optionsOpen,
  focus
}) => {
  const column = columns.find((c) => c.publicId === uiFilter.filter.columnId)!
  const columnOptions = columns.map((column) => ({
    icon: <IconSelector kind={column.kind} />,
    label: column.name,
    value: column.publicId
  }))

  const handleColumnChange = (columnId: string) => {
    const newColumn = columns.find((c) => c.publicId === columnId)!
    const newFilter = {
      ...uiFilter,
      filter: {
        ...uiFilter.filter,
        columnId: columnId,
        columnName: newColumn.name,
        multipleValues: []
      }
    }

    // if the newly choosen column kind is not supported by the existing operator
    // then change it
    if (!isValidFilterTypeForColType(newColumn.kind, newFilter.filter.filterType)) {
      newFilter.filter.filterType = IFilterType.eq
    }

    onChange(newFilter)
  }

  // when the filter type is changed
  const handleFilterTypeChange = (filterType: IFilterType) => {
    const newFilter = {
      ...uiFilter,
      filter: {
        ...uiFilter.filter,
        filterType: filterType
      }
    }
    onChange(newFilter)
  }

  // when value is changed
  const handleChangeValue = (value: string) => {
    const newFilter = {
      ...uiFilter,
      filter: {
        ...uiFilter.filter,
        value: value
      }
    }
    onChange(newFilter)
  }

  // when multiple values are changed
  const handleChangeMulti = (values: string[]) => {
    const newFilter = {
      ...uiFilter,
      filter: {
        ...uiFilter.filter,
        multipleValues: values
      }
    }
    onChange(newFilter)
  }

  const handleGroupingChange = (value: string) => {
    onChangeGrouping(uiFilter, value)
  }

  const handleDelete = (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.preventDefault()
    onDelete(uiFilter)
  }

  // Types option list
  const typesOptions = Object.values(IFilterType)
    .reduce((acc, type) => {
      acc.push({
        value: type,
        label: FilterTypesToDescription[type]
      })
      return acc
    }, [] as { label: string; value: IFilterType }[])
    .filter((option) => {
      return column && column.kind && isValidFilterTypeForColType(column.kind, option.value)
    })
  return (
    <div className="flex items-center" style={{ padding: '10px 10px 0 20px' }}>
      <div
        className="flex items-center cursor-pointer text-secondary"
        style={{ marginRight: '10px' }}
        onClick={(event) => handleDelete(event)}
      >
        <Cross />
      </div>

      <div style={{ minWidth: '100px', marginRight: '5px' }}>
        {position === 0 ? (
          <Select
            options={[{ value: 'Where', label: 'Where' }]}
            optionsSelected={['Where']}
            onOptionClick={() => {
              return null
            }}
            disabled={true}
          />
        ) : null}
        {position >= 1 ? (
          <Select
            options={FILTER_GROUPING_OPTIONS}
            optionsSelected={uiFilter.filter.orGroup === 'main' || uiFilter.filter.orGroup === null ? ['and'] : ['or']}
            onOptionClick={(option) => handleGroupingChange(option)}
          />
        ) : null}
      </div>
      <div style={{ minWidth: '200px', marginRight: '5px' }}>
        <Select
          options={columnOptions}
          optionsSelected={uiFilter.filter.columnId ? [uiFilter.filter.columnId] : []}
          onOptionClick={(option) => handleColumnChange(option)}
        />
      </div>
      <div style={{ minWidth: '200px', marginRight: '5px' }}>
        <Select
          options={typesOptions}
          optionsSelected={uiFilter.filter.filterType ? [uiFilter.filter.filterType] : []}
          onOptionClick={(option) => handleFilterTypeChange(option as IFilterType)}
        />
      </div>
      <div style={{ width: '300px' }}>
        <FilterInput
          columns={columns}
          filterType={uiFilter.filter.filterType}
          columnId={uiFilter.filter.columnId}
          value={uiFilter.filter.value}
          multipleValues={
            uiFilter.filter.multipleValues
              ? uiFilter.filter.multipleValues.map((value) =>
                  value !== undefined && value !== null ? value?.toString() : ''
                )
              : []
          }
          onChange={handleChangeValue}
          onChangeMultipleValues={handleChangeMulti}
          optionsOpen={optionsOpen}
          focus={focus}
        />
      </div>
    </div>
  )
}

export default FilterItem
