import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { validateSingleLinkField } from 'components/spreadsheet/helpers/functions'
import { useApplicationStore } from 'hooks/application'
import Button from 'components/button'
import { Delete } from 'components/icons'

interface MultilinkCellModalProps extends ModalProps {
  links: string[]
  isAdmin: boolean
  isContributor: boolean
  setCellValue: (value: string[], onSuccess: () => void, onError: () => void) => void
}

const MultilinkCellModal: React.FC<MultilinkCellModalProps> = ({
  id,
  open,
  setOpen,
  links,
  setCellValue,
  isAdmin,
  isContributor
}) => {
  const { setSnackbarMessage } = useApplicationStore()
  const [processing, setProcessing] = useState<boolean>(false)
  const [newLink, setNewLink] = useState<string>('')

  const handleAddLink = () => {
    if (processing || !newLink) return
    const result = validateSingleLinkField(newLink)
    setProcessing(true)
    if (result.pass) {
      setCellValue(
        [...links, newLink],
        () => setProcessing(false),
        () => setProcessing(false)
      )
    } else {
      setSnackbarMessage({ status: 'error', message: result.error })
      setProcessing(false)
    }
    setNewLink('')
  }

  const handleRemoveLink = (index: number) => {
    setProcessing(true)
    const newLinks = [...links]
    newLinks.splice(index, 1)
    setCellValue(
      newLinks,
      () => setProcessing(false),
      () => setProcessing(false)
    )
    setNewLink('')
  }

  const canAddLinks = isAdmin || isContributor

  return (
    <Modal id={id} open={open} setOpen={setOpen} title={'Edit Multilink'}>
      {canAddLinks && (
        <div className="inline-flex" style={{ marginLeft: '5px', marginBottom: '10px' }}>
          <input
            placeholder="New link"
            value={newLink}
            disabled={processing}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewLink(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
          />
          <Button
            internalType="accept"
            style={{ width: 100, marginLeft: '10px' }}
            onClick={() => handleAddLink()}
            disabled={!newLink}
          >
            {processing ? <div className="spin" style={{ height: '20px', width: '20px' }} /> : 'Add'}
          </Button>
        </div>
      )}

      <ul className="w-full overflow-x-hidden" style={{ listStyle: 'none', height: '350px', margin: '0 0 0 10px' }}>
        {links.map((link, index) => (
          <li
            key={`${index}-${link}`}
            className="w-full inline-flex"
            style={{ marginTop: '10px', paddingRight: '5px' }}
          >
            <a
              className="relative flex items-center truncate"
              style={{ width: 'calc(100% - 30px)', marginRight: '5px' }}
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link}
            </a>
            {canAddLinks && (
              <span
                className="relative"
                style={{
                  cursor: processing ? 'auto' : 'pointer',
                  color: processing ? 'disabled' : 'inherit',
                  top: '2px',
                  marginRight: '5px'
                }}
                onClick={() => {
                  if (!processing) handleRemoveLink(index)
                }}
              >
                <Delete style={{ height: '24px', width: '24px' }} />
              </span>
            )}
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default MultilinkCellModal
