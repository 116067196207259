import React from 'react'
import Menu, { MenuProps } from 'components/menu'
import { IAggregateTypes } from 'components/spreadsheet/types'
import { ITableViewColumn } from 'types'
import { isValidAggregateTypeForColType } from 'components/spreadsheet/helpers/aggregate'
import { updateColumn } from 'components/spreadsheet/contexts/data/actions'
import { useProject } from 'hooks/project'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

const AggregateMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { project } = useProject()
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const column = spreadsheetData.viewDetails.columns.find(
    (column: ITableViewColumn) => column.publicId === menuState.columnId
  )

  const updateAggregateOption = (column: ITableViewColumn, type: number) => {
    if (menuState.columnId !== undefined) {
      setSpreadsheetData({
        type: 'CHANGE_COLUMN_AGGREGATE',
        columnId: menuState.columnId,
        aggregate: type
      })
      setMenuState(INITIAL_CONTEXT_MENU_STATE)

      if (spreadsheetData.isAdmin) {
        updateColumn(
          project.publicId,
          'aggregate',
          spreadsheetData,
          setSpreadsheetData,
          column.publicId,
          type,
          () => {
            return
          },
          () => {
            return
          }
        )
      }
    }
  }

  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}>
        {column && (
          <li>
            {Object.keys(IAggregateTypes)
              .filter((type: string) => {
                return !isNaN(parseInt(type))
              })
              .map((type, typeNumber) => {
                const numberType = parseInt(type)
                if (numberType !== undefined && isValidAggregateTypeForColType(column.kind, numberType))
                  return (
                    <div
                      key={typeNumber}
                      className="cursor-pointer rounded hover-bg-light-grey transition-all"
                      style={{ padding: '10px' }}
                      onClick={() => updateAggregateOption(column, typeNumber)}
                    >
                      {IAggregateTypes[parseInt(type)]}
                    </div>
                  )
              })}
          </li>
        )}
      </div>
    </Menu>
  )
}

export default React.memo(AggregateMenu)
