import React, { useEffect, ReactElement, VideoHTMLAttributes } from 'react'
import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'
import { getAuthToken } from 'helpers/auth'

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  blockProps: { src: string }
}

export default React.forwardRef<HTMLVideoElement, VideoProps>(function EmbeddedVideo(props, ref): ReactElement {
  const { blockProps, ...otherProps } = props
  const { ...elementProps } = otherProps
  const [mortaSrc, setMortaSrc] = React.useState<string>()
  const [ready, setReady] = React.useState(false)

  const signUrl = async () => {
    const token = getAuthToken()
    if (ready || !token) return

    const signedFileTokenResponse = await api.signUrl(blockProps.src)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', signedFileTokenResponse.data.url)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('Authorization', token)
    xhr.send()

    xhr.onload = function () {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          const file = window.URL.createObjectURL(this.response)
          setMortaSrc(file)
          setReady(true)
        } else {
          console.error('Something went wrong')
          setReady(true)
        }
      }
    }
  }

  useEffect(() => {
    if (blockProps.src && isMortaUploadedUrl(blockProps.src)) {
      signUrl()
    } else {
      setReady(true)
    }
  }, [])

  if (!ready || !mortaSrc) {
    return <span>Loading video...</span>
  }

  return <video ref={ref} {...elementProps} controls src={mortaSrc} />
})
