import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Modal from 'components/modal'
import api from 'helpers/api'
import { IUsed } from 'types'
import { Link } from 'react-router-dom'
import useApplicationStore from 'hooks/application'

interface RelatedProps {
  open: boolean
  setOpen: (userMenuOpen: boolean) => void
  publicId: string
  type: 'process' | 'table'
}

const Related: React.FC<RelatedProps> = ({ open, setOpen, publicId, type }) => {
  const { displayErrorMessage } = useApplicationStore()
  const [related, setRelated] = useState<IUsed[]>()

  useEffect(() => {
    if (open) {
      if (type === 'table') {
        api
          .getWhereTableUsed(publicId)
          .then((response) => {
            setRelated(response.data)
          })
          .catch((error) => {
            displayErrorMessage(error)
            setRelated([])
          })
      }
    }
  }, [open])

  const appRootElement = document.getElementById('root')

  const usedProcesses = related && related.filter((relation) => relation.type === 'process')
  const usedJoins =
    related && related.filter((relation) => relation.type === 'sourceJoin' || relation.type === 'targetJoin')
  const usedSelects =
    related && related.filter((relation) => relation.type === 'sourceSelect' || relation.type === 'targetSelect')

  if (appRootElement && open) {
    return ReactDOM.createPortal(
      <Modal id="search-modal" open={open} setOpen={setOpen} title="Related Resources">
        <div className="text-lg" style={{ flexGrow: 0, flexShrink: 0 }}>
          <div className="flex flex-column" style={{ padding: '10px 0px' }}>
            <p style={{ marginBottom: '30px' }}>These are all the Morta resources that are linked to this {type}.</p>
            {/* Used Processes */}
            <div className="shadow flex flex-column" style={{ padding: '30px 10px' }}>
              <div className="font-bold">
                {usedProcesses && usedProcesses.length > 0
                  ? `${usedProcesses.length} Related Documents`
                  : usedProcesses !== undefined
                  ? `No Related Documents`
                  : 'Related Documents'}
              </div>

              {!usedProcesses && (
                <div className="skeleton-box" style={{ width: '100%', height: '20px', marginTop: '15px' }} />
              )}
              {usedProcesses &&
                usedProcesses.map((used, usedNumber) => {
                  return (
                    <Link
                      key={usedNumber}
                      to={used.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginTop: '15px' }}
                    >
                      {used.name}
                    </Link>
                  )
                })}
            </div>

            {/* Used Joins */}

            <div className="shadow flex flex-column" style={{ padding: '30px 10px', marginTop: '30px' }}>
              <div className="font-bold">
                {usedJoins && usedJoins.length > 0
                  ? `${usedJoins.length} Related Look Ups`
                  : usedJoins !== undefined
                  ? `No Related Look Ups`
                  : 'Related Look Ups'}
              </div>
              {!usedJoins && (
                <div className="skeleton-box" style={{ width: '100%', height: '20px', marginTop: '15px' }} />
              )}
              {usedJoins &&
                usedJoins.map((used, usedNumber) => {
                  return (
                    <Link
                      key={usedNumber}
                      to={used.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginTop: '15px' }}
                    >
                      {used.name}
                    </Link>
                  )
                })}
            </div>
            {/* Used Selects */}
            <div className="shadow flex flex-column" style={{ padding: '30px 10px', marginTop: '30px' }}>
              <div className="font-bold">
                {usedSelects && usedSelects.length > 0
                  ? `${usedSelects.length} Related Selects`
                  : usedSelects !== undefined
                  ? `No Related Selects`
                  : 'Related Selects'}
              </div>
              {!usedSelects && (
                <div className="skeleton-box" style={{ width: '100%', height: '20px', marginTop: '15px' }} />
              )}
              {usedSelects &&
                usedSelects.map((used, usedNumber) => {
                  return (
                    <Link
                      key={usedNumber}
                      to={used.link}
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginTop: '15px' }}
                    >
                      {used.name}
                    </Link>
                  )
                })}
            </div>
          </div>
        </div>
      </Modal>,
      appRootElement
    )
  } else {
    return <div></div>
  }
}

export default Related
