import React from 'react'
import api from 'helpers/api'
import { IFileParentResource } from 'types'
import { Picture, Attachment, Video } from 'components/icons'
import useApplicationStore from 'hooks/application'

interface Props {
  editorState: any
  onChange: (arg0: any) => void
  modifier: any
  mediaType: string
  resources?: IFileParentResource[]
}

const UploadMedia: React.FC<Props> = ({ editorState, onChange, modifier, mediaType, resources }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const { displayErrorMessage } = useApplicationStore()

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return false
    }

    const file = e.target.files[0]

    try {
      const response = await api.uploadFile(file, resources)

      if (mediaType === 'image') {
        onChange(modifier(editorState, response.data.url, { filename: response.data.filename }))
      } else if (mediaType === 'video') {
        onChange(modifier(editorState, { src: response.data.url }))
      } else {
        onChange(modifier(editorState, response.data.url, response.data.filename))
      }
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  return (
    <div className="flex">
      <div
        className="flex items-center hover-bg-light-grey transition-all rounded"
        style={{ padding: '7px', marginRight: '10px' }}
        title={`Add ${mediaType === 'image' ? 'Image' : mediaType === 'video' ? 'Video' : 'File'}`}
        onClick={(event) => {
          event.stopPropagation()
          inputRef.current!.click()
        }}
      >
        <label htmlFor={'file-input-' + mediaType}>
          {mediaType === 'image' ? <Picture /> : mediaType === 'video' ? <Video /> : <Attachment />}
        </label>
        <input type="file" id="file" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      </div>
    </div>
  )
}

export default UploadMedia
