import React, { useEffect, useState } from 'react'
import Menu, { MenuProps } from 'components/menu'
import { useApplicationStore } from 'hooks/application'
import Select from 'components/select'
import { useDataContext } from 'components/spreadsheet/contexts/data'
import { BLOCK_GROUP_BY, NUMBER_COLUMN_TYPES, SELECT_COLUMN_TYPES } from 'components/spreadsheet/constants/const'
import { ITableViewChart, IChartType } from 'types'
import Checkbox from 'components/checkbox'
const ChartMenu: React.FC<MenuProps> = ({ id, menuState, setMenuState, width }) => {
  const { spreadsheetData, setSpreadsheetData } = useDataContext()
  const [chart, setChart] = useState<ITableViewChart>(
    spreadsheetData.userConfiguration.chartSettings === null
      ? spreadsheetData.viewDetails.chartSettings === null
        ? { chartType: null, showLegend: true, showValues: false, columnLabelId: '', columnValueId: '' }
        : spreadsheetData.viewDetails.chartSettings
      : spreadsheetData.userConfiguration.chartSettings
  )

  const { setSnackbarMessage } = useApplicationStore()
  const chartTypes = [
    { label: 'Pie', value: 'pie' },
    { label: 'Bar', value: 'bar' }
  ]

  useEffect(() => {
    if (
      chart &&
      (chart.chartType !== spreadsheetData.userConfiguration.chartSettings?.chartType ||
        chart.columnLabelId !== spreadsheetData.userConfiguration.chartSettings?.columnLabelId ||
        chart.columnValueId !== spreadsheetData.userConfiguration.chartSettings?.columnValueId ||
        chart.showLegend !== spreadsheetData.userConfiguration.chartSettings?.showLegend ||
        chart.showValues !== spreadsheetData.userConfiguration.chartSettings?.showValues)
    ) {
      setSpreadsheetData({
        type: 'ADD_CHART',
        chart: chart
      })
    }
  }, [chart])

  const handleSelectChartType = (chartType: IChartType) => {
    if (chart) {
      setChart({ ...chart, chartType: chartType })
    }
  }
  const handleAddLabelColumn = (columnId: string) => {
    if (chart) {
      const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
      if (column) {
        const columnKind = column.kind
        if (BLOCK_GROUP_BY.includes(columnKind)) {
          setSnackbarMessage({ status: 'error', message: `Charts for columns of type ${columnKind} are not allowed` })
          return
        }
      }
      setChart({ ...chart, columnLabelId: columnId })
    }
  }

  const handleAddValueColumn = (columnId: string) => {
    if (chart) {
      const column = spreadsheetData.viewDetails.columns.find((column) => column.publicId === columnId)
      if (column) {
        const columnKind = column.kind
        if (BLOCK_GROUP_BY.includes(columnKind)) {
          setSnackbarMessage({ status: 'error', message: `Charts for columns of type ${columnKind} are not allowed` })
          return
        }
      }
      setChart({ ...chart, columnValueId: columnId })
    }
  }

  const handleShowLegend = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (chart) {
      setChart({ ...chart, showLegend: event.target.checked })
    }
  }

  const handleShowValues = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (chart) {
      setChart({ ...chart, showValues: event.target.checked })
    }
  }

  const getAggregationType = () => {
    const columnValue = spreadsheetData.viewDetails.columns.find((column) => column.publicId === chart.columnValueId)
    const columnLabel = spreadsheetData.viewDetails.columns.find((column) => column.publicId === chart.columnLabelId)
    if (!columnValue || !columnLabel) return null

    if (SELECT_COLUMN_TYPES.includes(columnLabel.kind)) return 'Count'
    else if (NUMBER_COLUMN_TYPES.includes(columnValue.kind)) return 'Sum'
    else return 'Count'
  }
  return (
    <Menu id={id} menuState={menuState} setMenuState={setMenuState} width={width}>
      <div style={{ listStyle: 'none', padding: '0px', margin: '10px' }}></div>
      <div className="flex items-center rounded" style={{ padding: '10px', height: '50px', marginTop: '20px' }}>
        <span style={{ minWidth: '150px', marginRight: '10px' }}>Chart types:</span>
        <div className="w-full">
          <Select
            options={chartTypes}
            onOptionClick={(option) => handleSelectChartType(option as IChartType)}
            optionsSelected={!chart || chart.chartType === null ? [] : [chart.chartType]}
          />
        </div>
      </div>
      <div className="flex items-center rounded" style={{ padding: '10px', height: '80px', marginTop: '30px' }}>
        <span style={{ minWidth: '150px', marginRight: '10px' }}>Label Column:</span>
        <div className="w-full">
          <Select
            options={spreadsheetData.viewDetails.columns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => handleAddLabelColumn(option)}
            info="Only columns that are not values can be selected."
            disabled={!chart || chart.chartType === null}
            optionsSelected={!chart || chart.columnLabelId === '' ? [] : [chart.columnLabelId]}
          />
        </div>
      </div>
      <div className="flex items-center rounded" style={{ padding: '10px', height: '80px', marginTop: '30px' }}>
        <span style={{ minWidth: '150px', marginRight: '10px' }}>Value Column:</span>
        <div className="w-full">
          <Select
            options={spreadsheetData.viewDetails.columns.map((column) => {
              return { value: column.publicId, label: column.name }
            })}
            onOptionClick={(option) => handleAddValueColumn(option)}
            info="Only columns that are not label can be selected."
            disabled={!chart || chart.chartType === null}
            optionsSelected={!chart || chart.columnValueId === '' ? [] : [chart.columnValueId]}
          />
        </div>
      </div>
      <div className="flex items-center rounded" style={{ padding: '10px', height: '40px', marginTop: '15px' }}>
        <span style={{ minWidth: '150px', marginRight: '10px' }}>Aggregation:</span>
        <div className="w-full">{getAggregationType()}</div>
      </div>
      <div className="flex items-center rounded" style={{ padding: '10px', height: '40px', marginTop: '15px' }}>
        <span style={{ minWidth: '150px', marginRight: '10px' }}>Show legend:</span>
        <div className="w-full">
          <Checkbox defaultChecked={chart && chart.showLegend} onChange={handleShowLegend}></Checkbox>
        </div>
      </div>
      <div className="flex items-center rounded" style={{ padding: '10px', height: '80px', marginTop: '15px' }}>
        <span style={{ minWidth: '150px', marginRight: '10px' }}>Show values:</span>
        <div className="w-full" style={{ display: 'inherit !important' }}>
          <Checkbox defaultChecked={chart && chart.showValues} onChange={handleShowValues}></Checkbox>
        </div>
      </div>
    </Menu>
  )
}

export default React.memo(ChartMenu)
