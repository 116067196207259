import React from 'react'
import Button from 'components/button'
import { IMemberInvite, ITableWithCells } from 'types'
import { capitaliseFirstLetter } from 'helpers/utils'

export const mapInvitedMembersToTableData = (
  invites: IMemberInvite[],
  tagTablesWithCells: ITableWithCells[],
  onResend: (invite: IMemberInvite) => void,
  onRemove: (invite: IMemberInvite) => void,
  onUpdate: (invite: IMemberInvite) => void
) => {
  return invites.map((invite: IMemberInvite) => {
    return {
      email: { label: invite.email, value: invite.email },
      invitedBy: {
        label: `${invite.invitedBy.name} (${invite.invitedBy.email})`,
        value: `${invite.invitedBy.name} (${invite.invitedBy.email})`
      },
      date: { label: invite.createdAt.split('T')[0], value: invite.createdAt },
      projectRole: { label: capitaliseFirstLetter(invite.projectRole), value: invite.projectRole },
      tags: { label: invite.tags.length > 0 ? renderTags(invite, tagTablesWithCells) : <i>No tags set</i>, value: '' },
      controls: {
        label: (
          <div className="flex">
            <Button internalType="secondary" onClick={() => onResend(invite)}>
              Resend
            </Button>
            <Button internalType="secondary" className="ml-10px" onClick={() => onUpdate(invite)}>
              Update
            </Button>
            <Button internalType="danger" className="ml-10px" onClick={() => onRemove(invite)}>
              Delete
            </Button>
          </div>
        ),
        value: ''
      }
    }
  })
}

const renderTags = (invite: IMemberInvite, tagTablesWithCells: ITableWithCells[]) => {
  const tableToTagValues: Record<string, string[]> = {}

  for (const tag of invite.tags) {
    for (const table of tagTablesWithCells) {
      for (const cell of table.cells) {
        if (cell.id === tag.referencePublicId) {
          if (tableToTagValues[table.publicId]) {
            tableToTagValues[table.publicId].push(cell.value)
          } else {
            tableToTagValues[table.publicId] = [cell.value]
          }
        }
      }
    }
  }

  return Object.keys(tableToTagValues).map((tableId) => {
    const table = tagTablesWithCells.find((t) => t.publicId == tableId)
    return (
      <div className="mb-4" key={tableId}>
        <div className="font-bold mb-2">{table?.name}</div>
        <div className="flex">
          {tableToTagValues[tableId].map((value) => (
            <div key={value} className="mr-10px p-2 bg-grey rounded">
              {value}
            </div>
          ))}
        </div>
      </div>
    )
  })
}
