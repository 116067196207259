import React from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import api from 'helpers/api'
import TwoFactorCodeForm, { IFormValues } from 'views/auth/components/TwoFactorCodeForm'
import { HOME } from 'routes'
import useApplicationStore from 'hooks/application'
import { redirectTo } from 'helpers/redirect'

const TwoFactorLogin: React.FC = () => {
  const { updateUser } = useAuth()
  const { push } = useHistory()
  const { displayErrorMessage } = useApplicationStore()
  const params = new URLSearchParams(window.location.search)
  const loginToken = params.get('loginToken')

  const handleSubmit = async (values: IFormValues) => {
    try {
      const response = await api.loginWith2Fa(values.code, loginToken!)
      updateUser(response.data)
      push(redirectTo(HOME))
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-12 text-2xl">Enter your 2FA code</h1>
        <p>Use the authenticator device associated with your account to generate your 2FA code</p>
        <br />
        <TwoFactorCodeForm onSubmit={handleSubmit} />
      </div>
    </div>
  )
}

export default TwoFactorLogin
