import React, { useEffect, useState, useRef } from 'react'
import { IColumnTypes } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import { ViewTypes } from 'components/spreadsheet/constants/const'
import { ICellValue, IContextMenuState, ITableViewColour, ITableViewColumn } from 'types'
import { GROUP_KEY_SPLIT } from 'components/spreadsheet/constants/const'
import { getCellColour } from 'components/spreadsheet/helpers/colouring'
import Button from 'components/button'
import { Triangle } from 'components/icons'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'
import SelectMenu from 'components/select/components/menu'
import { checkValuesSame } from 'components/spreadsheet/helpers/paste'
import { cancelTimeout, requestTimeout, TimeoutID } from 'helpers/timer'
interface SelectCellProps {
  value: string | string[]
  align: 'center' | 'left' | 'right'
  color: string
  kind: IColumnTypes
  readOnly: boolean
  isAdmin: boolean
  isContributor: boolean
  editing: boolean
  width: number
  rowHeight: number
  selected: boolean
  isExpanded?: boolean
  validation: boolean
  isJoined: boolean
  formValues?: Record<string, ICellValue>
  setEditing: (editing: boolean) => void
  handleKeyDown?: (event: React.KeyboardEvent) => void
  setCellValue: (value: string | string[], onSuccess: () => void) => void
  type: ViewTypes
  column: ITableViewColumn | null
  colourSettings: ITableViewColour[]
  rowData?: {
    [columnId: string]: ICellValue
  }
}

const SelectCellComponent: React.FC<SelectCellProps> = ({
  value,
  align,
  color,
  kind,
  readOnly,
  editing,
  width,
  rowHeight,
  selected,
  isExpanded,
  isAdmin,
  isContributor,
  isJoined,
  formValues,
  validation,
  setEditing,
  handleKeyDown,
  setCellValue,
  column,
  type,
  colourSettings,
  rowData
}) => {
  const inputRef: React.RefObject<HTMLDivElement> = useRef(null)
  const selectionTimeout = useRef<TimeoutID | null>(null)
  const [selectCellMenu, setSelectCellMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const kindOptions = column && column.kindOptions
  const [currentValue, setCurrentValue] = useState<string | string[]>(value ? value : '')

  useEffect(() => {
    const newValue = value ? value : ''
    if (!checkValuesSame(newValue, currentValue, kind)) {
      setCurrentValue(newValue)
    }
  }, [value])

  const getDependencyCachedOptions = () => {
    if (kindOptions?.tableOptions?.dependencies?.length) {
      const keys: string[] = []
      kindOptions.tableOptions.dependencies.map((dependency) => {
        let cellValue
        if (type === ViewTypes.FORM) {
          cellValue = formValues![dependency.columnId]
        } else {
          if (rowData) cellValue = rowData[dependency.columnId]
        }
        keys.push(`${cellValue}`)
      })
      const finalKey = keys.join(GROUP_KEY_SPLIT)

      const cachedOptionsDict = kindOptions?.tableOptions?.cachedOptions
        ? (kindOptions.tableOptions.cachedOptions as { [key: string]: string[] })
        : {}
      return cachedOptionsDict[finalKey] || []
    }
    return (kindOptions?.tableOptions?.cachedOptions as string[]) || []
  }

  const cachedOptions = getDependencyCachedOptions()

  const manualOptions = kindOptions?.manualOptions || []
  const allOptions = [...cachedOptions, ...manualOptions].map((option) => {
    return { value: option, label: option }
  })

  useEffect(() => {
    if (editing && !selected && !isExpanded) {
      setEditing(false)
    }
  }, [editing, selected])

  const handleSetCellValue = (value: string | string[]) => {
    if (setCellValue && (isAdmin || isContributor)) {
      setCurrentValue(value)
      if (selectionTimeout.current !== null) cancelTimeout(selectionTimeout.current)
      selectionTimeout.current = requestTimeout(
        () => {
          setCellValue(value, () => {
            return null
          })
        },
        kind === 'multiselect' ? 1000 : 250
      )
    }
  }

  const handleOptionSelected = (selectedOption: string) => {
    if (kind === 'select') {
      // if selected option is the same as the current value of the cell we need to clear the cell
      const newValue = selectedOption === currentValue ? '' : selectedOption
      handleSetCellValue(newValue)
    } else if (kind === 'multiselect') {
      const arrSelectedValues = currentValue ? [...(currentValue as string[])] : []

      // Remove any invalid values
      const cleanedSelectedValues = arrSelectedValues.filter(
        (selectedValue) => !!allOptions.find((option) => selectedValue === option.value)
      )

      // Check wheater the selected option is not part of the current array of values
      // if it is already there, we need to remove it.
      const newSelectedValues = cleanedSelectedValues.filter((val) => val !== selectedOption)

      // If the selected option was not part of the array of values we need to add it
      if (newSelectedValues.length === cleanedSelectedValues.length) {
        newSelectedValues.push(selectedOption)
      }
      handleSetCellValue(newSelectedValues)
    }
  }

  const isArray = Array.isArray(currentValue)
  const wrapTextAlignment = !isArray
    ? 'center'
    : (currentValue as string[]).length <= (rowHeight ? rowHeight * 3 : 1)
    ? 'center'
    : 'strech'

  return (
    <div
      className={`cell-value ${selected ? 'focus' : ''}`}
      ref={inputRef}
      tabIndex={-1}
      style={{
        textAlign: align,
        color: color,
        maxHeight: isExpanded ? '100%' : `${getRowHeightVariable(rowHeight) - 2}px`,
        overflowY: kind === 'multiselect' ? 'auto' : 'hidden'
      }}
      onKeyDown={(event: React.KeyboardEvent) => {
        event.persist()
        if (handleKeyDown) handleKeyDown(event)
      }}
      onClick={(event: React.MouseEvent) => {
        if (readOnly) {
          isJoined ? event.persist() : event.stopPropagation()
        } else {
          event.persist()
          if (isExpanded) {
            setSelectCellMenu({
              ...selectCellMenu,
              open: true,
              top: `${event.currentTarget.getBoundingClientRect().bottom}px`,
              left: `${event.currentTarget.getBoundingClientRect().left}px`,
              bottom: 'auto',
              right: 'auto'
            })
          }
        }
      }}
      onDoubleClick={(event: React.MouseEvent) => {
        if (readOnly) {
          event.stopPropagation()
          return
        }
        if (!isExpanded) {
          event.persist()
          setSelectCellMenu({
            ...selectCellMenu,
            open: true,
            top: `${event.currentTarget.getBoundingClientRect().bottom}px`,
            left: `${event.currentTarget.getBoundingClientRect().left}px`,
            bottom: 'auto',
            right: 'auto'
          })
        } else {
          event.stopPropagation()
        }
      }}
    >
      <div
        className="truncate flex content-center"
        style={{
          height: isExpanded ? '100%' : `${getRowHeightVariable(rowHeight) - 3}px`,
          alignItems: wrapTextAlignment,
          width: width ? width - 18 : '100%'
        }}
      >
        {(selected || isExpanded) && !readOnly && (
          <Button
            internalType="grey"
            style={{ maxWidth: '27px', width: '27px', padding: '2px' }}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation()
              setSelectCellMenu({
                ...selectCellMenu,
                open: true,
                top: `${event.currentTarget.getBoundingClientRect().bottom}px`,
                left: `${event.currentTarget.getBoundingClientRect().left}px`,
                bottom: 'auto',
                right: 'auto'
              })
            }}
          >
            <Triangle type="down" style={{ height: '12px', width: '12px' }} />
          </Button>
        )}
        {!currentValue || (isArray && (currentValue as string[]).length === 0) ? (
          <span>&nbsp;</span>
        ) : kind === 'select' ? (
          <span
            id="select-value-list"
            className="flex flex-wrap overflow-hidden p-0"
            style={{ width: validation ? 'calc(100% - 25px)' : '100%' }}
          >
            <span
              id="select-value-item"
              className="truncate bg-light-grey rounded h-fit border-1px border-solid border-grey"
              style={{
                margin: '2px',
                padding: '0px 3px',
                backgroundColor: column
                  ? getCellColour(column, currentValue.toLocaleString(), colourSettings, true)
                  : 'inherit'
              }}
            >
              {currentValue.toLocaleString()}
            </span>
          </span>
        ) : (
          isArray && (
            <div
              id="select-value-list"
              className="flex flex-wrap overflow-hidden p-0"
              style={{
                width: validation ? 'calc(100% - 25px)' : '100%',
                height: isExpanded
                  ? '100%'
                  : (currentValue as string[]).length <= (rowHeight ? rowHeight * 3 : 1)
                  ? 'fit-content'
                  : `${getRowHeightVariable(rowHeight) - 3}px`
              }}
            >
              {(currentValue as string[]).map((val, index) => (
                <div
                  key={`${index}-${val}`}
                  id="select-value-item"
                  className="truncate bg-light-grey rounded h-fit border-1px border-solid border-grey"
                  style={{
                    margin: '2px',
                    padding: '0px 3px',
                    backgroundColor: column ? getCellColour(column, val, colourSettings, true) : 'inherit'
                  }}
                >
                  {val}
                </div>
              ))}
            </div>
          )
        )}
      </div>

      {selectCellMenu.open && (
        <SelectMenu
          id="select-cell-menu"
          menuState={selectCellMenu}
          setMenuState={setSelectCellMenu}
          options={allOptions}
          onOptionClick={(option) => handleOptionSelected(option)}
          width={isExpanded ? 500 : width}
          multiselect={kind === 'multiselect'}
          optionsSelected={currentValue ? (currentValue as string[]) : []}
        />
      )}
    </div>
  )
}

export const SelectCell = React.memo(SelectCellComponent)
