import React, { useState, useRef, useEffect } from 'react'
import { EditorContent, IProcessObject } from 'types'
import { useApplicationStore } from 'hooks/application'
import {
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  AtomicBlockUtils,
  ContentBlock,
  Modifier
} from 'draft-js'
import Editor, { composeDecorators } from '@draft-js-plugins/editor'
import createImagePlugin from '@draft-js-plugins/image'
import createVideoPlugin from '@draft-js-plugins/video'
import createFocusPlugin from '@draft-js-plugins/focus'
import createResizeablePlugin from '@draft-js-plugins/resizeable'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createAnchorPlugin from './plugins/AnchorPlugin'
import ControlPanel from './components/ControlPanel'
import { colorStyleFn } from './components/Highlighter'

// Custom block components
import EmbeddedFile from './components/EmbeddedFile'
import EmbeddedImage from './components/EmbeddedImage'
import EmbeddedVideo from './components/EmbeddedVideo'
import EmbeddedIframe from './components/EmbeddedIframe'
import EmbeddedTable from './components/EmbeddedTable'

import { IFileParentResource } from 'types'
import ErrorBoundary from 'components/error'

// Initiate editor plugins
const focusPlugin = createFocusPlugin()
const resizeablePlugin = createResizeablePlugin()
const anchorPlugin = createAnchorPlugin()
const linkifyPlugin = createLinkifyPlugin({
  target: '_blank'
})

const decorator = composeDecorators(resizeablePlugin.decorator, focusPlugin.decorator)
const imagePlugin = createImagePlugin({ decorator, imageComponent: EmbeddedImage })
const videoPlugin = createVideoPlugin({ decorator, videoComponent: EmbeddedVideo })
const { types } = videoPlugin

export interface RichEditorProps {
  databaseDoc: any // TODO
  onChange?: (newContent: EditorContent) => void
  readOnly: boolean
  editorId: string
  resources?: IFileParentResource[]
  border?: boolean
  process?: IProcessObject
  processSectionId?: string
  processResponseId?: string
}

export const RichEditor: React.FC<RichEditorProps> = ({
  databaseDoc,
  onChange,
  readOnly,
  editorId,
  resources,
  border,
  process,
  processSectionId,
  processResponseId
}: RichEditorProps) => {
  const plugins = [focusPlugin, resizeablePlugin, imagePlugin, videoPlugin, anchorPlugin, linkifyPlugin]
  const { setSnackbarMessage } = useApplicationStore()
  const [editorState, setEditorState] = useState<EditorState>()
  const [editorFocused, setEditorFocused] = useState<boolean>(false)
  const editorContainer = useRef<HTMLDivElement>(null)
  const editor = useRef<Editor>(null)

  useEffect(() => {
    if (databaseDoc && databaseDoc.content && isValidDraftJsRawObject(databaseDoc.content)) {
      const content = convertFromRaw(databaseDoc.content)
      setEditorState(EditorState.createWithContent(content))
    } else setEditorState(EditorState.createEmpty())
  }, [editorId])

  const insertFile = (editorState: EditorState, url: string, filename: string) => {
    if (RichUtils.getCurrentBlockType(editorState) === types.ATOMIC) {
      return editorState
    }
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('FILE', 'IMMUTABLE', { src: url, filename: filename })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
  }

  const insertIframe = (editorState: EditorState, url: string, height: number) => {
    if (RichUtils.getCurrentBlockType(editorState) === types.ATOMIC) {
      return editorState
    }
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('IFRAME', 'IMMUTABLE', { src: url, height })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
  }

  const insertTable = (editorState: EditorState, tableId: string, viewId: string, height: number) => {
    if (RichUtils.getCurrentBlockType(editorState) === types.ATOMIC) {
      return editorState
    }
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('TABLE', 'IMMUTABLE', { tableId, viewId, height })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    return AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ')
  }

  const isValidDraftJsRawObject = (rawObject: any) => {
    if (!rawObject || typeof rawObject !== 'object') return false
    if (!Array.isArray(rawObject.blocks)) return false
    if (typeof rawObject.entityMap !== 'object') return false

    for (const block of rawObject.blocks) {
      if (typeof block.key !== 'string') return false
      if (typeof block.text !== 'string') return false
      if (typeof block.type !== 'string') return false
      if (typeof block.depth !== 'number') return false
      if (!Array.isArray(block.inlineStyleRanges)) return false
      if (!Array.isArray(block.entityRanges)) return false
      if (typeof block.data !== 'object') return false

      for (const range of block.inlineStyleRanges) {
        if (typeof range.offset !== 'number' || typeof range.length !== 'number' || typeof range.style !== 'string') {
          return false
        }
      }

      for (const range of block.entityRanges) {
        if (typeof range.offset !== 'number' || typeof range.length !== 'number' || typeof range.key !== 'number') {
          return false
        }
      }
    }

    // If all checks pass, return true
    return true
  }

  const handleOnChange = (newEditorState: EditorState) => {
    // Trigger update when actual content updates https://github.com/facebook/draft-js/issues/2804
    const currentContent = editorState?.getCurrentContent()
    const newContent = newEditorState?.getCurrentContent()

    // If only content selection changes don't trigger update
    if (editorState?.getSelection() !== newEditorState?.getSelection() && currentContent === newContent) {
      setEditorState(newEditorState)
      return
    }

    if (onChange) onChange({ content: convertToRaw(newContent) })
    setEditorState(newEditorState)
  }

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      handleOnChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  const toggleStyle = (controlType: string, styleType: string) => {
    if (editorState) {
      if (controlType === 'INLINE') return handleOnChange(RichUtils.toggleInlineStyle(editorState, styleType))
      return handleOnChange(RichUtils.toggleBlockType(editorState, styleType))
    }
  }

  const myBlockRenderer = (contentBlock: ContentBlock) => {
    if (!editorState) return null

    const type = contentBlock.getType()
    if (type === 'atomic') {
      const contentState = editorState.getCurrentContent()
      const block = contentBlock.getEntityAt(0)
      if (block) {
        const entity = contentState.getEntity(block)
        if (entity) {
          const entityType = entity.getType()
          if (entityType === 'FILE') {
            const data = entity.getData()
            const src = data.src
            const filename = data.filename

            return {
              component: EmbeddedFile,
              editable: false,
              props: {
                src: src,
                filename: filename
              }
            }
          }

          if (entityType === 'IFRAME') {
            const data = entity.getData()
            const src = data.src
            const height = data.height

            return {
              component: EmbeddedIframe,
              editable: false,
              props: {
                src: src,
                height: height
              }
            }
          }

          if (entityType === 'TABLE') {
            const data = entity.getData()
            const tableId = data.tableId
            const viewId = data.viewId
            const height = data.height

            return {
              component: EmbeddedTable,
              editable: false,
              props: {
                tableId: tableId,
                viewId: viewId,
                process: process,
                sectionId: processSectionId,
                responseId: processResponseId,
                height: height
              }
            }
          }
        }
      }
    }
  }

  if (!editorState) return null

  return (
    <ErrorBoundary>
      <div
        className="w-full rounded"
        ref={editorContainer}
        style={{
          padding: '0rem 0rem 0rem 0rem',
          userSelect: readOnly ? 'none' : 'all'
        }}
      >
        <div className="RichEditor-root editor">
          <div
            className={`RichEditor-editor rounded ${readOnly ? '' : 'editor-focused'}`}
            style={{ border: border ? '1px solid black' : 'none', padding: '11px 5px' }}
          >
            <Editor
              data-cy="editor"
              customStyleMap={colorStyleFn()}
              editorState={editorState}
              ref={editor}
              spellCheck={true}
              onFocus={() => setEditorFocused(true)}
              onBlur={(event: any) => {
                if (editorContainer.current && !editorContainer.current.contains(event.relatedTarget)) {
                  setEditorFocused(false)
                }
              }}
              plugins={plugins}
              handleKeyCommand={handleKeyCommand}
              onChange={handleOnChange}
              readOnly={readOnly}
              blockRendererFn={myBlockRenderer}
              handlePastedText={(text, html, editorState) => {
                try {
                  const newState = Modifier.replaceText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    text.trim()
                  )
                  handleOnChange(EditorState.push(editorState, newState, 'insert-fragment'))
                  return 'handled'
                } catch (e) {
                  setSnackbarMessage({ status: 'error', message: 'Failed to paste text' })
                  return 'not-handled'
                }
              }}
            />
          </div>
          {editorFocused && (
            <ControlPanel
              editorState={editorState}
              onToggle={toggleStyle}
              onChange={handleOnChange}
              addImageModifier={imagePlugin.addImage}
              addVideoModifier={videoPlugin.addVideo}
              addFileModifier={insertFile}
              addIframeModifier={insertIframe}
              addTableModifier={insertTable}
              resources={resources}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default RichEditor
