import React, { useState, useEffect, useRef, memo } from 'react'
import Button from 'components/button'
import { ICommentThreadObject, ICommentObject, CommentThreadStatusType, ActionMenuType } from 'types'
import { useApplicationStore } from 'hooks/application'
import CommentUser from 'components/comments/commentUserDetails'
import ReplyCommentComponent from 'components/comments/replyComment'
import api from 'helpers/api'
import CommentActionsMenu from 'components/comments/commentActionsMenu'
import { dateTime } from 'helpers/date'
import { CollapseAll, ExpandAll, Tick, Comment as CommentIcon } from 'components/icons'
import { ISpreadsheetData } from 'components/spreadsheet/types'
import useProject from 'hooks/project'

interface ICommentThreadActionsButtonsProps {
  action: ActionMenuType | null
  disabled: boolean
  processing: boolean
  onYes: (action: ActionMenuType) => void
  onCancelButton: () => void
}

const CommentThreadActionsButtons: React.FC<ICommentThreadActionsButtonsProps> = ({
  action,
  disabled,
  processing,
  onYes,
  onCancelButton
}) => {
  const message = action === 'edit' ? 'this comment?' : 'this comments thread'
  return (
    <div className="block" style={{ margin: '5px' }}>
      {action === 'delete' && (
        <div>
          <span style={{ height: '20px' }}> Are you sure you want to </span>
          <span className="bold" style={{ height: '20px' }}>
            {action}
          </span>{' '}
          <span>{message}</span>
        </div>
      )}
      <div className="inline-flex" style={{ margin: '5px 0px 10px 0px' }}>
        <Button
          style={{ marginRight: '5px', width: '80px' }}
          onClick={() => onYes(action)}
          disabled={disabled}
          isLoading={processing}
        >
          {action === 'edit' ? 'Update' : 'Yes'}
        </Button>
        <Button
          style={{ marginRight: '5px', width: '80px' }}
          internalType="danger"
          onClick={() => onCancelButton()}
          disabled={disabled}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

interface ICommentThreadReplyActionsButtonsProps {
  disabled: boolean
  processing: boolean
  onReply: () => void
}
const CommentThreadReplyActionsButtons: React.FC<ICommentThreadReplyActionsButtonsProps> = ({
  disabled,
  processing,
  onReply
}) => {
  return (
    <div className="inline-flex" style={{ margin: '5px 0px 10px 0px' }}>
      <Button
        style={{ marginRight: '5px', width: '80px' }}
        onClick={() => onReply()}
        disabled={disabled}
        isLoading={processing}
      >
        Reply
      </Button>
    </div>
  )
}

export interface ICommentsThreadProps {
  projectId: string
  intialCommentThread: ICommentThreadObject
  selected: boolean
  index: number
  permissionLevel: number
  spreadsheetData?: ISpreadsheetData
  onSelected: (public_id: string) => void
  onCommentThreadChange: (index: number, commentThread: ICommentThreadObject) => void
}
const CommentsThreadComponent: React.FC<ICommentsThreadProps> = ({
  projectId,
  intialCommentThread,
  selected,
  index,
  permissionLevel,
  spreadsheetData,
  onSelected,
  onCommentThreadChange
}) => {
  const { projectOwner, projectAdmin } = useProject()
  const [commentThread, setCommentThread] = useState<ICommentThreadObject>(intialCommentThread)
  const [mainCommentText, setMainCommentText] = useState('')
  const [replyCommentText, setReplyCommentText] = useState('')
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const [readOnly, setReadOnly] = useState(true)
  const [selectedAction, setSelectedAction] = useState<ActionMenuType>(null)
  const { displayErrorMessage } = useApplicationStore()
  const [processing, setProcessing] = useState<boolean>(false)
  const [processingReply, setProcessingReply] = useState<boolean>(false)
  const [showReplyComments, setShowReplyComments] = useState<boolean>(true)

  useEffect(() => {
    if (!readOnly) {
      if (commentRef.current) {
        commentRef.current!.selectionStart = commentRef.current!.value.length
        commentRef.current!.selectionEnd = commentRef.current!.value.length
        commentRef.current.focus()
      }
    }
  }, [readOnly])

  useEffect(() => {
    if (!selected) {
      setReplyCommentText('')
    }
  }, [selected])

  useEffect(() => {
    onCommentThreadChange(index, commentThread)
    if (commentThread.resolvedAt) {
      setReadOnly(true)
      setSelectedAction(null)
      setMainCommentText('')
    }
  }, [commentThread])

  const handleStatusCommentThread = (status: CommentThreadStatusType) => {
    let additionalContext = {}
    if (spreadsheetData) {
      additionalContext = {
        processPublicId: spreadsheetData.processId,
        processSectionPublicId: spreadsheetData.processSectionId,
        processResponsePublicId: spreadsheetData.processResponseId
      }
    }

    const context = { context: { projectId, ...additionalContext } }

    api
      .updateStatusCommentThread(context, commentThread!.publicId, status)
      .then((response) => {
        setCommentThread(response.data)
        setReplyCommentText('')
      })
      .catch((error) => {
        displayErrorMessage(error)
      })
  }

  const handleOnSelectedActionButton = (action: ActionMenuType, comment: ICommentObject) => {
    setProcessing(true)
    if (action === 'edit') {
      let additionalContext = {}
      if (spreadsheetData) {
        additionalContext = {
          processPublicId: spreadsheetData.processId,
          processSectionPublicId: spreadsheetData.processSectionId,
          processResponsePublicId: spreadsheetData.processResponseId
        }
      }
      api
        .updateComment(commentThread.publicId, comment.publicId, {
          commentText: mainCommentText,
          context: { projectId, ...additionalContext }
        })
        .then((response) => {
          const comments = [...commentThread.comments]
          comments[0] = response.data
          setCommentThread({ ...commentThread, comments: comments })
          setSelectedAction(null)
          setProcessing(false)
          setReadOnly(true)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setProcessing(false)
        })
    } else if (action === 'delete') {
      let additionalContext = {}
      if (spreadsheetData) {
        additionalContext = {
          processPublicId: spreadsheetData.processId,
          processSectionPublicId: spreadsheetData.processSectionId,
          processResponsePublicId: spreadsheetData.processResponseId
        }
      }

      const context = { context: { projectId, ...additionalContext } }
      api
        .deleteCommentThread(context, commentThread.publicId)
        .then((response) => {
          setCommentThread(response.data)
          setSelectedAction(null)
          setProcessing(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setProcessing(false)
        })
    }
  }

  const handleOnReply = () => {
    setProcessingReply(true)
    let additionalContext = {}
    if (spreadsheetData) {
      additionalContext = {
        processPublicId: spreadsheetData.processId,
        processSectionPublicId: spreadsheetData.processSectionId,
        processResponsePublicId: spreadsheetData.processResponseId
      }
    }
    const data = { commentText: replyCommentText, context: { projectId, ...additionalContext } }
    api
      .postComment(commentThread.publicId, data)
      .then((response) => {
        setCommentThread({ ...commentThread, comments: [...commentThread.comments, response.data] })
        setProcessingReply(false)
        setReplyCommentText('')
      })
      .catch((error) => {
        displayErrorMessage(error)
        setProcessingReply(false)
      })
  }

  const handleOnDisplayReplyComments = () => {
    setShowReplyComments(!showReplyComments)
    setReplyCommentText('')
  }

  const hadleOnReplyCommentChange = (index: number, comment: ICommentObject) => {
    commentThread.comments[index] = comment
    const commentsToUpdate = commentThread.comments.filter((comment) => !comment.deletedAt)
    setCommentThread({ ...commentThread, comments: commentsToUpdate })
  }

  const handleOnSelectedActionMenu = (action: ActionMenuType) => {
    setSelectedAction(action)
    if (action === 'edit') {
      setReadOnly(false)
    } else {
      setReadOnly(true)
      setMainCommentText('')
    }
  }

  const handleCancelButton = () => {
    setSelectedAction(null)
    setMainCommentText('')
  }

  if (!commentThread) {
    return null
  }

  /**
   * First comment
   */
  const mainComment = commentThread!.comments[0]
  /**
   * Remaining comments are replies to the main comment
   */
  const replyComments = [...commentThread!.comments]
  replyComments.shift()

  const displayShowHideButton = !commentThread.resolvedAt || (commentThread.resolvedAt && replyComments.length > 0)

  return (
    <div className="rounded" style={{ padding: '5px' }}>
      <div
        className="rounded bg-really-light-blue"
        style={{ padding: '10px', marginTop: '5px' }}
        onClick={() => onSelected(commentThread.publicId)}
      >
        <div className="inline-flex space-between w-full items-center">
          <CommentUser comment={mainComment} />
          <div className="inline-flex">
            {(commentThread.isCommentInitiator || permissionLevel == 4 || projectAdmin || projectOwner) && (
              <div
                className="cursor-pointer flex items-center p-10px"
                title={commentThread.resolvedAt ? `Re-open` : 'Resolve'}
                onClick={() => handleStatusCommentThread(commentThread.resolvedAt ? `reopen` : 'resolve')}
              >
                {commentThread.resolvedAt ? <CommentIcon /> : <Tick />}
              </div>
            )}

            {displayShowHideButton && (
              <div
                className="cursor-pointer flex items-center p-10px"
                title={showReplyComments ? `Hide` : 'Show'}
                onClick={() => handleOnDisplayReplyComments()}
              >
                {showReplyComments ? <CollapseAll /> : <ExpandAll />}
              </div>
            )}
            {commentThread.isCommentInitiator && !commentThread.resolvedAt && (
              <CommentActionsMenu
                onSelectedActionMenu={(action: ActionMenuType) => handleOnSelectedActionMenu(action)}
              />
            )}
          </div>
        </div>
        {!readOnly ? (
          <textarea
            id={`main-comment-${mainComment.publicId}`}
            style={{ border: readOnly ? 'none' : '1px solid black', marginBottom: '5px' }}
            ref={commentRef}
            value={mainCommentText || mainComment.commentText}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMainCommentText(event.target.value)}
            readOnly={readOnly}
          />
        ) : (
          <div className="w-full bg-white rounded whitespace-pre-wrap select-text" style={{ padding: '10px' }}>
            {mainCommentText || mainComment.commentText}
          </div>
        )}
        {selectedAction && (
          <CommentThreadActionsButtons
            action={selectedAction}
            onYes={(action: ActionMenuType) => handleOnSelectedActionButton(action, mainComment)}
            onCancelButton={() => handleCancelButton()}
            disabled={processing}
            processing={processing}
          />
        )}
        {showReplyComments && replyComments && (
          <div>
            <div className="rounded" style={{ marginLeft: '15px' }}>
              {replyComments.map((comment: ICommentObject, index: number) => {
                const reply_index = index + 1 // index =0 is the parent comment. Replies starts on index = 1
                return (
                  <ReplyCommentComponent
                    key={`reply-comment-${comment.publicId}`}
                    projectId={projectId}
                    commentThreadId={commentThread.publicId}
                    initialComment={comment}
                    index={reply_index}
                    onCommentChange={(reply_index, comment) => hadleOnReplyCommentChange(reply_index, comment)}
                    isResolvedCommentThread={!!commentThread.resolvedAt}
                    spreadsheetData={spreadsheetData}
                  />
                )
              })}
            </div>

            {!commentThread.resolvedAt && (
              <div className="flex items-center flex-column" style={{ padding: '5px 0px 5px 0px' }}>
                <textarea
                  id={`reply-comment-${mainComment.publicId}`}
                  style={{ marginBottom: '5px' }}
                  value={replyCommentText}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setReplyCommentText(event.target.value)}
                />
                <div className="flex w-full items-center justify-end">
                  <CommentThreadReplyActionsButtons
                    disabled={processingReply || !replyCommentText}
                    processing={processingReply}
                    onReply={() => handleOnReply()}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        {commentThread.resolvedAt && commentThread.resolver && (
          <span>
            Resolved by {commentThread.resolver.name} on {dateTime(new Date(commentThread.resolvedAt)).toLocaleString()}
          </span>
        )}
      </div>
    </div>
  )
}

function areEqualCommentThread(prevCommentThread: ICommentThreadObject, nextCommentThread: ICommentThreadObject) {
  return (
    prevCommentThread.deletedAt === nextCommentThread.deletedAt &&
    prevCommentThread.resolvedAt === nextCommentThread.resolvedAt &&
    prevCommentThread.updatedAt === nextCommentThread.updatedAt &&
    prevCommentThread.comments.length === nextCommentThread.comments.length
  )
}

function areEqual(prevProps: ICommentsThreadProps, nextProps: ICommentsThreadProps) {
  return (
    prevProps.selected === nextProps.selected &&
    prevProps.index === nextProps.index &&
    prevProps.permissionLevel === nextProps.permissionLevel &&
    areEqualCommentThread(prevProps.intialCommentThread, nextProps.intialCommentThread)
  )
}

export default memo(CommentsThreadComponent, areEqual)
