import React from 'react'
import { IViewPortColumn } from 'components/spreadsheet/types'
import { getRowHeightVariable } from 'components/spreadsheet/helpers/functions'
import constants from 'style/constants.module.scss'

interface Props {
  column: IViewPortColumn
  rowHeight: number
  value: string
}

const GroupLoadingCell: React.FC<Props> = ({ column, rowHeight, value }) => {
  const heightPx = `${getRowHeightVariable(rowHeight)}px`
  const maxWidth = column.column.width - 40

  return (
    <div
      style={{
        width: `${column.column.width}px`,
        left: `${column.left}px`,
        height: heightPx,
        position: column.isFrozen ? 'sticky' : 'absolute',
        zIndex: column.isFrozen ? 4 : 'initial',
        boxShadow: column.isLastFrozenColumn
          ? `${Number.parseInt(constants.frozenShadowWidth)}px 0 1px ${constants.grey}`
          : 'none'
      }}
      className="border-r-1px border-solid border-darker-grey"
    >
      <div className="relative">
        <span
          style={{
            maxWidth: `${maxWidth}px`
          }}
        >
          <span
            className="absolute inline-block"
            style={{ zIndex: 2, padding: '5px 5px 0 5px', wordBreak: 'break-word' }}
          >
            {value}
          </span>
        </span>
      </div>
    </div>
  )
}

export default GroupLoadingCell
