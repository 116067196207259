import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useAuth } from 'hooks/auth'
import { getQueryParameter } from 'helpers/utils'
import api, { APIError } from 'helpers/api'
import { HOME, SIGN_IN, TWO_FACTOR_SIGNIN } from 'routes'
import { redirectTo } from 'helpers/redirect'

const GoogleComplete: React.FC = () => {
  const { push } = useHistory()
  const { updateUser } = useAuth()
  const [, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const code = getQueryParameter('code')

  const completeOAuth = async () => {
    try {
      const response = await api.completeGoogleOAuth(code!)
      setLoading(false)
      if (response.metadata.require2FaRedirect) {
        push(`${TWO_FACTOR_SIGNIN}?loginToken=${response.metadata.userToken}`)
        return
      } else {
        updateUser(response.data)
        push(redirectTo(HOME))
      }
    } catch (e) {
      if (e instanceof APIError) {
        setErrorMessage(e.message)
      }

      console.error(e)
      setError(true)
    }
  }

  React.useEffect(() => {
    completeOAuth()
  }, [code])

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        <h1 className="mb-12 text-2xl">Signing you into your Morta account</h1>
        {error && (
          <div>
            <p>
              {errorMessage === '' && (
                <span>
                  There was an error signing your into your account. If the problem persists contact the adminstrator on
                  your Google account.
                </span>
              )}
              {errorMessage !== '' && <span>{errorMessage}</span>}
            </p>

            <br />
            <p>
              Return to <Link to={SIGN_IN}>sign in page</Link>.
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default GoogleComplete
