import React from 'react'

interface ArchivedWarningProps {
  message: string
}

const ArchivedWarning: React.FC<ArchivedWarningProps> = ({ message }) => {
  return (
    <div
      className="absolute bg-red text-white p-4 rounded text-base"
      style={{ left: '50%', transform: 'translate(-50%, 0)', zIndex: 99 }}
    >
      {message}
    </div>
  )
}

export default ArchivedWarning
