import React, { useState } from 'react'
import Button from 'components/button'
import { IProjectSecret } from 'types'
import { PROJECT } from 'app-constants'
import { capitaliseFirstLetter } from 'helpers/utils'
export interface ProjectSecretFormValues {
  name: string
  value: string
}

interface Props {
  secret?: IProjectSecret
  onSubmit: (values: ProjectSecretFormValues) => void | Promise<void>
}

const ProjectSecretForm: React.FC<Props> = ({ secret, onSubmit }: Props) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [values, setValues] = useState<ProjectSecretFormValues>({
    name: secret ? secret.name : '',
    value: ''
  })

  const nameError = !values.name.match(/^[^\d\W]\w*$/)
  const valueError = values.value === ''

  const handleSubmit = async () => {
    setSubmitting(true)
    await onSubmit(values)
    setSubmitting(false)
  }

  return (
    <div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">Secret Name</div>
        <input
          placeholder="Please enter a name for this secret"
          value={values.name}
          disabled={secret?.isReadOnly}
          onChange={(event) => setValues({ ...values, name: event.target.value })}
        />
        {nameError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            The secret name cannot be blank, must start with a letter and have no spaces or special characters
          </p>
        )}
      </div>
      <div className="mb-8">
        <div className="border-b-2px border-solid border-grey py-10px mb-4 font-bold">New Secret Value</div>
        <input
          placeholder="Please enter a secret value"
          onChange={(event) => setValues({ ...values, value: event.target.value })}
        />
        {valueError && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            The secret value cannot be blank
          </p>
        )}
      </div>

      <div className="flex w-full items-center justify-end">
        <Button disabled={nameError || valueError} isLoading={submitting} onClick={() => handleSubmit()}>
          {secret ? `Update ${capitaliseFirstLetter(PROJECT)} Secret` : `Add ${capitaliseFirstLetter(PROJECT)} Secret`}
        </Button>
      </div>
    </div>
  )
}

export default ProjectSecretForm
