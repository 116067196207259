import { useMemo } from 'react'

import constants from 'style/constants.module.scss'

import { IViewPortColumn } from '../types'
import { ITableViewGroup, ITableViewColumn } from 'types'

interface Args {
  rawColumns: readonly ITableViewColumn[]
  rawGroupByColumns: readonly ITableViewGroup[]
  frozenIndex: number
  hiddenColumnIds: string[]
}

export function useColumnsViewport({ rawColumns, rawGroupByColumns, frozenIndex, hiddenColumnIds }: Args) {
  const { columns, lastFrozenColumnIndex } = useMemo(() => {
    let lastFrozenColumnIndex = -1
    let lastGroupedColumnIndex = -1
    const groupedColumnIds = rawGroupByColumns.map((group) => group.columnId)

    const columns = rawColumns
      .filter((column) => !hiddenColumnIds.includes(column.publicId)) /// remove hidden columns from view
      .sort((columnA, columnB) => {
        // Sort grouped columns
        if (groupedColumnIds.includes(columnA.publicId)) {
          if (groupedColumnIds.includes(columnB.publicId)) {
            return groupedColumnIds.indexOf(columnA.publicId) - groupedColumnIds.indexOf(columnB.publicId)
          }
          return -1
        }
        if (groupedColumnIds.includes(columnB.publicId)) return 1

        // Sort other columns last
        return 0
      })
      .map((rawColumn, idx) => {
        const isGrouped = groupedColumnIds.includes(rawColumn.publicId) ?? false
        const frozen = idx < frozenIndex
        const originalPosition = rawColumns.indexOf(rawColumn)

        const viewPortColumn: IViewPortColumn = {
          column: rawColumn,
          originalPosition,
          isFrozen: frozen,
          position: idx,
          isGrouped,
          isLastFrozenColumn: false,
          isLastGroupedColumn: false,
          left: 0 // cumulative left offset
        }

        if (frozen) {
          lastFrozenColumnIndex++
        }
        if (isGrouped) {
          lastGroupedColumnIndex++
        }

        return viewPortColumn
      })

    if (lastFrozenColumnIndex !== -1) {
      columns[lastFrozenColumnIndex].isLastFrozenColumn = true
    }
    if (lastGroupedColumnIndex !== -1) {
      columns[lastGroupedColumnIndex].isLastGroupedColumn = true
    }

    // Headers and cells get placed after the row number cell, so we add an offset here
    let leftOffset = Number(constants.rowNumberColumnWidth)

    columns.forEach((viewportColumn, idx) => {
      viewportColumn.position = idx
      viewportColumn.left = leftOffset
      leftOffset += viewportColumn.column.width
    })

    return {
      columns,
      lastFrozenColumnIndex
    }
  }, [rawColumns, rawGroupByColumns, frozenIndex, hiddenColumnIds])

  return { columns, lastFrozenColumnIndex }
}
