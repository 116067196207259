import React, { useState } from 'react'
import { EditorState, RichUtils } from 'draft-js'
import Button from 'components/button'
import Menu from 'components/menu'
import { Link } from 'components/icons'
import constants from 'style/constants.module.scss'
import { IContextMenuState } from 'types'
import { INITIAL_CONTEXT_MENU_STATE } from 'app-constants'

/**
 * General Anchor props
 */
interface GeneralAnchorControlProps {
  editorState: EditorState
  onChange: (nextEditorState: EditorState) => void
}

/**
 * Add anchor section
 */
interface AddAnchorFormProps extends GeneralAnchorControlProps {
  onClose: () => void
}

const AddAnchorForm: React.FC<AddAnchorFormProps> = ({ editorState, onChange, onClose }: AddAnchorFormProps) => {
  const [url, setUrl] = useState('')

  const handleSubmit = () => {
    const content = editorState.getCurrentContent()
    // create new entity
    const contentStateWithEntity = content.createEntity('LINK', 'MUTABLE', { url: url })
    // get last entity key
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    // update the content block of current editor state
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })

    onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
    handleClose()
  }

  const handleClose = () => {
    setUrl('')
    onClose()
  }

  const handleKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    } else if (event.key === 'Escape') {
      handleClose()
    }
  }

  return (
    <div className="flex" style={{ padding: '12px 20px' }} onKeyDown={(event) => handleKeyPressed(event)}>
      <input
        id="link-target"
        placeholder="Enter the link"
        value={url}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUrl(event.target.value)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyPressed(event)}
      />
      <Button style={{ marginLeft: '7px' }} onClick={() => handleSubmit()}>
        Add
      </Button>
    </div>
  )
}

interface AddAnchorIconProps {
  editorState: EditorState
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void
}

const AddAnchorIcon: React.FC<AddAnchorIconProps> = ({ editorState, onClick }: AddAnchorIconProps) => {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      onClick(event)
    }
  }

  return (
    <div
      className="flex items-center hover-bg-light-grey transition-all rounded"
      title="Add Link"
      style={{ padding: '7px', marginRight: '10px', cursor: 'pointer', color: constants.green }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event)}
    >
      <Link />
    </div>
  )
}

type AddAnchorControlProps = GeneralAnchorControlProps
export const AddAnchorControl: React.FC<AddAnchorControlProps> = ({ editorState, onChange }: AddAnchorControlProps) => {
  const [open, setOpen] = useState(false)
  const [anchorMenu, setAnchorMenu] = useState<IContextMenuState>(INITIAL_CONTEXT_MENU_STATE)

  const anchorMenuWidth = 500
  const offset = 500

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      setOpen(!open)
      setAnchorMenu({
        open: true,
        top: `${event.currentTarget.getBoundingClientRect().bottom + 10}px`,
        left: `${event.currentTarget.getBoundingClientRect().left - anchorMenuWidth + offset}px`,
        right: 'auto',
        bottom: 'auto'
      })
    }
  }

  const handleClose = () => {
    setAnchorMenu(INITIAL_CONTEXT_MENU_STATE)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <AddAnchorIcon editorState={editorState} onClick={(event) => handleClick(event)} />
      <Menu id="anchor-menu" menuState={anchorMenu} setMenuState={setAnchorMenu} width={anchorMenuWidth} zIndex={1000}>
        <AddAnchorForm editorState={editorState} onChange={onChange} onClose={handleClose} />
      </Menu>
    </React.Fragment>
  )
}

/**
 * Remove anchor icon section
 */
type RemoveAnchorControlProps = GeneralAnchorControlProps
export const RemoveAnchorControl: React.FC<RemoveAnchorControlProps> = ({
  editorState,
  onChange
}: RemoveAnchorControlProps) => {
  const hadleRemoveAnchor = () => {
    const selection = editorState.getSelection()
    if (!selection.isCollapsed()) {
      onChange(RichUtils.toggleLink(editorState, editorState.getSelection(), null))
    }
  }
  return (
    <div
      className="flex items-center hover-bg-light-grey transition-all rounded"
      title="Remove Link"
      style={{ padding: '7px', marginRight: '10px', cursor: 'pointer', color: constants.red }}
      onClick={() => hadleRemoveAnchor()}
    >
      <Link />
    </div>
  )
}
