import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'helpers/api'
import { IUserObject } from 'types'
import useAuth from 'hooks/auth'
import { HOME } from 'routes'
import { mapUsersToTableData } from 'views/admin/helpers/utils'
import Modal from 'components/modal'
import Button from 'components/button'
import Audit from 'components/audit'
import { Table } from 'components/table'
import Select from 'components/select'
import { USER_SUBSCRIPTION_LEVELS } from 'app-constants'
import useApplicationStore from 'hooks/application'
import { numberWithCommas } from 'components/spreadsheet/helpers/format'

interface IAdminUserObject extends IUserObject {
  password: string | null
  subscriptionLevel: number
}

const AdminHome: React.FC = () => {
  document.title = 'Morta | Admin Panel'

  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const [totalUsers, setTotalUsers] = useState<number>()
  const [advancedUsers, setAdvancedUsers] = useState<number>()
  const [users, setUsers] = useState<IUserObject[]>([])
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [editUserModal, setEditUserModal] = useState<IAdminUserObject>()
  const [auditModal, setAuditModal] = useState<{ open: boolean; user?: IUserObject }>({ open: false })

  const [userEmailSearch, setUserEmailSearch] = useState<string>('')
  const [userSubscriptionSearch, setUserSubscriptionSearch] = useState<number>(-1)

  const { startMasquerade } = useAuth()
  const { push } = useHistory()

  useEffect(() => {
    api
      .getTotalUsers()
      .then((response) => {
        setTotalUsers(response.data.totalUsers)
        setAdvancedUsers(response.data.advancedUsers)
      })
      .catch((e) => {
        displayErrorMessage(e)
      })
  }, [])

  const onSearchForUsers = () => {
    setLoadingUsers(true)
    api
      .getAllUsers(userEmailSearch, userSubscriptionSearch === -1 ? undefined : userSubscriptionSearch)
      .then((response) => {
        setUsers(response.data)
        setLoadingUsers(false)
      })
      .catch((e) => {
        displayErrorMessage(e)
        setLoadingUsers(false)
      })
  }

  const createLoginHandler = async (user: IUserObject) => {
    try {
      const response = await api.getMasqueradeCredentials(user)
      startMasquerade(response.data.token)
      push(HOME)
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const verifyUserEmail = async (user: IUserObject) => {
    try {
      const response = await api.verifyUserEmail(user.publicId)
      if (response.data && users) {
        const newUsers = [...users].map((userObject: IUserObject) => {
          if (userObject.publicId === user.publicId) {
            return { ...userObject, isEmailVerified: true }
          } else {
            return userObject
          }
        })

        setUsers(newUsers)
      }
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const remove2fa = async (user: IUserObject) => {
    try {
      const response = await api.adminRemove2fa(user.publicId)
      if (response.data && users) {
        const newUsers = [...users].map((userObject: IUserObject) => {
          if (userObject.publicId === user.publicId) {
            return { ...userObject, is2FaEnabled: false }
          } else {
            return userObject
          }
        })

        setUsers(newUsers)
      }
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const handleEditUser = (user: IUserObject) => {
    setEditUserModal({ ...user, password: null })
  }

  const password8CharactersError = !(editUserModal && editUserModal.password && editUserModal.password.match(/.{8,}/))
  const passwordLowercaseError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[a-z])/)
  )
  const passwordUppercaseError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[A-Z])/)
  )
  const passwordNumericError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[0-9])/)
  )
  const passwordSpecialError = !(
    editUserModal &&
    editUserModal.password &&
    editUserModal.password.match(/^(?=.*[!@#$%^&*])/)
  )

  const passwordError =
    password8CharactersError ||
    passwordLowercaseError ||
    passwordUppercaseError ||
    passwordNumericError ||
    passwordSpecialError

  const handleUserSave = async () => {
    try {
      if (
        editUserModal &&
        (editUserModal.password === undefined ||
          editUserModal.password === null ||
          editUserModal.password === '' ||
          (editUserModal.password && !passwordError))
      ) {
        api
          .adminUpdateUser(
            editUserModal.publicId,
            editUserModal.name,
            editUserModal.email,
            editUserModal.password,
            editUserModal.subscriptionLevel,
            editUserModal.subscriptionQuota,
            editUserModal.subscriptionManagerEmail && editUserModal.subscriptionManagerEmail !== ''
              ? editUserModal.subscriptionManagerEmail
              : null
          )
          .then(() => {
            if (users) {
              const newUsers = [...users].map((userObject: IUserObject) => {
                if (userObject.publicId === editUserModal.publicId) {
                  return {
                    ...userObject,
                    name: editUserModal.name,
                    email: editUserModal.email,
                    subscriptionLevel: editUserModal.subscriptionLevel,
                    subscriptionQuota: editUserModal.subscriptionQuota,
                    subscriptionManagerEmail: editUserModal.subscriptionManagerEmail
                  }
                } else {
                  return userObject
                }
              })

              setUsers(newUsers)
              setSnackbarMessage({
                status: 'success',
                message: 'User updated successfully'
              })
            }
            setEditUserModal(undefined)
          })
          .catch((e) => {
            displayErrorMessage(e)
          })
      } else {
        console.error('There is something wrong with the password.')
      }
    } catch (e) {
      displayErrorMessage(e)
    }
  }

  const openAuditModal = (user: IUserObject) => {
    setAuditModal({ open: true, user })
  }

  const tableData = mapUsersToTableData(
    users,
    createLoginHandler,
    verifyUserEmail,
    handleEditUser,
    remove2fa,
    openAuditModal,
    setSnackbarMessage
  )

  return (
    <div className="w-full h-full flex flex-column select-none" style={{ padding: '20px' }}>
      <div
        className="flex w-full items-center bg-light-grey"
        style={{ padding: '10px', marginBottom: '15px', gap: '30px' }}
      >
        <div className="flex flex-column h-full">
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            # Users On Platform
          </div>
          {totalUsers !== undefined ? (
            <div className="ml-auto text-xl">{numberWithCommas(totalUsers)}</div>
          ) : (
            <span className="ml-auto skeleton-box" style={{ height: '28.695px', width: '50px' }} />
          )}
        </div>

        <div className="flex flex-column h-full">
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            # Advanced Users On Platform
          </div>
          {advancedUsers !== undefined && totalUsers !== undefined ? (
            <div className="ml-auto text-xl">{numberWithCommas(advancedUsers)}</div>
          ) : (
            <span className="ml-auto skeleton-box" style={{ height: '28.695px', width: '50px' }} />
          )}
        </div>

        <div className="flex flex-column h-full">
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            # Users In Search
          </div>
          <div className="ml-auto text-xl">{numberWithCommas(users.length)}</div>
        </div>

        <div className="flex flex-column h-full">
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            # Advanced Users In Search
          </div>
          <div className="ml-auto text-xl">
            {numberWithCommas(users.filter((user) => !user.isOnFreeTrial && user.subscriptionLevel === 1).length)}
          </div>
        </div>

        <div className="flex flex-column ml-auto" style={{ width: '200px' }}>
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            Subscription Filter:
          </div>
          <Select
            options={[
              { label: 'All', value: '-1' },
              ...USER_SUBSCRIPTION_LEVELS.map((level, levelIndex) => {
                return { label: level, value: levelIndex.toString() }
              })
            ]}
            onOptionClick={(option) => setUserSubscriptionSearch(Number.parseInt(option))}
            optionsSelected={[userSubscriptionSearch.toString()]}
          />
        </div>

        <div className="flex flex-column" style={{ width: '200px' }}>
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            Email Filter:
          </div>
          <input
            type="text"
            placeholder="Filter by user email"
            className="rounded border-1px border-solid"
            onChange={(event) => setUserEmailSearch(event.target.value)}
          />
        </div>

        <div className="flex flex-column" style={{ marginLeft: '20px' }}>
          <div className="italic text-secondary text-sm" style={{ marginBottom: '5px' }}>
            User Search
          </div>
          <Button internalType="accept" onClick={() => onSearchForUsers()}>
            Search For Users
          </Button>
        </div>
      </div>

      <Table
        data={tableData}
        include={[
          { header: 'User', id: 'user' },
          { header: 'Email', id: 'email' },
          { header: 'Public Id', id: 'publicId' },
          { header: 'Signed Up', id: 'createdAt' },
          { header: 'Subscription Level', id: 'subscriptionLevel' },
          { header: '2fa Enabled?', id: 'is2faEnabled' },
          { header: 'Super Admin', id: 'isSuperAdmin' },
          { header: 'Email Verified?', id: 'verified' },
          { header: 'Support Access?', id: 'allowSupportAccess' },
          { header: 'Subscription Quota', id: 'subscriptionQuota' },
          { header: '# Allocated Subscription', id: 'numberOfManagedSubscriptionUsers' },
          { header: 'Subscription Manager', id: 'subscriptionManagerEmail' },
          { header: 'Edit', id: 'edit' },
          { header: 'Login', id: 'login' }
        ]}
        defaultSort={'email'}
        defaultSortAscending={false}
        loading={loadingUsers}
      />
      {editUserModal && (
        <Modal
          id="edit-user-modal"
          open={editUserModal !== undefined}
          setOpen={() => setEditUserModal(undefined)}
          title={`Edit User`}
        >
          <div className="mb-8">
            <div className="font-bold mb-4">User Name</div>
            <input
              type="text"
              defaultValue={editUserModal.name}
              onChange={(event) => setEditUserModal({ ...editUserModal, name: event.target.value })}
            />
          </div>
          <div className="mb-8">
            <div className="font-bold mb-4">User Email</div>
            <input
              type="text"
              defaultValue={editUserModal.email}
              onChange={(event) => setEditUserModal({ ...editUserModal, email: event.target.value })}
            />
          </div>
          <div className="mb-8">
            <div className="font-bold mb-4">New User Password</div>
            <input
              type="password"
              onChange={(event) => setEditUserModal({ ...editUserModal, password: event.target.value })}
              autoComplete="new-password"
            />
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${password8CharactersError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${password8CharactersError ? '⤫' : '✓'} Password must be at least 8 characters long`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordLowercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordLowercaseError ? '⤫' : '✓'} Password must contain at least one lowercase letter`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordUppercaseError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordUppercaseError ? '⤫' : '✓'} Password must contain at least one uppercase letter`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordNumericError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordNumericError ? '⤫' : '✓'} Password must contain at least one numeric character`}
              </p>
            )}
            {editUserModal.password && editUserModal.password !== '' && (
              <p className={`${passwordSpecialError ? 'text-red' : 'text-green'}`} style={{ marginTop: '10px' }}>
                {`${passwordSpecialError ? '⤫' : '✓'} Password must contain at least one special character: !@#$%^&*`}
              </p>
            )}
          </div>

          <div className="mb-8">
            <div className="font-bold mb-4">Advanced License Quota</div>
            <input
              type="number"
              min={0}
              defaultValue={editUserModal.subscriptionQuota}
              onChange={(event) =>
                setEditUserModal({ ...editUserModal, subscriptionQuota: Number.parseInt(event.target.value) })
              }
            />
          </div>

          <div className="mb-8">
            <div className="font-bold mb-4">Subscription Level</div>
            <Select
              options={USER_SUBSCRIPTION_LEVELS.map((level, levelIndex) => {
                return { label: level, value: levelIndex.toString() }
              })}
              onOptionClick={(option) => {
                setEditUserModal({ ...editUserModal, subscriptionLevel: Number.parseInt(option) })
              }}
              optionsSelected={[editUserModal.subscriptionLevel.toString()]}
            />
          </div>

          <div className="mb-8">
            <div className="font-bold mb-4">Subscription Manager</div>
            <input
              type="text"
              defaultValue={editUserModal.subscriptionManagerEmail}
              disabled={editUserModal.subscriptionLevel !== 1}
              onChange={(event) => setEditUserModal({ ...editUserModal, subscriptionManagerEmail: event.target.value })}
            />
          </div>

          <div className="mb-8 flex items-center justify-end">
            <Button internalType="danger" onClick={() => setEditUserModal(undefined)}>
              Cancel
            </Button>
            <Button style={{ marginLeft: '15px' }} internalType="accept" onClick={() => handleUserSave()}>
              Save
            </Button>
          </div>
        </Modal>
      )}
      {auditModal.open && auditModal.user && (
        <Modal
          id={'user-audit'}
          open={auditModal.open}
          setOpen={(open) => setAuditModal({ ...auditModal, open })}
          title="Audit Log"
        >
          <Audit type={'user'} resourceId={auditModal.user.publicId} />
        </Modal>
      )}
    </div>
  )
}

export default AdminHome
