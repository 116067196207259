import useAuth from 'hooks/auth'
import React from 'react'
import { Link } from 'react-router-dom'
import { HOME } from 'routes'

const ResourceDeleted: React.FC<{ inProcess?: boolean }> = ({ inProcess }) => {
  const { user } = useAuth()
  return (
    <div className="bg-white flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-20">
      <div className="flex flex-column w-full justify-center items-center">
        <div style={{ fontSize: inProcess ? '1.5rem' : '2rem', marginTop: inProcess ? '250px' : 'none' }}>
          Sorry, the resource you were looking for was not found.
        </div>
        {!inProcess && user && (
          <Link style={{ fontSize: '1.1rem', marginTop: '20px' }} to={HOME}>
            Return To Home
          </Link>
        )}
      </div>
    </div>
  )
}

export default ResourceDeleted
