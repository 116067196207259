import React, { useState } from 'react'
import Modal, { ModalProps } from 'components/modal'
import { useProject } from 'hooks/project'
import api from 'helpers/api'
import { history } from 'helpers/history'
import { PROCESS_BASE, PROJECT_BASE } from 'routes'
import { useApplicationStore } from 'hooks/application'
import Button from 'components/button'
import { Plus } from 'components/icons'
import { IFolder } from 'types'

interface INewProcess {
  name: string
  type: string
  loading: boolean
}

const INITIAL_PROCESS_STATE = {
  name: '',
  type: '',
  loading: false
}

const NewProcess: React.FC<ModalProps> = ({ id, open, setOpen }) => {
  const { project, updateProject } = useProject()
  const [newProcess, setNewProcess] = useState<INewProcess>(INITIAL_PROCESS_STATE)
  const { displayErrorMessage, setSnackbarMessage } = useApplicationStore()

  const createProcess = () => {
    if (newProcess.name !== '') {
      setNewProcess({ ...newProcess, loading: true })
      api
        .createNewProcess({
          projectId: project.publicId,
          name: newProcess.name,
          type: newProcess.type,
          context: { projectId: project.publicId }
        })
        .then((response) => {
          updateProject({
            ...project,
            folders: project.folders.map((folder: IFolder) =>
              folder.id === ''
                ? {
                    ...folder,
                    resources: folder.resources
                      ? [
                          ...folder.resources,
                          {
                            name: response.data.name,
                            type: 'process',
                            process: response.data
                          }
                        ]
                      : [
                          {
                            name: response.data.name,
                            type: 'process',
                            process: response.data
                          }
                        ]
                  }
                : folder
            )
          })

          setNewProcess({ ...newProcess, loading: false })
          history.push(PROJECT_BASE + project.publicId + PROCESS_BASE + response.data.publicId)
          setOpen(false)
        })
        .catch((error) => {
          displayErrorMessage(error)
          setNewProcess({ ...newProcess, loading: false })
        })
    } else {
      setSnackbarMessage({
        status: 'error',
        message: 'Please enter a name for the document.'
      })
    }
  }

  return (
    <Modal id={id} open={open} setOpen={setOpen} title="Create New Document">
      <input
        placeholder="Enter the name of your new document"
        onChange={(event) => setNewProcess({ ...newProcess, name: event.target.value })}
        autoFocus
        style={{ marginBottom: '20px' }}
      />

      <Button
        internalType="accept"
        className="ml-auto text-base"
        onClick={() => createProcess()}
        data-cy="create-table-button"
      >
        <Plus style={{ marginRight: '5px' }} /> Create New Document
      </Button>
    </Modal>
  )
}

export default NewProcess
