import React, { useState } from 'react'
import Button from 'components/button'

interface SupportAccessFormProps {
  allowSupportAccess: boolean
  onSupportAccessChange: (supportAccess: boolean, twoFactorCode: string) => void
}

const SupportAccessForm: React.FC<SupportAccessFormProps> = ({ onSupportAccessChange, allowSupportAccess }) => {
  const [twoFactorCode, setTwoFactorCode] = useState<string>('')
  return (
    <div>
      <div className="mb-4">
        <input
          placeholder="Please enter your 2fa code from your device"
          type="text"
          onChange={(event) => setTwoFactorCode(event.target.value)}
        />
        {twoFactorCode === '' && (
          <p className="text-red" style={{ marginTop: '10px' }}>
            Your 2FA code cannot be blank
          </p>
        )}
      </div>

      <div className="flex items-center justify-end">
        <Button
          internalType={allowSupportAccess ? 'danger' : 'accept'}
          style={{ marginTop: '20px' }}
          onClick={() => onSupportAccessChange(!allowSupportAccess, twoFactorCode)}
          data-cy="update-support-access-button"
          disabled={twoFactorCode === ''}
        >
          {allowSupportAccess ? 'Disable' : 'Enable'} Support Access
        </Button>
      </div>
    </div>
  )
}

export default SupportAccessForm
