import React, { useState, useEffect, ReactElement, AnchorHTMLAttributes } from 'react'
import api from 'helpers/api'
import { isMortaUploadedUrl } from 'helpers/utils'

export interface FileProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  blockProps: { src: string; filename: string }
}

export default React.forwardRef<HTMLAnchorElement, FileProps>(function EmbeddedFile(props, ref): ReactElement {
  const { blockProps } = props
  const [src, setSrc] = useState<string>(blockProps.src)
  const [ready, setReady] = useState(false)
  const signUrl = async () => {
    const signedFileTokenResponse = await api.signUrl(src)
    setSrc(signedFileTokenResponse.data.url)
    setReady(true)
  }

  useEffect(() => {
    if (isMortaUploadedUrl(src)) {
      signUrl()
    } else {
      setReady(true)
    }
  }, [])

  return (
    <a
      href={ready ? src : '#still-loading'}
      target="_blank"
      rel="noopener noreferrer"
      ref={ref}
      download={blockProps.filename}
    >
      {blockProps.filename}
    </a>
  )
})
