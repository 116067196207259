import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import api from 'helpers/api'
import { useApplicationStore } from 'hooks/application'
import { SIGN_IN } from 'routes'
import Button from 'components/button'
import { APIError } from 'helpers/api'

const SendEmailVerificationCode: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState<string>('')
  const { setSnackbarMessage } = useApplicationStore()
  document.title = 'Morta | Verify Email'

  const handleEmailVerification = async () => {
    try {
      await api.sendEmailVerification(email)
      setEmailSent(true)
    } catch (e) {
      if (e instanceof APIError) {
        setSnackbarMessage({ message: e.message, status: 'error' })
      }
    }
  }

  const emailError = email === ''

  return (
    <div className="bg-really-light-blue flex flex-1 items-center justify-center overflow-hidden box-border h-screen w-full px-8">
      <div
        className="h-fit flex flex-column bg-white border-1px border-solid border-black p-8 heavy-shadow rounded text-primary"
        style={{ width: '550px' }}
      >
        {emailSent ? (
          <div>
            <h1>Verification Email Sent</h1>
            <p>We have sent an email to your account with details on how to verify your email address.</p>
            <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
              Return to <Link to={SIGN_IN}>Sign In</Link>
            </p>
          </div>
        ) : (
          <div>
            <h1 className="mb-12 text-2xl">Email Not Verified?</h1>
            <br />
            <input type="text" placeholder="Your email address" onChange={(event) => setEmail(event.target.value)} />
            {emailError && <p className="text-red">Your email address cannot be blank</p>}
            <Button style={{ marginTop: '20px' }} onClick={() => handleEmailVerification()} disabled={emailError}>
              Send Email Verification
            </Button>
            <p className="border-t-1px border-solid border-grey mt-20px pt-20px">
              Return to <Link to={SIGN_IN}>Sign In</Link>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SendEmailVerificationCode
